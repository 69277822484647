import React from 'react';
import {
    IonCheckbox,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLoading,
    IonPage,
    IonSpinner,
    IonSelect,
    IonSelectOption,
    IonModal,
    withIonLifeCycle
} from '@ionic/react';
import { caretForwardOutline } from "ionicons/icons";
import './offers.sass';
import SummaryCard from "../../shared/components/summary-card/summaryCard";
import {scanOutline} from "ionicons/icons";
import EmptyState from "../../shared/components/empty-state/emptyState";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import moment from "moment";
import {Toast} from "../../shared/components/generic/toast";
import {getOffers} from "../../shared/apiCalls/offerCalls";
import {getFromStorage} from "../../shared/helpers/storage";
import ApiService from "../../shared/services/network-services/api.service";
import {OfferModel} from "./offer.model";
import {SummaryCardModel} from "../../shared/components/summary-card/summaryCard.model";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import SendOfferService from "../../shared/apiCalls/sendOffer.service";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import {getActivities} from "../../shared/apiCalls/customerCalls";
import {CustomerModel} from "../../shared/models/customer.model";

interface IState {
    pageLoading: boolean
    validatingCode: boolean,
    loading: boolean,
    bulkEditMode: boolean,
    offers: OfferModel[],
    myOffersSummaries: SummaryCardModel[],
    customerPhone: string,
    showRedeemerModal: boolean,
    customers: CustomerModel[],
    toast?: {
        toastMessage?: string,
        toastOpen?: boolean,
        toastColor?: string,
    }
}

class Offers extends React.Component<any, IState> {
    state = {
        pageLoading: true,
        loading: false,
        validatingCode: false,
        bulkEditMode: false,
        offers: [{
            _id: {
                $oid: ''
            },
            DESCRIPTION: "",
            END_DATE: {$date: 0},
            START_DATE: {$date: 0},
            TITLE: "",
            STATUS: "",
            MERCHANT_ID: "",
            UPDATED_AT: {$date: 0},
            OFFER_CODE: ''
        }],
        myOffersSummaries: [
            {
                title: "Active Offers",
                value: '0',
                description: "Number of currently active offers",
            }
        ],
        customerPhone: "",
        customers: [] as CustomerModel[],
        showRedeemerModal: false,
        toast: {
            toastMessage: "success",
            toastOpen: false,
            toastColor: "success",
        }
    };

    constructor(props: any) {
        super(props);
        this.validateCode = this.validateCode.bind(this);
    }

    async ionViewWillEnter() {
        await this.fetchOffers();
    }

    /*
    * Callback handler for toggling between the bulk edit and normal view
    * */
    toggleBulkEdit(status: boolean) {
        this.setState({
            bulkEditMode: status
        })
    }

    // TODO use a better life cycle event
    componentDidMount() {
        this.setState({offers: []});
        this.fetchOffers();
        this.fetchCustomersList();
    }

    fetchOffers = async () => {
        try {
            await this.setState({loading: true});
            const offers = await getOffers();
            this.setState({
                pageLoading: false,
                offers,
                myOffersSummaries: [
                    {
                        title: "Active Offers",
                        value: offers.length.toString(),
                        description: "Number of currently active offers",
                    }
                ]
            });
        } catch (e) {
            await this.setState({loading: false, pageLoading: false});
            throw e;
        } finally {
            await this.setState({loading: false, pageLoading: false,});
        }
    };

    async fetchCustomersList() {
        const customers = await getActivities("");
        this.setState({customers});
    }

    // Invokes the offer creation flow
    createOffer = async () => {
        this.props.history.push("/create-offer")
    };

    async validateCode(e: any) {
        this.setState({validatingCode: true});
        e.preventDefault();
        const formData = await new FormData(e.target);
        let jsonObject: any = {};
        formData.forEach(function (value, key) {
            jsonObject[key] = value;
        });
        const merchant: any = await getFromStorage("merchant");

        const apiService = new ApiService();

        return await apiService.getResource(`/validate_offer?offer_code=${jsonObject.code}&merchant_id=${merchant?.id?.$oid}`)
            .then((response: any) => {
                const data = response.data;
                
                // if (data) {
                //     if (data.valid) {
                //         this.setState({
                //             toast: {
                //                 toastOpen: true,
                //                 toastMessage: "Success: Offer code is valid",
                //                 toastColor: "success",
                //             }
                //         }, () => {
                //             const offer: OfferModel = data.offer;
                //             this.clearOfferValidationInput();
                //             // Track event
                //             Amplitude.getInstance().logEvent(EVENTS_TRACKED.VALIDATED_OFFER,
                //                 {merchantDetails: merchant});

                //             this.props.history.push({pathname: '/offer-details', state: {offerID: offer._id.$oid}});

                //         });
                //     } else if (data.message) {
                //         this.setState({
                //             toast: {
                //                 toastOpen: true,
                //                 toastMessage: "Invalid: Offer code is invalid. Try another one.",
                //                 toastColor: "danger"
                //             }
                //         });
                //     } else if(data.inactive) {
                //         this.setState({
                //             toast: {
                //                 toastOpen: true,
                //                 toastMessage: "Invalid: Offer has already been redeemed.",
                //                 toastColor: "danger"
                //             }
                //         });
                //     }
                // }
                // this.setState({validatingCode: false});
            })
            .catch((error: any) => {
                    throw error
                }
            );
    }
    
    redeemOffer = async (e: any) => {
        // this.setState({validatingCode: true});
        e.preventDefault();
        
        const formData = await new FormData(e.target);
        // let jsonObject: any = {};
        // formData.forEach(function (value, key) {
        //     jsonObject[key] = value;
        // });
        const merchant: any = await getFromStorage("merchant");
        let jsonObject: any = UtilityFunctions.jsonFromFormData(formData);
        // const phone = jsonObject.msisdn.substring(1).toString().replace(/\s+/g, '')
        // console.log('this.state', this.state)
        // const phone = this.state.customerPhone
        const postObj = {
            ...jsonObject,
            // msisdn: phone,
            merchant_id: merchant?.id?.$oid
        };
        this.setState({ toast: { toastOpen: false}, validatingCode: true })

        //return false
        return await SendOfferService.redeemOfferCode(postObj)
            .then((response: any) => {
                const data = response.data;
                this.setState({validatingCode: false})
                if (response) {
                    if (response.status==="success") {
                        this.setState({
                            toast: {
                                toastOpen: true,
                                toastMessage: "Success: Offer successfully redeemed for this customer",
                                toastColor: "success",
                            }
                        }, () => {
                            // const offer: OfferModel = data.offer;
                            this.clearOfferValidationInput();
                            // Track event
                            Amplitude.getInstance().logEvent(EVENTS_TRACKED.REDEEMED_OFFER,
                                {merchantDetails: merchant});

                            // this.props.history.push({pathname: '/offer-details', state: {offerID: offer._id.$oid}});
                            this.showRedeemer(false)
                        });
                        this.showRedeemer(false)
                    }else if (response.message==="invalid") {
                        this.setState({
                            toast: {
                                toastOpen: true,
                                toastMessage: "Invalid: Offer code is invalid. Try another one.",
                                toastColor: "danger"
                            }
                        });
                    } else if(response.inactive) {
                        this.setState({
                            toast: {
                                toastOpen: true,
                                toastMessage: "Invalid: Offer has already been redeemed for this customer.",
                                toastColor: "danger"
                            }
                        });
                    }
                }
                this.setState({validatingCode: false});
            })
            .catch((error: any) => {
                    throw error
                }
            );
    }

    clearOfferValidationInput() {
        const validateOfferInput: HTMLInputElement = document.getElementById('validateOfferInput') as HTMLInputElement;
        validateOfferInput.value = '';
    }

    closeToast = () => {
        this.setState({toast: {toastOpen: false}});
    };

    openOfferDetails(e: any, offer: OfferModel) {
        e.preventDefault();
        this.props.history.push({pathname: '/offer-details', state: {offerID: offer._id.$oid}})
    }

    validatePhoneNumber(e?: any) {
        const { customerPhone } = this.state;
        const phoneNumberValidity = isPossiblePhoneNumber(customerPhone) && isValidPhoneNumber(customerPhone);
        if (!phoneNumberValidity) {
            this.setState({
                toast:{
                    toastOpen: true,
                    toastMessage: "Kindly enter a valid phone number",
                    toastColor: "danger",
                }
            });
        }
    }

    onNumberChange = (e: any) => {
        this.setState({ toast: {toastOpen: false }});
        this.setState({ customerPhone: e })
    };

    showRedeemer = (status: boolean) => {
        this.setState({
            showRedeemerModal: status
        })
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                (<ContentLoader/>)
                }
                <IonContent>
                    <IonLoading
                        cssClass=''
                        isOpen={this.state.validatingCode}
                        message={'Validating'}/>

                    <div className="offers">
                        <div className="title-section">
                            <div className="page-details">
                                <h1 className="page-title">Offers</h1>
                            </div>
                        </div>

                        <div className="my-offers-page">
                            <div className="page-section summary no-margin">
                                <div className="section-content -horizontal-scroll">
                                    <div className="horizontal-scroll">
                                        <SummaryCard summaries={this.state.myOffersSummaries}/>
                                    </div>
                                </div>
                            </div>

                            <div className="page-section offers-list">
                                {(this.state.offers?.length > 0) ? 
                                <>
                                    <div className="sub-section">
                                        {/* <p className="sub-section-title">Redeem an offer</p> */}
                                        <div className="sub-section-content btn-section-content">
                                            <button 
                                                type="submit" 
                                                className="ajua-button -medium-button -bold -button-new-offer"
                                                onClick={() => this.createOffer()}>
                                                Create offer &nbsp;
                                                <IonIcon className="-left" icon={caretForwardOutline}/>
                                            </button>
                                            <button 
                                                type="submit" 
                                                className="ajua-button -neutral -medium-button -bold"
                                                onClick={() => this.showRedeemer(true)}>
                                                Redeem offer &nbsp;
                                                <IonIcon className="-left" icon={caretForwardOutline}/>
                                            </button>
                                        </div>
                                    </div>
                                    <br/>

                                    <div className="title">
                                        <p className="section-title">My Offers</p>
                                    </div>
                                </> : ''
                                }

                                <ul className="ajua-list">
                                    {this.state.loading && (<IonSpinner color="black" name="crescent"/>)}
                                    {(this.state.offers?.length < 1 && !this.state.loading) ?
                                        (
                                            <EmptyState icon={'assets/icons/my_offers.svg'}
                                                        title={'No Offers'}
                                                        description={'You can manage offers here. Create one and send to a customer.'}
                                                        actionButtonText={'Create Offer'}
                                                        actionCallback={this.createOffer}/>
                                        ) : this.state.offers.map((offer, index) => {
                                            return (
                                                <li key={index} className="list-item">
                                                    <div
                                                        className={`check-box ${this.state.bulkEditMode ? '' : '-hide'}`}>
                                                        <IonCheckbox checked={false}/>
                                                    </div>
                                                    <div onClick={(e) => this.openOfferDetails(e, offer)}
                                                         className="card">
                                                        <AjuaCard onLongPress={(status) => this.toggleBulkEdit(status)}>
                                                            <div className="offer-details no-text-wrap">
                                                                <p className="name">{offer.TITLE}</p>
                                                                <p className="validity">
                                                                    {moment(offer.START_DATE.$date).format('Do MMM YYYY')}
                                                                    &nbsp;-&nbsp;
                                                                    {moment(offer.END_DATE.$date).format('Do MMM YYYY')}
                                                                </p>
                                                            </div>
                                                            <div className={`offer-value -${offer.STATUS}`}>
                                                                {offer.STATUS}
                                                            </div>
                                                        </AjuaCard>
                                                    </div>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                                <Toast message={this.state.toast?.toastMessage} open={this.state.toast?.toastOpen}
                                       position="bottom"
                                       color={this.state.toast?.toastColor}
                                       closeToast={this.closeToast}/>
                            </div>
                        </div>
                    </div>
                    <IonModal isOpen={this.state.showRedeemerModal} cssClass='redeem-offer-modal' onDidDismiss={() => this.showRedeemer(false)}>
                        <div className="actions-title">
                            <h3 className="modal-title">Redeem an offer:</h3>
                            <button className="btn-close2 ajua-button" onClick={() => this.showRedeemer(false)}>&times;</button>
                        </div>
                        <div className="">
                            <div className="sub-section-content">
                                <form onSubmit={this.redeemOffer} className="validate-offer-code">
                                    <div className="form-group">
                                        <IonItem className="redemption-input-container ion-no-padding">
                                            <IonInput name="offer_code" className="redemption-input" type="text"
                                                        placeholder="Offer code" id="validateOfferInput"
                                                        required/>
                                            <IonIcon icon={scanOutline}/>
                                        </IonItem>
                                    </div>
                                    <div className="form-group">
                                        <IonSelect name="msisdn" className="ajua-select"
                                                    interface="action-sheet"
                                                    value=""
                                                    placeholder="Select customer">
                                            {this.state.customers.map((customer: CustomerModel, index: number) => {
                                                return <IonSelectOption key={index} value={customer.CUSTOMER_ID}><span className="customer-name">{customer?.METADATA?.name || customer.CUSTOMER_ID}</span> <span className="customer-phone">{customer?.METADATA?.name ? ` - (${customer.CUSTOMER_ID})` : ''}</span></IonSelectOption>
                                            })}
                                        </IonSelect>
                                    {/* </IonItem> */}
                                    </div>
                                    <button type="submit" className="ajua-button -neutral -full-width -medium-button -bold">
                                        Redeem
                                    </button>
                                </form>
                            </div>
                        </div>
                    </IonModal>
                </IonContent>
            </IonPage>
        );
    }
}

export default withIonLifeCycle(Offers);

import React from "react";
import "./userProfile.sass"
import {
    IonContent,
    IonHeader,
    IonInput,
    IonLoading,
    IonPage, IonIcon, IonSelect,
    IonSelectOption, withIonLifeCycle
} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import * as yup from "yup";
import {ErrorMessage, Field, FieldProps, Form, Formik} from 'formik';
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {Toast} from "../../shared/components/generic/toast";
import {INDUSTRIES} from "../../shared/helpers/data.list";
import UserProfileService from "./userProfile.service";
import {getMerchant} from "../../shared/apiCalls/customerCalls";
import MerchantProvider from "../../shared/services/providers/merchant.provider";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import {createOutline} from 'ionicons/icons';
import {Link} from "react-router-dom";

interface ProfileSchema {
    turnover?: string,
    number_of_staff?: string,
    email_address?: string,
    industry?: string
}

interface IState {
    toastMessage: string,
    toastOpen: boolean,
    toastColor: string,
    loading: boolean,
    updatingCustomer: boolean,
    merchant: MerchantModel
}

class UserProfile extends React.Component<any, IState> {
    private userProfileService = new UserProfileService();

    state = {
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        loading: true,
        updatingCustomer: false,
        merchant: {} as MerchantModel
    };

    async componentDidMount() {
        await this.fetchUpdatedProfile();
    }

    async ionViewWillLeave() {
        // Update the offline merchant profile
        await MerchantProvider.refreshOfflineMerchantProfile();
    }

    async fetchUpdatedProfile() {
        // Get updated merchant Profile
        const merchantID = (await getFromStorage(STORAGE_FIELDS.MERCHANT)).id?.$oid;
        const merchant = await getMerchant(merchantID);
        await saveToStorage(STORAGE_FIELDS.MERCHANT, merchant);
        this.setState({merchant, loading: false});
    }

    // Merchant registration form schema
    private profileValidationSchema = yup.object().shape({
        turnover: yup.string(),
        number_of_staff: yup.string(),
        email_address: yup.string().nullable(),
        industry_name: yup.string(),
    });


    async updateProfile(values: ProfileSchema, resetForm: any) {
        this.setState({loading: true}, async () => {
            const merchantUpdates = {...values, merchant_id: this.state?.merchant?.id?.$oid};

            this.userProfileService.updateMerchantDetails(merchantUpdates).then(async (resp: any) => {
                if (resp.status === 200) {
                    let updatedMerchant = {...this.state.merchant, ...merchantUpdates};

                    // Updates the object with the merchant email address in the collaborators field
                    if (updatedMerchant.collaborators) {
                        updatedMerchant.collaborators[0].email_address = values.email_address || '';
                        delete updatedMerchant['email_address']; // Remove email appended to object root
                        updatedMerchant.ajua_account_details.industry = values.industry || '';
                        delete updatedMerchant['industry']; // Remove industry appended to object root
                    }

                    this.setState({merchant: updatedMerchant}, async () => {
                        await saveToStorage(STORAGE_FIELDS.MERCHANT, updatedMerchant);
                        this.setState({
                            toastOpen: true,
                            toastMessage: "Successfully updated profile.",
                            toastColor: "success",
                            loading: false
                        });

                        // Track event
                        Amplitude.getInstance().logEvent(EVENTS_TRACKED.UPDATE_PROFILE,
                            {merchantDetails: updatedMerchant});
                    });
                } else {
                    this.setState({
                        toastOpen: true,
                        toastMessage: "Error: Failed to update profile.",
                        toastColor: "danger",
                        loading: false
                    });
                }
            }).catch((error => {
                console.log('Error: ', error);
                this.setState({
                    toastOpen: true,
                    toastMessage: "Error: Failed to update profile.",
                    toastColor: "danger",
                    loading: false
                })
            }))
        });
    }

    closeToast = () => {
        this.setState({toastOpen: false});
    };

    render() {

        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Business Profile"}}/>
                </IonHeader>
                <IonContent>
                    <div className="user-profile">
                        <IonLoading
                            cssClass=''
                            isOpen={this.state.loading}
                            message={'Loading'}/>
                        <div className="page-section general-details">
                            <div className="section-content">
                                
                                    <div className="stats">
                                        <AjuaCard className="stat-entry">
                                            <p className="stat-title">CUSTOMER RATING</p>
                                            {
                                                this.state.merchant?.ajua_account_details?.surveys?.ratings_surveys?.average_customer_rating ?
                                                <>
                                                    <p className="stat-value -fraction">
                                                        {this.state.merchant?.ajua_account_details?.surveys?.ratings_surveys?.average_customer_rating || 0}
                                                        <span className="overall-rating">/5</span>
                                                    </p>
                                                    <p className="footer-label">Monthly average</p>
                                                </>
                                                : <p className="footer-label mt-1"><em>Not rated yet</em></p>
                                            }
                                            
                                        </AjuaCard>
                                    </div>
                            </div>
                        </div>
                        <div className="page-section general-details">
                            {/* <div className="title">
                                <p className="section-title">Account Details</p>
                            </div> */}
                            <div className="section-content">
                                {
                                    this.state.merchant ? (
                                        <AjuaCard>
                                            <div className="read-only-details flexed-div">
                                                <div>
                                                    <label className="ajua-form-label">Business Name</label>
                                                    <p className="entry-value">{this.state.merchant?.business_name}</p>
                                                </div>
                                                <div>
                                                    {/* <button className="btn-close2 ajua-button" onClick={() => this.showAppChooser(false)}>&times;</button> */}
                                                    <Link to={'/edit-profile'} className="ajua-button no-decoration -neutral-text -small-button btn-edit-profile">
                                                        <IonIcon icon={createOutline} className="-left"/> Edit
                                                    </Link>
                                                </div>
                                                
                                            </div>
                                            <div className="read-only-details">
                                                <label className="ajua-form-label">Business description</label>
                                                <p className="entry-value">{this.state.merchant?.business_description}</p>
                                            </div>
                                            <div className="read-only-details">
                                                <label className="ajua-form-label">Industry</label>
                                                <p className="entry-value">{this.state.merchant?.ajua_account_details?.industry || 'Not Provided'}</p>
                                            </div>
                                            <div className="read-only-details">
                                                <label className="ajua-form-label">Number of employees</label>
                                                <p className="entry-value">{this.state.merchant?.number_of_staff || 'Not Provided'}</p>
                                            </div>
                                            <div className="read-only-details">
                                                <label className="ajua-form-label">State</label>
                                                <p className="entry-value">{this.state.merchant.ajua_account_details?.city || this.state.merchant.ajua_account_details?.state}</p>
                                            </div>
                                            {
                                                this.state.merchant.collaborators ? (
                                                    <>
                                                        <div className="read-only-details">
                                                            <label className="ajua-form-label">Full name</label>
                                                            <p className="entry-value">{this.state.merchant?.collaborators[0]?.first_name} {this.state.merchant?.collaborators[0]?.last_name}</p>
                                                        </div>
                                                        <div className="read-only-details">
                                                            <label className="ajua-form-label">Phone number</label>
                                                            <p className="entry-value">+{this.state.merchant.collaborators[0].msisdn}</p>
                                                        </div>
                                                        <div className="read-only-details">
                                                            <label className="ajua-form-label">Email</label>
                                                            <p className="entry-value">{this.state.merchant?.collaborators[0]?.email_address || 'Not Provided'}</p>
                                                        </div>                                                        
                                                    </>
                                                ) : ''
                                            }


                                        </AjuaCard>
                                    ) : 'null'
                                }
                            </div>
                        </div>
                        
                        
                        {/* <div className="page-section extra-details">
                            <div className="title">
                                <p className="section-title">Extra Information</p>
                            </div>

                            <div className="section-content">
                                <Formik
                                    initialValues={{
                                        turnover: this.state.merchant?.turnover,
                                        number_of_staff: this.state.merchant?.number_of_staff,
                                        email_address: this.state.merchant?.collaborators ? this.state.merchant?.collaborators[0]?.email_address : '',
                                        industry: this.state.merchant.ajua_account_details?.industry
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={this.profileValidationSchema}
                                    onSubmit={(values, {resetForm}) => this.updateProfile(values, resetForm)}>

                                    {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                        <Form>
                                            <Field type="text" name="email_address">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            What is your email address?</p>
                                                        <IonInput type="email" className="ajua-input"
                                                                  {...fieldProps.field}
                                                                  placeholder="e.g bobo@gmail.com"
                                                                  onIonChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  name="email_address"/>
                                                        <ErrorMessage name="email_address">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field type="text" name="number_of_staff">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            How many employees do you have?</p>
                                                        <IonInput type="number" inputmode="numeric"
                                                                  className="ajua-input"
                                                                  {...fieldProps.field}
                                                                  placeholder="e.g 10"
                                                                  onIonChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  name="number_of_staff"/>
                                                        <ErrorMessage name="number_of_staff">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field type="text" name="turnover">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            How much money does your business bring in a
                                                            month?</p>
                                                        <IonInput type="number" className="ajua-input"
                                                                  placeholder="e.g ₦ 20,000"
                                                                  onIonChange={handleChange}
                                                                  {...fieldProps.field}
                                                                  onBlur={handleBlur}
                                                                  name="turnover"/>
                                                        <ErrorMessage name="turnover">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            {}

                                            <Field type="text" name="industry">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            What industry best describes your business?</p>
                                                        <IonSelect {...fieldProps.field}
                                                                   name="industry"
                                                                   onIonChange={handleChange}
                                                                   onBlur={handleBlur}
                                                                   interface="action-sheet"
                                                                   className="ajua-select"
                                                                   placeholder="Select Industry">
                                                            {INDUSTRIES.map((industry, index) => {
                                                                return <IonSelectOption key={index}
                                                                                        value={industry}>{industry}</IonSelectOption>
                                                            })}
                                                        </IonSelect>
                                                        <ErrorMessage name="industry">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <button type="submit" className="ajua-button -success -full-width"
                                                    disabled={!(isValid && dirty)}>
                                                UPDATE DETAILS
                                            </button>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div> */}

                        
                    </div>
                    {/* <Toast message={this.state.toastMessage} open={this.state.toastOpen} position="bottom"
                               color={this.state.toastColor}
                               closeToast={this.closeToast}/> */}
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(UserProfile);

import React from "react";
import {IonContent, IonHeader, IonIcon, IonPage, IonSpinner, IonModal, isPlatform, withIonLifeCycle} from "@ionic/react";
import "./offerDetails.sass";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import moment from "moment";
import {OfferModel} from "../offers/offer.model";
import OfferDetailsProvider from "./offerDetails.provider";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import ActivityCard from "../../shared/components/activity-card/ActivityCard";
import EmptyState from "../../shared/components/empty-state/emptyState";
import {send} from "ionicons/icons";
import FloatingActionBar from "../../shared/components/floating-action-bar/floatingActionBar";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import { ToastNotificationModel } from "../../shared/models/toast-notification.model";
import * as Amplitude from "amplitude-js";
import { EVENTS_TRACKED } from "../../shared/helpers/events-tracked";
import { Plugins } from "@capacitor/core";
import { MerchantModel } from "../../shared/models/merchant.model";
import { randomText } from "../../shared/helpers/utility-functions";
import SendOfferService from "../../shared/apiCalls/sendOffer.service";
import { AppAvailability } from '@ionic-native/app-availability';
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { SocialSharing } from "@ionic-native/social-sharing";

interface OffersProps {
    location: any;
    history?: any;
}

interface IState {
    merchant?: MerchantModel,
    loading: boolean,
    loadingOfferEvents: boolean,
    offerID: string,
    bulkEditMode: boolean,
    offerDetails: OfferModel,
    redemptionEvents: {
        ACTIVITY_ID: string,
        CUSTOMER_ID: string,
        TIMESTAMP: { $date: number },
        METADATA: {}
    }[],
    toastOptions: ToastNotificationModel,
    showAppChooserModal: boolean,
    offerCode: string
}

enum APP_PACKAGES {
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    TWITTER = 'twitter',
    WHATSAPP = 'whatsapp'
}

class OfferDetails extends React.Component<OffersProps, IState> {
    public offerDetailsProvider: OfferDetailsProvider = new OfferDetailsProvider();

    constructor(props: any) {
        super(props);
        this.state = {
            merchant: {} as MerchantModel,
            loading: true,
            loadingOfferEvents: true,
            bulkEditMode: false,
            offerID: this.props.location.state.offerID,
            offerDetails: {
                _id: {$oid: ''},
                MERCHANT_ID: '',
                TITLE: '',
                DESCRIPTION: '',
                STATUS: '',
                START_DATE: {$date: 0},
                END_DATE: {$date: 0},
                UPDATED_AT: {$date: 0},
                OFFER_CODE: ''
            },
            redemptionEvents: [{
                ACTIVITY_ID: "",
                CUSTOMER_ID: "",
                TIMESTAMP: {$date: 0},
                METADATA: {}
            }],
            toastOptions: {
                toastMessage: "success",
                toastOpen: false,
                toastColor: "success",
            },
            showAppChooserModal: false,
            offerCode: ""
        };
    }

    async componentDidMount() {
        await this.getOfferDetails();
        await this.getOfferEvents();

        const merchant: any = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({ merchant });
    }

    /*
    * Fetch all details about this offer
    * */
    async getOfferDetails() {
        const merchant: any = await getFromStorage(STORAGE_FIELDS.MERCHANT);

        await this.offerDetailsProvider.fetchOfferDetails(this.state.offerID, merchant?.id?.$oid).then((offerDetails: OfferModel) => {
            this.setState({
                offerDetails,
                loading: false
            })
        })
    }

    async getOfferEvents() {
        await this.offerDetailsProvider.getCustomerActivities().then((redemptionEvents: any) => {
            this.setState({redemptionEvents, loadingOfferEvents: false});
        })
    }

    async toggleOfferActivation(newStatus: string) {
        switch (newStatus) {
            case 'ACTIVE':
                await this.offerDetailsProvider.updateOfferStatus(true, this.state.offerID);
                this.setState({offerDetails: {...this.state.offerDetails, STATUS: 'ACTIVE'}});
                break;
            case 'INACTIVE':
                await this.offerDetailsProvider.updateOfferStatus(false, this.state.offerID);
                this.setState({offerDetails: {...this.state.offerDetails, STATUS: 'ACTIVE'}});
                break;
        }
    }

    offerStatus(status: string) {
        switch (status) {
            case 'ACTIVE':
                return (
                    <button className="ajua-button -small-button -success">ACTIVE</button>
                );
            case 'INACTIVE':
                return (
                    <button className="ajua-button -small-button -warning">IN-ACTIVE</button>
                );
        }
    }

    showAppChooser = (status: boolean) => {
        this.setState({
            showAppChooserModal: status
        })
    }

    sendOfferToSMS() {
        this.showAppChooser(false)
        this.props.history.push({
            pathname: "/send-offer",
            state: { offerID: this.state.offerID }
        })
    }
    
    async sendOfferToApp(appPackage: string) {
        if(!isPlatform('android')) {
            return false
        }
        // const { App } = Plugins;
        this.showAppChooser(false)
        // const merchant: any = await getFromStorage(STORAGE_FIELDS.MERCHANT)
        // const offer: any = await SendOfferService.getOfferCode(merchant.id.$oid, this.state.offerID)
        // let packageID: string = ''
        // let appUrl: string = ''
        // const msgToShare = `You have received an offer from us at ${this.state ?.merchant ?.business_name}. Present the offer code below on your next visit to redeem offer ${this.state.offerDetails?.TITLE}. Offer code: ${offer.offer.code}`
        const msgToShare = `Hello, you have received an offer, ${this.state.offerDetails?.TITLE}, from ${this.state ?.merchant ?.business_name}. Get ${this.state.offerDetails?.DESCRIPTION} by presenting  code ${this.state.offerDetails?.OFFER_CODE} on your next visit before ` + moment(this.state.offerDetails?.END_DATE.$date).format('Do MMM YYYY')  || 'Not Set'
        await this.checkPackage(appPackage, msgToShare)
        // await this.checkPackage(appPackage).then(async (response: any) => {
        //     console.log('5: ')
        //     const { packageID } = response
        //     console.log('packageID: ', packageID)
        //     if(packageID !== 'none') {
        //         await App.canOpenUrl({ url: packageID })
        //         await App.openUrl({ url: packageID })
        //         // InAppBrowser.create(appUrl, '_system')
        //     }
        // })
        
    }

    async checkPackage<T>(appPackage: string, msgToShare: string) {
        switch(appPackage) {
            case APP_PACKAGES.WHATSAPP:
                // packageID = this.checkAppAvailability('com.whatsapp') || this.checkAppAvailability('com.whatsapp.w4b') || false
                SocialSharing.shareViaWhatsApp(msgToShare)
            break;
            case APP_PACKAGES.FACEBOOK:
                // packageID = this.checkAppAvailability('com.facebook.katana') || this.checkAppAvailability('com.facebook.lite') || false
                SocialSharing.shareViaFacebook(msgToShare)
            break;
            case APP_PACKAGES.TWITTER:
                // packageID = this.checkAppAvailability('com.twitter.android') || this.checkAppAvailability('com.twitter.android.lite') || false
                SocialSharing.shareViaTwitter(msgToShare)
            break;
            case APP_PACKAGES.INSTAGRAM:
                // packageID = this.checkAppAvailability('com.instagram.android') || this.checkAppAvailability('com.instagram.lite') || false
                // SocialSharing.shareViaInstagram(msgToShare)
            break;
        }
    }

    async checkAppAvailability<T>(packageID: string) {
        //https://github.com/ohh2ahh/AppAvailability
        const { App } = Plugins;
        await AppAvailability.check(packageID)
        .then((isAvailable: boolean) => {
                if(isAvailable) {
                    App.openUrl({ url: packageID })
                    return packageID
                } 
                return false               
            }
          )
    }

    async sendOfferToOtherApps() {
        this.showAppChooser(false)
        const { Share } = Plugins;
        const merchant: any = await getFromStorage("merchant");
        const offer: any = await SendOfferService.getOfferCode(merchant.id.$oid, this.state.offerID);
        // const offer_code = this.getOfferCode();
        this.setState({ offerCode: offer });
        const shareSheetOptions = {
            text: `You have received an offer from us at ${this.state ?.merchant ?.business_name}. Present the offer code below on your next visit to redeem offer ${this.state.offerDetails?.TITLE}. Offer code: ${offer.offer.code}`,
            title: `${this.state ?.merchant ?.business_name}`,
            dialogTitle: 'Share',
            url: ''
        };
        
        Share.share(shareSheetOptions).then().catch(e => {
            this.setState({
                toastOptions: {
                    toastMessage: "Failed to share details.",
                    toastOpen: true,
                    toastColor: "danger",
                },
            });
        });


        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SEND_OFFER,
            { merchantDetails: this.state.merchant });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: this.state.offerDetails.TITLE}}/>
                </IonHeader>
                <IonContent>
                    <div className="offer-details-page">
                        <FloatingActionBar>
                            <div className="action-bar-content">
                                {/* <button onClick={() => this.sendOfferToSMS()}
                                        className="ajua-button -neutral -semi-bold -floating-action">
                                    Send Offer <IonIcon icon={send}/>
                                </button> */}
                                <button onClick={() => this.showAppChooser(true)}
                                        className="ajua-button -neutral -semi-bold -floating-action">
                                    Send Offer <IonIcon icon={send}/>
                                </button>
                            </div>
                        </FloatingActionBar>

                        {/* <IonLoading
                            cssClass='my-custom-class'
                            isOpen={this.state.loading}
                            message={'Loading'}/> */}

                        <div className="page-section validity">
                            <div className="ajua-card">
                                <div className="validity-details">
                                    <p className="title"><strong>Validity:</strong></p>
                                    <p>{moment(this.state.offerDetails?.START_DATE?.$date).format('D/MM/YYYY')} - {moment(this.state.offerDetails?.END_DATE?.$date).format('D/MM/YYYY')}</p>
                                </div>
                                {
                                    this.offerStatus(this.state.offerDetails?.STATUS)
                                }
                            </div>
                        </div>

                        <div className="page-section redeemed-offers">
                            <div className="title">
                                <p className="section-title">Offer Details</p>
                            </div>

                            <div className="section-content mt-1">
                                {this.state.loadingOfferEvents &&
                                (<IonSpinner color="black" name="crescent"/>)
                                }
                                {
                                    this.state.redemptionEvents.length > 0 ? (
                                        this.state.redemptionEvents.map((event, index) => {
                                            return <ActivityCard key={index} activity={event}/>
                                        })
                                    ) : <EmptyState title={'No Details'}
                                                    description={'No customer has redeemed an offer yet. Try sharing it to more of your customers.'}
                                                    icon={'assets/icons/cactus.svg'}/>

                                }
                            </div>
                        </div>
                    </div>
                    <IonModal isOpen={this.state.showAppChooserModal} cssClass='offer-details-modal' onDidDismiss={() => this.showAppChooser(false)}>
                        <div className="actions-title">
                            <h3 className="modal-title">Send offer via:</h3>
                            <button className="btn-close2 ajua-button" onClick={() => this.showAppChooser(false)}>&times;</button>
                        </div>
                        <div className="actions-grid">
                            <div className="actions-item ajua-card" onClick={() => this.sendOfferToSMS()}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_sms.svg'} alt=""/>
                                </div>
                                <div className="item-title">SMS</div>
                            </div>
                            <div className="actions-item ajua-card" onClick={() => this.sendOfferToApp(APP_PACKAGES.WHATSAPP)}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_wa.svg'} alt=""/>
                                </div>
                                <div className="item-title">WhatsApp</div>
                            </div>
                            <div className="actions-item ajua-card" onClick={() => this.sendOfferToApp(APP_PACKAGES.INSTAGRAM)}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_ig.svg'} alt=""/>
                                </div>
                                <div className="item-title">Instagram</div>
                            </div>
                            <div className="actions-item ajua-card" onClick={() => this.sendOfferToApp(APP_PACKAGES.TWITTER)}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_twitter.svg'} alt=""/>
                                </div>
                                <div className="item-title">Twitter</div>
                            </div>
                            <div className="actions-item ajua-card" onClick={() => this.sendOfferToApp(APP_PACKAGES.FACEBOOK)}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_fb.svg'} alt=""/>
                                </div>
                                <div className="item-title">Facebook</div>
                            </div>
                            <div className="actions-item ajua-card" onClick={() => this.sendOfferToOtherApps()}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_other_apps.svg'} alt=""/>
                                </div>
                                <div className="item-title">Other Apps</div>
                            </div>
                        </div>
                    </IonModal>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(OfferDetails);

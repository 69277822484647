import ApiService from "../../shared/services/network-services/api.service";
import { URL_ENDPOINTS } from "../../shared/url-endpoints";
import { DebtEntryModel } from "./debt.model";
import Customer from "../customer/customer";
import environment from "../../environments/environment";

interface DebtReminderModel {
    customer_id: string,
    response_status_code: number,
    data: any
}

export interface CustomerDebtProfileModel {
    total_debt: number,
    total_credit: number,
    ledger_balance: number,
    customer_id: string
}

export interface LedgerEntryModel {
  reference: string,
  credit: number,
  debit: number,
  due_date: string,
  notes: string,
  customer_id: string,
  _id: string,
  created_at: string,
  phone_number: string,
  name:string 
}

class DebtProvider {
    private ledgerBaseUrl = { baseUrl: environment.debtBaseUrl };

    private apiService = new ApiService();
    private apiResponseSize = 100;

    logDebt(postObj: any): Promise<{ _id: string }> {
        const url = URL_ENDPOINTS.ledgerUrl;

        return this.apiService.createResource(postObj, url, this.ledgerBaseUrl).then((response: any) => {
            if (response.data._id) {
                return response ?.data;
            } else {
                throw new Error();
            }
        }).catch(error => {
            throw error
        }
        );
    }

    payDebt(merchantID: string, postObj: any): Promise<any> {
        const url = `${URL_ENDPOINTS.ledgerUrl}?merchant_id=${merchantID}`;

        return this.apiService.createResource(postObj, url, this.ledgerBaseUrl).then((response: any) => {
            return response ?.data;
        }).catch(error => {
            throw error
        }
        );
    }

    getMerchantOwedDebt(merchantID: string): Promise<DebtEntryModel[]> {
        const url = `${URL_ENDPOINTS.merchantLedgerEntriesUrl}?merchant_id=${merchantID}&page_size=${this.apiResponseSize}&page_num=${1}`;

        return this.apiService.getResource(url, this.ledgerBaseUrl).then((response: any) => {
            return response ?.data;
        }).catch(error => {
            throw error
        });
    }

    getCustomerDebt(customerID: string): Promise<CustomerDebtProfileModel> {
        const url = `${URL_ENDPOINTS.ledgerUrl}/${customerID}/balance`;

        return this.apiService.getResource(url, this.ledgerBaseUrl).then((response: { data: CustomerDebtProfileModel }) => {
            return response ?.data;
        }).catch(error => {
            throw error
        }
        );
    }

    getDebtLedgerEntry(entryID: string): Promise<LedgerEntryModel> {
        const url = `${URL_ENDPOINTS.ledgerUrl}/${entryID}`;

        return this.apiService.getResource(url, this.ledgerBaseUrl).then((response: { data: LedgerEntryModel }) => {
            return response ?.data;
        }).catch(error => {
            throw error
        }
        );
    }

    updateDebt(entryID: string, putObj: any): Promise<{ _id: string }> {
        const url = `${URL_ENDPOINTS.ledgerUrl}/${entryID}`;

        return this.apiService.updateResourcePut(putObj, url, this.ledgerBaseUrl).then((response: any) => {
            if (response.data._id) {
                return response ?.data;
            } else {
                throw new Error();
            }
        }).catch(error => {
            throw error
        }
        );
    }

    sendDebtReminder(merchantID: string, customerID: string): Promise<DebtReminderModel> {
        const url = `${URL_ENDPOINTS.debtReminderUrl}`;
        const postObj = {
            customer_id: customerID
        };
        return this.apiService.createResource(postObj, url, this.ledgerBaseUrl).then((response: { data: DebtReminderModel }) => {
            return response.data;
        }).catch(error => {
            throw error
        }
        );
    }
}

export default DebtProvider

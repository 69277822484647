export const URL_ENDPOINTS = {
    merchantsUrl: '/merchants',
    createMerchant: '/create_merchant',
    updateMerchant: '/merchant',
    updateMerchantPayment: '/set-merchant-payments',
    createAdUrl: '/ad',
    customerProfileUrl: '/customers',
    customerActivityUrl: '/customer-activity',
    
    //Offers
    offersUrl: '/offers',
    bulkOffersUrl: '/send_offers',
    validateOfferUrl: '/validate_offer?offer_code=',
    supplierOffersFeedbackUrl: '/user-feedback',
    fetchOfferCode: '/get_offer_code',
    redeemOffer: '/redeem_offer_code',
    
    referralsUrl: '/referrals',
    paymentHookUrl: '/payment-hook',
    
    //debt
    ledgerUrl: '/api/v1/ledger',
    merchantLedgerEntriesUrl: '/api/v1/ledger_entries/merchant',
    customerLedgerEntriesUrl: '/api/v1/ledger_entries/customer',
    debtReminderUrl: '/api/v1/ledger/debt_reminder',

    //Ads
    submitAd: '/post_advert',
    getAds: '/get_adverts',

    //Transaction history
    transactionHistory: '/transaction_history',
};

export default URL_ENDPOINTS;

export const NEW_ENDPOINTS = {
    getOTP: '/generate_otp',
    validateOTP: '/validate_otp',

    //WhatsApp OTP
    getWhatsAppOTP: '/requestwhatsapp_otp', 
}

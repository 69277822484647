import React from "react";
import {AjuaToolbarProps} from "./ajuaToolbar.model";
import "./ajuaToolbar.sass";
import {IonIcon} from "@ionic/react";
import {chevronBackOutline} from 'ionicons/icons';
import {useHistory} from "react-router";

const AjuaToolbar: React.FC<AjuaToolbarProps> = (props: AjuaToolbarProps) => {
    const history = useHistory();
    const backButtonPressed = () => {
        if (props.backButtonRoute) {
            history.push(props.backButtonRoute);
        } else {
            history.goBack()
        }
    };

    return (
        <div className="ajua-toolbar">
            <IonIcon onClick={() => backButtonPressed()} icon={chevronBackOutline}/>
            <h1 className="title">{props.toolbar.title}</h1>
        </div>
    )
};

export default AjuaToolbar

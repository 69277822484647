import ApiService from "../../shared/services/network-services/api.service";
import { URL_ENDPOINTS } from "../../shared/url-endpoints"

class SendOfferService {

    static async sendOffer(postObj: any): Promise<any> {
        let apiService = new ApiService();
        return apiService.createResource(postObj, '/send_offer')
    }

    static async sendBulkOffer(postObj: any): Promise<any> {
        let apiService = new ApiService();
        return apiService.createResource(postObj, URL_ENDPOINTS.bulkOffersUrl)
    }

    static async getOfferCode(merchant_id: string, offer_id:string): Promise<any> {
        let apiService = new ApiService();
        // return apiService.getResource(postObj, URL_ENDPOINTS.bulkOffersUrl)
        const url = `${URL_ENDPOINTS.fetchOfferCode}?merchant_id=${merchant_id}&offer_id=${offer_id}`;
        return await apiService.getResource(url)
        .then(offerCode => {
            return offerCode?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
    }

    static async redeemOfferCode(postObj: any): Promise<any> {
        let apiService = new ApiService();
        // return apiService.createResource(postObj, '/send_offer')
        const url = URL_ENDPOINTS.redeemOffer
        return await apiService.createResource(postObj, url).then((response: any) => {
            if (response.data) {
                return response ?.data;
            } else {
                throw new Error();
            }
        }).catch(error => {
            throw error
        }
        );
    }
}

export default SendOfferService;

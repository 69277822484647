import React from "react";
import "./login.sass";
import {IonContent, IonIcon, IonPage, IonSpinner, IonSlides, IonSlide, withIonLifeCycle} from "@ionic/react";
import 'react-phone-number-input/style.css';
import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber
} from 'react-phone-number-input';
import { Link } from "react-router-dom";
import { getAuthOTP, getAuthOTPForWhatsApp } from "../../shared/apiCalls/authentication";
import { Toast } from "../../shared/components/generic/toast";
import { logoWhatsapp } from "ionicons/icons";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import { SocialSharing } from "@ionic-native/social-sharing";

class Login extends React.Component<any, any> {
    state = {
        pageLoading: true,
        phoneNumber: '',
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        requestingPin: false,
        sendPinButtonDisabled: false
    };

    async ionViewWillEnter(){
        await this.loggedInStatus();
    }

    async loggedInStatus() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        if (merchant) {
            this.setState({pageLoading: false});
            this.props.history.replace('/main/pulse');
        }
        this.setState({pageLoading: false});
    }

    closeToast = () => {
        this.setState({toastOpen: false});
    };

    sendPhoneNumber = async (e?: any) => {
        e?.preventDefault();

        const {phoneNumber} = this.state;
        const {history} = this.props;
        const phoneNumberValidity = isPossiblePhoneNumber(phoneNumber) && isValidPhoneNumber(phoneNumber);
        if (phoneNumberValidity) {
            this.setState({requestingPin: true, sendPinButtonDisabled: true});

            // remove the + from the number
            const slicedPhoneNumber = phoneNumber.substr(1);
            await getAuthOTP(slicedPhoneNumber)
                .then(resp => {
                    this.setState({requestingPin: false, sendPinButtonDisabled: false, phoneNumber: ''});
                    history.push({
                        pathname: '/otp',
                        state: {phoneNumber: phoneNumber}
                    });
                })
                .catch(error => {
                    this.setState({toastOpen: false, toastMessage: "", toastColor: ""})
                    this.setState({
                        toastOpen: true,
                        toastMessage: "The phone number entered is not tied to an existing user. Kindly register first.",
                        toastColor: "danger",
                        requestingPin: false,
                        sendPinButtonDisabled: false
                    });
                });
        } else {
            this.setState({
                toastOpen: true,
                toastMessage: "Kindly enter a valid phone number",
                toastColor: "danger",
                requestingPin: false
            });
        }
    };

    async loginWithWhatsApp() { 
        const { history } = this.props;
        const { phoneNumber } = this.state;
        await getAuthOTPForWhatsApp()
            .then(resp => {
                this.setState({requestingPin: false, sendPinButtonDisabled: false, phoneNumber: ''});

                //Redirect user to the OTP page
                history.push({
                    pathname: '/otp',
                    state: {phoneNumber: phoneNumber}
                });

                //Let OTP be handled by Janja
                SocialSharing.shareViaWhatsAppToReceiver('+19362510616', 'otp') 
            })
            .catch(error => {
                this.setState({toastOpen: false, toastMessage: "", toastColor: ""})
                this.setState({
                    toastOpen: true,
                    toastMessage: error.toString(),
                    toastColor: "danger",
                    requestingPin: false,
                    sendPinButtonDisabled: false
                });
            });
    }

    onChange = (e: any) => {
        this.setState({phoneNumber: e});
    };

    goBack() {
        this.props.history.push('/welcome-screen');
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                (<ContentLoader/>)
                }
                <IonContent>
                    <div className="ajua-auth-page login-page">
                        {/* <div className="auth-hero-image">
                            <div className="tint"/>
                        </div> */}

                        <div className="auth-form">
                            {/* <div onClick={() => this.goBack()} className="back-button">
                                <IonIcon icon={arrowBackOutline}/>
                            </div> */}

                            <div className="form-content">
                                <div className="auth-title">
                                    <div className="logo -branded">
                                        <img src={'assets/images/mtn_logo.png'}/>
                                    </div>
                                    <div className="description">
                                        <p className="app-title">MTN EnGauge</p>
                                        {/* <p className="page-title">Welcome Back,</p> */}
                                    </div>
                                </div>
                                <div className="description"> 
                                    <div className="description-text">
                                        <IonSlides id="welcome-sliders" pager={true}>
                                            <IonSlide>
                                                <div className="feature-preview">
                                                    <div className="feature-image">
                                                        <img src={'assets/images/img_splash_1.png'}/>
                                                    </div>
                                                    <div className="feature-details">
                                                        <p>Start accepting money from your customers in real time </p>
                                                    </div>
                                                </div>
                                            </IonSlide>

                                            <IonSlide>
                                                <div className="feature-preview">
                                                    <div className="feature-image">
                                                        <img src={'assets/images/img_splash_2.png'}/>
                                                    </div>
                                                    <div className="feature-details">
                                                        <p>Create and send special offers to your customers directly</p>
                                                    </div>
                                                </div>
                                            </IonSlide>

                                            <IonSlide>
                                                <div className="feature-preview">
                                                    <div className="feature-image">
                                                        <img src={'assets/images/img_splash_3.png'}/>
                                                    </div>
                                                    <div className="feature-details">
                                                        <p>Get feedback directly and instantly from your customers</p>
                                                    </div>
                                                </div>
                                            </IonSlide>
                                        </IonSlides>
                                        
                                    </div>
                                </div>
                                <div className="action-btns">
                                    <p className="sub-title -small">Sign-in to start managing your business and interacting with your customers</p>
                                    <form onSubmit={(e) => this.sendPhoneNumber(e)} className="form">
                                        <div className="form-group">
                                            <PhoneInput
                                                className="ajua-input -large"
                                                placeholder="Enter phone number"
                                                value={this.state.phoneNumber}
                                                type="tel"
                                                autoComplete="on"
                                                displayInitialValueAsLocalNumber
                                                inputMode="tel"
                                                defaultCountry='NG'
                                                countries={['NG']}
                                                onChange={this.onChange}
                                                required/>
                                        </div>

                                        <button type="submit" className="ajua-button -full-width -neutral"
                                                disabled={this.state.sendPinButtonDisabled}>{!this.state.requestingPin ?
                                            (<>Send PIN</>) :
                                            (<IonSpinner color="white" name="crescent"/>)}
                                        </button>
                                    
                                        
                                        {/*<p className="auth-hint">Having problems? <a href="#">Get help</a></p>*/}
                                    </form>
                                    <div className="or-divider">
                                        <hr />
                                        <p>Or</p>
                                        <hr />
                                    </div>
                                    <button type="button" className="ajua-button -full-width -success" onClick={() => this.loginWithWhatsApp()}><IonIcon icon={logoWhatsapp} className="-left"/> Login with WhatsApp</button>
                                    <div className="link-create-account"><Link to="register-merchant">Create account</Link></div>
                                </div>
                            </div>
                        </div>
                        <Toast 
                            message={this.state.toastMessage} 
                            open={this.state.toastOpen} 
                            position="bottom" 
                            color={this.state.toastColor}
                            closeToast={this.closeToast}/>
                    </div>
                    
                </IonContent>
            </IonPage>
        );
    }
}

export default withIonLifeCycle(Login);

import React from "react";
import "./feedbackPage.sass";
import {IonContent, IonHeader, IonPage} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import FeedbackCard from "../../shared/components/feedback-card/feedbackCard";
import pulseData from "../pulse/pulse.data";

class FeedbackPage extends React.Component {
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Feedback"}}/>
                </IonHeader>
                <IonContent>
                    <div className="page-section feedback-breakdown">
                        <div className="section-content">
                            <div className="ajua-feature-card">
                                <div className="background-image">
                                    <img src="assets/images/abstract_1.jpg"/>
                                </div>
                                <div className="content">
                                    <p className="content-title">All Interactions</p>
                                    <p className="content-value">37</p>
                                </div>
                            </div>
                            <div className="summaries">
                                <div className="ajua-summary-card">
                                    <p className="summary-title">This Week</p>
                                    <p className="summary-value">1</p>
                                </div>
                                <div className="ajua-summary-card">
                                    <p className="summary-title">This Month</p>
                                    <p className="summary-value">2</p>
                                </div>
                            </div>
                        </div>

                        <div className="sub-section feedback-history">
                            <p className="sub-section-title">Feedback History</p>
                            <div className="section-content">
                                {
                                    pulseData.feedback.map((feedback, index) => {
                                        return <FeedbackCard key={index} feedback={feedback}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default FeedbackPage;

import React from "react";
import "./manage-passcode.sass";
import {IonContent, IonHeader, IonIcon, IonPage, IonToggle, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../../shared/components/ajua-toolbar/ajuaToolbar";
import AjuaCard from "../../../shared/components/ajua-card/ajua-card";
import {lockClosedOutline} from 'ionicons/icons';
import {SecureStorage, SecureStorageObject} from '@ionic-native/secure-storage';
import {getFromStorage, SECURE_STORAGE_FIELDS} from "../../../shared/helpers/storage";

interface IState {
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string,
    }
}

class ManagePasscode extends React.Component<any, IState> {

    async ionViewWillEnter() {
        // const passCodeStorage: SecureStorageObject = await SecureStorage.create(SECURE_STORAGE_FIELDS.STORAGE_NAME);
        // if (passCodeStorage) {
        //     passCodeStorage.get(SECURE_STORAGE_FIELDS.PASSCODE)
        //         .then((data: string) => {
        //             console.log('Found Passcode')
        //         }, (error: Error) => {
        //             console.log('Passcode not set. Rerouting...');
        //             this.props.history.replace({pathname: '/set-passcode'});
        //         });
        // } else {
        //     this.props.history.replace({pathname: '/set-passcode'});
        //     console.log('No Passcode');
        // }

        const passcode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
        if (!passcode) {
            // console.log('Passcode not set. Rerouting...');
            this.setPasscode();
        }

    }

    setPasscode() {
        this.props.history.replace({pathname: '/set-passcode'});
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Privacy & Security"}}/>
                </IonHeader>
                <IonContent>

                    <div className="manage-passcode">
                        <AjuaCard>
                            <div className="passcode-toggle">
                                <div className="content">
                                    <IonIcon icon={lockClosedOutline} className=""/>
                                    <p>PIN Enabled</p>
                                </div>
                                <IonToggle checked={true} disabled={true} color="success"/>
                            </div>
                        </AjuaCard>

                        <button onClick={() => this.setPasscode()} className="ajua-button -full-width remove-passcode">
                            Change PIN
                        </button>
                    </div>

                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(ManagePasscode);

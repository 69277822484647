import React from "react";
import "./home.sass";
import { IonContent, IonModal, IonIcon, IonSlides, IonSlide, IonPage, withIonLifeCycle } from "@ionic/react";
import BusinessCode from "../../shared/components/business-code/businessCode";
import { Link } from "react-router-dom";
import { MerchantModel } from "../../shared/models/merchant.model";
import { getFromStorage, saveToStorage, STORAGE_FIELDS, SECURE_STORAGE_FIELDS } from "../../shared/helpers/storage";
import { Plugins } from "@capacitor/core";
import { Toast } from "../../shared/components/generic/toast";
import { ToastNotificationModel } from "../../shared/models/toast-notification.model";
import * as Amplitude from "amplitude-js";
import { EVENTS_TRACKED } from "../../shared/helpers/events-tracked";
import { ACTIVITY_IDS } from "../../shared";
import {
    personAdd,
    megaphone,
    pricetag
} from "ionicons/icons";

// Progress Bar Component
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { IProfileMilestones } from "../../App";
import { getActivities } from "../../shared/apiCalls/customerCalls";
import { getOffers } from "../../shared/apiCalls/offerCalls";
// import { Clipboard } from '@ionic-native/clipboard'

export enum MilestoneStepStates {
    NOT_STARTED = "not_started",
    CURRENT = "current",
    ERROR = "error",
    COMPLETED = "completed"
}

interface IState {
    merchant?: MerchantModel,
    toastOptions: ToastNotificationModel,
    profileMilestoneStates: IProfileMilestones | undefined,
    profileMilestoneCompleted: boolean,
    profileMilestones: {
        state: any,
        name: string,
        label: string,
        content: any
    }[],
    showTourGuideModal: boolean
    sliderButtons: {
        prev: string,
        next: string,
        prevDisabled: boolean,
        nextDisabled: boolean
    }
}

class Home extends React.Component<any, IState> {

    state: IState = {
        merchant: {} as MerchantModel,
        toastOptions: {
            toastMessage: "success",
            toastOpen: false,
            toastColor: "success",
        },
        profileMilestoneStates: undefined,
        profileMilestoneCompleted: false,
        profileMilestones: [],
        showTourGuideModal: false,
        sliderButtons: {
            prev: 'Start',
            next: 'Next',
            prevDisabled: true,
            nextDisabled: false
        }
    };

    private slidesRef = React.createRef<any>();
    private slideFirst = React.createRef<any>();
    private slideLast = React.createRef<any>();

    async ionViewWillEnter() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({ merchant })
        await this.refreshMilestoneWidget()
        await this.checkIfViewedGuides()
    }

    async ionViewDidEnter() {
        // await this.refreshMilestoneWidget()
    }

    async refreshMilestoneWidget() {
        const now = Math.floor(Date.now()/1000)
        const nextVisitIsAt = await getFromStorage(STORAGE_FIELDS.NEXT_TIME_TO_VIEW_APP_MILESTONES_STATES)

        if(nextVisitIsAt) {
            if(Number(nextVisitIsAt) > Number(now)) {
                this.setState({ profileMilestoneCompleted: true });
                return false
            } 
        }
        
        
        const customers = await getActivities("&activity_id=0");
        // const offers = await getOffers();
        const currentProfileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        const paymentInfo = await getFromStorage(STORAGE_FIELDS.MERCHANT_PAYMENT_INFO);
       
        const profileMilestones: IProfileMilestones = {
            ...currentProfileMilestones,
            addBusinessDetails: !!merchant?.business_description,
            addPaymentInfo: !!paymentInfo?.account_number,
            addCustomer: customers.length > 0
        };

        await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, profileMilestones);

        await this.updateMilestoneCompletionStatus();

        const defaultProfileMilestoneStatus = [
            {
                state: profileMilestones.addBusinessDetails ? MilestoneStepStates.COMPLETED : MilestoneStepStates.NOT_STARTED,
                name: "add business details",
                label: "Add Business Details",
                content: <div className="widget-content">
                    <h1>Add business details</h1>
                    <p className="content-description">
                        Add your business details to help customers identify your brand.
                    </p>

                    <div className="widget-content-actions">
                        <button onClick={() => {
                            this.props.history.push({ pathname: '/edit-profile' })
                        }} className="ajua-button -small-button -bold -success">
                            <IonIcon className="-left" icon={personAdd} /> Edit profile
                        </button>
                        <button onClick={() => this.dismissTillNextLogin()}
                                className="-neutral -small-button maybe-later">
                                Maybe later
                        </button>
                    </div>
                </div>
            },
            {
                state: profileMilestones.addPaymentInfo ? MilestoneStepStates.COMPLETED : MilestoneStepStates.NOT_STARTED,
                name: "add payment info",
                label: "Add Payment Info",
                content: <div className="widget-content">
                    <h1>Add payment information</h1>
                    <p className="content-description">
                        Insert your bank details to link your business short code with your bank account.
                    </p>

                    <div className="widget-content-actions">
                        <button onClick={() => this.props.history.push({ pathname: '/track-payments' })} className="ajua-button -small-button -success">
                            <IonIcon className="-left" icon={pricetag} /> Add payment info
                        </button>
                        <button onClick={() => this.dismissTillNextLogin()}
                                className="-neutral -small-button maybe-later">
                                Maybe later
                        </button>
                    </div>
                </div>
            },
            {
                state: profileMilestones.addCustomer ? MilestoneStepStates.COMPLETED : MilestoneStepStates.NOT_STARTED,
                name: "add customer",
                label: "Add customer",
                content: <div className="widget-content">
                    <h1>Add customers</h1>
                    <p className="content-description">
                        Have your customer database in one place and start EnGauging with them.
                    </p>

                    <div className="widget-content-actions">
                        <button onClick={() => {
                            this.props.history.push({ pathname: '/select-customer-import-method' })
                        }} className="ajua-button -small-button -success">
                            <IonIcon className="-left" icon={personAdd} /> Add customers
                        </button>
                        <button onClick={() => this.dismissTillNextLogin()}
                                className="-neutral -small-button maybe-later">
                                Maybe later
                        </button>
                    </div>
                </div>
            },
        ];
        this.setState({ profileMilestones: defaultProfileMilestoneStatus }, () => {
            this.updateMilestoneCompletionStatus();
        }); 
    }

    get milestoneWidgetStartingStep() {
        const { profileMilestones } = this.state;
        if (profileMilestones.length > 0) {
            const found = profileMilestones.findIndex((milestone: any) => {
                return milestone.state === MilestoneStepStates.NOT_STARTED
            });
            return found
        } else {
            return 0
        }
    }

    async updateMilestoneCompletionStatus() {
        const profileMilestoneStates = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);
        const milestoneStatesEntries = Object.entries(profileMilestoneStates);
        if (profileMilestoneStates) {
            for (let i = 0; i < milestoneStatesEntries.length; i++) {
                if (!milestoneStatesEntries[i][1]) {
                    this.setState({ profileMilestoneCompleted: false });
                    break;
                }
                this.setState({ profileMilestoneCompleted: true });
            }
        }
    }

    async openShareSheet() {
        const { Share } = Plugins;
        const shareSheetOptions = {
            //text: `Welcome to ${this.state ?.merchant ?.business_name}. Dial ${this.state ?.merchant ?.ussd_string} and be the first in line for offers and discounts.`,
            text: `Hey there, we are growing. We now have an exciting and easy way for you to engage with us at ${this.state?.merchant?.business_name}. Just dial ${this.state?.merchant?.ussd_string} to give us feedback, pay for goods, and register for offers and discounts.`,
            title: `${this.state ?.merchant ?.business_name}`,
            dialogTitle: 'Share',
            url: ''
        };
        let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);

        Share.share(shareSheetOptions).then(async (resp) => {
            // profileMilestones.advertise = true;
            // await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, profileMilestones);
            // this.refreshMilestoneWidget();
        }).catch(e => {
            this.setState({
                toastOptions: {
                    toastMessage: "Failed to share details.",
                    toastOpen: true,
                    toastColor: "danger",
                },
            });
            this.refreshMilestoneWidget();
        });


        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SHARE_AD,
            { merchantDetails: this.state.merchant });
    }

    openDeals() {
        // Track event
        Amplitude.getInstance().logEvent(EVENTS_TRACKED.CLICKED_DEALS,
            { merchantDetails: this.state.merchant });
        this.props.history.push({ pathname: "/deals" });
    }

    viewPublications() {
        // Track event
        Amplitude.getInstance().logEvent(EVENTS_TRACKED.CLICKED_NEWSPAPER,
            { merchantDetails: this.state.merchant });

        this.props.history.push({ pathname: '/coming-soon' })
    }

    async handleTrackPaymentRoute() {
        const paymentInfo: any = await getFromStorage(STORAGE_FIELDS.MERCHANT_PAYMENT_INFO);
        if (paymentInfo && paymentInfo != 'undefined') {
            this.props.history.push({
                pathname: "/main/pulse",
                state: { filterBy: ACTIVITY_IDS.NEW_CUSTOMER_PAYMENT }
            });
        } else {
            this.props.history.push({ pathname: "/track-payments" });
        }
    }

    closeToast = () => {
        this.setState({ toastOptions: { ...this.state.toastOptions, toastOpen: false } });
    }

    showTourGuide = (status: boolean) => {
        this.setState({
            showTourGuideModal: status
        })
        if(status===false) {
            saveToStorage(STORAGE_FIELDS.VIEWED_FEATURE_GUIDES, 'viewed');
        }
    }

    async checkIfViewedGuides() {
        const passcode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
        // if (!passcode)
        await getFromStorage(STORAGE_FIELDS.VIEWED_FEATURE_GUIDES).then( data => {
            if(data !== 'viewed' && passcode) {
                Amplitude.getInstance().logEvent(EVENTS_TRACKED.VIEWED_TOUR_GUIDE,
                    { merchantDetails: this.state.merchant });
                this.setState({
                    showTourGuideModal: true
                })
            }
        });
    }

    slideToPrev = async () =>{
        let swiper: any = await this.slidesRef.current.getSwiper()
        let btnTextPrev = swiper.isBeginning ? 'Start' : 'Back'
        let prevDisabled = swiper.isBeginning ? true : false
        swiper.slidePrev(300)
        this.setState({
            sliderButtons:{
                ...this.state.sliderButtons,
                prev: btnTextPrev, 
                next:'Next',
                prevDisabled,
                nextDisabled: false
            }
        })
    }

    slideToNext = async () => {
        let swiper: any = await this.slidesRef.current.getSwiper()
        let btnTextPrev = swiper.isEnd ? 'End' : 'Next'
        let nextDisabled = swiper.isEnd ? true : false
        swiper.slideNext(300)
        this.setState({
            sliderButtons:{
                ...this.state.sliderButtons,
                prev: 'Back', 
                next:btnTextPrev,
                nextDisabled,
                prevDisabled: false
            }
        })
    }

    /**
     * Disable the milestones for 24 hours
     */
    async dismissTillNextLogin() {
        const now = Math.floor(Date.now()/1000)
        const nextVisitIsAt = (60 * 60 * 24 ) + now
        this.setState({ profileMilestoneCompleted: true });
        await saveToStorage(STORAGE_FIELDS.NEXT_TIME_TO_VIEW_APP_MILESTONES_STATES, nextVisitIsAt);
    }

    render() {
        const { profileMilestones, profileMilestoneCompleted } = this.state;

        return (
            <IonPage>
                <IonContent>
                    <div className="quick-actions home-page">
                        <div className="home-title-section mb-0">

                            <div className="home-title-top">
                                <div className="title-heading"><h1>MTN EnGauge</h1></div>
                                <div className="title-settings">
                                    <Link to={{ pathname: '/settings' }} className="no-decoration store-avatar-wrapper">
                                        <div className="settings">
                                            <img src={'assets/icons/ic_menu_96.png'} alt="none" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="home-title-section-details">
                                {/* <div className="store-avatar">
                                    profile
                                </div> */}
                                <div className="store-details">
                                    <div className="business-name">
                                        
                                        <h3>{this.state ?.merchant ?.business_name || ""}</h3>
                                    </div>
                                    <p className="business-short-code">Your unique business short code</p>
                                    <BusinessCode code={this.state ?.merchant ?.ussd_string || ""} />
                                </div>
                            </div>
                        </div>

                        <div className="page-section home-page-section">
                            <div className="section-content">
                                {
                                    profileMilestones.length > 0 && !profileMilestoneCompleted ?
                                        <div className="profile-milestones">
                                            <div className="background-overlay" />
                                            <div className="milestone-widget-header">
                                                <h1 className="profile-milestones-title">What's next? </h1>
                                            </div>
                                            <StepProgressBar
                                                startingStep={this.milestoneWidgetStartingStep}
                                                wrapperClass={'milestone-widget-wrapper'}
                                                // labelClass={'milestone-label'}
                                                progressClass={'milestone-progress'}
                                                contentClass={'milestone-content'}
                                                stepClass={'step-indicator'}
                                                buttonWrapperClass={'milestone-widget-actions'}
                                                steps={profileMilestones ? profileMilestones : []} />
                                        </div> : ''
                                }
                                <div className="actions-grid">

                                    <div className="quick-action-card">
                                        <div className="action-details">
                                            <div className="action-icon">
                                                <img onClick={() => this.openShareSheet()} src={'assets/icons/ic_promotions.svg'} alt="none" />
                                            </div>
                                            <div className="action-details-description">
                                                <p className="action-title">Promote your business</p>
                                                <p className="action-description">
                                                    Advertise your business to get more customers on SMS or social media
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="call-to-actions">
                                            <button onClick={() => this.openShareSheet()}
                                                className="ajua-button -xs-button -neutral -full-width -home-button">
                                                Adverts
                                            </button>
                                        </div> */}
                                    </div>

                                    <div className="quick-action-card">
                                        <div className="action-details">
                                            <div className="action-icon">
                                            <Link to="/main/customers" className="no-decoration"><img src={'assets/icons/ic_engagement.svg'} alt="none" /></Link>
                                            </div>
                                            <div className="action-details-description">
                                                <p className="action-title">Engage your customers</p>
                                                <p className="action-description">
                                                    Build a stronger bond with your customers with feedback &amp; rating over SMS
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="call-to-actions">
                                            <Link to="/main/customers" className="no-decoration">
                                                <button className="ajua-button -xs-button -neutral -full-width -home-button">
                                                Feedback
                                                </button>
                                            </Link>
                                        </div> */}
                                    </div>
                                    
                                    <div className="quick-action-card">
                                        <div className="action-details">
                                            <div className="action-icon">
                                                <Link to="/create-offer" className="no-decoration"><img src={'assets/icons/ic_offers.svg'} alt="none" /></Link>
                                            </div>
                                            <div className="action-details-description">
                                                <p className="action-title">Create personalised offers</p>
                                                <p className="action-description">
                                                    Reward your customers with personalised discounts and offers
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="call-to-actions">
                                            <Link to="/create-offer" className="no-decoration">
                                                <button className="ajua-button -xs-button -neutral -full-width -home-button">
                                                    Promos
                                                </button>
                                            </Link>
                                        </div> */}
                                    </div>
                                    
                                    <div className="quick-action-card">
                                        <div className="action-details">
                                            <div className="action-icon">
                                                <img onClick={() => this.handleTrackPaymentRoute()} src={"assets/icons/ic_payments.svg"} alt="none" />
                                            </div>
                                            <div className="action-details-description">
                                                <p className="action-title">Track payments</p>
                                                <p className="action-description">
                                                    Get paid via USSD and follow the status of your payments in realtime
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="call-to-actions">
                                            <button onClick={() => this.handleTrackPaymentRoute()} className="ajua-button -xs-button -neutral -full-width -home-button">
                                                Payments
											</button>
                                        </div> */}
                                    </div>
                                    

                                    

                                    

                                    {/* <div className="quick-action-card">
                                        <div className="action-details">
                                            <div className="action-icon">
                                                <img src={'assets/icons/sale.svg'}/>
                                            </div>
                                            <p className="action-title">Deals</p>
                                            <p className="action-description">
                                                Get deals from manufacturers and suppliers
                                            </p>
                                        </div>

                                        <div className="call-to-actions">
                                            <button onClick={() => {
                                                this.openDeals()
                                            }} className="ajua-button -xs-button -neutral -full-width">
                                                View Deals
                                            </button>
                                        </div>
                                    </div> */}

                                    
                                    {/*<div className="quick-action-card">*/}
                                    {/*    <div className="action-details">*/}
                                    {/*        <div className="action-icon">*/}
                                    {/*            <img src={'assets/icons/newspaper.svg'}/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="action-title">Newspapers</p>*/}
                                    {/*        <p className="action-description">*/}
                                    {/*            Get upto date with the latest news and stories.*/}
                                    {/*        </p>*/}
                                    {/*    </div>*/}

                                    {/*    <div className="call-to-actions">*/}
                                    {/*        <button onClick={() => this.viewPublications()}*/}
                                    {/*                className="ajua-button -xs-button -neutral -full-width">*/}
                                    {/*            Start Reading*/}
                                    {/*        </button>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                        </div>
                            
                        <IonModal isOpen={this.state.showTourGuideModal}  cssClass='tour-guide-modal' onDidDismiss={() => this.showTourGuide(false)}>
                            <div className="actions-title">
                                <h3 className="modal-title">&nbsp;</h3>
                                <button className="btn-close2 ajua-button" onClick={() => this.showTourGuide(false)}>&times;</button>
                            </div>
                            <div className="">
                                <IonSlides id="welcome-sliders" ref={this.slidesRef}>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_welcome.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Welcome! </p>
                                                <p>Ready to EnGauge? </p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_add_customers.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>First, add customers from your phone contacts or manually to engauge your customers (MTN Only).</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_bank_details.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Add your bank details to allow you to receive payments via the USSD code directly into your bank account.</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_short_code.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Send free 150 SMS notifications to your customers.</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_track_payments.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Get quick updates on your recent payments, feedback and redeemed offers here.</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_send_offers.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Send and redeem customized offers through free 150 SMS or on social media platforms.</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_checklist_outline.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Keep an eye of your debtors by listing them and sharing SMS reminders on due payments.</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                    <IonSlide>
                                        <div className="feature-preview">
                                            <div className="feature-image">
                                                <img src={'assets/icons/tour_startup_outline.svg'} alt="none"/>
                                            </div>
                                            <div className="feature-details">
                                                <p>Now you can get started!</p>
                                                <p>Contact LetsTalk.NG@mtn.com for help.</p>
                                            </div>
                                        </div>
                                    </IonSlide>
                                </IonSlides>
                            </div>
                            <div className="sliders-nav-actions">
                                <button ref={this.slideFirst} disabled={this.state.sliderButtons.prevDisabled} className="ajua-button -small-button btn-prev" onClick={() => this.slideToPrev()}>{this.state.sliderButtons.prev}</button>
                                <button ref={this.slideLast} disabled={this.state.sliderButtons.nextDisabled} className="ajua-button -small-button btn-next" onClick={() => this.slideToNext()}>{this.state.sliderButtons.next}</button>
                            </div>
                        </IonModal>
                        <Toast message={this.state.toastOptions.toastMessage} open={this.state.toastOptions.toastOpen}
                            position="bottom" color={this.state.toastOptions.toastColor}
                            closeToast={this.closeToast} />
                        
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Home)

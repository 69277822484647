import React from "react";
import "./manualCustomerEntry.sass";
import {
    IonCheckbox,
    IonContent, IonHeader,
    IonIcon,
    IonInput,
    IonPage,
    IonSpinner,
    IonTextarea,
    IonToast,
    IonModal, IonButton,
    withIonLifeCycle,
} from "@ionic/react";
import CustomerActivityTypes from "../../../shared/helpers/CustomerActivityTypes";
import { Toast } from "../../../shared/components/generic/toast";
import { createActivity, sendSurvey } from "../../../shared/apiCalls/customerCalls";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { getFromStorage, saveToStorage, STORAGE_FIELDS } from "../../../shared/helpers/storage";
import { MerchantModel } from "../../../shared/models/merchant.model";
import {
    warningOutline, informationCircle
} from "ionicons/icons";
import { Link } from "react-router-dom";
import * as Amplitude from "amplitude-js";
import { EVENTS_TRACKED } from "../../../shared/helpers/events-tracked";
import AjuaToolbar from "../../../shared/components/ajua-toolbar/ajuaToolbar";
import { MilestoneStepStates } from "../../home/home";
import { IProfileMilestones } from "../../../App";
import { CustomerModel } from "../../../shared/models/customer.model";
import { getActivities } from "../../../shared/apiCalls/customerCalls";
import { STATIC_LIST_OF_CARRIER_PREFIXES } from "../../../shared/helpers/data.list";


class ManualCustomerEntry extends React.Component<any, any> {
    state = {
        addingCustomer: false,
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        customerObject: {
            metadata: {
                name: "",
                notes: "",
            },
            msisdn: "",
            welcome_msg: true
        },
        customers: [] as CustomerModel[],
        merchant: {} as MerchantModel,
        showPhoneCodeInfoModal: false
    };

    private list_of_carriers_codes = STATIC_LIST_OF_CARRIER_PREFIXES;

    async ionViewDidEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({ merchant });

        if (!merchant) {
            this.setState({
                toastMessage: "danger",
                toastOpen: true,
                toastColor: "Unable to fetch account details. Try again.",
                pageLoading: false
            })
        }
        // await this.getCustomers();

    }

    componentDidMount() {
        this.setState({ customers: [] }, () => {
            this.getCustomers();
        });
    }

    showPhoneCodeInfo = (status: boolean) => {
        this.setState({
          showPhoneCodeInfoModal: false
        })
        this.setState({
          showPhoneCodeInfoModal: status
        })
    }

    getCustomers = async () => {
        try {
            const customers = await getActivities("&activity_id=0");
            this.setState({ customers });
        } catch (e) {
            // this.setState({ loading: false });
        }
    };


    closeToast = () => {
        this.setState({ toastOpen: false });
    };


    onSendMessageChanged = (e: any) => {
        const { customerObject } = this.state;
        this.setState({ customerObject: { ...customerObject, welcome_msg: e.detail.checked } })
    };

    onNumberChange = (e: any) => {
        const { customerObject } = this.state;
        this.setState({ toastOpen: false });
        this.setState({ customerObject: { ...customerObject, msisdn: e } })
    };

    onTextInputChange = (name: string, e: any) => {
        const { customerObject } = this.state;
        this.setState({
            customerObject: {
                ...customerObject,
                metadata: { ...customerObject.metadata, [name]: e.detail.value! }
            }
        })
    };

    /**
     * Add new customer data
     * @param e
     * @return {Promise<void>}
     */
    addCustomer = async (e: any) => {
        e.preventDefault();
        this.setState({
            toastOpen: false
        });
        const { customerObject } = this.state;
        const existPhoneNumbers = this.state.customers.map(c => {
            return c.CUSTOMER_ID
        })
        if (isPossiblePhoneNumber(customerObject.msisdn) && isValidPhoneNumber(customerObject.msisdn)) {
            const foundIndex = existPhoneNumbers.findIndex(p => p.trim() === customerObject.msisdn.substring(1).toString().trim()); //remove the leading "+"
            if (foundIndex !== -1) {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Phone number already registered. Try a different one.",
                    toastColor: "danger"
                });
                return;
            }
            // const pref = 0 + String(customerObject.msisdn.slice(0, -7).toString().trim()).replace(/^\+[0-9]{1,3}(|\-)/, "") //remove the country code if it exists
            // const isOnlyMtnCode = this.list_of_carriers_codes.findIndex(p => p.trim() === pref);
            // if(isOnlyMtnCode === -1) {
            //     this.setState({
            //         toastOpen: true,
            //         toastMessage: "You customer can only add an MTN phone number",
            //         toastColor: "danger"
            //     });
            //     return;
            // }
            this.setState({ addingCustomer: true });
            try {
                const merchant: MerchantModel = await getFromStorage("merchant");
                const requestObject = {
                    ...customerObject,
                    "activity_id": CustomerActivityTypes.addCustomer,
                    "merchant_id": merchant ?.id ?.$oid
                };
                await createActivity(requestObject)
                    .then(async (message) => {
                        await this.updateProfileState();
                        this.setState({
                            toastOpen: true,
                            toastMessage: "success",
                            toastColor: "success",
                            customerObject: {
                                metadata: {
                                    name: "",
                                    note: "",
                                },
                                msisdn: "",
                            }
                        }, async () => {
                            if (merchant.ajua_account_details.subscription_status && customerObject.welcome_msg) {
                                await this.sendFeedbackSurvey(customerObject, merchant);
                            }

                            // Track event
                            Amplitude.getInstance().logEvent(EVENTS_TRACKED.ADD_CUSTOMER,
                                { merchantDetails: merchant });

                            this.props.history.push('/main/customers');
                        });

                    })
                    .catch(
                        error => {
                            this.setState({
                                toastOpen: true,
                                toastMessage: error || "request failed",
                                toastColor: "danger"
                            });
                        }
                    );

            } catch (e) {
                // catch global
                console.log(e);
            } finally {
                this.setState({
                    addingCustomer: false,
                });
            }
        } else {
            this.setState({
                toastOpen: true,
                toastMessage: "Kindly enter a valid phone number",
                toastColor: "danger"
            });
        }
    };

    /*
    * Sends the customer a feedback survey
    * */
    async sendFeedbackSurvey(customerObject: any, merchant: MerchantModel) {
        const sendSurveyObject = {
            "phone_number": customerObject.msisdn.substr(1),
            "merchant_id": merchant ?.id ?.$oid
        };

        if (customerObject.welcome_msg) {
            await sendSurvey(sendSurveyObject)
                .then(message => {
                    this.setState({
                        toastOpen: true,
                        toastMessage: "success",
                        toastColor: "success",
                        customerObject: {
                            metadata: {
                                name: "",
                                note: "",
                            },
                            msisdn: "",
                            welcome_msg: true
                        }
                    }, () => {
                        // Track event
                        Amplitude.getInstance().logEvent(EVENTS_TRACKED.REQUEST_FEEDBACK,
                            { merchantDetails: merchant });
                        this.props.history.push('/main/customers');
                    });
                })
                .catch(
                    error => {
                        this.setState({
                            toastOpen: true,
                            toastMessage: error || "request failed",
                            toastColor: "danger"
                        });
                    }
                );
        }
    }

    validatePhoneNumber(e?: any) {
        const { customerObject } = this.state;
        const phoneNumberValidity = isPossiblePhoneNumber(customerObject.msisdn) && isValidPhoneNumber(customerObject.msisdn);
        if (!phoneNumberValidity) {
            this.setState({
                toastOpen: true,
                toastMessage: "Kindly enter a valid phone number",
                toastColor: "danger",
            });
        }
    }

    async updateProfileState() {
        let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);
        profileMilestones.addCustomer = true;

        await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, profileMilestones);
    }

    render() {
        const { addingCustomer, toastMessage, toastOpen, toastColor, customerObject, merchant } = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{ title: "Add Customer" }} />
                </IonHeader>
                <IonToast
                    isOpen={this.state.toastOpen}
                    message={this.state.toastMessage}
                    color={this.state.toastColor}
                    duration={4000}
                />
                <IonContent>
                    <div className="manual-customer-entry">
                        <div className="page-section">
                            <div className="section-content">

                                <div className="customer-details-form no-margin">
                                    <form onSubmit={this.addCustomer}>

                                        <div className="form-group">
                                            <p className="ajua-form-label">Enter your customer's
                                                name 
                                                {/* <small>(optional)</small> */}
                                            </p>
                                            <IonInput className="ajua-input" placeholder="Enter Full Name"
                                                onIonChange={e => this.onTextInputChange("name", e)}
                                                value={customerObject.metadata.name} />
                                        </div>

                                        <div className="form-group">
                                            <p className="ajua-form-label">Enter your customer's phone number</p>
                                            <PhoneInput
                                                onBlur={(e) => this.validatePhoneNumber(e)}
                                                placeholder="Enter phone number"
                                                value={customerObject.msisdn}
                                                type="tel"
                                                autoComplete="on"
                                                displayInitialValueAsLocalNumber
                                                inputMode="tel"
                                                countries={['NG']}
                                                className="ajua-input"
                                                defaultCountry='NG'
                                                onChange={this.onNumberChange}
                                                required />
                                                <p className="code-prefix-modal-trigger" onClick={() => this.showPhoneCodeInfo(true)}><IonIcon icon={informationCircle} className="code-prefix-modal-trigger-icon"/> Only MTN phone numbers allowed</p>
                                        </div>

                                        <div className="form-group">
                                            <p className="ajua-form-label">Type anything you want to remember about this
                                                customer</p>
                                            <IonTextarea className="ajua-textarea" value={customerObject.metadata.notes}
                                                onIonChange={e => this.onTextInputChange("notes", e)}>
                                            </IonTextarea>

                                            {
                                                merchant ?.ajua_account_details ?.subscription_status ? (
                                                    <>
                                                        <div className="ajua-checkbox check-box mb-0">
                                                            <IonCheckbox onIonChange={this.onSendMessageChanged}
                                                                checked={customerObject.welcome_msg} />
                                                            Request feedback
                                                        </div>

                                                        <p className="hint mt-1">
                                                            Feedback will appear on your Pulse page when the customer
                                                            submits
                                                            their
                                                            feedback.
                                                        </p>
                                                    </>
                                                ) : (
                                                        <div>
                                                            <p className="hint mt-1"><strong><IonIcon
                                                                icon={warningOutline} /> Request for customer
                                                                    feedback</strong> is
                                                                    only available to paying subscribers. Change your
                                                                    subscription
                                                            in <Link to={'/payments-subscriptions'}>settings</Link>.</p>
                                                        </div>
                                                    )
                                            }

                                        </div>

                                        <button type="submit" className="ajua-button -success -full-width">
                                            {!addingCustomer ?
                                                (<span>Add Customer</span>) :
                                                (<IonSpinner color="white" name="crescent" />)}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Toast message={toastMessage} open={toastOpen} position="bottom" color={toastColor}
                        closeToast={this.closeToast} />
                    
                    <IonModal isOpen={this.state.showPhoneCodeInfoModal} cssClass='code-prefix-custom-class'>
                        <button className="btn-close ajua-button" onClick={() => this.showPhoneCodeInfo(false)}>&times;</button>
                        <p>MTN EnGauge App regulations require that you can only send SMS to customers with MTN phone numbers.</p>
                    </IonModal>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(ManualCustomerEntry);

class StyleHelper {

    static capitalize(text: string): string {
        return text.toLowerCase()
            .split(' ')
            .map(function (word) {
                return word[0]?.toUpperCase() + word.substr(1);
            })
            .join(' ')
    }
}

export default StyleHelper

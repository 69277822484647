import React from "react";
import {IonContent, IonHeader, IonIcon, IonInput, IonPage, withIonLifeCycle} from "@ionic/react";
import "./newspapers.sass";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {NewspaperModel} from "./newspaper.model";
import {
    searchOutline,
    funnelOutline
} from "ionicons/icons";

import {DUMMY_NEWSPAPERS} from "./dummyNewspapers.data";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";

interface IState {
    pageLoading: boolean,
    newsPaperList: NewspaperModel[],
    merchant?: MerchantModel
}

class Newspapers extends React.Component<any, any> {
    state = {
        pageLoading: true,
        newsPaperList: DUMMY_NEWSPAPERS,
        merchant: {} as MerchantModel
    };

    componentDidMount(): void {
        this.fetchPublications();
    }

    async ionViewWillEnter() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        await this.setState({merchant});
    }

    /*
    * TODO: Implement fetching of publications from the API when available
    * */
    fetchPublications() {
        this.setState({pageLoading: false});
    }

    openSubscriptionSettings() {
        this.props.history.push('/payments-subscriptions')
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                (<ContentLoader/>)
                }

                <div className="content-container">

                    {
                        !this.state?.merchant?.ajua_account_details?.subscription_status ? (
                            <div className="content-overlay">
                                <div className="subscribe-to-view">
                                    <p className='title-text'>Subscribe To View</p>
                                    <p className="description-text">Access to the newspaper catalogue is limited to only
                                        active subscribers.</p>
                                    <button onClick={() => this.openSubscriptionSettings()}
                                            className="ajua-button -purple -medium-button">Start Subscription
                                    </button>
                                </div>
                            </div>
                        ) : ''
                    }

                    <>
                        <IonHeader>
                            <AjuaToolbar toolbar={{title: 'All Newspapers'}}/>
                        </IonHeader>
                        <IonContent>
                            <div className="all-newspapers">

                                <div className="filter-options">
                                    <div className="ajua-search-component">
                                        <IonInput type="text" className="ajua-search-input"
                                                  placeholder="Search by title, date, publication"
                                                  name="search_newspapers"/>
                                        <IonIcon icon={searchOutline}/>
                                    </div>

                                    <AjuaCard>
                                        <div className="more-filter-options">
                                            <IonIcon icon={funnelOutline}/>
                                        </div>
                                    </AjuaCard>
                                </div>

                                <div className="newspaper-list">
                                    {
                                        this.state.newsPaperList.map((newspaper: NewspaperModel, index) => {
                                            return (
                                                <div key={index} className="newspaper-preview">
                                                    <div className="newspaper-thumbnail">
                                                        <img src={newspaper.preview_thumbnail}/>
                                                    </div>

                                                    <div className="newspaper-details">
                                                        <p className="newspaper-name">{newspaper.publication_name}</p>
                                                        <p className="issue-date">{newspaper.issue_date}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </IonContent>
                    </>
                </div>

            </IonPage>
        )
    }
}

export default withIonLifeCycle(Newspapers);

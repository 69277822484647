///<reference path="../../../typings.d.ts" />

import React from "react";
import "./merchant-registration.sass";
import * as yup from 'yup';
import {
    IonCheckbox,
    IonContent,
    IonHeader,
    IonInput, IonLoading,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea
} from "@ionic/react";
import {
    COUNTRIES,
    INDUSTRIES,
    NIGERIAN_STATES
} from "../../shared/helpers/data.list";
import MerchantRegistrationService from "./merchantRegistration.service";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { Toast } from "../../shared/components/generic/toast";
import StyleHelper from "../../shared/helpers/style.helper";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import "../../theme/stylesheets/react-select-search.sass";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import environment from "../../environments/environment";
import { MerchantModel } from "../../shared/models/merchant.model";
import { getFromStorage, saveToStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import {ContextMenuService} from "../../shared/components/context-menu/context-menu.service";
import * as Amplitude from "amplitude-js";
import { EVENTS_TRACKED } from "../../shared/helpers/events-tracked";
import {IProfileMilestones} from "../../App";

interface IState {
    merchantPhoneNumber: string,
    addingMerchant: boolean,
    toastMessage: string,
    toastOpen: boolean,
    toastColor: string,
    selectedCountry: string,
    selectedState: string,
    loading: boolean,
    merchantRegistrationFormData: MerchantRegistrationSchema | undefined
}

interface MerchantRegistrationSchema {
    business_name: string,
    //industry_name?: string,
    //business_description: string,
    //full_name: string,
    phone_number: string,
    //state: string,
    referral_code?: string,
    terms: string
}

interface ReferralCodeSchema {
    referral_code?: string,
}

export enum MERCHANT_REGISTRATION_STATUSES {
    SUCCESS = "New Merchant Create Account",
    ALREADY_EXISTS = "Merchant Already Exists"
}

class MerchantRegistration extends React.Component<any, IState> {

    // Services
    private merchantRegistrationService = new MerchantRegistrationService();

    // View
    private countries: string[] = COUNTRIES;
    private list_of_states: { state: string; lgas: string[] }[] = NIGERIAN_STATES;

    // Merchant registration form schema
    private merchantRegistrationValidationSchema = yup.object().shape({
        business_name: yup.string().required('The name of your business is required.'),
        // industry_name: yup.string(),
        // business_description: yup.string().required('A description of your business is required.'),
        // full_name: yup.string().required('Your name is required.'),
        phone_number: yup.string().required('The business number is required.').test('phone_number', 'Enter a valid phone number', UtilityFunctions.validatePhoneNumber),
        // state: yup.string().required('State is required'),
        terms: yup.string().test('terms', 'To proceed, please agree to the terms and conditions', this.validateTerms)
    });

    private referralCodeValidationSchema = yup.object().shape({
        referral_code: yup.string().min(4).required('Kindly enter a referral code.'),
    });

    constructor(props: any) {
        super(props);
        this.state = {
            merchantPhoneNumber: '',
            addingMerchant: false,
            toastMessage: "success",
            toastOpen: false,
            toastColor: "success",
            selectedCountry: this.countries[1], // By default the selected country is Nigeria
            selectedState: this.list_of_states[0].state,
            loading: false,
            merchantRegistrationFormData: undefined
        }
    }

    closeToast = () => {
        this.setState({ toastOpen: false });
    };

    componentDidMount(): void {
    }

    getFirstLastName(name: string): { first_name: string, last_name: string } {
        const merchantName: string[] = name.split(' ');
        let last_name, first_name;
        if (merchantName.length > 1) {
            first_name = merchantName[0];
            last_name = merchantName[1];
        } else {
            first_name = merchantName[0];
            last_name = '';
        }

        return { first_name, last_name }
    }

    async registerMerchant(values: MerchantRegistrationSchema, resetForm?: any) {
        this.setState({ loading: true });

        // Perform form submission
        const postObj = {
            ...values,
            //...this.getFirstLastName(values.full_name),
            city: '',
            country: 'nigeria'
        };

        // delete postObj['full_name'];

        this.merchantRegistrationService.registerMerchant<{ data: { msg: string, status: string, merchant_profile: MerchantModel } }>(postObj)
            .then((response) => {

                const status = response.data.status;
                switch (status) {
                    case MERCHANT_REGISTRATION_STATUSES.SUCCESS:
                        this.setState({
                            toastOpen: true,
                            toastMessage: "Successfully submitted",
                            toastColor: "success",
                            loading: false
                        }, () => {
                            // this.updateProfileState()
                            saveToStorage(STORAGE_FIELDS.MERCHANT_TEMP, response.data.merchant_profile);
                            resetForm({});
                            Amplitude.getInstance().logEvent(EVENTS_TRACKED.SIGN_UP,
                                { merchantDetails: postObj });
                            // this.props.history.replace("/subscription-payment");
                            this.props.history.replace("/successful-payment");
                        });
                        break;

                    case MERCHANT_REGISTRATION_STATUSES.ALREADY_EXISTS:
                        this.setState({
                            toastOpen: true,
                            toastMessage: `An account registered using <strong>+${postObj.phone_number} already exists</strong>. Try registering using a different number.`,
                            toastColor: "danger",
                            loading: false
                        });
                        break
                }
            }).catch(error => {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Error creating account",
                    toastColor: "danger",
                    loading: false
                });
            });
    }

    /**
    async openReferralCodePrompt(values: MerchantRegistrationSchema, resetForm: any) {
        this.setState({merchantRegistrationFormData: values}, () => {
            console.log('1')
            ContextMenuService.openContextMenu(this.referralCodePromptTemplate());
        });
    }*/

    /* 
    async submitReferralCode(values: ReferralCodeSchema, resetForm?: any) {
        let registrationForm = this.state.merchantRegistrationFormData;

        if (registrationForm) {
            registrationForm = {...registrationForm, referral_code: values.referral_code};
            this.setState({merchantRegistrationFormData: registrationForm}, async () => {
                await this.registerMerchant(registrationForm as MerchantRegistrationSchema);
                if (resetForm)
                    resetForm();

                ContextMenuService.closeContextMenu();
            });
        }
    } */

    async updateMerchantFormData(event: any) {
        event.preventDefault();
        const data = await new FormData(event.target);
    }

    async updateProfileState() {
        let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);
        profileMilestones.addBusinessDetails = true;

        await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, profileMilestones);
    }

    stateChanged(change: any) {
        this.setState({
            selectedState: change.detail.value
        })
    }

    phoneNumberChanged = (e: any) => {
        this.setState({ merchantPhoneNumber: e });
    };

    validateTerms(status: string): any {
        switch (status) {
            case 'off':
                return false;
            case 'on':
                return true;
        }
    }

    openTC() {
        InAppBrowser.create('https://docs.google.com/document/d/e/2PACX-1vRiMVchN1S0_qHIMs4MyqpvOA0IcKXYbndThmPsw4tAo-Fk_XC-KThfRk-WEama_wZtRKF_J0BKQUj6/pub', '_blank', environment.inAppBrowserOptions);
    }

    selectedCity(selectedState: string): string {
        return this.list_of_states[this.list_of_states.findIndex(state => {
            return state.state === selectedState;
        })].lgas[0]
    }

    /**
    referralCodePromptTemplate() {
        return (
            <>
                <div className="context-menu-title">
                    <img src={'./assets/icons/refer.svg'} className="title-img" alt="none"/>
                    <p className="title">Have a referral code?</p>
                    <p className="menu-sub-title -with-margin">Enter a referral code if you have one or select 'Continue' to proceed.</p>

                    <div className="context-menu-body">
                        <Formik
                            initialValues={{
                                referral_code: '',
                            }}
                            validationSchema={this.referralCodeValidationSchema}
                            onSubmit={(values, {resetForm}) => this.submitReferralCode(values, resetForm)}>
                            {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                <Form>

                                    <Field name="referral_code">
                                        {(fieldProps: FieldProps) => (
                                            <div className="form-group">
                                                <p className="ajua-form-label">Enter Referral Code</p>
                                                <IonInput {...fieldProps.field} className="ajua-input" type="text"
                                                          placeholder="Referral code"
                                                          onBlur={handleBlur}
                                                          onIonChange={handleChange}
                                                          name="referral_code"/>
                                                <ErrorMessage name="referral_code">
                                                    {(msg) => (
                                                        <InlineAlert type={'error'} message={msg}/>
                                                    )}
                                                </ErrorMessage>
                                            </div>

                                        )}
                                    </Field>

                                    <button type="submit" className="ajua-button -medium-button -success -full-width"
                                            disabled={!(isValid && dirty)}>
                                        SUBMIT CODE
                                    </button>

                                    <div className="or-divider">
                                        <hr/>
                                        <p>Or</p>
                                        <hr/>
                                    </div>

                                    <button type="button" onClick={() => {
                                        this.submitReferralCode(values)
                                    }} className="ajua-button -medium-button -neutral -full-width">
                                        CONTINUE
                                    </button>
                                </Form>
                            )}

                        </Formik>

                    </div>
                </div>
            </>
        )
    } */

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{ title: "Create Account" }} />
                </IonHeader>
                <IonContent>
                    <div className="merchant-registration">
                        <IonLoading
                            cssClass=''
                            isOpen={this.state.loading}
                            message={'Submitting'} />

                        <div className="hero">
                            <div className="overlay">
                                <div className="content">
                                    <div className="logo">
                                        <img src={'assets/images/mtn_logo.png'} alt='Logo' />
                                    </div>
                                    <div className="description">
                                        <h3>Merchant Registration</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="hero-image">
                                <img src={'assets/images/shoppers.png'} alt='Shoppers' />
                            </div>
                        </div>

                        <div className="merchant-registration-form">
                            <Formik
                                initialValues={{
                                    business_name: '',
                                    //industry_name: '',
                                    //business_description: '',
                                    //full_name: '',
                                    phone_number: '',
                                    //state: this.list_of_states[0].state, // Pick the first state
                                    referral_code: '',
                                    terms: 'off'
                                }}
                                validationSchema={this.merchantRegistrationValidationSchema}
                                onSubmit={(values, {resetForm}) => this.registerMerchant(values, resetForm)}>
                                {/* onSubmit={(values, { resetForm }) => this.registerMerchant(values, resetForm)}> */}

                                {({ touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values }) => (
                                    <Form>
                                        <Field type="text" id="business_name" name="business_name">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">
                                                        Enter the name of your business</p>
                                                    <IonInput type="text" className="ajua-input"
                                                        placeholder="e.g Bobo's Barbershop"
                                                        {...fieldProps.field}
                                                        onIonChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="business_name" />
                                                    <ErrorMessage name="business_name">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg} />
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field>


                                        {/* <Field name="industry_name">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">What industry best describes your
                                                        business? <small>(optional)</small></p>

                                                    <IonSelect {...fieldProps.field}
                                                        name="industry_name"
                                                        onBlur={handleBlur}
                                                        interface="action-sheet"
                                                        onIonChange={handleChange}
                                                        className="ajua-select"
                                                        placeholder="Select Industry">
                                                        {INDUSTRIES.sort().map((industry, index) => {
                                                            return <IonSelectOption key={index}
                                                                value={industry}>{industry}</IonSelectOption>
                                                        })}
                                                    </IonSelect>
                                                </div>
                                            )}
                                        </Field> */}

                                        {/* <Field name="business_description">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">What do you want customers to
                                                        know about your business?</p>
                                                    <IonTextarea {...fieldProps.field}
                                                        className="ajua-textarea"
                                                        onBlur={handleBlur}
                                                        onIonChange={handleChange}
                                                        placeholder="e.g The best barbershop in Lagos"
                                                        name="business_description" />
                                                    <ErrorMessage name="business_description">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg} />
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field> */}


                                        {/* <Field validate={UtilityFunctions.validatePhoneNumber} name="full_name">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">Enter your full name</p>
                                                    <IonInput {...fieldProps.field}
                                                        className="ajua-input" type="text"
                                                        onBlur={handleBlur}
                                                        onIonChange={handleChange}
                                                        placeholder="Your full name"
                                                        name="full_name" />
                                                    <ErrorMessage name="full_name">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg} />
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field> */}


                                        <Field name="phone_number">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">Enter business phone number</p>
                                                    <PhoneInput
                                                        {...fieldProps.field}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        countries={['NG']}
                                                        autoComplete="on"
                                                        onChange={(value) => setFieldValue('phone_number', value)}
                                                        displayInitialValueAsLocalNumber
                                                        defaultCountry='NG'
                                                        className="ajua-input"
                                                        placeholder="Enter phone number"
                                                        name="phone_number" />
                                                    <ErrorMessage name="phone_number">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg} />
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field>

                                        {/* <Field name="state">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">
                                                        In which state is your business located?
                                                    </p>
                                                    <IonSelect {...fieldProps.field} name="state"
                                                        className="ajua-select"
                                                        interface="action-sheet"
                                                        onBlur={handleBlur}
                                                        onIonChange={(change) => setFieldValue('state', change.detail.value)}
                                                        placeholder="Select State">
                                                        {this.list_of_states.map((state, index) => {
                                                            return <IonSelectOption key={index} value={state.state}>
                                                                {StyleHelper.capitalize(state.state)}</IonSelectOption>
                                                        })}
                                                    </IonSelect>
                                                    <ErrorMessage name="state">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg} />
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field> */}

                                        <Field name="referral_code">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group no-margin">
                                                    <p className="ajua-form-label">Referral
                                                        Code <small>(optional)</small>
                                                    </p>
                                                    <IonInput {...fieldProps.field} className="ajua-input" type="text"
                                                        placeholder="Enter referral code"
                                                        onBlur={handleBlur}
                                                        onIonChange={handleChange}
                                                        name="referral_code" />
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="terms">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <div className="ajua-check-box">
                                                        <IonCheckbox {...fieldProps.field} onIonFocus={handleBlur}
                                                            name="terms"
                                                            onClick={() => setFieldValue('terms', values.terms === 'on' ? 'off' : 'on')}
                                                            checked={values.terms === 'on'} />
                                                        <p>I have read and agree to the <span onClick={this.openTC}
                                                            className="hyper-link">Terms & Conditions</span>
                                                        </p>
                                                    </div>
                                                    <ErrorMessage name="terms">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg} />
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field>

                                        <button type="submit" className="ajua-button -success -full-width"
                                            disabled={!(isValid && dirty)}>
                                            REGISTER
                                        </button>
                                    </Form>
                                )}
                            </Formik>

                            <Toast message={this.state.toastMessage} open={this.state.toastOpen} position="bottom"
                                color={this.state.toastColor}
                                closeToast={this.closeToast} />
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default MerchantRegistration;

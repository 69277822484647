import ApiService from "../services/network-services/api.service";
import { URL_ENDPOINTS } from "../url-endpoints"

export default class AdsService {
    private apiService = new ApiService();

    /**
     * get all ads
     * @return {Promise<any>}
     */
    async getAds(city: string, country: string, industry: string) {
        const apiService = new ApiService();
        return await apiService.createResource(
            { city, country, industry },
            `${URL_ENDPOINTS.getAds}`)
            .then(activities => {
                return activities?.data;
            })
            .catch(
                error => {
                    throw error
                }
            )
    }

    /**
     * create ad
     * @return {Promise<any>}
     * @param body
     */
    async submitAd(body: object) {
        const apiService = new ApiService();
        return await apiService.createResource(
            body,
            `${URL_ENDPOINTS.submitAd}`,)
            .then((response: any) => {
                return response?.data;
            })
            .catch(
                error => {
                    throw error
                }
            );
    }
}

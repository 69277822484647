import React from "react";
import "./importedCustomersList.sass";
import {IonContent, IonHeader, IonPage} from "@ionic/react";
import AjuaToolbar from "../../../shared/components/ajua-toolbar/ajuaToolbar";

class ImportedCustomersList extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render(){
        return(
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Edit Imported Contacts"}}/>
                </IonHeader>
                <IonContent>
                    <div className="imported-customers">

                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default ImportedCustomersList;

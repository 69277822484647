import React from "react";
import "./logDebt.sass";
import {
    IonContent, IonDatetime,
    IonHeader,
    IonInput, IonLoading,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    withIonLifeCycle
} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import * as yup from "yup";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import { getActivities } from "../../shared/apiCalls/customerCalls";
import { CustomerModel } from "../../shared/models/customer.model";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import DebtProvider, { LedgerEntryModel } from "../debt/debt.provider";
import { MerchantModel } from "../../shared/models/merchant.model";
import { getFromStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import { Toast } from "../../shared/components/generic/toast";
import * as Amplitude from "amplitude-js";
import { EVENTS_TRACKED } from "../../shared/helpers/events-tracked";

interface IState {
    customers: CustomerModel[],
		customerID: string,
		entryID: string,
    pageLoading: boolean,
    loggingDebt: boolean,
    merchant?: MerchantModel
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string,
    },
		ledgerEntry: LedgerEntryModel | undefined
}

interface LogDebtSchema {
    customer_id: string,
    debit?: number,
    reference?: string,
    due_date?: any
}

class LogDebt extends React.Component<any, IState> {
    private debtProvider: DebtProvider = new DebtProvider();

    // Debt logging schema
    private debtLoggingValidationSchema = yup.object().shape({
        customer_id: yup.string().required('Kindly select a customer before proceeding.'),
        debit: yup.number().required('Kindly enter the amount loaned to customer'),
        reference: yup.string(),
        due_date: yup.string(),
    });

    state = {
        customers: [],
        customerID: "",
        entryID: "",
        pageLoading: true,
        loggingDebt: false,
        merchant: {} as MerchantModel,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: "success",
        },
				ledgerEntry: {} as LedgerEntryModel
    };

    constructor(props: any) {
        super(props);
    }

    ionViewWillEnter() {
        
    }

    async ionViewDidEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);

        const customers = await getActivities("&activity_id=0");
        this.setState({
            customers,
            pageLoading: false,
            merchant
        })
        const {entryID, customerID} = this.props.location.state;
        if(customerID)
            this.setState({customerID});
        if(entryID) {
            this.setState({entryID});
            this.getCurrentLedgerEntry(entryID);
        }
        // console.log('this.props.location.state', this.props.location.state)
				
    }

    /**
     * Get the ledger entry object for this record for this customer
     */
    async getCurrentLedgerEntry(entryID: string) {
        await this.debtProvider.getDebtLedgerEntry(`${entryID}`).then(ledgerEntry => {
            this.setState({ledgerEntry, pageLoading: false});
        }, error => {
            this.setState({pageLoading: false});
        });
    }

    handleSubmitForm(values: LogDebtSchema, resetForm: any) {
        if(this.state.entryID) {
            this.updateDebt(this.state.entryID, values, resetForm)
        } else {
            this.logDebt(values, resetForm)
        }
    }

    async logDebt(values: LogDebtSchema, resetForm: any) {
        this.setState({ loggingDebt: true });

        if (this.state ?.merchant) {
            await this.debtProvider.logDebt({ ...values, customer_id: `${values.customer_id}-${this.state.merchant.id.$oid}` }).then(resp => {
                if (resp) {
                    this.setState({
                        toastOptions: {
                            toastMessage: 'Successfully saved customer debt',
                            toastOpen: true,
                            toastColor: "success",
                        },
                        loggingDebt: false,
                        entryID : "",
                        ledgerEntry: undefined
                    });
                    Amplitude.getInstance().logEvent(EVENTS_TRACKED.LOG_DEBT,
                        { merchantDetails: this.state.merchant });
                    resetForm({});
										
                    setTimeout(() => {
                        this.props.history.push({pathname: "/main/debt"});
                    }, 500);
                }
            }, error => {
                this.setState({
                    toastOptions: {
                        toastMessage: 'Failed to save customer debt. Try again.',
                        toastOpen: true,
                        toastColor: "danger",
                    },
                    loggingDebt: false
                })
            });
        }
    }

    async updateDebt(entryID: string, values: LogDebtSchema, resetForm: any) {
        this.setState({ loggingDebt: true });

        if (this.state ?.merchant) {
            await this.debtProvider.updateDebt(entryID, { ...values, customer_id: `${values.customer_id}-${this.state.merchant.id.$oid}` }).then(resp => {
                if (resp) {
                    this.setState({
                        toastOptions: {
                            toastMessage: 'Successfully updated customer debt',
                            toastOpen: true,
                            toastColor: "success",
                        },
                        loggingDebt: false
                    });
                    Amplitude.getInstance().logEvent(EVENTS_TRACKED.UPDATE_DEBT,
                        { merchantDetails: this.state.merchant });
                    resetForm({});
										
										setTimeout(() => {
											this.props.history.push({pathname: "/main/debt"});
										}, 500);
                }
            }, error => {
                this.setState({
                    toastOptions: {
                        toastMessage: 'Failed to save customer debt. Try again.',
                        toastOpen: true,
                        toastColor: "danger",
                    },
                    loggingDebt: false
                })
            });
        }
    }

    closeToast = () => {
        this.setState({ toastOptions: { ...this.state.toastOptions, toastOpen: false } });
    };

    render() {

        let d = new Date(),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        const dateToday = [year, month, day].join('-');

        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{ title: "Log Debt" }} />
                </IonHeader>
                <IonContent>
                    {this.state.pageLoading &&
                        (<ContentLoader />)
                    }
                    <div className="log-debt-page">
                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={this.state.loggingDebt}
                            message={'Loading'} />

                        <div className="page-section">
                            <div className="section-content log-debt-form">
															  <Formik
                                    initialValues={{
                                        customer_id: this.state.customerID,
                                        debit: this.state.ledgerEntry?.debit,
                                        reference: this.state.ledgerEntry?.reference,
                                        due_date: this.state.ledgerEntry?.due_date
                                    }}
																		enableReinitialize={true}
                                    validationSchema={this.debtLoggingValidationSchema}
                                    onSubmit={(values, { resetForm }) => this.handleSubmitForm(values, resetForm)}>
                                    {({ touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values }) => (
                                        <Form>
                                            <Field type="text" id="customer_id" name="customer_id">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Customer</p>
                                                        <IonSelect {...fieldProps.field}
                                                            name="customer_id"
                                                            onBlur={handleBlur}
                                                            interface="action-sheet"
                                                            onIonChange={handleChange}
                                                            className="ajua-select"
                                                            placeholder="Select Customer">
                                                            {this.state.customers.sort().map((customer: CustomerModel, index) => {
                                                                return <IonSelectOption key={index}
                                                                    value={customer.CUSTOMER_ID}>{customer.METADATA.name}</IonSelectOption>
                                                            })}
                                                        </IonSelect>
                                                        <ErrorMessage name="customer_id">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg} />
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field type="text" id="debit" name="debit">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Amount</p>
                                                        <IonInput {...fieldProps.field} className="ajua-input"
                                                            onBlur={handleBlur}
                                                            onIonChange={handleChange}
                                                            type="number"
                                                            inputMode="numeric" name="debit"
                                                            placeholder="Enter the debt amount e.g ₦ 500." />
                                                        <ErrorMessage name="debit">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg} />
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field type="text" id="due_date" name="due_date">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Due Date <small>(Optional)</small></p>
                                                        <IonDatetime {...fieldProps.field} className="ajua-datetime"
                                                            displayFormat="DD MMMM, YYYY"
                                                            placeholder="Select Date"
                                                            name='due_date'
                                                            min={dateToday}
                                                            max={'2100'}
                                                            onIonChange={handleChange}
                                                            onBlur={handleBlur} />
                                                    </div>
                                                )}
                                            </Field>

                                            <Field type="text" id="reference" name="reference">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Reason <small>(Optional)</small></p>
                                                        <IonTextarea {...fieldProps.field} className="ajua-textarea"
                                                            name='reference'
                                                            onIonChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder={'Enter a reason for the debt e.g to purchase sugar'} />
                                                        <ErrorMessage name="reference">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg} />
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <button disabled={!(isValid && dirty)} type="submit"
                                                className="ajua-button -success -full-width">
                                                Log Debt
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        <Toast message={this.state.toastOptions ?.toastMessage} open={this.state.toastOptions ?.toastOpen}
                            position="bottom" color={this.state.toastOptions ?.toastColor}
                            closeToast={this.closeToast} />
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(LogDebt);

import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

/*
* Enum denoting all the fields available in the app's local storage
* */
export enum STORAGE_FIELDS {
    APP_INITIAL_LAUNCH = "app_initial_launch",
    MERCHANT = "merchant",
    MERCHANT_TEMP = "merchant_temp",
    SMS_BALANCE = "sms_balance",
    VIEWED_INTRO_SLIDE = "viewed_intro_slide",
    SUBSCRIPTION = "subscription",
    TOKEN = "token",
    EXPIRY = "expiry",
    PROFILE_MILESTONES_STATES = "profile_milestones",
    NEXT_TIME_TO_VIEW_APP_MILESTONES_STATES = "next_time_to_view_app_milestones", // We want to show the milestones to the user after 24 hours
    APP_MILESTONES_STATES = "app_milestones", // I added an extra key to hold new set of milestones because of an error that was caused by empty keys
    MERCHANT_PAYMENT_INFO = "merchant_payment_info",
    VIEWED_FEATURE_GUIDES = "viewed_feature_guides",
    ADS_TRIAL_START = "ads_trial_start" // note the time ads trial started and count 7 days
}

export enum SECURE_STORAGE_FIELDS {
    STORAGE_NAME = "SECURE_STORAGE",
    PASSCODE = "passcode",
    SHOW_PASSCODE = "show_passcode"
}

/*
* Save data to the local Ionic Storage
* @param key: string - The name of the storage field (from the STORAGE_FIELDS Enum)
* @param value: any - The value to store in the storage field
* @throws error - Throws an error when there's trouble storing data.
* */
export async function saveToStorage(key: string, value: any): Promise<void> {
    try {
        await Storage.set({
            key: key,
            value: JSON.stringify(value)
        });
    } catch (e) {
        throw e
    }

}

/*
* Obtain data from the local Ionic Storage
* @param key: string - The name of the storage field (from the STORAGE_FIELDS Enum)
* @throws error - Throws an error when there's trouble obtaining data.
* */
export async function getFromStorage(key: string): Promise<any> {
    try {
        const { value } = await Storage.get({ key: key });
        if (value != 'undefined' && value != null) return JSON.parse(value);
        else return value;
    } catch (e) {
        throw e
    }

}

/*
* Deletes specific values from the local Ionic Storage
* @param key: string - The name of the storage field (from the STORAGE_FIELDS Enum)
* @throws error - Throws an error when there's trouble obtaining data.
* */
export async function removeFromStorage(key: string): Promise<void> {
    try {
        await Storage.remove({
            key: key
        });
    } catch (e) {
        throw e;
    }
}

/*
* Clears the entire local Ionic Storage
* */
export async function clearStorage(): Promise<void> {
    try {
        await Storage.clear();
    } catch (e) {
        throw e;
    }
}

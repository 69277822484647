import React from "react";
import {TransactionCardModel} from "../../models/transactionCard.model";
import './transactionCard.sass';
import moment from "moment";
import { cashOutline, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons'
import { IonIcon } from "@ionic/react";


interface IProps {
    transaction: TransactionCardModel,
    toggleQuickActions?: (open: boolean) => void,
    USSD?: string
}

const TransactionCard: React.FC<IProps> = (props: IProps) => {
    return (
        <div className="transaction-card">
            <div className="channel-logo">
                <IonIcon className="payment-activity" icon={cashOutline} />
            </div>
            <div className="transaction-details">
                <div className="customer-details">
                    <div className="customer">{props.transaction.account_name}</div>
                    <div className="time">{moment(props.transaction.time_created).fromNow()  || 'Not Set'}</div>
                </div>
                <div className="amount">
                    {props.transaction.transaction_status === 'success' 
                    ? 
                    <span className="status-success">₦{props.transaction.amount}</span>
                    : 
                    <span className="status-failure">₦{props.transaction.amount}</span>
                    }
                </div>
            </div>

        </div>
    )
};

export default TransactionCard;

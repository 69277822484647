import React from 'react';
import {IonToast, IonContent} from '@ionic/react';

type position = "top" | "bottom" | "middle" | undefined;

interface ToastProps {
    message?: string;
    position: position;
    open?: boolean;
    color?: string;
    closeToast: Function;
}

export function Toast(props: ToastProps) {
    const {message, position, open, color, closeToast} = props;
    return (
        <IonContent>

            <IonToast
                isOpen={open || false}
                message={message}
                position={position}
                color={color}
                duration={4000}
                buttons={[
                    {
                        text: 'X',
                        role: 'cancel',
                        handler: () => {
                            closeToast();
                        }
                    }
                ]}
            />
        </IonContent>
    );
}

import React from "react";
import "./inlineAlert.sass";
import {IonIcon} from "@ionic/react";
import {warning, alertCircle, thumbsUp, informationCircle} from 'ionicons/icons'

interface IProps {
    type: 'success' | 'warning' | 'info' | 'error',
    message: string
}
const InlineAlert: React.FC<IProps> = (props: IProps) => {
    const getAlertIcon = (type: string) => {
        switch (type){
            case 'success':
                return thumbsUp;
            case 'warning':
                return warning;
            case 'error':
                return alertCircle;
            case 'info':
                return informationCircle;
        }
    };

    return (
        <div className={`ajua-inline-alert -${props.type}`}>
            <IonIcon className="alert-icon" icon={getAlertIcon(props.type)}/>
            <p>{props.message}</p>
        </div>
    )
};

export default InlineAlert;

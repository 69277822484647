import React from "react";
import "./successful-payment.sass";
import {IonContent, IonPage, IonIcon} from "@ionic/react";
import {Link} from "react-router-dom"; //arrowForwardOutline
import {
    arrowForwardOutline
} from "ionicons/icons";
import { getFromStorage, saveToStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import { MerchantTempModel } from "../../shared/models/merchant.model";

interface IState {
    merchant?: MerchantTempModel
}

class SuccessfulPayment extends React.Component<any, any> {
    state: IState = {
        merchant: {} as MerchantTempModel
    };

    async componentDidMount() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT_TEMP);
        this.setState({ merchant });
        //console.log('this.state.merchant?.collaborators', this.state.merchant)
    }

    render() {
        return (
            <IonPage>
                <IonContent>
                    <div className="successful-payment">
                        <div className="illustration">
                            <img src={'assets/icons/confetti.svg'}/>
                        </div>
                        <div className="message">
                            <p className="title">Congratulations {this.state.merchant?.first_name}!</p>
                            {/* <p className="message-content">Congratulations on creating your business account! Please refer to your SMS messages for your new unique business code.</p> */}
                            <p className="message-content">You have successfully created your business account. Your unique business short code is displayed on your profile.</p>
                        </div>

                        <div className="actions">
                            <Link to="login">
                                <button className="ajua-button -medium-button -small-width -success">Login <IonIcon size="small" icon={arrowForwardOutline} /></button>
                            </Link>
                        </div>

                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default SuccessfulPayment;

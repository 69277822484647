import { URL_ENDPOINTS } from "../../shared/url-endpoints";
import ApiService from "../../shared/services/network-services/api.service";

export default class MerchantRegistrationService {
    private apiService = new ApiService();

    /*
    * Creates a new merchant
    * @param postObj: Details of the merchant being registered
    * */
    async registerMerchant<T>(postObj: object): Promise<T> {
        const endpoint = URL_ENDPOINTS.createMerchant;
        return this.apiService.createResource(postObj, endpoint);
    }
}

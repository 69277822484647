import React from 'react'
import {
    IonContent,
    IonLoading,
    IonIcon,
    IonPage,
    IonHeader,
    IonRefresher,
    IonRefresherContent,
    withIonLifeCycle
} from '@ionic/react'
import './createInAppAds.sass';
import InAppAdsCreateWidget from "../../shared/components/in-app-ads-card-create/inAppAdsCreateWidget"
import { getFromStorage, saveToStorage, STORAGE_FIELDS } from "../../shared/helpers/storage"
import {
    megaphoneOutline,
    checkmarkSharp,
    createOutline,
    personOutline,
    cloudUploadOutline
} from "ionicons/icons"
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar"
import { Link } from "react-router-dom"
import { Toast } from "../../shared/components/generic/toast"
import { MerchantModel } from "../../shared/models/merchant.model"
import ContentLoader from "../../shared/components/content-loader/contentLoader"
import {getOffers} from "../../shared/apiCalls/offerCalls"
import AjuaCard from "../../shared/components/ajua-card/ajua-card"
import AdsService from "../../shared/apiCalls/ads.service"
import environment from '../../environments/environment'
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked"
import * as Amplitude from "amplitude-js"

import { Plugins, CameraResultType, CameraOptions } from '@capacitor/core'
const { Camera } = Plugins

class CreateInAppAds extends React.Component<any, any> {
    private adsService: AdsService = new AdsService()

    state = {
        pageLoading: true,
        activityLoading: true,
        pullToRefresh: true,
        merchant: {} as MerchantModel,
        latestOffer: {
            _id: {
                $oid: ''
            },
            DESCRIPTION: "",
            END_DATE: {$date: 0},
            START_DATE: {$date: 0},
            TITLE: "",
            STATUS: "",
            MERCHANT_ID: "",
            UPDATED_AT: {$date: 0},
            OFFER_CODE: ''
        },
        quickActionsModal: false,
        chosenAdTemplate: { imageUrl: 'assets/ad-templates/template_1.jpg', template_id: 1 },
        base64ImageToUpload: '',
        useAdTemplate: 'TRUE',
        SUBMITTING_DATA: false,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: "success"
        },
    };

    async ionViewWillEnter() {
        await this.getAdTemplates(null)
        await this.fetchOffers();       
    }

    async getAdTemplates(e: any) {
        // const merchantID = (await getFromStorage(STORAGE_FIELDS.MERCHANT)).id ?.$oid;
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        return await this.setState({
            merchant,
            pageLoading: false
        });
    }

    fetchOffers = async () => {
        try {
            const offers = await getOffers();
            const latestOffer = offers && offers.length && offers[offers.length - 1]
            this.setState({latestOffer});
        } catch (e) {
            throw e;
        } finally {
            await this.setState({loading: false, pageLoading: false,});
        }
    };

    selectAdTemplate(e: any) {
        this.setState({chosenAdTemplate: e, useAdTemplate: 'TRUE', base64ImageToUpload: null});
    }

    /**
	 * @private
	 * @function isAppOnDevice
	 * @description Simply check, if the environment is a device or desktop browser
	 * @return {boolean}
	 */
	private isAppOnDevice(): boolean {
		if (window.location.port === '8100') {
			return false;
		} else {
			return true;
		}
	}

    async selectMyImage() {
        if (!this.isAppOnDevice()) {
            return false
        }
        const options: CameraOptions = {
          quality: 100,
        //   sourceType: sourceType,
          saveToGallery: false,
          correctOrientation: true,
        //   direction: 'FRONT',
          allowEditing: false,
          height: 300,
          width: 500,
          resultType: CameraResultType.Base64
        };
        
        await Camera.getPhoto(options).then(imageData => {
            let base64Image = 'data:image/jpeg;base64,' + imageData.base64String
            this.setState({
                useAdTemplate: 'FALSE',
                chosenAdTemplate: {
                    imageUrl: base64Image, 
                    template_id: 0
                },
                base64ImageToUpload: imageData.base64String, 
                businessName: this.state.merchant?.business_name, 
                businessCode: this.state.merchant?.metadata?.mtn.ussd_code, template_id: 1 
                
            })
        });
    }

    submitAd = async () => {
        // title => the adverts title 
        // image_data =>the base64 encoded image data
        // details => the Advert details
        // template => if Advert is using an Image template return true
        // image_id. => if advert is using an image template return template ID i will give the template ID details if we settle on final templates 
        // msisdn. => the Merchants MSISDN
        // offer_details
        try {
            this.setState({SUBMITTING_DATA: true});
            const { template_id } = this.state.chosenAdTemplate
            if (this.state.merchant?.collaborators) {
                const obj = {
                    title: this.state.merchant?.business_name, 
                    image_data: this.state.base64ImageToUpload || 'null', 
                    details: this.state.latestOffer?.TITLE || 'null', 
                    template: this.state.useAdTemplate, 
                    image_id: template_id, 
                    offer_details: this.state.latestOffer?.DESCRIPTION || null,
                    msisdn: this.state?.merchant?.collaborators[0].msisdn
                }
                
                await this.adsService.submitAd(obj).then((res) => {
                    this.setState({SUBMITTING_DATA: false});
                    if (res.status.toLowerCase()  ==='success') {
                        this.setState({
                            SUBMITTING_DATA: false,
                            toastOptions: {
                                toastMessage: res.message,
                                toastOpen: true,
                                toastColor: "success"
                              }
                        }, async () => {
                            const adsTrialStart = await getFromStorage(STORAGE_FIELDS.ADS_TRIAL_START)
                            if (Number(adsTrialStart) < 10) { 
                                // If this is the first time we are creating an ad
                                const adsStartedOn = Math.floor(Date.now()/1000)
                                await saveToStorage(STORAGE_FIELDS.ADS_TRIAL_START, adsStartedOn);
                                // Track event
                                Amplitude.getInstance().logEvent(EVENTS_TRACKED.CREATED_AD_FIRST_TIME,
                                    {merchantDetails: this.state.merchant})
                            } else {
                                // Track event
                                Amplitude.getInstance().logEvent(EVENTS_TRACKED.CREATED_AD,
                                    {merchantDetails: this.state.merchant})
                            }
                        });
                    }
                    setTimeout(() => {
                        this.props.history.push({ pathname: '/main/pulse' })
                    }, 2000)
                });
            }
        } catch (e) {
            //Todo
            this.setState({
                SUBMITTING_DATA: false,
                toastOptions: {
                    toastMessage: "Unable to submit. Please try again.",
                    toastOpen: true,
                    toastColor: "danger"
                  }
            });
        }

    }

    backButtonPressed() {
        this.props.history.goBack()
    };

    // Helper function
    // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    closeToast = () => {
        this.setState({ toastOptions: { ...this.state.toastOptions, toastOpen: false } });
    };
    
    render() {
        // const { subscription_status, subscription_expiry_date } = this.state.merchant?.ajua_account_details?.subscription_status
        const now = Math.floor(Date.now()/1000)
        // const expiry = Math.floor(Number(subscription_expiry_date.$date) / 1000)
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Create an Ad"}}/>
                </IonHeader>
                {this.state.pageLoading &&
                    (<ContentLoader />)
                }
                {/* <div className="back-navigation">
                    <IonIcon onClick={() => this.backButtonPressed()} icon={chevronBackOutline}/>
                    <p>Back</p>
                </div> */}
                <IonContent>
                    <IonLoading
                        cssClass=''
                        isOpen={this.state.SUBMITTING_DATA}
                        message={'Submitting'}/>

                    <IonRefresher snapbackDuration="400ms" slot="fixed"
                        pullFactor={0.5} pullMin={100} pullMax={100}
                        onIonRefresh={(e) => this.getAdTemplates(e)}>
                        <IonRefresherContent
                            pullingText="Pull to refresh"
                            refreshingText="Updating templates">
                        </IonRefresherContent>
                    </IonRefresher>
                    
                    <div className="page-section advertise-store -page-section-first-child">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={megaphoneOutline} /> Choose a template</p>
                        </div>
                        <div className="section-content -horizontal-scroll">
                            <div className="horizontal-scroll">
                                <div className="advertisements">
                                    {
                                        environment.inAppAdsTemplates.map((template, index) => {
                                            return <InAppAdsCreateWidget 
                                                        key={index} 
                                                        businessName={this.state.merchant?.business_name}
                                                        businessCode={this.state.merchant?.metadata?.mtn?.ussd_code.toString()}
                                                        latestOfferTitle={this.state.latestOffer?.TITLE}
                                                        imageUrl={template.imageUrl} 
                                                        template_id={template.template_id} 
                                                        onSelectTemplate={() => this.selectAdTemplate(template)} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-section business-profile-general-details no-margin">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={personOutline} /> Confirm business profile info</p>
                        </div>
                        <div className="section-content">
                            {
                            this.state.merchant ? (
                                <AjuaCard>
                                    <div className="read-only-details flexed-div">
                                        <div>
                                            <label className="ajua-form-label">Business Name</label>
                                            <p className="entry-value">{this.state.merchant?.business_name}</p>
                                        </div>
                                        <div>
                                            <Link to={'/edit-profile'} className="ajua-button no-decoration -neutral-text -small-button btn-edit-profile">
                                                <IonIcon icon={createOutline} className="-left"/> Edit
                                            </Link>
                                        </div>
                                        
                                    </div>
                                    <div className="read-only-details">
                                        <label className="ajua-form-label">Business short code</label>
                                        <p className="entry-value">{this.state.merchant?.metadata?.mtn?.ussd_code}</p>
                                    </div>
                                    <div className="read-only-details">
                                        <label className="ajua-form-label">Latest offer</label>
                                        <p className="entry-value">{this.state.latestOffer?.TITLE || 'Not available'}
                                            &nbsp;<Link to={{pathname: '/create-offer', state: { referrer: '/create-ad' }}} className="btn-modify-offer">
                                                <IonIcon icon={createOutline} className="-left"/>
                                            </Link>
                                        </p> 
                                        <p className="entry-value-description">{this.state.latestOffer?.DESCRIPTION || ''}</p> 
                                    </div>
                                </AjuaCard>
                            ) : 'null'
                        }
                        </div>
                    </div><br/>

                    <div className="page-section discount-ideas chosen-ad">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={megaphoneOutline} />Chosen Ad</p>
                            <button onClick={() => this.selectMyImage()} className="ajua-button no-decoration -neutral -small-button">
                                <IonIcon icon={cloudUploadOutline} className="-left"/> Upload your own image
                            </button>
                        </div>
                        <div className="section-content">
                            <div className="in-app-chosen-template">
                                <div className="advert-preview">
                                    <div className="overlay">
                                        <div className="top">
                                            <p className="business-name">{this.state.merchant?.business_name}</p>
                                        </div>
                                        <div className="bottom">
                                            <p className="dial-code">Dial <strong>*6031*{this.state.merchant?.metadata?.mtn?.ussd_code}#</strong></p>
                                            <p className="description">Special offer: {this.state.latestOffer?.TITLE || 'Offer not available'}</p>
                                        </div>
                                    </div>
                                    <div className="thumbnail">
                                        <img className="ad-bg-image" src={this.state.chosenAdTemplate?.imageUrl} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { !this.state.merchant?.ajua_account_details?.subscription_status ? 
                        <div className="page-section">
                            <div className="section-content">
                                <div className="create-ads-cta">
                                    <div className="icon">
                                        {/* <IonIcon icon={bulbOutline} className="-left"/> */}
                                        <img src={'assets/icons/stopwatch.png'} alt=""/>
                                    </div>
                                    <div className="description">
                                        Your ad will be displayed for 24 hours. To display for 7 days click 
                                        <Link to={'/payments-subscriptions'} className="no-decoration">&nbsp;<strong>here</strong>&nbsp;</Link>to subscribe.
                                    </div>
                                </div>
                            </div>
                        </div>
                     : ''}
                    <div className="page-section discount-ideas chosen-ad">
                        <div className="section-content">
                            <button type="submit" className="ajua-button -success -full-width" onClick={() => this.submitAd()}>
                                <IonIcon icon={checkmarkSharp} className="-left"/>Submit Ad
                            </button>
                        </div>
                        <Toast message={this.state.toastOptions ?.toastMessage} open={this.state.toastOptions ?.toastOpen}
                            position="bottom" color={this.state.toastOptions ?.toastColor}
                            closeToast={this.closeToast} />
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(CreateInAppAds);


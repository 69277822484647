import React from "react";
import './feedbackCard.sass';
import {FeedbackCardModel} from "./feedbackCard.model";
import {Link} from "react-router-dom";
import moment from "moment";

const FeedbackCard: React.FC<{ feedback: FeedbackCardModel }> = (props: { feedback: FeedbackCardModel }) => {
    const {npsScore, commId, createDate, npsComment} = props.feedback;
    return (
        <Link to="/customer" className="feedback-card">
            <div className="rating">
                <div className="score -passive">{npsScore}</div>
                <div className="emoji"><span role="img" aria-label="NPS EMOJI">
                    {(npsScore > -1 && npsScore < 7) && (<span>☹️</span>)}
                    {(npsScore === 7 || npsScore === 8) && (<span>😐</span>)}
                    {(npsScore === 9 || npsScore === 10) && (<span>😃</span>)}
                </span>
                </div>
            </div>
            <div className="feedback-details">
                <p className="customer-name">{commId}</p>
                <p className="customer-message">{npsComment}</p>
                <p className="time">{moment(createDate).fromNow()}</p>
            </div>
        </Link>
    );
};

export default FeedbackCard;

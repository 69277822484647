import { InAppBrowserOptions } from "@ionic-native/in-app-browser";

const environment = {
    version: '1.2.1',
    baseUrl: 'https://merchant-api.ajua.com/v0',
    debtBaseUrl: 'https://debt.ajua.com',
    authBaseUrl: 'https://auth.ajua.com',
    apiUsername: 'android',
    AUTH_KEY: 'wto873yeotw-ayqoq782954b925b296bv597bcobdnwnn',
    apiPassword: 'e666d552-0785-41ca-8138-9109d9e9721e',
    SMS_SHORT_CODE: '6032',
    ROWS_PER_PAGE: 60,
    inAppBrowserOptions: {
        hideurlbar: 'yes',
        toolbarcolor: '#FFCC00',
        navigationbuttoncolor: '#4C4C4C'
    } as InAppBrowserOptions,

    // sentryDNS: "https://56155bd9c4bd446ab681ad707afa2fb0@o412185.ingest.sentry.io/5288544", // SENTRY PRODUCTION
    sentryDNS: "https://8b8c4c06ce3249afbd775baa125ac7f7@o412185.ingest.sentry.io/5306256", // SENTRY DEVELOPMENT

    // amplitudeAPIKey: 'c13118f7a2b72a24a8700b9cf0aed7b4', // AMPLITUDE PRODUCTION
    // amplitudeAPIKey: '0cd6405ef918493dfe2b7f1a9e1227c1', // AMPLITUDE DEVELOPMENT
    amplitudeAPIKey: '56ce6cc1bb8c74fca76f48c4d4031cf5', // AMPLITUDE PRODUCTION
    inAppAdsTemplates: [
        { template_id: 1, imageUrl: 'assets/ad-templates/template_1.jpg'},
        { template_id: 2, imageUrl: 'assets/ad-templates/template_2.jpg'},
        { template_id: 3, imageUrl: 'assets/ad-templates/template_3.jpg'},
        { template_id: 4, imageUrl: 'assets/ad-templates/template_4.jpg'},
        { template_id: 5, imageUrl: 'assets/ad-templates/template_5.jpg'},
        // { template_id: 6, imageUrl: 'assets/ad-templates/template_6.jpg'},
    ],
    adsTrialPeriod: 7 // 7 dats
};

export default environment;

import React from "react";
import "./customer.sass"
import {
    IonContent,
    IonIcon,
    IonLoading,
    IonPage,
    IonSpinner,
    IonTextarea,
    withIonLifeCycle,
		IonAlert
} from "@ionic/react";
import {
    getCustomer,
    getCustomerActivities,
    sendSurvey,
    updateCustomerProfile
} from "../../shared/apiCalls/customerCalls";
import ActivityCard from "../../shared/components/activity-card/ActivityCard";
import {chevronBackOutline, saveSharp} from "ionicons/icons";
import EmptyState from "../../shared/components/empty-state/emptyState";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {Toast} from "../../shared/components/generic/toast";
import {
    pricetag,
    chatboxEllipses,
    chatbubbles,
    call,
    notificationsOutline,
    cashOutline,
    createOutline,
		trashOutline
} from "ionicons/icons";
import {MerchantModel} from "../../shared/models/merchant.model";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import DebtProvider, {CustomerDebtProfileModel, LedgerEntryModel} from "../debt/debt.provider";

interface CustomerState {
    customerID: string,
    entryID: string,
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string,
    },
    alertOptions: {
            alertMessage: string,
            alertOpen: boolean,
            alertColor: string
    },
    loading: boolean,
    updatingCustomer: boolean,
    customerActivities: {
        ACTIVITY_ID: string,
        CUSTOMER_ID: string,
        TIMESTAMP: { $date: number },
        METADATA: any
    }[],
    customer: {
        name: string,
        notes: string,
        msisdn: string,
        CUSTOMER_ID: string,
        TIMESTAMP: { $date: number },
    },
    merchant: MerchantModel,
    customerDebtProfile: CustomerDebtProfileModel | undefined,
    ledgerEntry: LedgerEntryModel | undefined,
    debt_value_to_cancel: number
}

class Customer extends React.Component<any, CustomerState> {
    private debtProvider: DebtProvider = new DebtProvider();

    state = {
        toastOptions: {
            toastMessage: "success",
            toastOpen: false,
            toastColor: "success"
        },
        alertOptions: {
            alertMessage: "success",
            alertOpen: false,
            alertColor: "success"
        },
        loading: false,
        updatingCustomer: false,
        customerID: '',
        entryID: '',
        customerActivities: [{
            ACTIVITY_ID: "",
            CUSTOMER_ID: "",
            TIMESTAMP: {$date: 0},
            METADATA: {}
        }],
        customer: {
            name: "",
            notes: "",
            msisdn: "",
            CUSTOMER_ID: "",
            TIMESTAMP: {$date: 0},
        },
        merchant: {} as MerchantModel,
        customerDebtProfile: {} as CustomerDebtProfileModel,
        ledgerEntry: {} as LedgerEntryModel,
        debt_value_to_cancel: 0

    };

    async ionViewDidEnter() {
        const {entryID, customerID} = this.props.location.state;
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        
				if (customerID) {
					this.setState({customerActivities: [], customerID, entryID, merchant}, async () => {
							await this.getCustomerDetails(customerID.replace('+', ''));
							await this.checkCustomerDebt();
							if(entryID) 
								await this.getCurrentLedgerEntry(entryID)
							// encode customerId because of the + symbol chance
							this.getCustomerActivities(encodeURIComponent(customerID));
					});
        }

    }

    async checkCustomerDebt() {
        await this.debtProvider.getCustomerDebt(`${this.state.customerID}-${this.state.merchant.id.$oid}`).then(debtProfile => {
            this.setState({customerDebtProfile: debtProfile, loading: false});
        }, error => {
            this.setState({loading: false});
        });
    }

		/**
		 * Get the ledger entry object for this record for this customer
		 */
    async getCurrentLedgerEntry(entryID: string) {
        await this.debtProvider.getDebtLedgerEntry(`${entryID}`).then(ledgerEntry => {
            this.setState({ledgerEntry, loading: false});
        }, error => {
            this.setState({loading: false});
        });
    }

    /**
     * get the customer profile
     * @param {string} phoneNumber
     * @return {Promise<void>}
     */
    async getCustomerDetails(phoneNumber: string) {
        try {
            const customer = await getCustomer(phoneNumber);
            this.setState({customer});
        } catch (e) {
            // catch global
            console.log(e);
        }
    }

    /**
     * get all customer activities for the current customer
     * @param {string} customerId
     * @return {Promise<void>}
     */
    getCustomerActivities = async (customerId: string) => {
        try {
            this.setState({loading: true});
            const customerActivities = await getCustomerActivities(`&msisdn=${customerId}`);
            this.setState({customerActivities});
        } catch (e) {
            // catch global
            console.log(e);
        } finally {
            this.setState({loading: false});
        }
    };

    /**
     * sends the customer a survey
     * @return {Promise<void>}
     */
    requestFeedback = async () => {
        this.setState({loading: true});
        const merchant: MerchantModel = await getFromStorage("merchant");
        if (merchant.ajua_account_details.subscription_status) {
            const {customer} = this.state;
            const {customerID} = this.props.location.state;
            const sendSurveyObject = {
                "phone_number": customerID || customer.msisdn,
                "merchant_id": merchant?.id?.$oid
            };
            await sendSurvey(sendSurveyObject)
                .then(message => {
                    this.setState({
                        loading: false,
                        toastOptions: {
                            toastOpen: true,
                            toastMessage: "Feedback request sent",
                            toastColor: "success"
                        }
                    });
                    // Track event
                    Amplitude.getInstance().logEvent(EVENTS_TRACKED.REQUEST_FEEDBACK,
                        {merchantDetails: merchant});
                })
                .catch(
                    error => {
                        this.setState({
                            loading: false,
                            toastOptions: {
                                toastOpen: true,
                                toastMessage: error || "request failed",
                                toastColor: "danger"
                            }
                        });
                    }
                );
        } else {
            this.setState({
                loading: false,
                toastOptions: {
                    toastOpen: true,
                    toastMessage: "Request for customer feedback is <strong>only available to paying subscribers.</strong>",
                    toastColor: "danger"
                }
            }, () => {
                this.props.history.push({pathname: '/payments-subscriptions'});
            });
        }
    };

    /**
     * Update customer notes
     * @return {Promise<void>}
     */
    updateNotes = async () => {
        const {customer} = this.state;
        this.setState({updatingCustomer: true});
        const merchant: any = await getFromStorage("merchant");
        const updateObject = {
            name: customer.name,
            notes: customer.notes,
            merchant_id: merchant?.id?.$oid,
            msisdn: customer.msisdn
        };
        await updateCustomerProfile(updateObject)
            .then(message => {
                this.setState({
                    toastOptions: {
                        toastOpen: true,
                        toastMessage: "Customer profile updated",
                        toastColor: "success"
                    }
                });
            })
            .catch(
                error => {
                    this.setState({
                        toastOptions: {
                            toastOpen: true,
                            toastMessage: error || "request failed",
                            toastColor: "danger"
                        }
                    });
                }
            );
        this.setState({updatingCustomer: false});
    };

    onTextInputChange = (name: string, e: any) => {
        const {customer} = this.state;
        this.setState({
            customer: {
                ...customer,
                [name]: e.detail.value
            }
        })
    };

    sendSMS(customerID: string) {
        this.props.history.push({pathname: "/send-message", state: {customer: customerID}})
    }

    sendOffer(customerID: string) {
        this.props.history.push({pathname: "/send-offer", state: {customer: customerID}})
    }

    async sendDebtReminder(customerID: string) {
        this.setState({loading: true}, async () => {
            await this.debtProvider.sendDebtReminder(this.state.merchant.id.$oid, `${customerID}-${this.state.merchant.id.$oid}`).then(response => {
                this.setState({
                    toastOptions: {
                        toastMessage: "<strong>Debt reminder</strong> to customer.",
                        toastOpen: true,
                        toastColor: "success"
                    },
                    loading: false
                });
            }, error => {
                this.setState({
                    toastOptions: {
                        toastMessage: "Error sending debt reminder. Please try again.",
                        toastOpen: true,
                        toastColor: "danger"
                    },
                    loading: false
                });
            });


        });
    }

    openDebtPayment(customerID: string) {
        this.props.history.push({pathname: "/debt-payment", state: {customerID: this.state.customerID}});
    }

    openDebtEditor(customerID: string) {
		this.props.history.push({pathname: "/log-debt", state: {entryID: this.state.entryID, customerID: this.state.customerID}});
    }

		//Cancelling debt is the same as a merchant paying the debt for the customer so we simply send a post with credit payload
    async cancelDebt(customerID: string) {
        //this.state.customerDebtProfile.ledger_balance === 0
        const payload = {
            credit: this.state.debt_value_to_cancel,
            notes: "",
            transaction_type: "CANCELLED",
            customer_id: `${customerID}-${this.state.merchant.id.$oid}`
        }
        
        this.setState({loading: true}, async () => {
            await this.debtProvider.payDebt(this.state.merchant.id.$oid, payload)
            .then(response => {
                    this.setState({
                            toastOptions: {
                                    toastMessage: "Debt cancelled successfully.",
                                    toastOpen: true,
                                    toastColor: "success"
                            },
                            loading: false
                    });
                    setTimeout(()=> {
                        this.props.history.push({pathname: "/main/debt"});
                    }, 500)
            }, error => {
                    this.setState({
                            toastOptions: {
                                    toastMessage: "Error cancelling. Please try again.",
                                    toastOpen: true,
                                    toastColor: "danger"
                            },
                            loading: false
                    });
            });


        });
        
    }

    confirmCancel = (debt_type: string) => {
        let msg = "";
        let debt_value = 0;
        const this_entry_val = this.state?.ledgerEntry?.debit
        const total_debt_val = Math.abs(this.state?.customerDebtProfile?.ledger_balance)
        switch(debt_type) {
            case 'all':
                debt_value = Math.abs(this.state?.customerDebtProfile?.ledger_balance)
                msg = "Do you want to cancel all the debt for this customer?";
                break;
            case 'this_entry':
                debt_value = this_entry_val > total_debt_val ? total_debt_val : this_entry_val
                msg = `Do you want to cancel this debt of ₦ ${debt_value}?`;
                break;
        }
        this.setState({
            alertOptions: {
                ...this.state.alertOptions, 
                alertOpen: true, 
                alertMessage: msg,
            },
            debt_value_to_cancel: debt_value
        });
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };

    closeAlert = () => {
        this.setState({alertOptions: {...this.state.alertOptions, alertOpen: false}});
    };

    backButtonPressed() {
        this.props.history.goBack()
    };

    render() {
        return (
            <IonPage>
                {/*<IonHeader>*/}
                {/*    <AjuaToolbar toolbar={{title: this.state?.customer?.name || this.state?.customer?.msisdn}}/>*/}
                {/*</IonHeader>*/}
                <IonContent>
                    <div className="customer-page">
                        <div className="back-navigation">
                            <IonIcon onClick={() => this.backButtonPressed()} icon={chevronBackOutline}/>
                            <p>Back</p>
                        </div>
                        <div className="profile-header">
                            <div className="avatar">
                                <p className="initials">{UtilityFunctions.getInitials(this.state?.customer?.name || '')}</p>
                            </div>
                            <div className="details">
                                <div className="customer-info">
                                    <p className="customer-name">{this.state?.customer?.name || this.state?.customer?.msisdn || 'Loading Name'}</p>
                                    {
                                        this.state?.customer?.msisdn ? (
                                            <p className="customer-phone">{`+${this.state?.customer?.msisdn}`}</p>
                                        ) : <p className="customer-phone">Loading Phone Number</p>
                                    }
                                </div>

                                <div className="contact-actions">

                                    <div className="action-container">
                                        <a href={`tel:0${this.state.customer.msisdn.substr(3)}`}
                                           className="action -green no-decoration">
                                            <IonIcon icon={call}/>
                                        </a>
                                        <p>Call</p>
                                    </div>

                                    <div className="action-container">
                                        <div onClick={() => this.sendSMS(this.state.customerID)} className="action">
                                            <IonIcon icon={chatboxEllipses}/>
                                        </div>
                                        <p>SMS</p>
                                    </div>

                                    <div className="action-container">
                                        <div onClick={() => this.sendOffer(this.state.customerID)}
                                             className="action -warning">
                                            <IonIcon icon={pricetag}/>
                                        </div>
                                        <p>Offer</p>
                                    </div>

                                    <div className="action-container">
                                        <div onClick={this.requestFeedback} className="action -purple">
                                            <IonIcon icon={chatbubbles}/>
                                        </div>
                                        <p>Feedback</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <IonLoading
                            cssClass=''
                            isOpen={this.state.loading}
                            message={'Loading'}/>

                        {
                            Object.entries(this.state.customerDebtProfile).length > 0 ? (
                                this.state.customerDebtProfile?.ledger_balance === 0 ? '' :
                                    (
                                        <div className="page-section pending-debt">
                                            <div className="section-content">
                                                <AjuaCard className={this.state?.customerDebtProfile?.ledger_balance > 0 ? '-paid' : ''}>
                                                    <div className="pending-debt-widget">
                                                        {this.state?.customerDebtProfile?.ledger_balance > 0 ? 
                                                        <p className="widget-title">You owe customer </p>
                                                         : 
                                                        <p className="widget-title">Customer owes you </p>}
                                                        <p className="amount">₦ {this.state?.customerDebtProfile?.ledger_balance?.toLocaleString() || '0.00'}</p>
                                                        <br/>
                                                        {this.state?.ledgerEntry?.debit > 0 || this.state?.ledgerEntry?.credit > 0 ?
													    <p className="widget-title-ledger">This Ledger entry {this.state?.ledgerEntry?.debit > 0}</p>
                                                        : ''}
                                                        {this.state?.ledgerEntry?.debit > 0 ? 
                                                            <p className="amount-ledger">Debt of ₦ {this.state?.ledgerEntry?.debit?.toLocaleString() || '0.00'}</p> 
                                                            : '' 
                                                        }
                                                        {this.state?.ledgerEntry?.credit > 0 ?
                                                        	<p className="amount-ledger">Payment of ₦ {this.state?.ledgerEntry?.credit?.toLocaleString() || '0.00'}</p>
													    : ''
                                                        }
                                                        {this.state.customerDebtProfile?.ledger_balance >= 0 ? '' :
                                                            <div className="widget-actions">
                                                                <button
                                                                    onClick={() => this.sendDebtReminder(this.state.customerID)}
                                                                    className="ajua-button -semi-bold -small-button ">
                                                                    <IonIcon
                                                                        className="-left" icon={notificationsOutline}/>Send
                                                                    Reminder
                                                                </button>
                                                                <button
                                                                    onClick={() => this.openDebtPayment(this.state.customerID)}
                                                                    className="ajua-button -semi-bold -small-button">
                                                                    <IonIcon className="-left" icon={cashOutline}/>Log
                                                                    Payment
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </AjuaCard>
                                            </div>
                                        </div>
                                    )
                            ) : ''
                        }

                        {
                        Object.entries(this.state.ledgerEntry).length > 0 ? (	
                            //Only display buttons to modify or cancel debt if this is a debit entry
                            this.state.ledgerEntry.debit === 0 || this.state?.customerDebtProfile?.ledger_balance===0 ? '' :
                            (
                                <div className="page-section actions-btns">
                                    <div className="section-content">
                                        <div className="actions-btns-widget">
                                            <div className="widget-actions">
                                                <button
                                                        onClick={() => this.openDebtEditor(this.state.customerID)}
                                                        className="ajua-button -semi-bold -small-button ">
                                                        <IonIcon
                                                                className="-left" icon={createOutline}/>Modify
                                                </button>
                                                <button
                                                        onClick={() => this.confirmCancel('this_entry')}
                                                        className="ajua-button -semi-bold -small-button">
                                                        <IonIcon className="-left" icon={trashOutline}/>
                                                            Cancel debt of ₦ {this.state?.ledgerEntry?.debit > Math.abs(this.state?.customerDebtProfile?.ledger_balance) ? 
                                                            Math.abs(this.state?.customerDebtProfile?.ledger_balance).toLocaleString() : Math.abs(this.state?.ledgerEntry?.debit).toLocaleString() || '0.00'}
                                                </button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            ) : ''
                        }
                        { 
                        Object.entries(this.state.customerDebtProfile).length > 0 ? (
                            this.state.customerDebtProfile?.ledger_balance >= 0 ? '' :
                            <div className="page-section actions-btns">
                                <div className="section-content">
                                    <button
                                            onClick={() => this.confirmCancel('all')}
                                            className="ajua-button -semi-bold -full-width -small-button">
                                            <IonIcon className="-left" icon={trashOutline}/>Cancel all the debt
                                    </button>
                                </div>
                            </div>
                        ) : ''
                        }
                        <div className="page-section">
                            <div className="page-section mb-0 customer-notes">
                                <div className="title">
                                    <p className="section-title">Notes</p>
                                </div>
                                <div className="section-content">
                                    <div className="notes">
                                        <IonTextarea className="ajua-textarea" value={this.state?.customer?.notes}
                                                     onIonChange={e => this.onTextInputChange("notes", e)}
                                                     placeholder="Enter any extra information about this customer"/>
                                    </div>
                                    <button onClick={this.updateNotes}
                                            className="ajua-button -full-width -medium-button">
                                        {!this.state.updatingCustomer ?
                                            (<><IonIcon className="mr-1" icon={saveSharp}/> Save Notes</>) :
                                            (<><IonSpinner color="black" name="crescent"/> &nbsp; Updating...</>)}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="page-section breakdown">
                            <div className="title">
                                <p className="section-title"> Activity</p>
                            </div>

                            <div className="section-content">
                                {this.state.loading && (<IonSpinner color="black" name="crescent"/>)}
                                {this.state.customerActivities?.length > 0 ?
                                    this.state.customerActivities.map((activity, index) => {
                                        return <ActivityCard key={index} activity={activity}
                                                             USSD={this.state.merchant.ussd_string}/>
                                    }) : <EmptyState title={'No Activity'}
                                                     description={'This customer has not yet interacted with your business.'}
                                                     icon={'assets/icons/cactus.svg'}/>
                                }
                            </div>
                        </div>
                        <Toast message={this.state.toastOptions?.toastMessage} open={this.state.toastOptions?.toastOpen}
                               position="bottom" color={this.state.toastOptions?.toastColor}
                               closeToast={this.closeToast}/>

                        <IonAlert
                            isOpen={this.state.alertOptions?.alertOpen}
                            onDidDismiss={this.closeAlert}
                            cssClass='my-custom-class'
                            header={'Cancel debt!'}
                            message={this.state.alertOptions?.alertMessage}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: this.closeAlert
                                },
                                {
                                    text: 'Okay',
                                    handler: () => {
                                        this.cancelDebt(this.state.customerID)
                                    }
                                }
                            ]}
                        />
                    </div>

                </IonContent>

            </IonPage>
        )
    }
}

export default withIonLifeCycle(Customer);

import ApiService from "../../shared/services/network-services/api.service";
import URL_ENDPOINTS from "../../shared/url-endpoints";

export default class NewReferralService {
    async getMerchantReferralCode(merchant_id: string): Promise<{ referral_code: string }> {
        const apiService = new ApiService();
        const postObj = {merchant_id};
        return await apiService.createResource(postObj, URL_ENDPOINTS.referralsUrl)
            .then((response: any) => {
                return response?.data;
            })
            .catch(
                error => {
                    throw error
                });
    }

    async sendReferralCode(merchant_id: string, referral_number: string): Promise<{ referral_code: string }> {
        const apiService = new ApiService();
        const postObj = {merchant_id, referral_number};
        return await apiService.createResource(postObj, URL_ENDPOINTS.referralsUrl)
            .then((response: any) => {
                return response?.data;
            })
            .catch(
                error => {
                    throw error
                }
            );
    }
}

import React from "react";
import "./payments-and-subscriptions.sass"
import {IonBadge, IonContent, IonHeader, IonLoading, IonPage, IonModal, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {cardOutline} from "ionicons/icons";
import FloatingActionBar from "../../shared/components/floating-action-bar/floatingActionBar";
import MerchantProvider from "../../shared/services/providers/merchant.provider";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import ApiService from "../../shared/services/network-services/api.service";
import { URL_ENDPOINTS } from "../../shared/url-endpoints";
import {Toast} from "../../shared/components/generic/toast";
import { capitalize } from "../../shared/helpers/utility-functions"
import moment from "moment";
import { SocialSharing } from "@ionic-native/social-sharing";
import environment from "../../environments/environment";

interface IProps {

}

interface IState {
    plans: IPaymentPlan[],
    merchant: MerchantModel,
    selectedPlan: IPaymentPlan,
    pageLoading: boolean,
    showPaymentInstructions: boolean,
    initiatingPayment: boolean,
    toastOptions: {
        toastMessage?: string,
        toastOpen: boolean,
        toastColor?: string,
    },
    showSubsOptionChooserModal: boolean
}

interface IPaymentPlan {
    duration: string,
    title: string,
    description: string,
    cost: number,
    selected: boolean,
    keyword: string
}

class PaymentsAndSubscriptions extends React.Component<any, IState> {

    async ionViewDidEnter() {
        const merchant = await MerchantProvider.refreshOfflineMerchantProfile();
        if (merchant) {
            await this.setupSelectedSubscription();
            this.setState({merchant, pageLoading: false});
        }
    }

    async ionViewWillLeave() {
        // Update the offline merchant profile
        await MerchantProvider.refreshOfflineMerchantProfile();
    }

    constructor(props: any) {
        super(props);
        this.state = {
            plans: [
                {
                    duration: 'monthly',
                    title: 'Mnthly',
                    description: 'Pay for the service on a monthly basis.',
                    cost: 500,
                    selected: false,
                    keyword: 'ENGM'
                },
                {
                    duration: 'yearly',
                    title: 'Yearly',
                    description: 'Use the service uninterrupted for a whole year.',
                    cost: 5500,
                    selected: false,
                    keyword: 'ENGY'
                }
            ],
            selectedPlan: {} as IPaymentPlan,
            showPaymentInstructions: false,
            initiatingPayment: false,
            merchant: {} as MerchantModel,
            pageLoading: true,
            toastOptions: {
                toastMessage: "success",
                toastOpen: false,
                toastColor: "success",
            },
            showSubsOptionChooserModal: false,
        }
    }

    async setupSelectedSubscription() {
        const selectedPlan: IPaymentPlan = await getFromStorage(STORAGE_FIELDS.SUBSCRIPTION);
        if (selectedPlan) {
            const updatedPlans = this.state.plans.map(plan => {
                plan.selected = plan.duration === selectedPlan.duration;
                return plan;
            });
            this.setState({plans: updatedPlans});
        }
    }

    async changePaymentPlan(plan: IPaymentPlan, index: number) {
        this.setState({
            selectedPlan: plan,
            plans: this.state.plans.map(item => {
                if (item === plan) {
                    item.selected = true;
                    return item
                } else {
                    item.selected = false;
                    return item
                }
            })
        });

        await saveToStorage(STORAGE_FIELDS.SUBSCRIPTION, plan);
    }

    async showSubsOptionChooser (status: boolean, plan?: IPaymentPlan) {
        this.setState({
            showSubsOptionChooserModal: status
        })
        if(status && plan) {
            this.setState({
                selectedPlan: plan,
                plans: this.state.plans.map(item => {
                    if (item === plan) {
                        item.selected = true;
                        return item
                    } else {
                        item.selected = false;
                        return item
                    }
                })
            });
        }

        await saveToStorage(STORAGE_FIELDS.SUBSCRIPTION, plan);
    }

    get selectedPlan() {
        return this.state.plans.find((plan: IPaymentPlan) => {
            return plan.selected;
        })
    }

    openSuccessPage() {
        this.props.history.replace('/successful-payment');
    }

    async paySubscription() {
        this.setState({initiatingPayment: true}, async () => {
            const apiService = new ApiService();

            if (this.state.merchant?.collaborators) {
                const postObj = {
                    msisdn: this.state?.merchant?.collaborators[0].msisdn,
                    amount: this.selectedPlan?.cost,
                    frequency: this.selectedPlan?.duration
                };

                return await apiService.createResource(postObj, URL_ENDPOINTS.paymentHookUrl)
                    .then((response: any) => {
                        if (response.status === 200) {
                            this.setState({
                                initiatingPayment: false, showPaymentInstructions: false, toastOptions: {
                                    toastOpen: true,
                                    toastMessage: "Initiating payment...",
                                    toastColor: "success",
                                }
                            }, () => {
                                this.props.history.replace({pathname: '/settings'})
                            });
                        }
                    })
                    .catch(
                        error => {
                            this.setState({
                                initiatingPayment: false, toastOptions: {
                                    toastOpen: true,
                                    toastMessage: "Error initiating payment. Try again.",
                                    toastColor: "error",
                                }
                            });
                            throw error
                        }
                    );
            }
        });
    }

    paySubscriptionBySMS() {
        this.showSubsOptionChooser(false)
        SocialSharing.shareViaSMS(this.state.selectedPlan.keyword, environment.SMS_SHORT_CODE)
    }

    paySubscriptionByUSSD() {
        this.showSubsOptionChooser(false)
        this.setState({showPaymentInstructions: true})
    }

    closeToast = () => {
        this.setState({toastOptions: {toastOpen: false}});
    };

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Payments & Subscriptions"}}/>
                </IonHeader>
                {this.state.pageLoading &&
                (<ContentLoader/>)
                }
                <IonContent>

                    {
                        this.state.showPaymentInstructions ? <div className="payment-instructions">
                            <IonLoading
                                cssClass=''
                                isOpen={this.state.initiatingPayment}
                                message={'Loading'}/>

                            <p className="title">Subscription Payment</p>
                            <p className="description">
                                    Click <span>Proceed</span> to initiate payment of <strong>₦
                                {this.selectedPlan?.cost.toLocaleString()}</strong> towards
                                a <span>{this.selectedPlan?.duration} subscription</span>. You will be asked to confirm
                                payment as shown below:
                            </p>
                            <img className="ussd-instructions" src={'assets/images/payment_instructions.png'} alt="none"/>
                            <div className="payment-actions">
                                <button onClick={() => {
                                    this.setState({showPaymentInstructions: false})
                                }} className="ajua-button -medium-button">Cancel
                                </button>
                                <button onClick={() => this.paySubscription()}
                                        className="ajua-button -medium-button -success">Proceed
                                </button>
                            </div>
                        </div> 
                        : ''
                    }

                    <div className="subscription-payment">

                        <div className="page-section">
                            <div className="section-content">
                                <FloatingActionBar>
                                    <div className="payment-status">
                                        <AjuaCard>
                                            <p>
                                                <strong>Subscription Status</strong>:
                                                {
                                                    this.state?.merchant?.ajua_account_details?.subscription_status ? (
                                                        <IonBadge color={'success'}>Subscribed</IonBadge>
                                                    ) : (
                                                        <IonBadge color={'warning'}>Not Subscribed</IonBadge>
                                                    )
                                                }
                                            </p>
                                        </AjuaCard>
                                    </div>
                                </FloatingActionBar>

                                <div className="payment-plans">
                                    <div className="sub-section">
                                        <p className="sub-section-title -no-padding -margin-bottom -black">
                                            {this.state?.merchant?.ajua_account_details?.subscription_status ? 
                                            'Your current plan' :
                                            'Payment Plans'}
                                            
                                        </p>
                                    </div>
                                    
                                    { this.state?.merchant?.ajua_account_details?.subscription_status
                                        ?   
                                        <>
                                            <div className={`subscription-card ajua-card`}>
                                                <div className="plan">
                                                    <p className="name">{this.state?.merchant?.ajua_account_details?.subscriptions_package}</p>
                                                    <p className="description">Expires on {moment(this.state?.merchant?.ajua_account_details?.subscription_expiry_date.$date).format('Do MMM YYYY')}</p>
                                                </div>
                                                <div className="cost">
                                                    <p>
                                                        {this.state?.merchant?.ajua_account_details?.subscription_amount === 'free' ? 
                                                        capitalize(this.state?.merchant?.ajua_account_details?.subscription_amount) : `₦ ${this.state?.merchant?.ajua_account_details?.subscription_amount}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        (
                                        // <div className="sub-section">
                                        //     <p className="sub-section-title -no-padding -margin-bottom -black">Payment Plans</p>
                                        // </div>
                                        
                                        this.state?.plans.map((plan, index) => {
                                            return (
                                                <div key={index} onClick={() => this.showSubsOptionChooser(true, plan)}
                                                     className={`subscription-card ajua-card ${plan.selected ? '-selected' : ''}`}>
                                                    <div className="plan">
                                                        <p className="name">{plan.duration}</p>
                                                        <p className="description">{plan.description}</p>
                                                    </div>
                                                    <div className="cost">
                                                        <p>₦ {plan.cost.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        )
        
                                    }

                                    {/*
                                    {
                                        // DELETE THIS
                                        this.state?.plans.map((plan, index) => {
                                            return (
                                                <div key={index}  onClick={() => this.showSubsOptionChooser(true, plan)}  
                                                     className={`subscription-card ajua-card ${plan.selected ? '-selected' : ''}`}>
                                                    <div className="plan">
                                                        <p className="name">{plan.duration}</p>
                                                        <p className="description">{plan.description}</p>
                                                    </div>
                                                    <div className="cost">
                                                        <p>₦ {plan.cost.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }*/}

                                    {
                                        !this.state.merchant?.ajua_account_details?.subscription_status ?
                                            <button onClick={() => {
                                                this.setState({showPaymentInstructions: true})
                                            }} className="ajua-button -success -full-width mt-1">Pay Subscription</button> : ''
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <IonModal isOpen={this.state.showSubsOptionChooserModal} cssClass='subscription-options-modal' onDidDismiss={() => this.showSubsOptionChooser(false)}>
                        <div className="actions-title">
                            <h3 className="modal-title">{this.state.selectedPlan.title} subscription</h3>
                            <button className="btn-close2 ajua-button" onClick={() => this.showSubsOptionChooser(false)}>&times;</button>
                        </div>
                        <div className="actions-modal-subtitle">Choose your payment method</div>
                        <div className="actions-grid">
                            <div className="actions-item ajua-card" onClick={()=> this.paySubscriptionBySMS()}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_sms.svg'} alt=""/>
                                </div>
                                <div className="item-title">
                                    <div className="item-option-title">SMS</div>
                                    <div>Click here to send "<strong>{this.state.selectedPlan.keyword}</strong>" to 6032 to complete  payment</div>
                                </div>
                            </div>
                            <div className="actions-item ajua-card" onClick={() => this.paySubscriptionByUSSD()}>
                                <div className="item-img">
                                    <img src={'assets/icons/ic_offer_other_apps.svg'} alt=""/>
                                </div>
                                <div className="item-title">
                                    <div className="item-option-title">USSD</div>
                                    <div>Click here to continue</div>
                                </div>
                            </div>
                        </div>
                    </IonModal>
                    <Toast message={this.state.toastOptions.toastMessage} open={this.state.toastOptions.toastOpen}
                           position="bottom" color={this.state.toastOptions.toastColor}
                           closeToast={this.closeToast}/>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(PaymentsAndSubscriptions);

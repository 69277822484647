import React from "react";
import "./debt-payment.sass";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {
    IonContent,
    IonHeader,
    IonInput,
    IonPage,
    IonSelect,
    IonSelectOption,
    withIonLifeCycle
} from "@ionic/react";
import {ErrorMessage, Field, FieldProps, Form, Formik} from "formik";
import {CustomerModel} from "../../shared/models/customer.model";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import {Toast} from "../../shared/components/generic/toast";
import {MerchantModel} from "../../shared/models/merchant.model";
import * as yup from "yup";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {getActivities} from "../../shared/apiCalls/customerCalls";
import DebtProvider from "../debt/debt.provider";

interface IState {
    customerID: string,
    customers: CustomerModel[],
    pageLoading: boolean,
    submittingPayment: boolean,
    merchant?: MerchantModel
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string,
    }
}

interface PayDebtSchema {
    credit: number
}

class DebtPayment extends React.Component<any, any> {
    private debtProvider: DebtProvider = new DebtProvider();

    // Credit logging schema
    private debtPaymentValidationSchema = yup.object().shape({
        credit: yup.number().required('Kindly enter the amount paid by customer.')
    });

    state = {
        customerID: '',
        customers: [],
        pageLoading: true,
        submittingPayment: false,
        merchant: {} as MerchantModel,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: "success",
        }
    };

    async ionViewWillEnter() {
        const customerID = this.props.location.state?.customerID;
        this.setState({customerID});
    }

    async ionViewDidEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);

        // const customers = await getActivities("&activity_id=0");
        this.setState({
            // customers,
            pageLoading: false,
            merchant
        })
    }

    async payDebt(values: PayDebtSchema, resetForm: any) {
        this.setState({loadinig: true}, async () => {
            await this.debtProvider.payDebt(this.state.merchant.id.$oid, {
                ...values,
                customer_id: `${this.state.customerID}-${this.state.merchant.id.$oid}`
            }).then(response => {
                resetForm({});
                this.setState({
                    toastOptions: {
                        toastMessage: "Submitted payment successfully.",
                        toastOpen: true,
                        toastColor: "success"
                    },
                    loading: false
                });
                setTimeout(() => {
                    this.props.history.goBack();
                }, 500);
            }, error => {
                this.setState({
                    toastOptions: {
                        toastMessage: "Error submitting payment. Please try again.",
                        toastOpen: true,
                        toastColor: "danger"
                    },
                    loading: false
                });
            });
        });
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Debt Payment"}}/>
                </IonHeader>
                <IonContent>
                    <div className="debt-payment">
                        <div className="page-section">
                            <div className="section-content log-debt-form">
                                <Formik
                                    initialValues={{
                                        credit: 0,
                                    }}
                                    validationSchema={this.debtPaymentValidationSchema}
                                    onSubmit={(values, {resetForm}) => this.payDebt(values, resetForm)}>
                                    {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                        <Form>
                                            <Field type="text" id="credit" name="credit">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Amount</p>
                                                        <IonInput {...fieldProps.field}
                                                                  onBlur={handleBlur}
                                                                  onIonChange={handleChange}
                                                                  className="ajua-input"
                                                                  type="number"
                                                                  inputMode="numeric" name="credit"
                                                                  placeholder="Enter the amount paid."/>
                                                        <ErrorMessage name="credit">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <button disabled={!(isValid && dirty)} type="submit"
                                                    className="ajua-button -success -full-width">
                                                Submit Payment
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        <Toast message={this.state.toastOptions?.toastMessage} open={this.state.toastOptions?.toastOpen}
                               position="bottom" color={this.state.toastOptions?.toastColor}
                               closeToast={this.closeToast}/>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(DebtPayment);

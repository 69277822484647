import ApiService from "../../shared/services/network-services/api.service";
import {getActivities} from "../../shared/apiCalls/customerCalls";

class OfferDetailsProvider {
    public apiService: ApiService = new ApiService();

    /*
    * Fetch the details of an offer
    * @param offerID: string
    * @param merchantID: string
    * @returns
    * */
    async fetchOfferDetails(offerID: string, merchantID: string) {
        return this.apiService.getResource(`/offers?offer_id=${offerID}&merchant_id=${merchantID}`)
            .then((response: any) => {
                return response.data.offer;
            })
    }


    static OfferDetailsData = {
        offerDetailsSummaries: [
            {
                title: "Value Redeemed",
                value: "₦ 0.00",
                description: "Value of offer claimed this month",
                type: "success"
            },
            {
                title: "New Customers",
                value: "0",
                description: "Customers who claimed this offer",
            }
        ],
    };

    async getCustomerActivities() {
        return await getActivities(`&activity_id=${5}`);
    };

    async updateOfferStatus(active: boolean, offerID: string) {
        const postObj = {
            active,
            offerID: offerID
        };
        return await this.apiService.createResource(postObj, `/offers`,)
            .then((response: any) => {
                return response.data;
            })
    }
}

export default OfferDetailsProvider;

import React from "react";
import "./edit-profile.sass";
import {
    IonContent,
    IonHeader, IonIcon,
    IonInput, IonLoading,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea, withIonLifeCycle
} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {ErrorMessage, Field, FieldProps, Form, Formik} from "formik";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import {COUNTRIES, INDUSTRIES, NIGERIAN_STATES} from "../../shared/helpers/data.list";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import PhoneInput from "react-phone-number-input";
import StyleHelper from "../../shared/helpers/style.helper";
import {Toast} from "../../shared/components/generic/toast";
import * as yup from "yup";
import {ToastNotificationModel} from "../../shared/models/toast-notification.model";
import {checkmarkSharp} from 'ionicons/icons';
import {CollaboratorsModel, MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import EditProfileService from "../../shared/apiCalls/edit-profile.service";
import {SummaryCardModel} from "../../shared/components/summary-card/summaryCard.model";
import MerchantProvider from "../../shared/services/providers/merchant.provider";

interface IState {
    toastOptions: ToastNotificationModel,
    merchant: MerchantModel,
    pageLoading: boolean,
    loading: boolean
}

interface EditProfileSchema {
    business_name: string,
    industry_name?: string,
    business_description: string,
    full_name: string,
    phone_number: string,
    state?: string,
    turnover?: string,
    number_of_staff?: string,
    email_address?: string
}

class EditProfile extends React.Component<any, IState> {
    // Services
    private editProfileService = new EditProfileService();

    // View
    private list_of_states: { state: string; lgas: string[] }[] = NIGERIAN_STATES;

    // Merchant registration form schema
    private editProfileValidationSchema = yup.object().shape({
        business_name: yup.string().required('The name of your business is required.').nullable(),
        industry_name: yup.string().nullable(),
        business_description: yup.string().required('A description of your business is required.').nullable(),
        full_name: yup.string().required('Your name is required.').nullable(),
        phone_number: yup.string().required('The business number is required.').test('phone_number', 'Enter a valid phone number', UtilityFunctions.validatePhoneNumber).nullable(),
        state: yup.string().required('State is required').nullable(),
        turnover: yup.string().nullable(),
        number_of_staff: yup.string().nullable(),
        email_address: yup.string().email('Invalid email provided').nullable()
    });

    state: IState = {
        pageLoading: true,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: "success",
        },
        merchant: {} as MerchantModel,
        loading: false
    };

    async ionViewWillEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({merchant});
    }

    async updateProfile(values: EditProfileSchema, resetForm: any) {
        const newVals = {...values, ...this.getFirstLastName(values.full_name)}
        this.setState({loading: true}, async () => {
            const merchantID = this.state?.merchant?.id.$oid;            
            await this.editProfileService.registerMerchant(merchantID, newVals).then(async (resp) => {
                const updatedProfile = await MerchantProvider.refreshOfflineMerchantProfile();
                await saveToStorage(STORAGE_FIELDS.MERCHANT, updatedProfile);

                this.setState({
                    loading: false, toastOptions: {
                        toastMessage: "Successfully updated profile.",
                        toastOpen: true,
                        toastColor: "success",
                    }
                })
            }, error => {
                this.setState({
                    loading: false, toastOptions: {
                        toastMessage: "Failed to update profile. Try again.",
                        toastOpen: true,
                        toastColor: "danger",
                    }
                })
            });
        });
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };

    getFirstLastName(name: string): { first_name: string, last_name: string } {
        const merchantName: string[] = name.split(' ');
        let last_name, first_name;
        if (merchantName.length > 1) {
            first_name = merchantName[0];
            last_name = merchantName[1];
        } else {
            first_name = merchantName[0];
            last_name = '';
        }

        return { first_name, last_name }
    }

    get merchantCollaboratorProfile(): CollaboratorsModel {
        if (Object.entries(this.state.merchant).length > 0) {
            if (this.state.merchant.collaborators)
                return this.state.merchant.collaborators[0]
        }
        return {} as CollaboratorsModel
    }

    selectedCity(selectedState: string): string {
        return this.list_of_states[this.list_of_states.findIndex(state => {
            return state.state === selectedState;
        })].lgas[0]
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Edit business profile"}}/>
                </IonHeader>
                <IonContent>
                    {
                        Object.entries(this.state.merchant).length > 0 ? (
                            <div className="edit-profile-page">
                                <IonLoading
                                    cssClass=''
                                    isOpen={this.state.loading}
                                    message={'Submitting'}/>

                                <Formik
                                    initialValues={{
                                        business_name: this.state.merchant?.business_name,
                                        industry_name: this.state.merchant?.ajua_account_details?.industry,
                                        business_description: this.state.merchant?.business_description,
                                        full_name: this.merchantCollaboratorProfile?.first_name && this.merchantCollaboratorProfile?.first_name + ' ' + this.merchantCollaboratorProfile?.last_name,
                                        phone_number: `+${this.merchantCollaboratorProfile.msisdn}`,
                                        state: this.state.merchant?.ajua_account_details?.state,
                                        turnover: this.state.merchant?.turnover,
                                        number_of_staff: this.state.merchant?.number_of_staff,
                                        email_address: this.merchantCollaboratorProfile?.email_address
                                    }}
                                    isInitialValid={false}
                                    validationSchema={this.editProfileValidationSchema}
                                    onSubmit={(values, {resetForm}) => this.updateProfile(values, resetForm)}>

                                    {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                        <Form>
                                            <Field validate={UtilityFunctions.validatePhoneNumber} name="full_name">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Your full name</p>
                                                        <IonInput {...fieldProps.field}
                                                                  className="ajua-input" type="text"
                                                                  onBlur={handleBlur}
                                                                  onIonChange={handleChange}
                                                                  placeholder="Your full name"
                                                                  name="full_name"/>
                                                        <ErrorMessage name="full_name">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

											<Field type="text" id="business_name" name="business_name">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            Business name</p>
                                                        <IonInput type="text" className="ajua-input"
                                                                  placeholder="e.g Bobo's Barbershop"
                                                                  {...fieldProps.field}
                                                                  onIonChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  name="business_name"/>
                                                        <ErrorMessage name="business_name">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field name="phone_number">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Business phone number</p>
                                                        <PhoneInput
                                                            {...fieldProps.field}
                                                            onBlur={handleBlur}
                                                            inputMode="tel"
                                                            countries={['NG']}
                                                            autoComplete="on"
                                                            onChange={(value) => setFieldValue('phone_number', value)}
                                                            displayInitialValueAsLocalNumber
                                                            defaultCountry='NG'
                                                            className="ajua-input"
                                                            placeholder="Enter phone number"
                                                            name="phone_number"/>
                                                        <ErrorMessage name="phone_number">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>
                                            <Field type="text" name="email_address">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            Business email address</p>
                                                        <IonInput type="email" className="ajua-input"
                                                                  {...fieldProps.field}
                                                                  placeholder="e.g bobo@gmail.com"
                                                                  onIonChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  name="email_address"/>
                                                        <ErrorMessage name="email_address">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

											<Field name="business_description">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Business description</p>
                                                        <IonTextarea {...fieldProps.field}
                                                                     className="ajua-textarea"
                                                                     onBlur={handleBlur}
                                                                     onIonChange={handleChange}
                                                                     placeholder="e.g The best barbershop in Lagos"
                                                                     name="business_description"/>
                                                        <ErrorMessage name="business_description">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>
																						
											<Field type="text" name="number_of_staff">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">
                                                            How many employees do you have?</p>
                                                        <IonInput type="number" inputmode="numeric"
                                                                  className="ajua-input"
                                                                  {...fieldProps.field}
                                                                  placeholder="e.g 10"
                                                                  onIonChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  name="number_of_staff"/>
                                                        <ErrorMessage name="number_of_staff">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field type="text" name="turnover">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label"> Monthly Gross Income of the business(₦)</p>
                                                        <IonInput type="number" className="ajua-input"
                                                                  placeholder="e.g ₦ 20,000"
                                                                  onIonChange={handleChange}
                                                                  {...fieldProps.field}
                                                                  onBlur={handleBlur}
                                                                  name="turnover"/>
                                                        <ErrorMessage name="turnover">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field name="industry_name">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">Industry <small>(optional)</small>
                                                        </p>

                                                        <IonSelect {...fieldProps.field}
                                                                   name="industry_name"
                                                                   onBlur={handleBlur}
                                                                   interface="action-sheet"
                                                                   onIonChange={handleChange}
                                                                   className="ajua-select"
                                                                   placeholder="Select Industry"
                                                                   >
                                                                       {/* value={this.state.merchant?.ajua_account_details?.industry} */}
                                                            {INDUSTRIES.sort().map((industry, index) => {
                                                                return <IonSelectOption key={index}
                                                                                        value={industry}>{industry}</IonSelectOption>
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                )}
                                            </Field>

                                            <Field name="state">
                                                {(fieldProps: FieldProps) => (
                                                    <div className="form-group">
                                                        <p className="ajua-form-label">State</p>
                                                        <IonSelect {...fieldProps.field} name="state"
                                                                   className="ajua-select"
                                                                   interface="action-sheet"
                                                                   onBlur={handleBlur}
                                                                   onIonChange={(change) => setFieldValue('state', change.detail.value)}
                                                                   placeholder="Select State"
                                                                   >
                                                                       {/* value={this.state.merchant?.ajua_account_details?.state} */}
                                                            {this.list_of_states.map((state, index) => {
                                                                return <IonSelectOption key={index} value={state.state}>
                                                                    {StyleHelper.capitalize(state.state)}</IonSelectOption>
                                                            })}
                                                        </IonSelect>
                                                        <ErrorMessage name="state">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                            <button type="submit" className="ajua-button -success -full-width"
                                                    disabled={!(isValid && dirty)}>
                                                <IonIcon icon={checkmarkSharp} className="-left"/>Update Profile
                                            </button>
                                        </Form>
                                    )}
                                </Formik>

                                <Toast message={this.state.toastOptions.toastMessage}
                                       open={this.state.toastOptions.toastOpen}
                                       position="bottom"
                                       color={this.state.toastOptions.toastColor}
                                       closeToast={this.closeToast}/>
                            </div>
                        ) : ''
                    }
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(EditProfile);

import {
    IonContent,
    IonFab,
    IonFabButton, IonFabList,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs, withIonLifeCycle,
    isPlatform
} from "@ionic/react";
import React from "react";
import "./main.sass";
import {Link, Redirect, Route} from "react-router-dom";
import Pulse from "../pulse/pulse";
import Customers from "../customers/customers";
import Offers from "../offers/offers";
import { addOutline, megaphone} from "ionicons/icons";
import ComingSoon from "../coming-soon/comingSoon";
import QuickActions from "../home/home";
import {saveToStorage, getFromStorage, SECURE_STORAGE_FIELDS, STORAGE_FIELDS} from "../../shared/helpers/storage";
import * as Amplitude from "amplitude-js";
import {MerchantModel} from "../../shared/models/merchant.model";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import Debt from "../debt/debt";
// import { FCM } from '@ionic-native/fcm'
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core'
import { getActivities } from "../../shared/apiCalls/customerCalls";
import { Appsflyer } from '@ionic-native/appsflyer'

const { PushNotifications } = Plugins

const INITIAL_STATE = {
    notifications: [{ id: 'id', title: "Test Push", body: "This is my first push notification" }],
  };

class Main extends React.Component<any, any> {

    state: any = {};
    props: any = {};

    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    async ionViewWillEnter() {
        await this.updateProfileMilestones();
    }

    async updateProfileMilestones() {
      const customers = await getActivities("&activity_id=0");
      const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
      const paymentInfo = await getFromStorage(STORAGE_FIELDS.MERCHANT_PAYMENT_INFO);
  
      await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, {
        addBusinessDetails: merchant?.business_description !== null,
        addPaymentInfo: paymentInfo?.account_number !== undefined,
        addCustomer: customers.length > 0
      });
    }

    async componentDidMount() {
        await this.initializeAnalytics();
        await this.initialiseAppsFlyer();
        // if (isPlatform('pwa')) {
        await this.push()
        // }
    }

    push() {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        // console.log('push()');
    
        // On succcess, we should be able to receive notifications
        PushNotifications.addListener('registration',
          (token: PushNotificationToken) => {
            // alert('Push registration success, token: ' + token.value);
            // console.log('Push registration success, token: ' + token.value);
            // Todo
            // Add endpoint to send token to the server database
          }
        );
    
        // Some issue with your setup and push will not work
        PushNotifications.addListener('registrationError',
          (error: any) => {
            // alert('Error on registration: ' + JSON.stringify(error));
          }
        );
    
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotification) => {
            let notif = this.state.notifications;
            notif.push({ id: notification.id, title: notification.title, body: notification.body })
            this.setState({
              notifications: notif
            })
          }
        );
    
        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: PushNotificationActionPerformed) => {
            let notif = this.state.notifications;
            notif.push({ id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body })
            this.setState({
              notifications: notif
            })
          }
        );
      }

    /*
    * Kicks off the logging of the current user session
    * */
    async initializeAnalytics() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT)
        if (merchant) {
            if (merchant.collaborators && merchant.collaborators.length > 0) {
                let identify = new Amplitude.Identify().set('merchant', merchant)
                identify.set('subscribed', merchant.ajua_account_details.subscription_status)

                Amplitude.getInstance().identify(identify)

                // Track event
                Amplitude.getInstance().logEvent(EVENTS_TRACKED.LOGIN,
                    {merchantID: merchant?.collaborators[0]?.msisdn})
            }

        }
    }

    initialiseAppsFlyer = () => {
        const options = {
            devKey: 'URv5uZxxYpSw9tqSV4qoy7', // your AppsFlyer devKey
            isDebug: false,
            onInstallConversionDataListener: true,
        }
        Appsflyer.initSdk(options)
    }

    render() {
        return (
            <>
                <IonContent>
                    <Route path="/main">
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            <IonFabButton>
                                <IonIcon icon={addOutline}/>
                            </IonFabButton>
                            <IonFabList side="top">
                                <Link className="fab-link" to="/create-ad">
                                    <IonFabButton data-desc="Create Ads">
                                    <IonIcon className="fab-icon" icon={megaphone} />
                                    </IonFabButton>
                                </Link>
                                <Link className="fab-link" to={{
                                                                pathname: "/log-debt",
                                                                state: { 
                                                                    customerID: "", 
                                                                    entryID: "", 
                                                                    }
																}}>
                                    <IonFabButton data-desc="Log Debt">
                                        <i className="fab-icon icon-loan"/>
                                    </IonFabButton>
                                </Link>
                                <Link className="fab-link" to="/create-offer">
                                    <IonFabButton data-desc="Create Offer">
                                        <i className="fab-icon icon-promotions"/>
                                    </IonFabButton>
                                </Link>
                                <Link className="fab-link" to="/select-customer-import-method">
                                    <IonFabButton data-desc="Add Customer">
                                        <i className="fab-icon icon-add-group"/>
                                    </IonFabButton>
                                </Link>
                            </IonFabList>
                        </IonFab>
                    </Route>

                </IonContent>

                <IonTabs>
                    <IonRouterOutlet>
                        <Route path="/main/home" component={QuickActions} exact={true}/>
                        <Route path="/main/pulse" component={Pulse} exact={true}/>
                        <Route path="/main/customers" component={Customers} exact={true}/>
                        <Route path="/main/offers" component={Offers} exact={true}/>
                        <Route path="/main/debt" component={Debt} exact={true}/>
                        <Route path="/main" render={() => <Redirect to="/main/home"/>} exact={true}/>
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                        <IonTabButton tab="quick-actions" href="/main/home">
                            <i className="bottom-nav-icon icon-home"/>
                            <IonLabel className="bottom-nav-label">Home</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="pulse" href="/main/pulse">
                            <i className="bottom-nav-icon icon-pulse"/>
                            <IonLabel className="bottom-nav-label">Pulse</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="customers" href="/main/customers">
                            <i className="bottom-nav-icon icon-people"/>
                            <IonLabel className="bottom-nav-label">Customers</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="offers" href="/main/offers">
                            <i className="bottom-nav-icon icon-promotions"/>
                            <IonLabel className="bottom-nav-label">Offers</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="debt" href="/main/debt">
                            <i className="bottom-nav-icon icon-loan"/>
                            <IonLabel className="bottom-nav-label">Debt</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </>
        );
    }
}

export default withIonLifeCycle(Main);

import { URL_ENDPOINTS } from "../../shared/url-endpoints";
import ApiService from "../../shared/services/network-services/api.service";

export default class UserProfileService {
    private apiService = new ApiService();

    async updateMerchantDetails<T>(postObj: object): Promise<T> {
        const endpoint = URL_ENDPOINTS.updateMerchant;
        return this.apiService.updateResource(postObj, endpoint);
    }
}

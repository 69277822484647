import React from 'react';
import { IonCheckbox, IonContent, IonPage, withIonLifeCycle } from '@ionic/react';
import './debt.sass';
import SummaryCard from "../../shared/components/summary-card/summaryCard";
import DebtData from "./debt.data";
import { Link } from "react-router-dom";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import { DebtEntryModel } from "./debt.model";
import EmptyState from "../../shared/components/empty-state/emptyState";
import moment from "moment";
import DebtProvider from "./debt.provider";
import { getFromStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import { MerchantModel } from "../../shared/models/merchant.model";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import RevenueGraphWidget from "../../shared/components/revenue-graph-widget/revenueGraphWidget"
import { Toast } from "../../shared/components/generic/toast";
import { nFormatter } from '../../shared/helpers/utility-functions'

interface IState {
    bulkEditMode: boolean;
    debtOwed: DebtEntryModel[],
    merchant?: MerchantModel,
    pageLoading: boolean,
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string
    }
}
const debtGivenDaily = {
    labels: ['Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'],
    datasets: [
      {
        label: 'Given Last 7 days',
        // backgroundColor: 'rgba(255,99,132,0.2)',
        backgroundColor: 'rgba(255, 204, 0, 1)',
        borderColor: 'rgba(255, 204, 0, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 204, 0, 1)',
        hoverBorderColor: 'rgba(255, 204, 0, 1)',
        data: [6550, 8000, 8500, 7640, 5500, 7000, 7500]
      }
    ]
    };
const debtGivenAndRecovered = {
    labels: ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      /** {
        label: 'Given',
        // backgroundColor: 'rgba(255,99,132,0.2)',
        backgroundColor: 'rgba(255, 204, 0, 1)',
        borderColor: 'rgba(255, 204, 0, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 204, 0, 1)',
        hoverBorderColor: 'rgba(255, 204, 0, 1)',
        data: [-16550, -28000, -19000, -15640, -21500, -23000]
      },*/
      {
        label: 'Paid',
        // backgroundColor: 'rgba(255,99,132,0.2)',
        backgroundColor: 'rgba(19, 206, 100, 0.2)',
        borderColor: 'rgba(19, 206, 100, 0.2)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(19, 206, 100, 0.2)',
        hoverBorderColor: 'rgba(19, 206, 100, 0.2)',
        data: [15550, 27000, 15000, 16640, 25500, 26000]
      }
    ]
    };

class Debt extends React.Component<any, IState> {
    private debtProvider: DebtProvider = new DebtProvider();

    constructor(props: any) {
        super(props);
        this.state = {
            bulkEditMode: false,
            debtOwed: [],
            merchant: {} as MerchantModel,
            pageLoading: true,
            toastOptions: {
                toastMessage: "",
                toastOpen: false,
                toastColor: "success"
            },
        }
    }

    async ionViewDidEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({ merchant }, async () => {
            await this.getDebtOwed();
        });

    }

    async getDebtOwed() {
        if (this.state ?.merchant) {
            try {
                let debtOwed = await this.debtProvider.getMerchantOwedDebt(this.state.merchant ?.id ?.$oid);
						// debtOwed = debtOwed.filter(debt => debt.debit > 0)
                this.setState({ debtOwed, pageLoading: false });
            } catch (e) {
                this.setState({
                    pageLoading: false,
                    toastOptions: {
                        toastMessage: "There was a problem, please try again later",
                        toastOpen: true,
                        toastColor: "danger"
                      }
                });
            }
            
        }
    }

    logCustomerDebt() {
        this.props.history.replace({ pathname: '/log-debt' })
    }

    get totalDebtGiven() {
        let total = 0;
        this.state.debtOwed?.forEach((debt) => {
            total += debt.debit
        });
        return total
    }
    get totalDebtSettled() {
        let total = 0;
        this.state.debtOwed?.forEach((debt) => {
            total += debt.credit
        });
        return total;
    }
    get totalDebtPending() {
        return Number(this.totalDebtGiven - this.totalDebtSettled);
    }

    closeToast = () => {
        this.setState({ toastOptions: { ...this.state.toastOptions, toastOpen: false } });
    };

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                    (<ContentLoader />)
                }
                <IonContent>
                    <div className="debt">
                        <div className="title-section">
                            <div className="page-details">
                                <h1 className="page-title">Credit sales</h1>
                            </div>
                        </div>
                        <div className="debt-page-content">
                            <div className="page-section summary no-margin">
                                <div className="section-content -horizontal-scroll">
                                    <div>
                                        {/* <SummaryCard summaries={[
                                            {
                                                title: "Total Debt",
                                                value: `₦ ${this.totalDebtValue.toLocaleString()}`,
                                                description: "Total value of debt given to customers",
                                            }
                                        ]} /> */}
                                        <div className="debt-summary-cards">
                                            <div className="debt-summary-card -total-debt">
                                                <div className="-left">
                                                    <h3 className="debt-summary">Credit issued</h3>
                                                    <h1 className="value">₦ { nFormatter(this.totalDebtGiven, 2) }</h1>
                                                    {/* <p className="description">{this.state.customerSummaries[0].description}</p> */}
                                                </div>
                                                {/* <div className="-icon"><img src={'assets/icons/ic_customers.svg'} alt="none" /></div> */}
                                            </div>
                                            <div className="debt-summary-card -recovered-debt">
                                                <div className="-left">
                                                    <h3 className="debt-summary">Collected</h3>
                                                    <h1 className="value">₦ { nFormatter(this.totalDebtSettled, 2)}</h1>
                                                    {/* <p className="description">{this.state.customerSummaries[0].description}</p> */}
                                                </div>
                                                {/* <div className="-icon"><img src={'assets/icons/ic_customers.svg'} alt="none" /></div> */}
                                            </div>
                                            <div className="debt-summary-card -pending-debt">
                                                <div className="-left">
                                                    <h3 className="debt-summary">Outstanding</h3>
                                                    <h1 className="value">₦ { nFormatter(this.totalDebtPending, 2)}</h1>
                                                    {/* <p className="description">{this.state.customerSummaries[0].description}</p> */}
                                                </div>
                                                {/* <div className="-icon"><img src={'assets/icons/ic_customers.svg'} alt="none" /></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="page-section revenue-graphs-section no-margin">
                                <br/>
                                <div className="title">
                                    <p className="section-title">Debt recovery</p>
                                </div>
                                <div className="section-content -horizontal-scroll">
                                    <div className="revenue-hor-scroll horizontal-scroll">
                                        <div className="graphs">
                                        <RevenueGraphWidget 
                                            chartData={debtGivenDaily}
                                            chartType="line"
                                            chartHeight={200} />
                                        <RevenueGraphWidget 
                                            chartData={debtGivenAndRecovered}
                                            chartType="bar"
                                            chartHeight={200} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="page-section active-debt">
                                <div className="sub-section">
                                    {
                                        this.state.debtOwed.length > 0 ?
                                            <>
                                                <p className="sub-section-title">Credit ledger entries</p>
                                                {
                                                    this.state.debtOwed.map((debt, index) => {
                                                        return (
                                                            <ul key={index} className="ajua-list">

                                                                <li className="list-item">
                                                                    <div
                                                                        className={`check-box ${this.state.bulkEditMode ? '' : '-hide'}`}>
                                                                        <IonCheckbox checked={false} />
                                                                    </div>
                                                                    <Link to={{
                                                                        pathname: "/customer",
                                                                        state: { 
                                                                        customerID: debt.customer_id.substr(0, debt.customer_id.indexOf('-')), 
                                                                        entryID: debt._id, 
                                                                        }
                                                                    }}>
                                                                        <AjuaCard>
                                                                            <div className="debt-details no-text-wrap">
                                                                                <p className="name">{debt.name}</p>
                                                                                {
                                                                                    debt.debit > 0 ?
																					<p className="date-due">
                                                                                        <strong>Due date:</strong> {moment(debt.due_date).format('Do MMM YYYY')  || 'Not Set'}
                                                                                    </p>
																				    : 
                                                                                    debt.credit > 0 ? 
                                                                                    <p className="date-due">
                                                                                        <strong>Date received:</strong> {moment(debt.created_at).format('Do MMM YYYY')  || 'Not Set'}
                                                                                    </p> : ''
                                                                                }
                                                                                {debt.transaction_type ==="CANCELLED" ?
																					<p className="debt-cancelled">
                                                                                        You cancelled this debt
                                                                                    </p>
																				: ''}
                                                                            </div>
																        {debt.debit > 0 ? 
																			<div className="amount-debit">
                                                                                ₦ {debt.debit.toLocaleString()}
                                                                            </div>
                                                                        :
                                                                            <div className="amount-credit">
                                                                            ₦ {debt.credit.toLocaleString()}
                                                                            </div>																																							
                                                                            }
                                                                        </AjuaCard>
                                                                    </Link>
                                                                </li>
                                                            </ul>

                                                        );
                                                    })}
                                            </> 
                                        :
                                            <div className="sub-section">
                                                <div className="section-content">
                                                    <EmptyState icon={'assets/icons/cactus.svg'}
                                                        title={'No Debt Owed'}
                                                        actionButtonText={'Log Debt'}
                                                        description={'Start logging customer debt using the button below.'}
                                                        actionCallback={() => this.logCustomerDebt()} />
                                                </div>

                                            </div>
                                    }

                                </div>
                            </div>

                        </div>
                        <Toast message={this.state.toastOptions ?.toastMessage} open={this.state.toastOptions ?.toastOpen}
                            position="bottom" color={this.state.toastOptions ?.toastColor}
                            closeToast={this.closeToast} />
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Debt);

import React from "react";
import "./introSlides.sass"
import {IonContent, IonIcon, IonPage, withIonLifeCycle} from "@ionic/react";
import {arrowForwardOutline} from 'ionicons/icons';
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import { MerchantModel } from "../../shared/models/merchant.model";

interface IState {
    merchant?: MerchantModel,
    pageLoading: boolean
}

class IntroSlides extends React.Component<any, IState> {
    state = {
        merchant: {} as MerchantModel,
        pageLoading: true
    };

    async ionViewWillEnter() {
        await getFromStorage(STORAGE_FIELDS.VIEWED_INTRO_SLIDE).then(async (data) => {
            const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
            if (data && merchant) {
                this.setState({pageLoading: false, merchant});
                this.props.history.replace('/main');
            } else if (data) {
                this.setState({pageLoading: false});
                this.props.history.replace('/main');
            }
            this.setState({pageLoading: false, merchant});
        });
    }

    async openWelcomeScreen() {
        await saveToStorage(STORAGE_FIELDS.VIEWED_INTRO_SLIDE, 'viewed');
        this.props.history.replace('/main');
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                (<ContentLoader/>)
                }
                <IonContent>
                    <div className="walkthrough-screen about-pulse">
                        <div className="feature-preview">
                            <div className="feature-image">
                                <img src={'assets/icons/intro_slide_yes.svg'}/>
                            </div>
                            <div className="feature-details">
                                <h1>Congratulations!</h1>
                                {/* <p>Your free 30 Days subscription to <br/>MTN EnGauge has been activated</p> */}
                                {/* <br/> */}
                                <p>Here is your unique business short code:</p>
                                <p className="unique-code"><strong>{this.state ?.merchant ?.ussd_string}</strong></p>
                                <p>Ask your customers to pay you digitally by dialling this code. </p>
                            </div>
                            <div>
                                <br/>
                                <button onClick={() => this.openWelcomeScreen()}
                                        className="ajua-button -success get-started-btn">Continue <IonIcon icon={arrowForwardOutline}/>
                                </button>
                            </div>
                        </div>
                    </div>

                    
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(IntroSlides)

import ApiService from "../services/network-services/api.service";
import {getFromStorage} from "../helpers/storage";

/**
 * get all customer activities
 * @return {Promise<any>}
 */
export async function getOffers() {
    const apiService = new ApiService();
    const merchant: any = await getFromStorage("merchant");
    return await apiService.getResource(
        `/offers?merchant_id=${merchant?.id?.$oid}`)
        .then(activities => {
            return activities?.data;
        })
        .catch(
            error => {
                throw error
            }
        )
}

/**
 * create customer activity
 * @return {Promise<any>}
 * @param body
 */
export async function createOffers(body: object) {
    const apiService = new ApiService();
    await apiService.createResource(body, "/offers")
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error;
            }
        );
}

export enum EVENTS_TRACKED {
    LOGIN = "Logged In",
    UPDATE_PROFILE = "Updated their profile",
    ADD_CUSTOMER = "Added customer",
    CREATE_OFFER = "Created offer",
    SEND_OFFER = "Send offer",
    VALIDATED_OFFER = "Validated an offer",
    REDEEMED_OFFER = "Redeemed an offer",
    REQUEST_FEEDBACK = "Requested customer feedback",
    SEND_MESSAGE_SMS = "Sent message via SMS",
    SEND_MESSAGE_WHATSAPP = "Sent message via Whatsapp",
    SHARE_AD = "Shared advertisement",
    CLICKED_DEALS = "Opened deals page",
    CLICKED_NEWSPAPER = "Tried to view newspapers",
    REFER_A_FRIEND = "Referred a friend",
    ADD_PAYMENT_TRACKING = "Added payment tracking details",
    LOG_DEBT = "Log debt",
    UPDATE_DEBT = "Log debt",
    SIGN_UP = "Sign-up",
    UPDATE_PAYMENT_INFO = "Update payment info",
    VIEWED_TOUR_GUIDE = "Viewed tour guide",
    CREATED_AD = "Created an ad",
    CREATED_AD_FIRST_TIME = "Created an ad for the first time",
    CLICKED_ON_AD = "Clicked on ad",
}

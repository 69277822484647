import React from "react";
import "./newspaper-subscription.sass";
import {IonContent, IonHeader, IonPage} from "@ionic/react";
import {PublicationModel} from "./publication.model";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";

interface IState {
    pageLoading: boolean,
    publicationsList: PublicationModel[]
}

class NewspaperSubscription extends React.Component<any, any>{
    render(){
        return(
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: 'Publications'}}/>
                </IonHeader>
                <IonContent>

                </IonContent>
            </IonPage>
        )
    }
}

export default NewspaperSubscription

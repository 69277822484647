import { AndroidPermissions } from "@ionic-native/android-permissions";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";

class UtilityFunctions {

    /*
    * Creates a JSON object from the supplied form data
    * @params formData: FormData
    * @returns Object: JSON Object
    * */
    static jsonFromFormData(formData: FormData): any {
        let jsonObject: any = {};
        if (formData) {
            formData.forEach(function (value, key) {
                jsonObject[key] = value;
            });
        }

        return jsonObject;
    }

    static checkForSMSPermissions(): Promise<any> {
        return AndroidPermissions.requestPermissions([AndroidPermissions.PERMISSION.READ_PHONE_STATE, AndroidPermissions.PERMISSION.SEND_SMS]);
    }

    static validatePhoneNumber(phone: string): any {
        return isPossiblePhoneNumber(phone) && isValidPhoneNumber(phone);
    }

    /*
    * Obtains the first letter in every word in the sentence provided
    * @param sentence: string -> The sentence to strip initials from
    * @param limit: number -> The maximum initials to display
    * @return initials: string
    * */
    static getInitials(sentence: string, limit = 2): string {
        const initials = sentence.match(/\b(\w)/g);
        if (initials) {
            return initials.join('').slice(0, limit);
        } else {
            return 'N/A';
        }
    }
}

export default UtilityFunctions



/**
 * Sort an array using a key in ascending order
 * TODO: add a third arg to make it work both directions
 * @param array
 * @param key
 */
export function sortArrayByKey(array: any, key: any) {
    return array.sort(function (a: any, b: any) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}

export function formatNumber(num?: Number) {
    return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function capitalize(s: string)
{
    return s && s[0].toUpperCase() + s.slice(1);
}

export function randomText(length: Number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

export function randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function truncateStringWithDots(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
}

export function nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  
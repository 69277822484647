import React from "react";
import "./product-request.sass";
import {IonContent, IonHeader, IonPage, IonTextarea, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import * as yup from "yup";
import {ErrorMessage, Field, FieldProps, Form, Formik} from "formik";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import ProductRequestService from "./product-request.service";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import environment from "../../environments/environment";
import {Toast} from "../../shared/components/generic/toast";
import {ToastNotificationModel} from "../../shared/models/toast-notification.model";

interface IState {
    merchant?: MerchantModel | undefined
    submitting_request: boolean,
    toastOptions: ToastNotificationModel
}

interface ProductRequestSchema {
    request_description?: string,
}

class ProductRequest extends React.Component<any, IState> {
    private productRequestService = new ProductRequestService();

    state = {
        submitting_request: false,
        merchant: {} as MerchantModel,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: "success",
        }
    };

    async ionViewWillEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({merchant})
    }

    private productRequestValidationSchema = yup.object().shape({
        request_description: yup.string().required('Kindly enter enter your request before proceeding.')
    });

    footerLink() {
        const url = 'https://ajua.com/media_blogs/kam-ajua-partner-to-transform-customer-experience-between-consumers-smes-lenders-and-manufacturers-through-ajuas-sme-connect-platform/';
        InAppBrowser.create(url, '_blank', environment.inAppBrowserOptions);
    }

    sendProductRequest(values: ProductRequestSchema, resetForm: any) {
        this.setState({submitting_request: true});
        const {merchant} = this.state;
        if (values && merchant) {
            this.productRequestService.submitRequest(merchant.id.$oid, values.request_description as string).then((response: any) => {
                resetForm({});
                this.setState({
                    submitting_request: false,
                    toastOptions: {
                        toastMessage: "Successfully submitted your request.",
                        toastOpen: true,
                        toastColor: "success",
                    }
                });
            }, error => {
                this.setState({
                    submitting_request: false,
                    toastOptions: {
                        toastMessage: "Failed to submit request. Try again.",
                        toastOpen: true,
                        toastColor: "danger",
                    }
                })
            })
        }
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };


    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: 'Deal Request'}}/>
                </IonHeader>
                <IonContent>
                    <div className="product-request-page">
                        <h1 className="title">New deal request</h1>
                        <p className="description">Enter the products or manufacturers would you like to see and we will
                            try get them for you.</p>

                        <Formik
                            initialValues={{
                                request_description: '',
                            }}
                            validationSchema={this.productRequestValidationSchema}
                            onSubmit={(values: ProductRequestSchema, {resetForm}) => this.sendProductRequest(values, resetForm)}>
                            {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                <Form>
                                    <Field type="text-area" name="request_description">
                                        {(fieldProps: FieldProps) => (
                                            <div className="form-group">
                                                <IonTextarea {...fieldProps.field} className="ajua-textarea"
                                                             name='request_description' onIonChange={handleChange}
                                                             onBlur={handleBlur}
                                                             placeholder={'Enter details about the product or manufacturer'}/>
                                                <ErrorMessage name="request_description">
                                                    {(msg) => (
                                                        <InlineAlert type={'error'} message={msg}/>
                                                    )}
                                                </ErrorMessage>
                                            </div>
                                        )}
                                    </Field>
                                    <button type="submit" disabled={!(isValid && dirty)}
                                            className="ajua-button -full-width -success">Submit Request
                                    </button>
                                </Form>
                            )}
                        </Formik>


                        <div className="footer-content">
                            <p className="description">SME Connect has partnered with the <strong>Kenya Association of
                                Manufacturers (KAM)</strong> in order to connect manufacturers with businesses
                                like {this.state?.merchant?.business_name}</p>
                            <p onClick={() => this.footerLink()} className="learn-more">Learn More</p>
                        </div>
                    </div>

                    <Toast message={this.state.toastOptions?.toastMessage} open={this.state.toastOptions?.toastOpen}
                           position="bottom"
                           color={this.state.toastOptions?.toastColor}
                           closeToast={this.closeToast}/>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(ProductRequest);

import React from "react";
import "./deals.sass";
import {IonContent, IonHeader, IonIcon, IonPage, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {Link} from "react-router-dom";
import {cubeOutline} from "ionicons/icons";
import {SupplierOfferModel} from "../pulse/pulse";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {fetchSupplierOffers} from "../../shared/apiCalls/customerCalls";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import EmptyState from "../../shared/components/empty-state/emptyState";

interface IState {
    supplierOffers: SupplierOfferModel[],
    merchant: MerchantModel,
    pageLoading: boolean
}

class Deals extends React.Component<any, IState> {
    state = {
        supplierOffers: [] as SupplierOfferModel[],
        merchant: {} as MerchantModel,
        pageLoading: true
    };

    async ionViewWillEnter() {
        await this.getMerchantDetails();
        await this.getOfferDetails();
    }

    async getMerchantDetails() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({merchant});
    }

    async getOfferDetails() {
        const merchant = this.state.merchant;

        if (merchant.ajua_account_details.subscription_status) {
            try {
                if (merchant?.ajua_account_details?.industry) {
                    let supplierOffers: SupplierOfferModel[] = await fetchSupplierOffers(merchant.ajua_account_details.industry);

                    // Filter by country and industry
                    supplierOffers = supplierOffers.filter(offer => {
                        const industryMatch = offer.fields.industry?.findIndex((industry) => {
                            return industry.localeCompare(merchant.ajua_account_details.industry) === 0;
                        }) > -1;
                        const countryMatch = offer.fields?.country?.findIndex((country) => country.toLowerCase() === merchant.ajua_account_details.country.toLowerCase()) > -1;

                        return industryMatch && countryMatch;
                    });

                    this.setState({supplierOffers, pageLoading: false});
                }
            } catch (e) {
                this.setState({pageLoading: false});
                throw e;
            }
        } else {
            this.setState({pageLoading: false});
        }
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                (<ContentLoader/>)
                }
                <IonHeader>
                    <AjuaToolbar toolbar={{title: 'Supplier Deals'}}/>
                </IonHeader>
                <IonContent>
                    <div className="deals-page">
                        <div className="page-section">
                            <div className="section-content">
                                {
                                    this.state.supplierOffers?.length > 0 ? (
                                        <>
                                            {
                                                this.state.supplierOffers.map((offer: SupplierOfferModel, index) => {
                                                    return <Link key={index}
                                                                 to={{
                                                                     pathname: '/deal-details',
                                                                     state: {supplierOffer: offer}
                                                                 }}
                                                                 className={`deal-banner ${this.state.merchant.ajua_account_details.subscription_status ? '' : '-disabled'}`}>
                                                        <div className="icon">
                                                            <img
                                                                onError={(e: any) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "https://gocryptowise.com/wp-content/uploads/2019/09/Crypto-deals.pngs"
                                                                }}
                                                                src={offer.fields.deal_image ? offer.fields.deal_image : 'https://gocryptowise.com/wp-content/uploads/2019/09/Crypto-deals.png'}/>
                                                        </div>
                                                        <div className="details">
                                                            <p className="deal-title">{offer.fields.offer_description}</p>
                                                            <p className="supplier">By: {offer.fields.supplier}</p>
                                                        </div>
                                                    </Link>
                                                })
                                            }

                                            <div className="product-request">
                                                <p className="title">Can't find a product or supplier?</p>

                                                <Link className="no-decoration" to={'/product-request'}>
                                                    <button className="ajua-button -warning"><IonIcon className="-left"
                                                                                                      icon={cubeOutline}/> Make
                                                        a
                                                        request
                                                    </button>
                                                </Link>
                                            </div>


                                        </>
                                    ) : (
                                        <EmptyState title="No Offers Available"
                                                    description="There are no active supplier offers at the moment. Check again later."
                                                    icon={'assets/icons/cactus.svg'}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Deals);

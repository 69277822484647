import React, {ReactNode} from "react";
import "./context-menu.sass";
import {IonContent, IonIcon, IonPage, withIonLifeCycle} from "@ionic/react";
import {call, chatboxEllipses, chatbubbles, pricetag, eyeOutline} from "ionicons/icons";
import {Subject, Subscription} from "rxjs";
import {ContextMenuService} from "./context-menu.service";

interface IState {
    menuState: IContextMenuOptions
}

interface IProps {
    onCloseMenu: Function
    content: ReactNode
}

export interface IContextMenuOptions {
    show: boolean,
    content: any
}

class ContextMenu extends React.Component<IProps, IState> {
    private contextMenuSubscription: Subscription = new Subscription();

    state = {menuState: {show: false, content: ''}};

    componentDidMount() {
        this.contextMenuSubscription = ContextMenuService.getMenuState().subscribe(state => {
            this.setState({menuState: state});
        })
    }

    get menuVisibility() {
        return this.state.menuState.show;
    }

    closeContextMenu() {
        ContextMenuService.closeContextMenu();
    }

    componentWillUnmount(): void {
        this.contextMenuSubscription.unsubscribe();
    }

    render() {
        return (
            <IonPage style={{visibility: this.menuVisibility ? `visible` : `hidden`}}
                     className="context-menu-container">
                <IonContent>
                    <div style={{display: this.menuVisibility ? `block` : `none`}}
                         onClick={() => this.closeContextMenu()} className="overlay"/>
                    <div className="context-menu-content"
                         style={{bottom: this.menuVisibility ? '0' : `-${window.outerHeight}px`}}>
                        <div className="header-section">
                            <p onClick={() => this.closeContextMenu()}>Done</p>
                        </div>
                        {this.state.menuState.content}
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(ContextMenu);

import {IonContent, IonPage, withIonLifeCycle} from "@ionic/react";
import React from "react";
import PinInput from 'react-pin-input';
import "./lock-screen.sass";
import {MerchantModel} from "../../../shared/models/merchant.model";
import {
    clearStorage,
    getFromStorage,
    removeFromStorage,
    SECURE_STORAGE_FIELDS,
    STORAGE_FIELDS
} from "../../../shared/helpers/storage";

interface IState {
    merchant?: MerchantModel | undefined,
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string,
    }
}

class LockScreen extends React.Component<any, IState> {
    private unlockPINInput = React.createRef<PinInput>();

    state = {
        toastOptions: {
            toastMessage: '',
            toastOpen: false,
            toastColor: 'success',
        }
    };

    async ionViewWillEnter() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        if (merchant) {
            this.setState({
                merchant
            });
        } else {
            this.props.history.replace('/intro-slides');
        }
    }

    async unlockScreen(userCode: string) {
        const validCode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
        this.unlockPINInput.current?.clear();

        if (validCode === userCode) {
            this.props.history.replace('/main');
        } else {
            this.setState({
                toastOptions: {
                    toastMessage: "Wrong PIN. Please try again",
                    toastOpen: true,
                    toastColor: 'danger',
                }
            });
        }
    }

    async signOut() {
        await clearStorage();
        this.props.history.push({pathname: '/welcome-screen'});
    }

    render() {
        return (
            <IonPage>
                <IonContent>
                    <div className="lock-screen">
                        <img className="illustration" src={'assets/icons/padlock.svg'}/>

                        <h1 className="title">Enter PIN</h1>
                        <p className="description">Enter your <strong>4-digit PIN</strong> to unlock.</p>

                        <PinInput
                            ref={this.unlockPINInput}
                            length={4}
                            initialValue=""
                            secret
                            onChange={(value, index) => {
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{padding: '10px'}}
                            inputStyle={{borderColor: 'red'}}
                            inputFocusStyle={{borderColor: 'blue'}}
                            onComplete={(value, index) => {
                                this.unlockScreen(value);
                            }}/>

                        <div className="bottom-actions">
                            <p onClick={() => {
                                this.signOut()
                            }} className="forgot-pin">Forgot your PIN?</p>
                        </div>
                    </div>
                    {/*<div className="background-content">*/}
                    {/*    <div className="background-image">*/}
                    {/*        <div className="tint"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(LockScreen);

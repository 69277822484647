const pulseData = {
    feedback: [
        {
            commId: 'Joseph Omotunde',
            npsComment: 'The staff are very kind to customers, as usual. I will continue visiting Bobo’s.',
            npsScore: 9,
            createDate: '20 minutes ago'
        },
        {
            commId: 'Mary Kibanjo',
            npsComment: 'I made a appointment for 4pm but I got attended to an hour later..',
            npsScore: 6,
            createDate: '2 hours ago'
        },
        {
            commId: 'Brenda Chimamanda',
            npsComment: 'I’m not very pleased with the service I received today.',
            npsScore: 4,
            createDate: '3 hours ago'
        }
    ],
    transactions: [
        {
            customer: 'Joseph Omotunde',
            amount: '₦ 150.00',
            time: '20 minutes ago',
            channelLogo: ''
        },
        {
            customer: 'Linda Kosoko',
            amount: '₦ 180.00',
            time: '2 hours ago',
            channelLogo: ''
        },
        {
            customer: 'Michael Sima',
            amount: '₦ 120.00',
            time: '3 hours ago',
            channelLogo: ''
        },

    ],
    insights: [
        {
            id: 1,
            insight: 'Willing to pay more',
            message: '67% of barbershop customers in Kenya are willing to pay more for a great experience!'
        },
        {
            id: 2,
            insight: 'Speed and Efficiency',
            message: 'Speed and efficiency of service at barbershops in Kenya is the main differentiator between happy and unhappy customers.'
        }
    ],
    discountIdeas: [
        {
            discount: 'Welcome to Bobo\'s Barber Shop',
            duration: '21/04/2020 - 22/04/2020'
        }
    ],
    advertisements: [
        {image: 'assets/images/ad_bg.jpg'},
        {image: 'assets/images/ad_bg1.jpg'}
    ]
};

export default pulseData;

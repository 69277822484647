import React from 'react';
import { IonCheckbox, IonContent, IonIcon, IonPage, IonSpinner, IonInfiniteScroll, IonInfiniteScrollContent, withIonLifeCycle } from '@ionic/react';
import './customers.sass';
import ContentLoader from "../../shared/components/content-loader/contentLoader"
import SummaryCard from "../../shared/components/summary-card/summaryCard"
import EmptyState from "../../shared/components/empty-state/emptyState"
import AjuaCard from "../../shared/components/ajua-card/ajua-card"
import { Link } from "react-router-dom"
import { getActivities } from "../../shared/apiCalls/customerCalls"
import { SummaryCardModel } from "../../shared/components/summary-card/summaryCard.model"
import { CustomerModel } from "../../shared/models/customer.model"
import {
    person
} from "ionicons/icons"
import environment from "../../environments/environment"

interface IState {
    loading: boolean,
    bulkEditMode: boolean,
    customers: CustomerModel[],
    customerSummaries: SummaryCardModel[],
    pageLoading: boolean,
    disableInfiniteScroll: boolean,
    currentPage: number
}

class Customers extends React.Component<any, IState> {
    state = {
        pageLoading: true,
        loading: false,
        bulkEditMode: false,
        customers: [] as CustomerModel[],
        customerSummaries: [
            {
                title: 'All Customers',
                value: '0',
                description: 'Total number of customers',
            },
            {
                title: 'New Customers',
                value: '0',
                type: 'success',
                description: 'New customers registered this week',
            },
            {
                title: 'Repeat Customers',
                value: '0',
                description: 'Number of returning customers this week',
            }
        ],
        disableInfiniteScroll: false,
        currentPage: 1
    };

    constructor(props: any) {
        super(props);
        this.addCustomers = this.addCustomers.bind(this);
    }

    async ionViewWillEnter() {
        this.setState({ customers: [] });
        await this.getCustomers(1)
    }

    /*
    * Callback handler for toggling between the bulk edit and normal view
    * */
    toggleBulkEdit(status: boolean) {
        this.setState({
            bulkEditMode: status
        })
    }

    getCustomers = async (nextPage: number) => {
        try {
            this.setState({ loading: true });
            const customers = await getActivities(`&activity_id=0&page=${nextPage}`);
            this.setState({
                pageLoading: false,
                customers: [...this.state.customers, ...customers], 
                customerSummaries: [
                    {
                        title: 'All Customers',
                        value: customers.length.toString(),
                        description: 'Total number of customers',
                    }
                ],
                disableInfiniteScroll: customers.length < environment.ROWS_PER_PAGE
            });
        } catch (e) {
            this.setState({ loading: false });
        } finally {
            this.setState({ loading: false });
        }
    };

    LoadMoreCustomers = async ($event: CustomEvent<void>) => {
        this.setState({ currentPage: this.state.currentPage + 1 })
        const nextPage = this.state.currentPage
        await this.getCustomers(nextPage);
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    addCustomers() {
        this.props.history.push("/select-customer-import-method")
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                    (<ContentLoader />)
                }
                <IonContent>
                    <div className="customers">
                        <div className="title-section">
                            <div className="page-details">
                                <h1 className="page-title">Customers</h1>
                            </div>
                        </div>
                        <div className="customers-page">
                            <div className="page-section summary no-margin">
                                <div className="section-content -horizontal-scroll">
                                    <div className="horizontal-scroll">
                                        {/* <SummaryCard summaries={this.state.customerSummaries} /> */}
                                        <div className="summary-cards">
                                            <div className="summary-card">
                                                <div className="-left">
                                                    <h3 className="summary">{this.state.customerSummaries[0].title}</h3>
                                                    <h1 className={`value ${this.state.customerSummaries[0].type}`}>{this.state.customerSummaries[0].value}</h1>
                                                    <p className="description">{this.state.customerSummaries[0].description}</p>
                                                </div>
                                                <div className="-icon"><img src={'assets/icons/ic_customers.svg'} alt="none" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-section customer-list">
                                <ul className="ajua-list -compact">
                                    {this.state.loading && (<IonSpinner color="black" name="crescent" />)}
                                    {(!this.state.loading && this.state ?.customers ?.length < 1) ?
                                        (
                                            <EmptyState icon={'assets/icons/my_customers.svg'}
                                                title={'No Customers'}
                                                description={'Add your customers to immediately start getting feedback from them.'}
                                                actionButtonText={'Add customers'}
                                                actionCallback={this.addCustomers} />
                                        ) :
                                        this.state ?.customers.map((customer, index) => {
                                            return (
                                                <li key={index} className="list-item">

                                                    <div
                                                        className={`check-box ${this.state.bulkEditMode ? '' : '-hide'}`}>
                                                        <IonCheckbox checked={false} />
                                                    </div>

                                                    <AjuaCard onLongPress={(status) => {
                                                        this.toggleBulkEdit(status)
                                                    }}>
                                                        <Link
                                                            to={{
                                                                pathname: "/customer",
                                                                state: { customerID: customer.CUSTOMER_ID }
                                                            }}>
                                                            <div className="customer-card">
                                                                <IonIcon icon={person} />
                                                                <div className="customer-details">
                                                                    <p className="name">{customer ?.METADATA ?.name || "No Name"}</p>
                                                                    <p className="phone">
                                                                        <strong>+{customer.CUSTOMER_ID}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </Link>
                                                    </AjuaCard>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                                <IonInfiniteScroll threshold="100px"
                                    disabled={this.state.disableInfiniteScroll}
                                    onIonInfinite={(e: CustomEvent<void>) => this.LoadMoreCustomers(e)}>
                                    <IonInfiniteScrollContent
                                        loadingText="Loading more ...">
                                    </IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </div>
                        </div>
                    </div>
                </IonContent>

            </IonPage>
        );
    }
}

export default withIonLifeCycle(Customers);

import React from "react";
import "./transactionsPage.sass";
import {IonContent, IonHeader, IonSpinner, IonPage, IonIcon} from "@ionic/react"
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar"
import TransactionCard from "../../shared/components/transaction-card/transactionCard"
import TransactionsService from "../../shared/apiCalls/transactions.service"
import { getFromStorage, STORAGE_FIELDS } from "../../shared/helpers/storage"
import { MerchantModel } from "../../shared/models/merchant.model"
import { TransactionCardModel } from "../../shared/models/transactionCard.model"
import { checkmarkSharp } from "ionicons/icons"
import EmptyState from "../../shared/components/empty-state/emptyState"

class TransactionsPage extends React.Component {
    private transactionsService: TransactionsService = new TransactionsService()

    state = {
        merchant: {} as MerchantModel,
        customerTransactions: [] as TransactionCardModel[],
        activityLoading: true,
        total_sum: 0,
        daily_sum: 0,
        weekly_sum: 0,
        monthly_sum: 0
    }

    async componentDidMount() {
        //console.log('call ionViewWillEnter')
        let merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        await this.setState({
            merchant: merchant,
            daily_revenue: [...Array(500)].map(e=>Math.random()*500|0),
            monthly_revenue: [...Array(15000)].map(e=>Math.random()*15000|0),
        }, async () => {

            await this.getCustomerTransactions()
            
        });
    }

    async getCustomerTransactions() {
        this.setState({ activityLoading: true });
        try {
            if (this.state.merchant?.collaborators) {
                // const payload = {
                //     msisdn: '2348039314408', // this.state?.merchant?.collaborators[0].msisdn, 
                //     merchant_name: 'nurtureright canteen', // this.state?.merchant?.business_name, 
                //     type:"payments"
                // }
                const payload = {
                    msisdn: this.state?.merchant?.collaborators[0].msisdn, 
                    merchant_name: this.state?.merchant?.business_name, 
                    type: "payments"
                }
                await this.transactionsService.getTransactionHistory(payload).then( transactions => {
                    const sum = transactions.data.reduce((acc: number, item: TransactionCardModel) => acc + item.amount, 0)
                    this.setState({ customerTransactions: transactions.data, activityLoading: false, total_sum: sum })
                })
            }
        } catch (e) {
            //Todo
        }
    }

    async transferAmountToBank() {

    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Transactions"}}/>
                </IonHeader>
                <IonContent>
                    <div className="page-section transaction-breakdown">
                        {/* <div className="section-content">
                            <div className="ajua-feature-card">
                                <div className="background-image">
                                    <img src="assets/images/abstract.jpg"/>
                                </div>
                                <div className="content">
                                    <p className="content-title">Today's Revenue</p>
                                    <p className="content-value">₦ 2,400</p>
                                </div>
                            </div>
                            <div className="summaries">
                                <div className="ajua-summary-card">
                                    <p className="summary-title">This Week</p>
                                    <p className="summary-value">₦ 12,200</p>
                                </div>
                                <div className="ajua-summary-card">
                                    <p className="summary-title">This Month</p>
                                    <p className="summary-value">₦ 26,540</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="sub-section transaction-history">
                            <p className="sub-section-title">Transaction History</p>
                            <div className="section-content">
                                {this.state.activityLoading &&
                                    (<IonSpinner color="black" name="crescent" />)
                                }
                                {!this.state.activityLoading && this.state.customerTransactions ?.length === 0 ?
                                (<div>
                                    <EmptyState icon={'assets/icons/ic_payments.svg'}
                                        title={'No payment activity'}
                                        description=""/>
                                </div>)
                                : <>
                                    {
                                        this.state.customerTransactions.map((transaction, index) => {
                                            return <TransactionCard key={index} transaction={transaction}/>
                                        })
                                    }
                                </>
                                }
                            </div>

                        </div>

                        {/* <div className="section-content">
                            <button type="submit" disabled={!!this.state.total_sum===false} className="ajua-button -success -full-width" onClick={() => this.transferAmountToBank()}>
                                <IonIcon icon={checkmarkSharp} className="-left"/>Transfer ₦{this.state.total_sum } to my bank 
                            </button>
                        </div> */}

                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default TransactionsPage;

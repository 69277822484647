import React from "react";
import "./subscription-payment.sass";
import {IonContent, IonHeader, IonPage} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import {saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import environment from "../../environments/environment";

interface IProps {

}

interface IState {
    plans: IPaymentPlan[]
}

interface IPaymentPlan {
    duration: string,
    description: string,
    cost: string,
    selected: boolean
}

class SubscriptionPayment extends React.Component<any, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            plans: [
                // {
                //     duration: 'free',
                //     description: 'Use the service for free with limited features.',
                //     cost: 'FREE',
                //     selected: false
                // },
                {
                    duration: 'monthly',
                    description: 'Pay for the service on a monthly basis.',
                    cost: '₦ 500',
                    selected: false
                },
                {
                    duration: 'yearly',
                    description: 'Use the service uninterrupted for a whole year.',
                    cost: '₦ 5,500',
                    selected: false
                }
            ]
        }
    }

    payViaAirtime(event: any) {
        event.preventDefault();
        this.props.history.push('/successful-payment')
    }

    onlinePayment() {
        InAppBrowser.create('https://www.ajua.com/', '_blank',  environment.inAppBrowserOptions);
    }

    changePaymentPlan(plan: IPaymentPlan, index: number) {
        this.setState({
            plans: this.state.plans.map(item => {
                if (item === plan) {
                    item.selected = true;
                    return item
                } else {
                    item.selected = false;
                    return item
                }
            })
        });
    }

    async openSuccessPage() {
        const selectedSubscription = this.state.plans.find(subscription => {
            return subscription.selected;
        });
        await saveToStorage(STORAGE_FIELDS.SUBSCRIPTION, selectedSubscription);
        this.props.history.replace('/successful-payment');
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar backButtonRoute={'/login'} toolbar={{title: "Subscriptions"}}/>
                </IonHeader>
                <IonContent>
                    <div className="subscription-payment">

                        <div className="page-section">
                            <div className="section-content">

                                {/*<div className="payment-instructions mb-2">*/}
                                {/*    <div className="sub-section">*/}
                                {/*        <p className="sub-section-title -no-padding -margin-bottom -black">How To*/}
                                {/*            Pay</p>*/}
                                {/*    </div>*/}
                                {/*    <AjuaCard>*/}
                                {/*        <p>1. Open M-Pesa on your phone</p>*/}
                                {/*        <p>2. Enter Paybill: <strong>886300</strong></p>*/}
                                {/*        <p>3. Enter Account number: <strong>AJUA</strong></p>*/}
                                {/*        <p>4. Enter Amount: <strong>₦ 99</strong> or <strong>₦ 1,089</strong></p>*/}
                                {/*    </AjuaCard>*/}
                                {/*</div>*/}

                                <div className="payment-plans">
                                    <div className="sub-section">
                                        <p className="sub-section-title -no-padding -margin-bottom -black">Payment
                                            Plans</p>
                                    </div>

                                    {
                                        this.state.plans.map((plan, index) => {
                                            return (
                                                <div key={index} onClick={() => this.changePaymentPlan(plan, index)}
                                                     className={`subscription-card ajua-card ${plan.selected ? '-selected' : ''}`}>
                                                    <div className="plan">
                                                        <p className="name">{plan.duration}</p>
                                                        <p className="description">{plan.description}</p>
                                                    </div>
                                                    <div className="cost">
                                                        <p>{plan.cost}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                <div className="payment-methods">
                                    <button onClick={() => this.openSuccessPage()}
                                            className="ajua-button -success -full-width">
                                        Start your free trial for 30 Days 
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default SubscriptionPayment;

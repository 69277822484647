import React from "react";
import "./createOffer.sass";
import {
    IonContent,
    IonHeader,
    IonInput,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonTextarea
} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {Toast} from "../../shared/components/generic/toast";
import {createOffers} from "../../shared/apiCalls/offerCalls";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import {IProfileMilestones} from "../../App";


class CreateOffer extends React.Component<any, any> {
    state = {
        addingOffer: false,
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        offerObject: {
            description: "",
            name: "",
            duration: "daily",
        }
    };

    addOffer = async (e: any) => {
        e.preventDefault();
        this.setState({addingOffer: true});
        try {
            const {offerObject} = this.state;
            const merchant: any = await getFromStorage("merchant");
            const requestObject = {
                ...offerObject,
                "status": 0,
                "merchant_id": merchant?.id?.$oid
            };
            await createOffers(requestObject)
                .then(message => {
                    //this.updateProfileState();

                    this.setState({
                        toastOpen: true,
                        toastMessage: "success",
                        toastColor: "success",
                        offerObject: {
                            description: "",
                            name: "",
                            duration: "daily",
                        }
                    });

                    this.props.history.push(this.props.location.state.referrer || '/main/offers');

                    // Track event
                    Amplitude.getInstance().logEvent(EVENTS_TRACKED.CREATE_OFFER,
                        {merchantDetails: merchant});
                })
                .catch(
                    error => {
                        this.setState({
                            toastOpen: true,
                            toastMessage: error || "request failed",
                            toastColor: "danger"
                        });
                    }
                );
        } catch (e) {
            // catch global
            console.log(e);
        } finally {
            this.setState({
                addingOffer: false,
                offerObject: {
                    description: "",
                    name: "",
                    duration: "daily",
                }
            });
        }
    };

    onTextInputChange = (name: string, e: any) => {
        const {offerObject} = this.state;
        this.setState({offerObject: {...offerObject, [name]: e.detail.value!}})
    };

    closeToast = () => {
        this.setState({toastOpen: false});
    };

    // async updateProfileState() {
    //     let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.PROFILE_MILESTONES_STATES);
    //     profileMilestones.offers = true;

    //     await saveToStorage(STORAGE_FIELDS.PROFILE_MILESTONES_STATES, profileMilestones);
    // }

    render() {
        const {addingOffer, toastMessage, toastOpen, toastColor, offerObject} = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Create Offer"}}/>
                </IonHeader>
                <IonContent>
                    <div className="create-discount-page">
                        <div className="page-section">
                            <div className="section-content create-discount">
                                <form onSubmit={this.addOffer}>
                                    <div className="form-group">
                                        <p className="ajua-form-label">Offer Name</p>
                                        <IonInput required className="ajua-input" value={offerObject.name}
                                                  placeholder="Name of offer e.g Mother’s Day Discount"
                                                  onIonChange={e => this.onTextInputChange("name", e)}/>
                                    </div>
                                    <div className="form-group">
                                        <p className="ajua-form-label">Describe the offer</p>
                                        <IonTextarea required className="ajua-textarea" value={offerObject.description}
                                                     onIonChange={e => this.onTextInputChange("description", e)}>

                                        </IonTextarea>
                                    </div>
                                    <div className="form-group">
                                        <p className="ajua-form-label">How long do you want this offer to last?</p>
                                        <IonSelect onIonChange={e => this.onTextInputChange("duration", e)}
                                                   className="ajua-select" value={offerObject.duration}
                                                   placeholder="Set duration" defaultValue="daily">
                                            <IonSelectOption value="daily">A Day</IonSelectOption>
                                            <IonSelectOption value="weekly">A Week</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                    <button type="submit" className="ajua-button -success -full-width">
                                        {!addingOffer ?
                                            (<span>Create Offer</span>) :
                                            (<IonSpinner color="white" name="crescent"/>)}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Toast message={toastMessage} open={toastOpen} position="bottom" color={toastColor}
                           closeToast={this.closeToast}/>
                </IonContent>
            </IonPage>
        )
    }
}

export default CreateOffer;

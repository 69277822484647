import {SummaryCardModel} from "./summaryCard.model";
import React from "react";
import "./summaryCard.sass";

const SummaryCard: React.FC<{ summaries: SummaryCardModel[] }> = (props: { summaries: SummaryCardModel[] }) => {
    return (
        <div className="summary-cards">
            {
                props.summaries.map((summary, index) => {
                    return (
                        <div key={index} className="summary-card">
                            <h3 className="summary">{summary.title}</h3>
                            <h1 className={`value ${summary.type}`}>{summary.value}</h1>
                            <p className="description">{summary.description}</p>
                        </div>
                    )

                })
            }

        </div>
    )
};

export default SummaryCard;

import React from "react";
import "./welcomeScreen.sass";
import { IonContent, IonPage, IonSlides, IonSlide, withIonLifeCycle } from "@ionic/react";
import { Link } from "react-router-dom";
import { getFromStorage, saveToStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import ContentLoader from "../../shared/components/content-loader/contentLoader";

interface IState {
    pageLoading: boolean
}

class WelcomeScreen extends React.Component<any, IState> {
    state = {
        pageLoading: true
    };

    async ionViewWillEnter() {
        await saveToStorage(STORAGE_FIELDS.APP_INITIAL_LAUNCH, 'false');
        await this.loggedInStatus();
    }

    async loggedInStatus() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        if (merchant) {
            this.setState({ pageLoading: false });
            this.props.history.replace('/main')
        }
        this.setState({ pageLoading: false });
    }

    render() {
        return (
            <IonPage>
                {this.state.pageLoading &&
                    (<ContentLoader />)
                }
                <IonContent>
                    <div className="ajua-auth-page welcome-page">
                        {/* <div className="auth-hero-image -alternative-1">
                            <div className="tint" />
                        </div> */}

                        <div className="auth-form">
                            <div className="form-content welcome-page-form">
                                <div className="auth-title">
                                    <div className="logo -branded">
                                        <img src={'assets/images/mtn_logo.png'} />
                                    </div>
                                    <p className="get-started">MTN EnGauge</p>
                                    <p className="sub-title -small">Manage your business and interact with
                                        customers.</p>
                                </div>
                                <div className="description">
                                    
                                    <div className="description-text">
                                        <IonSlides id="welcome-sliders" pager={true}>
                                            <IonSlide>
                                                <div className="feature-preview">
                                                    <div className="feature-image">
                                                        <img src={'assets/images/img_splash_1.png'}/>
                                                    </div>
                                                    <div className="feature-details">
                                                        <p>Start accepting money from your customers in real time </p>
                                                    </div>
                                                </div>
                                            </IonSlide>

                                            <IonSlide>
                                                <div className="feature-preview">
                                                    <div className="feature-image">
                                                        <img src={'assets/images/img_splash_2.png'}/>
                                                    </div>
                                                    <div className="feature-details">
                                                        <p>Create and send special offers to your customers directly</p>
                                                    </div>
                                                </div>
                                            </IonSlide>

                                            <IonSlide>
                                                <div className="feature-preview">
                                                    <div className="feature-image">
                                                        <img src={'assets/images/img_splash_3.png'}/>
                                                    </div>
                                                    <div className="feature-details">
                                                        <p>Get feedback directly and instantly from your customers</p>
                                                    </div>
                                                </div>
                                            </IonSlide>
                                        </IonSlides>
                                    </div>
                                    
                                </div>
                                <div className="action-btns">
                                    <Link to="register-merchant">
                                        <button className="ajua-button -full-width -neutral">Create Account
                                        </button>
                                    </Link>

                                    <Link to="login">
                                        <button className="ajua-button -full-width">Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(WelcomeScreen)

import React from "react";
import './ActivtyCard.sass';
import { ActivityCardModel } from "./ActivityCardModel";
import moment from "moment";
import CustomerActivityTypes from "../../helpers/CustomerActivityTypes";
import { personAddSharp, cashOutline, chatboxEllipses, pricetagOutline, mailUnreadOutline } from 'ionicons/icons'
import { IonIcon } from "@ionic/react";
import AjuaNavLink from "../ajua-nav-link/ajua-nav-link";
import { formatNumber } from "../../helpers/utility-functions"

interface IProps {
    activity: ActivityCardModel,
    toggleQuickActions?: (open: boolean) => void,
    USSD?: string
}

const ActivityCard: React.FC<IProps> = (props: IProps) => {

    const { ACTIVITY_ID, TIMESTAMP, CUSTOMER_ID, METADATA } = props.activity;
    let score = 0;
    if (METADATA ?.rating) {
        score = parseInt(METADATA ?.rating.charAt(0));
    }

    function getActivityComponent() {
        switch (ACTIVITY_ID) {
            case CustomerActivityTypes.addCustomer:
                return (
                    <AjuaNavLink to={{ pathname: "/customer", state: { customerID: CUSTOMER_ID } }}
                        className="activity-card no-decoration add-customer">
                        <div className="card-content-left">
                            <IonIcon className="add-user-activity" icon={personAddSharp} />
                        </div>
                        <div className="card-content-right">
                            <div className="card-content-right-details">
                                <p className="activity-title">New customer added</p>
                                <p className="customer-name">{METADATA ?.name}</p>
                                <p className="customer-name"><span>+{CUSTOMER_ID}</span></p>
                            </div>
                            <div className="card-content-right-time">
                            <p className="activity-time">{moment(TIMESTAMP.$date).fromNow()}</p>
                            </div>                            
                        </div>
                    </AjuaNavLink>
                );
            case CustomerActivityTypes.ussdDial:
                return (
                    <div className="activity-card ussd-interest">
                        <div className="card-content-left">
                            <IonIcon className="interested-via-ussd-icon" icon={mailUnreadOutline} />
                        </div>
                        <div className="card-content-right">
                            <div className="card-content-right-details">
                                <p className="activity-title">Interested Customer</p>
                                <p className="customer-id">+{CUSTOMER_ID}</p>
                                <p className="interest"><strong>Interested In:</strong> {METADATA ?.NAVIGATION}</p>
                            </div>
                            <div className="card-content-right-time">
                                <p className="activity-time">{moment(TIMESTAMP.$date).fromNow()} via <span className="activity-channel"> {props.USSD}</span></p>
                            </div>
                        </div>
                    </div>
                );
            case CustomerActivityTypes.payment:
                return (<AjuaNavLink to={{ pathname: "/customer", state: { customerID: CUSTOMER_ID } }}
                    className="activity-card pay">
                    <div className="card-content-left">
                        <IonIcon className="payment-activity" icon={cashOutline} />
                    </div>
                    <div className="card-content-right">
                        <div className="card-content-right-details">
                            <p className="activity-title">Incoming payment </p>
                            <p className="customer-id -large"><strong>N {formatNumber(METADATA ?.amount)}</strong></p>
                            <p className="channel">From: <strong>{METADATA ?.name}</strong> <small>({CUSTOMER_ID})</small></p>
                        </div>
                        <div className="card-content-right-time">
                            <p className="activity-time">{moment(TIMESTAMP.$date).fromNow()}</p>
                        </div>
                    </div>
                </AjuaNavLink>);
            case CustomerActivityTypes.offerRedeemed:
                return (<div 
                    className="activity-card offer-redeemed">
                    <div className="card-content-left">
                        <IonIcon className="offer-redeemed-icon" icon={pricetagOutline} />
                    </div>
                    <div className="card-content-right">
                        <div className="card-content-right-details">
                            <p className="activity-title">Offer Redeemed</p>
                            <p className="offer-name">{METADATA ?.offer_title}</p>
                            {METADATA.name !== undefined ? (<p className="customer-details"><strong>By {METADATA ?.name} </strong></p>) : ''}
                            <p className="customer-details">+{CUSTOMER_ID}</p>
                        </div>
                        <div className="card-content-right-time">
                            <p className="activity-time">{moment(TIMESTAMP.$date).fromNow()}</p>
                        </div>
                    </div>
                </div>);
            case CustomerActivityTypes.messageOrFeedback:
                return (
                    <AjuaNavLink to={{ pathname: "/customer", state: { customerID: CUSTOMER_ID } }} className="activity-card customer-feedback">
                        <div className="card-content-left">
                            <IonIcon className="rating-activity" icon={chatboxEllipses} />
                        </div>
                        <div className="card-content-right">
                            <div className="card-content-right-details">
                                <p className="activity-title -customer-phone">{`+${METADATA.name || CUSTOMER_ID}`}</p>
                                {score < 3 &&
                                    (<p className="rating low">Rating: {METADATA ?.rating}/5</p>)
                                }
                                {score === 3 &&
                                    (<p className="rating mid">Rating: {METADATA ?.rating}/5</p>)
                                }
                                {score > 3 &&
                                    (<p className="rating high">Rating: {METADATA ?.rating}/5</p>)
                                }
                                <p className="customer-message">{METADATA ?.message}</p>
                            </div>
                            <div className="card-content-right-time">
                                <p className="activity-time">{moment(TIMESTAMP.$date).fromNow()}</p>
                            </div>
                        </div>
                    </AjuaNavLink>
                );
            case CustomerActivityTypes.rating:
                return (<p className="customer-message">Rated the business</p>);
        }
    }

    return (
        <>
            {
                getActivityComponent()

            }
        </>
    );
};

export default ActivityCard;

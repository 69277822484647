import React from "react";
import "./trackpayments.sass";
import * as Amplitude from "amplitude-js";
import UserProfileService from "../../shared/apiCalls/userProfile.service";
import {
  getFromStorage,
  saveToStorage,
  STORAGE_FIELDS,
  SECURE_STORAGE_FIELDS
} from "../../shared/helpers/storage";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  withIonLifeCycle,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonItem,
  IonModal, IonButton
} from "@ionic/react";
import { informationCircle, eyeOutline, eyeOffOutline } from "ionicons/icons";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import StyleHelper from "../../shared/helpers/style.helper";
import { ToastNotificationModel } from "../../shared/models/toast-notification.model";
import {
  MerchantModel,
  PaymentInfoSchema
} from "../../shared/models/merchant.model";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import * as yup from "yup";
import { STATIC_LIST_OF_BANKS } from "../../shared/helpers/data.list";
import { EVENTS_TRACKED } from "../../shared/helpers/events-tracked";
import PinInput from 'react-pin-input';
import { Toast } from "../../shared/components/generic/toast";
import {IProfileMilestones} from "../../App";

interface IState {
  toastOptions: ToastNotificationModel,
  merchant: MerchantModel,
  showAccNumber: boolean
  passwordIcon: any
  payInfo: PaymentInfoSchema,
  pageLoading: boolean,
  showEnterPINModal: boolean,
  loading: boolean,
  showBVNInfoModal: boolean,
  values: PaymentInfoSchema,
  resetForm: any
}

class TrackPayments extends React.Component<any, IState> {
  private unlockPINInput = React.createRef<PinInput>();
  private userProfileService = new UserProfileService();


  state: IState = {
    pageLoading: true,
    loading: false,
    toastOptions: {
      toastMessage: "",
      toastOpen: false,
      toastColor: "success"
    },
    showAccNumber: false,
    passwordIcon:  eyeOffOutline,
    payInfo: {} as PaymentInfoSchema,
    merchant: {} as MerchantModel,
    showEnterPINModal: false,
    showBVNInfoModal: false,
    values: {} as PaymentInfoSchema,
    resetForm: false
  };

  // View
  private list_of_banks = STATIC_LIST_OF_BANKS.sort((a, b) => (a.name > b.name) ? 1 : -1);

  // Merchant registration form schema
  private editFormValidationSchema = yup.object().shape({
    bank_id: yup.string().required("Please select a bank."),
    account_number: yup.number().typeError('Account number must be numeric digits').required("Account number is required."),
    // bvn_number: yup.number().typeError('BVN must be numeric digits').required("Bank Verification number is required."),
    account_name: yup.string().required("Account name is required.")
  });

  async ionViewWillEnter() {
    const payInfo = await getFromStorage(STORAGE_FIELDS.MERCHANT_PAYMENT_INFO);
    if (payInfo) {
      this.setState({ payInfo });
    }
  }

  async ionViewDidEnter() {
    const merchant: MerchantModel = await getFromStorage(
      STORAGE_FIELDS.MERCHANT
    );
    this.setState({ merchant });

    if (!merchant) {
      this.setState({
        pageLoading: false,
        toastOptions: {
          toastMessage: "Unable to fetch account details. Try again.",
          toastOpen: true,
          toastColor: "danger"
        }
      });
    }
  }

  async updateProfileState() {
      let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);
      profileMilestones.addPaymentInfo = true;

      await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, profileMilestones);
  }


  async fetchPaymentInfo() {
    const payInfo: PaymentInfoSchema = await getFromStorage(
      STORAGE_FIELDS.MERCHANT_PAYMENT_INFO
    );

    if (payInfo) {
      this.setState({ payInfo });
    }
  }

  async handleCapturePaymentInfo(values: PaymentInfoSchema, resetForm: any) {
    this.setState({ loading: true }, async () => {
      //TODO validate BVN
      //TODO  validate account number: Number
      if (!this.state.merchant.id.$oid || this.state.merchant.id.$oid == null) {
        return false;
      }
      const payload = {
        payment_info: values,
        merchant_id: this.state.merchant.id.$oid
      };
      this.userProfileService
        .createMerchantPaymentInfo(payload)
        .then(async (r: any) => {
          if (r.status === 200) {
            this.setState({
              loading: false,
              toastOptions: {
                toastMessage: "Successfully saved.",
                toastOpen: true,
                toastColor: "success"
              },
              // values: {} as PaymentInfoSchema,
              // resetForm: false
            });

            //Save locally
            saveToStorage(STORAGE_FIELDS.MERCHANT_PAYMENT_INFO, values);
            await this.updateProfileState();

            // Track event
            Amplitude.getInstance().logEvent(EVENTS_TRACKED.UPDATE_PAYMENT_INFO, {
              merchantDetails: this.state.merchant
            });

            
          } //ADD_PAYMENT_TRACKING
          else {
            this.setState({
              loading: false,
              toastOptions: {
                toastMessage: "Error: Failed to update profile.",
                toastOpen: true,
                toastColor: "danger"
              }
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            toastOptions: {
              toastMessage: "Error: Failed to update profile.",
              toastOpen: true,
              toastColor: "danger"
            }
          });
        });
    });
  }

  showBVNInfo = (status: boolean) => {
    this.setState({
      showBVNInfoModal: false
    })
    this.setState({
      showBVNInfoModal: status
    })
  }
  hideShowAccNumber() {
    this.setState({
      showAccNumber: !this.state.showAccNumber,
      passwordIcon: !this.state.showAccNumber ? eyeOutline : eyeOffOutline
    })  
  }
  showEnterPIN = (status: boolean, values: any, resetForm: any) => {
    this.setState({
      showEnterPINModal: false
    })
    this.setState({
      showEnterPINModal: status,
      values, 
      resetForm
    })
  }
  async unlockScreen(userCode: string) {
    const validCode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
    this.unlockPINInput.current?.clear();

    if (validCode === userCode) {
      this.setState({
        showEnterPINModal: false
      })
      this.handleCapturePaymentInfo(this.state.values, this.state.resetForm);
    } else {
        this.setState({
            toastOptions: {
                toastMessage: "Wrong PIN. Please try again",
                toastOpen: true,
                toastColor: 'danger',
            }
        });
    }
  }
  closeToast = () => {
    this.setState({ toastOptions: { ...this.state.toastOptions, toastOpen: false } });
  };

  render() {
    return (
      <IonPage>
        <IonHeader>
          <AjuaToolbar toolbar={{ title: "Track payments" }} />
        </IonHeader>
        <IonContent>
          <div className="track-payments-entry">
            <div className="page-section">
              <div className="section-content">
                <div className="customer-details-form no-margin">
                  <IonLoading
                    cssClass=""
                    isOpen={this.state.loading}
                    message={"Submitting"}
                  />
                  <p>Add your payment details below to start tracking payments from customers</p>
                  <Formik
                    initialValues={{
                      bank_id: this.state.payInfo.bank_id,
                      account_number: this.state.payInfo.account_number,
                      // bvn_number: this.state.payInfo.bvn_number,
                      account_name: this.state.payInfo.account_name
                    }}
                    enableReinitialize={true}
                    validationSchema={this.editFormValidationSchema}
                    onSubmit={(values, { resetForm }) =>
                      this.showEnterPIN(true, values, resetForm)
                    }
                  >
                    {({
                      touched,
                      handleChange,
                      handleBlur,
                      errors,
                      isValid,
                      dirty,
                      setFieldValue,
                      values
                    }) => (
                        <Form>
                          <Field name="bank_id" id="bank_id">
                            {(fieldProps: FieldProps) => (
                              <div className="form-group">
                                <p className="ajua-form-label">
                                  Select your bank
                              </p>
                                <IonSelect
                                  {...fieldProps.field}
                                  name="bank_id"
                                  onBlur={handleBlur}
                                  interface="action-sheet"
                                  onIonChange={handleChange}
                                  className="ajua-select"
                                  placeholder="Select your bank"
                                >
                                  {this.list_of_banks.map((bank, index) => {
                                    return (
                                      <IonSelectOption
                                        key={index}
                                        value={bank.code}
                                      >
                                        {StyleHelper.capitalize(bank.name)}
                                      </IonSelectOption>
                                    );
                                  })}
                                </IonSelect>
                              </div>
                            )}
                          </Field>
                          <Field
                            type="text"
                            id="account_number"
                            name="account_number"
                          >
                            {(fieldProps: FieldProps) => (
                              <div className="form-group input-acc-num">
                                <p className="ajua-form-label">Account number</p>
                                <IonInput
                                  type={!this.state.showAccNumber ? 'password' : 'text'}
                                  clear-on-edit="false"
                                  className="ajua-input"
                                  placeholder="xxxxxxxxx"
                                  {...fieldProps.field}
                                  onIonChange={handleChange}
                                  // onBlur={handleBlur}
                                  name="account_number"
                                />
                                <IonIcon icon={this.state.passwordIcon} class="passwordIcon" onClick={() => this.hideShowAccNumber()}></IonIcon>
                                <ErrorMessage name="account_number">
                                  {msg => (
                                    <InlineAlert type={"error"} message={msg} />
                                  )}
                                </ErrorMessage>
                              </div>
                            )}
                          </Field>
                          {/* <Field type="number" id="bvn_number" name="bvn_number">
                            {(fieldProps: FieldProps) => (
                              <div className="form-group">
                                <p className="ajua-form-label">BVN</p>
                                <IonInput
                                  type="password"
                                  clear-on-edit="false"
                                  inputmode="numeric"
                                  autocomplete="off"
                                  className="ajua-input"
                                  placeholder="xxxxxxxxx"
                                  {...fieldProps.field}
                                  onIonChange={handleChange}
                                  onBlur={handleBlur}
                                  name="bvn_number"
                                />
                                <p className="bvn-modal-trigger" onClick={() => this.showBVNInfo(true)}><IonIcon icon={informationCircle} className="bvn-modal-trigger-icon"/> Why is my BVN required?</p>
                                <ErrorMessage name="bvn_number">
                                  {msg => (
                                    <InlineAlert type={"error"} message={msg} />
                                  )}
                                </ErrorMessage>
                              </div>
                            )}
                          </Field> */}
                          <Field type="email" id="account_name" name="account_name">
                            {(fieldProps: FieldProps) => (
                              <div className="form-group">
                                <p className="ajua-form-label">Account name</p>
                                <IonInput
                                  type="text"
                                  className="ajua-input"
                                  placeholder="Account name"
                                  {...fieldProps.field}
                                  onIonChange={handleChange}
                                  onBlur={handleBlur}
                                  name="account_name"
                                />
                                <ErrorMessage name="account_name">
                                  {msg => (
                                    <InlineAlert type={"error"} message={msg} />
                                  )}
                                </ErrorMessage>
                              </div>
                            )}
                          </Field>
                          <button
                            type="submit"
                            className="ajua-button -neutral -full-width"
                            disabled={!(isValid && dirty)}
                          >
                            Submit
                        </button>
                        </Form>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <IonModal isOpen={this.state.showBVNInfoModal} cssClass='bvn-custom-class'>
            <IonIcon className="info-icon" icon={informationCircle}/><p>Central Bank of Nigeria (CBN) regulation stipulates that all merchants who receive payments via ecommerce must register a BVN</p>
            <IonButton onClick={() => this.showBVNInfo(false)}>Close</IonButton>
          </IonModal>
          <IonModal isOpen={this.state.showEnterPINModal} cssClass='redeem-offer-modal' onDidDismiss={() => this.showEnterPIN(false, null, null)}>
            <div className="actions-title">
                <h3 className="modal-title">Enter PIN</h3>
                <button className="btn-close2 ajua-button" onClick={() => this.showEnterPIN(false, null, null)}>&times;</button>
            </div>
            <div className="lock-screen">
              {/* <img className="illustration" src={'assets/icons/padlock.svg'}/> */}

              {/* <h1 className="title">Enter PIN</h1> */}
              <p className="description">Enter your <strong>4-digit PIN</strong> to continue.</p>

              <PinInput
                  ref={this.unlockPINInput}
                  length={4}
                  initialValue=""
                  secret
                  onChange={(value, index) => {
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{padding: '10px'}}
                  inputStyle={{borderColor: 'red'}}
                  inputFocusStyle={{borderColor: 'blue'}}
                  onComplete={(value, index) => {
                      this.unlockScreen(value);
                  }}/>
            </div>
          </IonModal>
          <Toast message={this.state.toastOptions ?.toastMessage} open={this.state.toastOptions ?.toastOpen}
              position="bottom" color={this.state.toastOptions ?.toastColor}
              closeToast={this.closeToast} />
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(TrackPayments);

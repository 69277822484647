import React from "react";
import "./floatingActionBar.sass"

interface IProps {

}

const FloatingActionBar: React.FC = (props: any) => {
    return (
        <div className="floating-action-bar">
            {props.children}
        </div>
    )
};

export default FloatingActionBar;

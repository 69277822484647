import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import {
    IonApp,
    IonRouterOutlet,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { HeaderColor } from '@ionic-native/header-color';
import * as Sentry from '@sentry/browser';
import environment from "./environments/environment";
import * as Amplitude from "amplitude-js";

/* Pages */
import Customer from "./pages/customer/customer";
import OfferDetails from "./pages/offer-details/offerDetails";
import DebtDetails from "./pages/debt-details/debtDetails";
import SelectCustomerImportMethod from "./pages/add-customer/select-customer-import-method/selectCustomerImportMethod";
import ImportedCustomersList from "./pages/add-customer/imported-customer-list/importedCustomersList";
import LogDebt from "./pages/log-debt/logDebt";
import CreateOffer from "./pages/create-offer/createOffer";
import CreateInAppAds from "./pages/create-in-app-ads/createInAppAds";
import Login from "./pages/login/login";
import OTP from "./pages/one-time-pin/otp";
import SendMessage from "./pages/send-message/sendMessage";
import TransactionsPage from "./pages/transactions-page/transactionsPage";
import FeedbackPage from "./pages/feedback-page/feedbackPage";
import { getPrefetchToken, setAPIToken } from "./shared/apiCalls/authentication";
import moment from "moment";
import MerchantRegistration from "./pages/merchant-registration/merchantRegistration";
import SubscriptionPayment from "./pages/subscription-payment/subscriptionPayment";
import SuccessfulPayment from "./pages/subscription-payment/successful-payment";
import WelcomeScreen from "./pages/welcome-screen/welcomeScreen";
import SendOffer from "./pages/send-offer/sendOffer";
import DealDetails from "./pages/deal-details/dealDetails";
import Settings from "./pages/settings/settings";
import UserProfile from "./pages/user-profile/userProfile";
import EditProfile from "./pages/edit-profile/edit-profile";
import IntroSlides from "./pages/intro-slides/introSlides";
import authGuard from "./shared/services/authentication/authGuard.service";
import ComingSoon from "./pages/coming-soon/comingSoon";
import PaymentsAndSubscriptions from "./pages/payments-and-subscriptions/paymentsAndSubscriptions";
import Main from "./pages/main/main";
import Deals from "./pages/deals/deals";
import NewspaperSubscription from "./pages/newspaper-subscription/newspaper-subscription";
import Newspapers from "./pages/newspapers/newspapers";
import LockScreen from "./pages/passcode/unlock-screen/lock-screen";
import TrackPayments from "./pages/track-payments/trackPayments";
import { setToken } from "./shared/services/authentication/auth"
import ContextMenu from "./shared/components/context-menu/context-menu"
import NewReferral from "./pages/new-referral/new-referral"

/* Helper Classes */
import { getFromStorage, saveToStorage, SECURE_STORAGE_FIELDS, STORAGE_FIELDS } from "./shared/helpers/storage";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import SetPasscode from "./pages/passcode/set-passcode/set-passcode";
import ManagePasscode from "./pages/passcode/manage-passcode/manage-passcode";
import { AppState, Plugins } from '@capacitor/core';

/* Theme variables */
import './theme/variables.css';
import './theme/stylesheets/ajua.sass';
import './theme/stylesheets/ajua-buttons.sass';
import './theme/stylesheets/ajua-icons.sass';
import './theme/stylesheets/ajua-forms.sass';
import ManualCustomerEntry from "./pages/add-customer/manual-customer-entry/manualCustomerEntry";
import ProductRequest from "./pages/product-request/product-request";
import DebtPayment from "./pages/debt-payment/debt-payment";

export interface IProfileMilestones {
    addBusinessDetails: boolean,
    addPaymentInfo: boolean
    addCustomer: boolean
    //advertise: boolean,
    //offers: boolean,
    //supplierDeals: boolean
}

// TODO: handle 401 as global when token expires
const App: React.FC = () => {
    // Initiate Metrics and Crashlytics
    Sentry.init({ dsn: environment.sentryDNS });
    Amplitude.getInstance().init(environment.amplitudeAPIKey);

    HeaderColor.tint('#F4C534');

    /*
    * Obtain API Token (necessary for all API calls)
    * */
   getPrefetchToken().then(async (authData) => {
        await setToken(authData ?.token);
        await saveToStorage(STORAGE_FIELDS.TOKEN, authData ?.token);
        await saveToStorage(STORAGE_FIELDS.EXPIRY, authData ?.expiry);
        const now = moment(new Date());
        const end = moment(authData ?.expiry);
        const duration = moment.duration(now.diff(end));
        const days = duration.asDays();

        // this will reset the token a day before the expiry and beyond
        if (days > -2) {
            // change token now
            setAPIToken().then(authData => {
                saveToStorage(STORAGE_FIELDS.TOKEN, authData ?.token);
                saveToStorage(STORAGE_FIELDS.EXPIRY, authData ?.expiry);
            }).catch(error => console.log(error));
        }

        const profileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);

        if (!profileMilestones) {
            await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, {
                addBusinessDetails: false,
                addPaymentInfo: false,
                addCustomer: false
            })
        }
    }).catch(error => console.log(error));

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route path="/welcome-screen" component={WelcomeScreen} />
                    <Route path="/login" component={Login} />
                    <Route path="/intro-slides" component={IntroSlides} />
                    <Route path="/register-merchant" component={MerchantRegistration} />
                    <Route path="/subscription-payment" component={SubscriptionPayment} />
                    <Route path="/successful-payment" component={SuccessfulPayment} />
                    <Route path="/otp" component={OTP} />
                    <Route path="/main" component={authGuard(Main)} />
                    <Route path="/settings" component={authGuard(Settings)} />
                    <Route path="/user-profile" component={authGuard(UserProfile)} />
                    <Route path="/edit-profile" component={authGuard(EditProfile)} />
                    <Route path="/payments-subscriptions" component={authGuard(PaymentsAndSubscriptions)} />
                    <Route path="/customer" component={authGuard(Customer)} />
                    <Route path="/offer-details" component={authGuard(OfferDetails)} />
                    <Route path="/deals" component={authGuard(Deals)} />
                    <Route path="/deal-details" component={authGuard(DealDetails)} />
                    <Route path="/product-request" component={authGuard(ProductRequest)} />
                    <Route path="/debt-details" component={authGuard(DebtDetails)} />
                    <Route path="/debt-payment" component={authGuard(DebtPayment)} />
                    <Route path="/select-customer-import-method" component={authGuard(SelectCustomerImportMethod)} />
                    <Route path="/manual-customer-entry" component={authGuard(ManualCustomerEntry)} />
                    <Route path="/imported-customers" component={authGuard(ImportedCustomersList)} />
                    <Route path="/log-debt" component={authGuard(LogDebt)} />
                    <Route path="/send-message" component={authGuard(SendMessage)} />
                    <Route path="/send-offer" component={authGuard(SendOffer)} />
                    <Route path="/create-offer" component={authGuard(CreateOffer)} />
                    <Route path="/create-ad" component={authGuard(CreateInAppAds)} />
                    <Route path="/transactions-page" component={authGuard(TransactionsPage)} />
                    <Route path="/feedback-page" component={authGuard(FeedbackPage)} />
                    <Route path="/newspapers" component={authGuard(Newspapers)} />
                    <Route path="/newspaper-subscription" component={authGuard(NewspaperSubscription)} />
                    <Route path="/new-referral" component={authGuard(NewReferral)} />
                    <Route path="/coming-soon" component={authGuard(ComingSoon)} />
                    <Route path="/lock-screen" component={authGuard(LockScreen)} />
                    <Route path="/manage-passcode" component={authGuard(ManagePasscode)} />
                    <Route path="/set-passcode" component={authGuard(SetPasscode)} />
                    <Route path="/track-payments" component={authGuard(TrackPayments)} />
                    <Route path="/" render={() => <Redirect to="/lock-screen" />} exact={true} />
                </IonRouterOutlet>
            </IonReactRouter>
            <ContextMenu />
        </IonApp>
    )
};

export default App;

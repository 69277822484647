import "./businessCode.sass"
import React from "react";
import {Plugins} from "@capacitor/core";
import {getFromStorage, STORAGE_FIELDS} from "../../helpers/storage";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../helpers/events-tracked";
import {MerchantModel} from "../../models/merchant.model";

const { Clipboard } = Plugins

interface IProps {
    code: string;
}

const BusinessCode: React.FC<IProps> = (props: IProps) => {

    const openShareSheet = async () => {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);

        const {Share, Clipboard} = Plugins;
        const shareSheetOptions = {
            text: `Welcome to ${merchant.business_name}. Dial ${merchant.ussd_string} and be the first in line for offers and discounts.`,
            title: `Welcome to ${merchant.business_name}.`,
            dialogTitle: 'Share Business Code',
            url: ''
        };

        Share.share(shareSheetOptions);

        // Track event
        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SHARE_AD,
            {merchantDetails: merchant});

    };
    
    

    const copyBusinessCode = async () => {
        Clipboard.write({
            string: props.code
        });
        let result = await Clipboard.read();
        console.log('Got', result.type, 'from clipboard:', result.value);
    }

    return (
        <div className="business-code-widget no-decoration">
            <div className="code-details">
                <div className="issuer-logo" onClick={() => openShareSheet()}>
                    <img src={'assets/images/mtn_logo.png'}/>
                </div>
                <p className="code" onClick={() => openShareSheet()}>{props.code} </p>
                {/* <p className="copy"><button onClick={()=> copyBusinessCode()}><img src="assets/icons/ic_copy_24.png" alt="btn"/></button></p> */}
                <p className="share"><button onClick={()=> openShareSheet()}><img src="assets/icons/ic_share.png" alt="btn"/></button></p>
            </div>
        </div>
    )

};

export default BusinessCode;

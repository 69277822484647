import React from "react";
import "./settings.sass"
import {IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonAlert, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {personOutline, arrowForward, logOutOutline, shieldOutline, send, call} from 'ionicons/icons';
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import {
    clearStorage,
    getFromStorage,
    removeFromStorage,
    saveToStorage,
    STORAGE_FIELDS
} from "../../shared/helpers/storage";
import BusinessCode from "../../shared/components/business-code/businessCode";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import {Link} from "react-router-dom";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getMerchant} from "../../shared/apiCalls/customerCalls";
import MerchantProvider from "../../shared/services/providers/merchant.provider";
import environment from "../../environments/environment";

interface IState {
    toastMessage: string,
    toastOpen: boolean,
    toastColor: string,
    loading: boolean,
    merchant: MerchantModel,
    alertOptions: {
        alertMessage: string,
        alertOpen: boolean,
        alertColor: string
},
}

class Settings extends React.Component<any, IState> {

    state = {
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        loading: true,
        merchant: {} as MerchantModel,
        alertOptions: {
            alertMessage: "success",
            alertOpen: false,
            alertColor: "success"
        },
    };

    async ionViewDidEnter() {
        await this.fetchUpdatedProfile();
    }

    async ionViewWillLeave() {
        // Update the offline merchant profile
        await MerchantProvider.refreshOfflineMerchantProfile();
    }

    async fetchUpdatedProfile() {
        // Get updated merchant Profile
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT)
        const merchantID = merchant.id?.$oid;
				if(merchantID) {
					// const merchant = await getMerchant(merchantID);
					// await saveToStorage(STORAGE_FIELDS.MERCHANT, merchant);
					this.setState({merchant, loading: false});
				} else {
					this.signOut();
				}
    }

    confirmSignout = () => {
        this.setState({
            alertOptions: {
                ...this.state.alertOptions, 
                alertOpen: true, 
                alertMessage: "Do you want to sign out?",
            }
        });
    }

    async signOut() {
        await clearStorage();
        this.props.history.push({pathname: '/welcome-screen'});
    }

    closeAlert = () => {
        this.setState({alertOptions: {...this.state.alertOptions, alertOpen: false}});
    };

    openTC() {
        InAppBrowser.create('https://docs.google.com/document/d/e/2PACX-1vRiMVchN1S0_qHIMs4MyqpvOA0IcKXYbndThmPsw4tAo-Fk_XC-KThfRk-WEama_wZtRKF_J0BKQUj6/pub', '_blank', environment.inAppBrowserOptions);
    }

    openPPolicy() {
        InAppBrowser.create('https://docs.google.com/document/d/e/2PACX-1vS9igrdA9q1Q_x3U_D0CMbOccbeI8Xg-NBIPz2MfiDLFQ7_DlYqCa6Lp6ESkijClfu-6hg59y03x5zp/pub', '_blank', environment.inAppBrowserOptions);
    }

    /*
    * Opens the help/faq documentation available online
    * */
    openHelp() {
        InAppBrowser.create('https://docs.google.com/document/u/1/d/e/2PACX-1vSGTEw95ZRo_PcUU8RTG2OPqMIwpPUtY8GTQNz7vjndroVVMm9-Ixt1cJZSH_tkeuNDyOV8ET-0iLlZ/pub', '_blank', environment.inAppBrowserOptions);
    }
    openFAQs() {
        InAppBrowser.create('https://docs.google.com/document/d/e/2PACX-1vRKsJ37BXrWYAmDy5TRqfnxOwTBg1Aran4p9ftDRAWFCQ3yCmACLoZjqLHNjyY5MELVjcz6MWHDGpxW/pub', '_blank', environment.inAppBrowserOptions);
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Settings"}}/>
                </IonHeader>
                <IonContent>
                    <IonLoading
                        cssClass=''
                        isOpen={this.state.loading}
                        message={'Loading'}/>

                    <div className="settings-page">
                        <div className="page-section profile-summary no-margin">
                            <div className="section-content">
                                <div className="profile-avatar">
                                    <img src={'assets/icons/profile-placeholder.jpg'}/>
                                </div>
                                <div className="profile-details">
                                    <p className="business-name">{this.state.merchant?.business_name}</p>
                                    <BusinessCode
                                        code={this.state?.merchant?.ussd_string || ""}/>
                                </div>
                            </div>
                        </div>
                        <div className="page-section settings-menu">
                            <div className="section-content">
                                <AjuaCard>
                                    <Link to={'/user-profile'} className="settings-option no-decoration">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/user-profile.png'}/>
                                            <p>Profile</p>
                                        </div>
                                        <IonIcon className="arrow-forward -success" icon={arrowForward}/>
                                    </Link>
                                </AjuaCard>
                                <AjuaCard>
                                    <Link to={'/payments-subscriptions'} className="settings-option no-decoration">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/wallet.png'}/>
                                            <p>Subscriptions</p>
                                        </div>
                                        <IonIcon className="arrow-forward -success" icon={arrowForward}/>
                                    </Link>
                                </AjuaCard>
                                <AjuaCard>
                                    <Link to={"/track-payments"} className="settings-option no-decoration">
                                    <div className="option-details">
                                        <img className="option-icon" src={"assets/icons/payment_tracking.png"} />
                                        <p>Payment info</p>
                                    </div>
                                    <IonIcon className="arrow-forward -success" icon={arrowForward} />
                                    </Link>
                                </AjuaCard>
                                <AjuaCard>
                                    <Link to={'/manage-passcode'} className="settings-option no-decoration">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/ic_set_passcode.png'}/>
                                            <p>Change PIN</p>
                                        </div>
                                        <IonIcon className="arrow-forward" icon={arrowForward}/>
                                    </Link>
                                </AjuaCard>
                                <AjuaCard>
                                    <p className="contact-details-title"><strong>Need help?</strong></p>
                                    <div className="contact-details">
                                        <div className="contact-details-item">
                                            <div className="icon"><IonIcon icon={send} className="-left"/> <strong>Email</strong></div>
                                            <div className="value"><a href="mailto:letstalk.ng@mtn.com">letstalk.ng@mtn.com</a></div>
                                        </div>
                                        <div className="contact-details-item">
                                            <div className="icon"><IonIcon icon={call} className="-left"/> <strong>Dial</strong></div>
                                            <div className="value"> <a href="tel:180">180 or 216</a></div>
                                        </div>
                                    </div>
                                </AjuaCard>
                                <AjuaCard>
                                    <div onClick={() => {
                                        this.openFAQs()
                                    }} className="settings-option">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/help.png'}/>
                                            <p>FAQs</p>
                                        </div>
                                        <IonIcon className="arrow-forward -success" icon={arrowForward}/>
                                    </div>
                                </AjuaCard>
                                <AjuaCard>
                                    <div onClick={() => { this.openPPolicy() }} className="settings-option">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/privacy.png'}/>
                                            <p>Privacy & Security</p>
                                        </div>
                                        <IonIcon className="arrow-forward" icon={arrowForward}/>
                                    </div>
                                </AjuaCard>
                                <AjuaCard>
                                    <div onClick={() => {
                                        this.openTC()
                                    }} className="settings-option">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/terms_and_conditions.png'}/>
                                            <p>Terms & Conditions</p>
                                        </div>
                                        <IonIcon className="arrow-forward -success" icon={arrowForward}/>
                                    </div>
                                </AjuaCard>
                                <AjuaCard>
                                    <div onClick={() => {
                                        this.confirmSignout()
                                    }} className="settings-option">
                                        <div className="option-details">
                                            <img className="option-icon" src={'assets/icons/sign-out.png'}/>
                                            <p>Sign Out</p>
                                        </div>
                                        <IonIcon className="arrow-forward" icon={arrowForward}/>
                                    </div>
                                </AjuaCard>
                            </div>
                        </div>
                        <div className="page-section bottom-options">
                            <div className="section-content">
                                <Link
                                to={"/new-referral"}
                                className="referral-code-widget no-decoration"
                                >
                                <p className="referral-title">Invite Friends</p>
                                <p className="referral-description">
                                    Grow together by sharing MTN EnGauge with your circle
                                </p>
                                </Link>

                                <div className="version-details">
                                    <p><strong>Version:</strong> {environment.version}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <IonAlert
                            isOpen={this.state.alertOptions?.alertOpen}
                            onDidDismiss={this.closeAlert}
                            cssClass='my-custom-class'
                            header={'Sign out'}
                            message={this.state.alertOptions?.alertMessage}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: this.closeAlert
                                },
                                {
                                    text: 'Okay',
                                    handler: () => {
                                        this.signOut()
                                    }
                                }
                            ]}
                        />
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Settings);


import React from "react";
import "./set-passcode.sass";
import {IonContent, IonHeader, IonPage, withIonLifeCycle} from "@ionic/react";
import PinInput from "react-pin-input";
import AjuaToolbar from "../../../shared/components/ajua-toolbar/ajuaToolbar";
import {getFromStorage, removeFromStorage, saveToStorage, SECURE_STORAGE_FIELDS, STORAGE_FIELDS} from "../../../shared/helpers/storage";
import {Toast} from "../../../shared/components/generic/toast";
import {MerchantModel} from "../../../shared/models/merchant.model";

interface IState {
    toastOptions: {
        toastMessage: string,
        toastOpen: boolean,
        toastColor: string,
    },
    status: string,
    code: string,
    confirmCode: string
}

enum PASSCODE_STATES {
    NEW = "new",
    CHANGE = "change"
}

class SetPasscode extends React.Component<any, IState> {
    private newPINInput = React.createRef<PinInput>();
    private confirmPINInput = React.createRef<PinInput>();
    private changePINInput = React.createRef<PinInput>();

    state = {
        toastOptions: {
            toastMessage: '',
            toastOpen: false,
            toastColor: 'success',
        },
        status: PASSCODE_STATES.NEW,
        changeCode: '',
        code: '',
        confirmCode: ''
    };

    async ionViewWillEnter() {
        const passcode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
        if (passcode) {
            this.setState({
                status: PASSCODE_STATES.CHANGE
            })
        }
    }

    async changePasscode(code: string) {
        const currentPasscode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
        if (code === currentPasscode) {
            await removeFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
            this.changePINInput.current?.clear();

            this.setState({status: PASSCODE_STATES.NEW});
        } else {
            this.changePINInput.current?.clear();

            this.setState({
                toastOptions: {
                    toastMessage: 'Wrong PIN entered. Try again.',
                    toastOpen: true,
                    toastColor: 'danger',
                }
            });
        }
    }

    async confirmPasscode(confirmCode: string) {
        // const passCodeStorage: SecureStorageObject = await SecureStorage.create(SECURE_STORAGE_FIELDS.STORAGE_NAME);
        //
        // passCodeStorage.set(SECURE_STORAGE_FIELDS.PASSCODE, code).then((saved: any) => {
        //     this.setState({
        //         toastOptions: {
        //             toastMessage: 'Successfully added your PIN.',
        //             toastOpen: true,
        //             toastColor: 'success',
        //         }
        //     }, () => {
        //         this.props.history.replace({pathname: '/manage-passcode'})
        //     });
        // }).catch(error => {
        //     this.setState({
        //         toastOptions: {
        //             toastMessage: 'Failed to save PIN. Try again.',
        //             toastOpen: true,
        //             toastColor: 'danger',
        //         }
        //     });
        //     // console.log('Error saving passcode: ', error);
        // });

        const {code} = this.state;
        if (confirmCode === code) {
            this.setState({
                toastOptions: {
                    toastMessage: '',
                    toastOpen: false,
                    toastColor: 'success',
                }
            }, async () => {
                await saveToStorage(SECURE_STORAGE_FIELDS.PASSCODE, code).then((saved) => {
                    this.confirmPINInput.current?.clear();

                    this.setState({
                        toastOptions: {
                            toastMessage: 'Successfully added your PIN.',
                            toastOpen: true,
                            toastColor: 'success',
                        }
                    }, async () => {
                        const viewedIntro = await getFromStorage(STORAGE_FIELDS.VIEWED_INTRO_SLIDE);
                        // const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
                        // const days_ago: number = (merchant.created_at.$date/1000) / (60 * 60 * 24)
                        if (!viewedIntro) { // && days_ago < 30
                            this.props.history.replace("/intro-slides");
                        } else {
                            //If this is not my first time loading the app 
                            //OR my account was created 30 days ago don't show me the slides
                            this.props.history.replace({pathname: '/main'});
                        }

                        
                    });
                }, error => {
                    this.confirmPINInput.current?.clear();

                    this.setState({
                        toastOptions: {
                            toastMessage: 'Failed to save PIN. Try again.',
                            toastOpen: true,
                            toastColor: 'danger',
                        }
                    });
                });
            });
        } else {
            this.confirmPINInput.current?.clear();

            this.setState({
                toastOptions: {
                    toastMessage: 'Invalid PIN. Try again.',
                    toastOpen: true,
                    toastColor: 'danger',
                },
                code: '',
                confirmCode: ''
            });
        }
    }

    setPasscode(code: string) {
        this.newPINInput.current?.clear();

        this.setState({code});
    }

    get displayCorrectForm() {
        const {code, status} = this.state;
        switch (status) {
            case PASSCODE_STATES.NEW:
                return code ? <>
                        <h1 className="title">Confirm PIN</h1>
                        <p className="description">
                            <strong>Confirm</strong> your <strong>4-digit PIN</strong>.
                        </p>

                        <PinInput
                            ref={this.confirmPINInput}
                            length={4}
                            initialValue=""
                            secret
                            onChange={(value, index) => {
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{padding: '10px'}}
                            inputStyle={{borderColor: 'red'}}
                            inputFocusStyle={{borderColor: 'blue'}}
                            onComplete={(value, index) => {
                                this.confirmPasscode(value);
                            }}/>
                    </> :
                    <>
                        <h1 className="title">Setup NEW PIN</h1>
                        <p className="description">Protect your account's content by entering a <strong>4-digit
                            PIN</strong>.</p>

                        <PinInput
                            ref={this.newPINInput}
                            length={4}
                            initialValue=""
                            secret
                            onChange={(value, index) => {
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{padding: '10px'}}
                            inputStyle={{borderColor: 'red'}}
                            inputFocusStyle={{borderColor: 'blue'}}
                            onComplete={(value, index) => {
                                this.setPasscode(value);
                            }}/>

                    </>;
            case  PASSCODE_STATES.CHANGE:
                return <>
                    <h1 className="title">CHANGE PIN</h1>
                    <p className="description">
                        Enter your current <strong>4-digit PIN</strong> before proceeding.
                    </p>

                    <PinInput
                        ref={this.changePINInput}
                        length={4}
                        initialValue=""
                        secret
                        onChange={(value, index) => {
                        }}
                        type="numeric"
                        inputMode="number"
                        style={{padding: '10px'}}
                        inputStyle={{borderColor: 'red'}}
                        inputFocusStyle={{borderColor: 'blue'}}
                        onComplete={(value, index) => {
                            this.changePasscode(value);
                        }}/>
                </>;

            default:
                return <></>
        }
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };

    render() {
        const {code} = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "PIN"}}/>
                </IonHeader>
                <IonContent>
                    <div className='setup-passcode'>
                        <img className="illustration" src={'assets/icons/padlock.svg'}/>
                        {this.displayCorrectForm}
                        <Toast message={this.state.toastOptions?.toastMessage} open={this.state.toastOptions?.toastOpen}
                               position="bottom" color={this.state.toastOptions?.toastColor}
                               closeToast={this.closeToast}/>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(SetPasscode);

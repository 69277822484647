import React from "react";
import { getFromStorage, SECURE_STORAGE_FIELDS, STORAGE_FIELDS } from "../../helpers/storage";
import { withRouter } from 'react-router';
import { AppState, Plugins } from "@capacitor/core";

export default function authGuard(Component: any) {

    class AuthGuardComponent extends React.Component<any, any> {
        state = {
            loggedIn: null
        };

        constructor(props: any) {
            super(props);
        }

        async componentDidMount() {
            this.setState({ loggedIn: await getFromStorage(STORAGE_FIELDS.MERCHANT) }, async () => {
                await this.checkRouteAccess();
            });
        }

        async checkRouteAccess() {
            if (!this.state.loggedIn) {
                const isInitialLaunch = await getFromStorage(STORAGE_FIELDS.APP_INITIAL_LAUNCH);
                const goto = isInitialLaunch != 'false' ? `/welcome-screen` : `/login`;
                this.props.history.push(goto);
            } else {
                const passcode = await getFromStorage(SECURE_STORAGE_FIELDS.PASSCODE);
                if (!passcode)
                    this.props.history.replace({ pathname: '/set-passcode' });

                const { App } = Plugins;
                // // Detect when app is in foreground or background
                // App.addListener('appStateChange', async (change: AppState) => {
                //     if (change.isActive) {
                //         this.props.history.replace({pathname: '/lock-screen'})
                //     }
                // });

            }
        }


        render() {
            return this.state.loggedIn ? <Component {...this.props} /> : null;
        }
    }

    return withRouter(AuthGuardComponent)
}

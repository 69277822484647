import React from "react";
import './inAppAdsCreate.sass';
import {Plugins} from '@capacitor/core';
import {IonIcon, IonRadio} from "@ionic/react";
import {shareSocialOutline} from "ionicons/icons";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../helpers/events-tracked";
import {getFromStorage, STORAGE_FIELDS} from "../../helpers/storage";

interface IProps {
    onSelectTemplate: (e: any) => void,
    imageUrl: string,
    businessName: string,
    businessCode?: string,
    template_id: number,
    latestOfferTitle?: string
}

class InAppAdsCreateWidget extends React.Component<IProps> {

    render() {
        return (
            <div className="in-app-ad-create-widget" onClick={() => this.props.onSelectTemplate(this.props)}>
                <div className="advert-preview">
                    <div className="overlay">
                        <div className="top">
                            <p className="business-name">{this.props?.businessName}</p>
                        </div>
                        <div className="bottom">
                            <p className="dial-code"><strong>*6031*{this.props?.businessCode}#</strong></p>
                            <p className="description">Tap here to get discounts &amp; offers</p>
                            <p className="dial-offer">Special offer: {this.props?.latestOfferTitle || 'Not available'}</p>
                        </div>
                    </div>
                    <div className="thumbnail">
                        <img className="ad-bg-image" src={this.props.imageUrl} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default InAppAdsCreateWidget;

import * as React from "react";
import "./send-offer.sass";
import {
    IonContent,
    IonHeader,
    IonInput, IonLoading,
    IonPage,
    IonSelect,
    IonSelectOption, IonSpinner,
    withIonLifeCycle
} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {Toast} from "../../shared/components/generic/toast";
import SendOfferService from "../../shared/apiCalls/sendOffer.service";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import {getFromStorage} from "../../shared/helpers/storage";
import {getOffers} from "../../shared/apiCalls/offerCalls";
import EmptyState from "../../shared/components/empty-state/emptyState";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import {getActivities} from "../../shared/apiCalls/customerCalls";
import {CustomerModel} from "../../shared/models/customer.model";

class SendOffer extends React.Component<any, any> {
    state = {
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        customerPhoneNumber: '',
        selectedOffer: '',
        offers: [],
        loading: false,
        fullScreenLoader: false,
        defaultOfferID: 0,
        customers: [] as CustomerModel[],
        currentOfferID: null
    };

    ionViewWillEnter(): void {
        this.setState({
            customerPhoneNumber: this.props.location?.state?.customer,
            loading: true
        }, async () => {
            await this.fetchOffersList();
            await this.fetchCustomersList();
            this.setState({loading: false});
        })
    }

    componentDidMount(): void {
        this.setState({
            customerPhoneNumber: this.props.location?.state?.customer
        }, async () => {
            await this.fetchOffersList();
        })
    }

    async fetchCustomersList() {
        const customers = await getActivities("");
        this.setState({customers});
    }

    async fetchOffersList() {
        try {
            await this.setState({loading: true});
            const offers = await getOffers();
            this.setState({offers});
        } catch (e) {
            // catch global
            console.log(e);
        } finally {
            await this.setState({loading: false});
        }
        const found: any = this.state.offers.find((offer: any) => {
            return offer._id.$oid === this.props.location.state?.offerID
        })
        this.setState({currentOfferID: found?._id?.$oid})
    }

    async sendOffer(event: any) {
        event.preventDefault();
        const formData = await new FormData(event.target);

        this.setState({fullScreenLoader: true}, async () => {
            let jsonObject: any = UtilityFunctions.jsonFromFormData(formData);

            const merchant: any = await getFromStorage("merchant");
            const postObj = {
                ...jsonObject,
                merchant_id: merchant?.id?.$oid
            };

            SendOfferService.sendOffer(postObj).then(resp => {
                if (resp.status === 200) {
                    this.setState({
                        fullScreenLoader: false,
                        toastOpen: true,
                        toastMessage: "Offer successfully sent",
                        toastColor: "success",
                    }, () => {
                        // Track event
                        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SEND_OFFER,
                            {merchantDetails: merchant});
                        this.props.history.push('home/offers');
                    });
                }
            }).catch((e) => {
                this.setState({
                    fullScreenLoader: false,
                    toastOpen: true,
                    toastMessage: "Kindly check your network and try again",
                    toastColor: "danger",
                });
            });
        });
    }

    async sendBulkOffer(event: any) { //bulkOffersUrl
        event.preventDefault();
        const formData = await new FormData(event.target);

        this.setState({fullScreenLoader: true}, async () => {
            let jsonObject: any = UtilityFunctions.jsonFromFormData(formData);

            const merchant: any = await getFromStorage("merchant");
            const postObj = {
                ...jsonObject,
                merchant_id: merchant?.id?.$oid
            };

            SendOfferService.sendBulkOffer(postObj).then(resp => {
                if (resp.status === 200) {
                    this.setState({
                        fullScreenLoader: false,
                        toastOpen: true,
                        toastMessage: "Offer successfully sent",
                        toastColor: "success",
                    }, () => {
                        // Track event
                        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SEND_OFFER,
                            {merchantDetails: merchant});
                        this.props.history.push('home/offers');
                    });
                }
            }).catch((e) => {
                this.setState({
                    fullScreenLoader: false,
                    toastOpen: true,
                    toastMessage: "Kindly check your network and try again",
                    toastColor: "danger",
                });
            });
        });
    }

    closeToast = () => {
        this.setState({toastOpen: false});
    };

    createOffer() {
        this.props.history.push('/create-offer');
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Send Offer"}}/>
                </IonHeader>
                <IonContent>
                    <IonLoading
                        cssClass=''
                        isOpen={this.state.fullScreenLoader}
                        message={'Loading'}/>
                    <div className="page-section send-offer-page">
                        {
                            !this.state.loading ? (
                                <div className="section-content">
                                    {
                                        this.state.offers?.length > 0 ? (
                                            <form onSubmit={(e) => this.sendBulkOffer(e)}>
                                                {/* <div className="form-group">
                                                    <p className="ajua-form-label">Send To</p>
                                                    <IonInput required className="ajua-input" placeholder="Phone Number"
                                                              type="tel"
                                                              inputMode="tel" name="msisdn"
                                                              value={this.state.customerPhoneNumber}/>
                                                </div> */}
                                                <div className="form-group">
                                                    <p className="ajua-form-label">Send To</p>

                                                    <IonSelect multiple={true} name="msisdns" className="ajua-select"
                                                               interface="alert"
                                                               value={this.state.customers.find(customer => {
                                                                   return customer.CUSTOMER_ID === this.state.customerPhoneNumber
                                                               })?.CUSTOMER_ID}
                                                               placeholder="Select customers">
                                                        {this.state.customers.map((customer: CustomerModel, index: number) => {
                                                            return <IonSelectOption key={index} value={customer.CUSTOMER_ID}><span className="customer-name">{customer?.METADATA?.name || customer.CUSTOMER_ID}</span> <span className="customer-phone">{customer?.METADATA?.name ? ` - (${customer.CUSTOMER_ID})` : ''}</span></IonSelectOption>
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group">
                                                    <p className="ajua-form-label">Select Offer</p>
                                                    <IonSelect name="offer_id" className="ajua-select"
                                                               placeholder="Select Offer"
                                                               value={this.state.currentOfferID}
                                                            //    value={this.state.offers[0]['_id']['$oid']}
                                                               >
                                                        {this.state.offers.map((offer: any, index: number) => {
                                                            return <IonSelectOption key={index}
                                                                                    value={offer?._id.$oid}>{offer.TITLE}</IonSelectOption>
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <button className="ajua-button sms -full-width -neutral">Send Offer </button>
                                            </form>
                                        ) : (
                                            <EmptyState title="No Offers Available"
                                                        description="There are no active offers to send. Create a new offer below."
                                                        icon={'assets/icons/cactus.svg'}
                                                        actionButtonText="Create New Offer"
                                                        actionCallback={() => this.createOffer()}/>
                                        )
                                    }
                                </div>) : (
                                <div className="loader">
                                    <IonSpinner color="white" name="crescent"/>
                                </div>
                            )
                        }

                        <Toast message={this.state.toastMessage} open={this.state.toastOpen} position="bottom"
                               color={this.state.toastColor} closeToast={this.closeToast}/>
                    </div>

                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(SendOffer);

import React from "react";
import "./ajua-card.sass";
import {createGesture, Gesture} from "@ionic/react";
import {GestureDetail} from "@ionic/core/dist/types/utils/gesture";

declare type LongPressCallBack = (pressed: boolean) => void;

interface IProps {
    onLongPress?: LongPressCallBack;
    className?: string;
}

interface IState {
    pressed: boolean
    longPressDelta: number;
    longPressDeltaThreshold: number;
}

class AjuaCard extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            pressed: false,
            longPressDelta: -1,
            longPressDeltaThreshold: 400
        }
    }

    componentDidMount(): void {
        // Only initiate gestures if there's a callback for long press
        if (this.props.onLongPress) {
            this.initiateGestures();
        }
    }

    /*
    * Toggles the 'pressed' status of the card
    * @params pressedStatus: boolean - The pressed status determines whether the card has been long pressed or not
    * */
    togglePressedStatus(pressedStatus: boolean) {
        if (this.props.onLongPress) {
            this.props.onLongPress(pressedStatus);
            this.resetPressedState();
        }
    }

    /*
    * Resets the pressed state once it has been emitted
    * Reason: To ensure changes in pressed status are handled by the host component
    * */
    resetPressedState() {
        this.setState({
            pressed: false
        })
    }

    /*
    * Initiate the long press gesture
    * */
    initiateGestures() {
        const longPressGesture: Gesture = createGesture({
            el: document.querySelector('.ajua-card') as Node,
            gestureName: 'longPress',
            direction: undefined,
            threshold: 0,
            passive: true,
            onStart: (detail) => {
                this.longPressCustomerCard(detail);
            },
            onEnd: (detail) => {
                const longPressDelta = detail.currentTime - detail.startTime;

                if (longPressDelta >= this.state.longPressDeltaThreshold) {
                    this.setState({
                        longPressDelta,
                        pressed: true
                    }, () => {
                        this.togglePressedStatus(this.state.pressed);
                    });
                } else {
                    this.setState({
                        longPressDelta: -1,
                        pressed: false
                    })
                }
            }
        });

        longPressGesture.enable(true);
    }

    longPressCustomerCard(detail: GestureDetail) {
        if (this.state.longPressDelta > -1) {
            this.setState({
                pressed: true
            })
        }
    }

    render() {
        return (
            <div className={`ajua-card ${this.props?.className || ''}`}>
                {this.props.children}
            </div>
        )
    }
}

export default AjuaCard;

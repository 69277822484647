import React from "react";
import "./otp.sass";
import { IonContent, IonIcon, IonInput, IonPage, IonProgressBar, IonSpinner, withIonLifeCycle } from "@ionic/react";
import { generateOTP, validateOTP, getAuthOTPForWhatsApp } from "../../shared/apiCalls/authentication";
import { Toast } from "../../shared/components/generic/toast";
import { saveToStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import { arrowBackOutline, chatbubbleEllipsesOutline, logoWhatsapp } from "ionicons/icons";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import * as yup from "yup";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert"
import { SocialSharing } from "@ionic-native/social-sharing"

interface Props {
    history: any,
    location: { state: any }
}

interface OTPFormSchema {
    otpNumber?: string,
}

let countDown: NodeJS.Timeout;

class OTP extends React.Component<Props> {
    state = {
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        counter: 60,
        currentProgress: (56 / 60),
        loggingIn: false,
        resendPin: false
    };

    private otpFormValidationSchema = yup.object().shape({
        otpNumber: yup.string().length(4, 'Error: Enter only a 4-digit PIN').required('Kindly enter a One Time Pin (OTP) proceeding')
    });

    ionViewDidEnter(): void {
        this.startCounter();
    }


    ionViewWillLeave() {
        if (this.state.loggingIn) {
            this.setState({ loggingIn: false });
        }
    }

    startCounter() {
        this.setState({
            counter: 60,
            currentProgress: (56 / 60)
        });
        countDown = setInterval(() => {
            const { counter } = this.state;
            if (counter > 0) {
                this.setState({
                    counter: counter - 1,
                    currentProgress: (counter / 60)
                });
            } else {
                this.setState({
                    resendPin: true
                })
            }
        }, 1000)
    }

    closeToast = () => {
        this.setState({ toastOpen: false });
    };

    async validateOTP(values: OTPFormSchema, resetForm: any) {
        if (values) {
            const { location, history } = this.props;
            clearInterval(countDown);
            this.setState({ loggingIn: true, resendPin: true }, async () => {
                const slicedPhoneNumber = location ?.state ?.phoneNumber ?.substr(1);
                await validateOTP(slicedPhoneNumber, values.otpNumber)
                    .then(async (merchantData) => {
                        if (merchantData.status === 'success') {
                            await saveToStorage(STORAGE_FIELDS.MERCHANT, merchantData ?.merchant);
                            await saveToStorage(STORAGE_FIELDS.MERCHANT_PAYMENT_INFO, merchantData ?.merchant ?.payment_info);
                            this.setState({ loggingIn: false });
                            resetForm({});
                            history.replace("/main");
                        } else {
                            this.setState({
                                toastOpen: true,
                                toastMessage: 'Invalid OTP entered! Try again.',
                                toastColor: "danger",
                                loggingIn: false,
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({
                            toastOpen: true,
                            toastMessage: error || "request failed",
                            toastColor: "danger",
                            loggingIn: false,
                        });
                    })
            });

        }

    };

    onNumberChange = (e: any) => {
        this.setState({ otpNumber: e.detail.value! });
    };

    async regeneratePIN() {
        const { location } = this.props;
        await generateOTP(location ?.state ?.phoneNumber.substr(1));
        this.startCounter();
        this.setState({
            resendPin: false,
            toastOpen: true,
            loggingIn: false,
            toastMessage: "A new PIN has been sent"
        });
    }

    async loginWithWhatsApp() { 
        await getAuthOTPForWhatsApp()
            .then(() => {
                this.setState({
                    resendPin: false,
                    toastOpen: true,
                    loggingIn: false,
                    toastMessage: "A new PIN has been sent"
                });

                //Let OTP be handled by Janja
                SocialSharing.shareViaWhatsAppToReceiver('+19362510616', 'otp') 
            })
            .catch(error => {
                this.setState({toastOpen: false, toastMessage: "", toastColor: ""})
                this.setState({
                    toastOpen: true,
                    resendPin: true,
                    loggingIn: true,
                    toastMessage: error.toString(),
                    toastColor: "danger"
                });
            });
    }

    goBack() {
        this.props.history.replace('/login');
    }

    render() {
        const { toastMessage, toastOpen, toastColor, currentProgress, counter, loggingIn, resendPin } = this.state;
        const { location } = this.props;
        return (
            <IonPage>
                <IonContent>
                    <div className="ajua-auth-page">
                        <div className="auth-hero-image">
                            <div className="tint" />
                        </div>

                        <div className="auth-form">
                            <div onClick={() => this.goBack()} className="back-button">
                                <IonIcon icon={arrowBackOutline} />
                            </div>
                            <div className="form-content">
                                <div className="auth-title">
                                    <div className="logo -branded">
                                        <img src={'assets/images/mtn_logo.png'} />
                                    </div>
                                    <div className="description">
                                        <p className="title">Confirm PIN</p>
                                        <p className="sub-title -small">Enter PIN sent
                                            to your phone number { location ?.state ?.phoneNumber ? <strong>({location ?.state ?.phoneNumber})</strong> : '' } </p>
                                    </div>
                                </div>
                                <div className="form">
                                    <Formik
                                        initialValues={{
                                            otpNumber: '',
                                        }}
                                        validationSchema={this.otpFormValidationSchema}
                                        onSubmit={(values: OTPFormSchema, { resetForm }) => this.validateOTP(values, resetForm)}>
                                        {({ touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values }) => (
                                            <Form>
                                                <Field type="number" name="otpNumber">
                                                    {(fieldProps: FieldProps) => (
                                                        <div className="form-group">
                                                            <IonInput
                                                                {...fieldProps.field}
                                                                onIonChange={handleChange}
                                                                onBlur={handleBlur}
                                                                type="number" inputmode="numeric" maxlength={4}
                                                                className="ajua-input -large otp-input"
                                                                placeholder="Enter One Time PIN  e.g. 1234" required />
                                                            <ErrorMessage name="otpNumber">
                                                                {(msg) => (
                                                                    <InlineAlert type={'error'} message={msg} />
                                                                )}
                                                            </ErrorMessage>
                                                        </div>
                                                    )}
                                                </Field>

                                                <button type="submit" disabled={!(isValid && dirty)}
                                                    className="ajua-button -full-width -neutral -large-button">
                                                    {!loggingIn ? (<span>Log In</span>) :
                                                        (<IonSpinner color="white" name="crescent" />)}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>

                                    {
                                        loggingIn ?
                                            '' :
                                            <div className="resend-pin-counter">
                                                <div className="counter-details">
                                                    <div className="intro">
                                                        <p><IonIcon icon={chatbubbleEllipsesOutline} />Resend PIN </p>
                                                        {
                                                            resendPin ?
                                                                <button onClick={() => this.regeneratePIN()}
                                                                    className="ajua-button -xs-button">Resend
                                                                    PIN</button> :
                                                                <p className="time-countdown">{`${counter}s`}</p>
                                                        }

                                                    </div>
                                                </div>
                                                <IonProgressBar type="determinate" color="dark"
                                                    value={currentProgress} />
                                                <br/>
                                                <div className="resend-for-wa">
                                                    { resendPin &&
                                                            <button onClick={() => this.loginWithWhatsApp()}
                                                                className="ajua-button -xs-button">
                                                                <IonIcon icon={logoWhatsapp} />&nbsp;&nbsp;Resend PIN on WhatsApp?
                                                            </button> 
                                                    }

                                                </div>
                                            </div>
                                    }
                                </div>
                                <Toast message={toastMessage} open={toastOpen} position="bottom" color={toastColor}
                                    closeToast={this.closeToast} />
                            </div>
                        </div>

                    </div>

                </IonContent>
            </IonPage>
        );
    }
}

export default withIonLifeCycle(OTP);

import React from "react";
import './inAppAdsWidget.sass';
import { inAppAdsWidgetModel} from "../../models/inAppAdsWidget.model";
import {Plugins} from '@capacitor/core';
import { IonSpinner} from "@ionic/react";
import {shareSocialOutline} from "ionicons/icons";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../helpers/events-tracked";
import {getFromStorage, STORAGE_FIELDS} from "../../helpers/storage";
import { truncateStringWithDots } from '../../helpers/utility-functions'
import GetAdImageService from '../../../shared/apiCalls/image.service'
import environment from "../../../environments/environment"
import { CallNumber } from '@ionic-native/call-number';

class InAppAdsWidget extends React.Component<{ advertisement: inAppAdsWidgetModel }> {
    // private callNumber: CallNumber = new CallNumber()
    state ={
        imageFinalUrl: ''
    }
    
    componentDidMount() {
        try {
            this.getImage()
        } catch (e) {
            // Todo
            console.log('error', e.toString())
        }
    }

    async openShareSheet() {
        const {Share} = Plugins;
        const shareSheetOptions = {
            text: `Welcome to ${this.props.advertisement?.business_name}. Dial ${this.props.advertisement?.code} and be the first in line for offers and discounts.`,
            title: `${this.props.advertisement?.business_name}`,
            dialogTitle: 'Share Poster',
            url: ''
        };
        Share.share(shareSheetOptions);

        // Track event
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SHARE_AD,
            {merchantDetails: merchant});
    }

    async getImage() {
        if (this.props.advertisement.template_img === 1) {
            const imageFinalUrl = environment.inAppAdsTemplates.filter(img => img.template_id === Number(this.props.advertisement.image_id))[0]?.imageUrl
            this.setState({ imageFinalUrl })
            return
        }
        const { image_location, template_img } = this.props.advertisement
        // console.log('this.state', this.state)

        await GetAdImageService.getImage(image_location, template_img).then((imageFinalUrl: string) => {
            this.setState({ imageFinalUrl })
        }) 
        
    }

    launchDialer() {
        // Track event
        Amplitude.getInstance().logEvent(EVENTS_TRACKED.CLICKED_ON_AD,
        {merchantDetails: this.props.advertisement?.business_name})
        try {
            if(this.props.advertisement?.code) {
                CallNumber.callNumber(this.props.advertisement?.code, true)
                .then(res => console.log('Launched dialer!', res))
                .catch(err => console.log('Error launching dialer', err));
            }
        } catch (e) {
            // Todo
        }
    }

    render() {
        return (
            <div className="in-app-ad-widget">
                <div className="advert-preview">
                    <div className="overlay">
                        {/* <div className="top" onClick={() => this.launchDialer()}>
                            {this.props.advertisement?.business_name && <p className="business-name">{this.props.advertisement?.business_name}</p>}
                        </div> */}
                        <div className="bottom" onClick={() => this.launchDialer()}>
                            {this.props.advertisement.offer_details && <p className="dial-offer"><span className="special-title"> Special offer:</span> {truncateStringWithDots(this.props.advertisement.offer_details, 50)}</p>}
                            {this.props.advertisement?.code && <> <p className="dial-code">Dial {this.props.advertisement?.code}</p> </>}
                            {/* <p className="description">Tap here to get discounts &amp; offers</p> */}
                            {this.props.advertisement?.business_name && <p className="business-name">{this.props.advertisement?.business_name}</p>}
                        </div>
                    </div>
                    <div className="thumbnail">
                        {this.state.imageFinalUrl ?
                        <a href={`tel:${this.props.advertisement?.code}`}><img width="222" height="174" className="ad-bg-image" src={this.state.imageFinalUrl} alt=""/></a>
                        :
                        <IonSpinner name="lines-small"></IonSpinner>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default InAppAdsWidget;

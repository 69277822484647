import React from "react";
import "./ajua-nav-link.sass";
import {Link, withRouter} from "react-router-dom";

/*
* This is a helper component that wraps React Router's Link component and prevents navigation to the current route
* */
class AjuaNavLink extends React.Component<any, any> {
    render() {

        // Compare the current route to the navigate-to route
        if (this.props.location.pathname === this.props.to.pathname) {
            return <div className={this.props.className}>{this.props.children}</div>
        } else {
            return <Link to={this.props.to} className={this.props.className}>
                {this.props.children}
            </Link>
        }
    }
}

export default withRouter(AjuaNavLink);

import React from "react";
import "./debtDetails.sass"
import {IonContent, IonHeader, IonPage} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import pulseData from "../pulse/pulse.data";
import TransactionCard from "../../shared/components/transaction-card/transactionCard";
import DebtDetailsData from "./debtDetails.data";

class DebtDetails extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Joseph Omotunde"}}/>
                </IonHeader>
                <IonContent>
                    <div className="debt-details-page">
                        <div className="page-section summary">
                            <div className="section-content">
                                <div className="ajua-feature-card">
                                    <div className="background-image">
                                        <img src="assets/images/abstract_2.jpg"/>
                                    </div>
                                    <div className="content">
                                        <p className="content-title">Amount Borrowed</p>
                                        <p className="content-value">₦ 170.00</p>
                                    </div>
                                </div>

                                <div className="ajua-card payment-progress">
                                    <p className="title">Payment Progress</p>
                                    <div className="progress-meter">
                                        <div className="values">
                                            <p className="current-value">₦ 50</p>
                                            <p className="total">₦ 170</p>
                                        </div>
                                        <div className="progress-bar">
                                            <div className="progress"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="ajua-card payment-period">
                                    <div className="payment-details">
                                        <p><strong>Payment Period:</strong></p>
                                        <p>01/01/2020 - 10/01/2020</p>
                                    </div>
                                    <button className="ajua-button -small-button -neutral">Edit</button>
                                </div>
                            </div>

                            <div className="sub-section payment-events">
                                <p className="sub-section-title">Payment Events</p>
                                <div className="section-content">
                                    {/* {
                                        DebtDetailsData.paymentEvents.map((transaction, index) => {
                                            return <TransactionCard key={index} transaction={transaction}/>
                                        })
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default DebtDetails;

import React from "react";
import "./contentLoader.sass";
import {withIonLifeCycle} from "@ionic/react";

class ContentLoader extends React.Component<any, any>{
    render(){
        return (
            <div className="content-loader">
                <div className="loading-spinner">
                    <div className="loader">
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withIonLifeCycle(ContentLoader);

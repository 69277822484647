import React from "react";
import "./selectCustomerImportMethod.sass";
import { IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonSpinner, isPlatform, withIonLifeCycle } from "@ionic/react";
import AjuaToolbar from "../../../shared/components/ajua-toolbar/ajuaToolbar";
import { getFromStorage, saveToStorage, STORAGE_FIELDS } from "../../../shared/helpers/storage";
import { MerchantModel } from "../../../shared/models/merchant.model";
import { createActivity, getMerchant } from "../../../shared/apiCalls/customerCalls";
import ContentLoader from "../../../shared/components/content-loader/contentLoader";
import { chevronForwardOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { Contact, Contacts } from '@ionic-native/contacts';
import CustomerActivityTypes from "../../../shared/helpers/CustomerActivityTypes";
import * as Amplitude from "amplitude-js";
import { EVENTS_TRACKED } from "../../../shared/helpers/events-tracked";
import { Plugins, AppState } from '@capacitor/core';
import { IProfileMilestones } from "../../../App";
import { CustomerModel } from "../../../shared/models/customer.model";
import { getActivities } from "../../../shared/apiCalls/customerCalls";
import { Toast } from "../../../shared/components/generic/toast";
import { STATIC_LIST_OF_CARRIER_PREFIXES } from "../../../shared/helpers/data.list";

const { App } = Plugins;

class SelectCustomerImportMethod extends React.Component<any, any> {
    public contactPicker: Contacts;

    constructor(props: any) {
        super(props);
        this.contactPicker = new Contacts();
    }

    state = {
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        pageLoading: true,
        customers: [] as CustomerModel[],
        merchant: {} as MerchantModel,
        addingCustomer: false,
        selectedContact: {} as Contact,
        alreadySelectedContacts: []
    };

    private list_of_carriers_codes = STATIC_LIST_OF_CARRIER_PREFIXES;

    async ionViewDidEnter() {
		const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        const merchantID = merchant?.id?.$oid;

        if (merchantID) {
            const merchant: MerchantModel = await getMerchant(merchantID);
            await saveToStorage(STORAGE_FIELDS.MERCHANT, merchant);
            await this.getCustomers();
            this.setState({ merchant, pageLoading: false });
        } else {
            this.setState({
                toastMessage: "danger",
                toastOpen: true,
                toastColor: "Unable to fetch account details. Try again.",
                pageLoading: false
            })
        }
    }

    getCustomers = async () => {
        try {
            const customers = await getActivities("&activity_id=0");
            this.setState({ customers });
        } catch (e) {
            // this.setState({ loading: false });
        }
    };

    async openContactPicker() {
        this.setState({ toastOpen: false });
        const existPhoneNumbers = this.state.customers.map(c => {
            return c.CUSTOMER_ID.substr(3) //strip off the leading "234" because phone numbers from the device don't have country code
        })
        const mythis = this;
        this.contactPicker.pickContact() ?.then(async (contact: Contact) => {
            if (contact) {
                this.setState({ selectedContact: contact }, async () => {
                    if (contact.phoneNumbers.length > 0) {
                        const phoneNumber = contact.phoneNumbers.find(phone => {
                            return phone.value
                        });
                        
                        if (phoneNumber && phoneNumber!== undefined) {
                            const strip_pref = String(phoneNumber ?.value).replace(/^\+[0-9]{1,3}(|\-)/, "")
                            const strip_zero = strip_pref.replace(/^[0]{1}/, "")
                            const foundIndex = existPhoneNumbers.findIndex(p => p.trim() === strip_zero);
                            if (foundIndex !== -1) {
                                mythis.setState({
                                    toastOpen: true,
                                    toastMessage: "Phone number already registered. Select a different one.",
                                    toastColor: "danger"
                                });
                                return;
                            }
                            // const prefCode = phoneNumber.value?.slice(0, -7).toString().trim()
                            // const isOnlyMtnCode = this.list_of_carriers_codes.findIndex(p => p.trim() === prefCode);
                            // if(isOnlyMtnCode === -1) {
                            //     this.setState({
                            //         toastOpen: true,
                            //         toastMessage: "You customer can only add an MTN phone number",
                            //         toastColor: "danger"
                            //     });
                            //     return;
                            // }
                            const foundExistingIndex = this.state.alreadySelectedContacts.findIndex(p => p === strip_zero);
                            if (foundExistingIndex !== -1) {
                                mythis.setState({
                                    toastOpen: true,
                                    toastMessage: "Phone number already registered. Select a different one.",
                                    toastColor: "danger"
                                });
                                return;
                            }
                            mythis.setState({alreadySelectedContacts: [ ...this.state.alreadySelectedContacts, strip_zero]});
                            await this.addCustomer();
                        }
                    }
                });
            } else {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Unable to import contact. Try again",
                    toastColor: "danger"
                });
            }

        })
    }

    async updateProfileState() {
        let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.APP_MILESTONES_STATES);
        profileMilestones.addCustomer = true;

        await saveToStorage(STORAGE_FIELDS.APP_MILESTONES_STATES, profileMilestones);
    }

    /**
     * Add new customer data
     * @return {Promise<void>}
     */
    addCustomer = async () => {
        const contact: Contact = this.state.selectedContact;

        if (Object.entries(contact).length > 0) {

            let primaryPhone = contact.phoneNumbers.find(phone => {
                return phone.value
            });
            if (primaryPhone) {
                if (primaryPhone.value ?.charAt(0) === '0') {
                    primaryPhone.value = `234${primaryPhone ?.value.substr(1)}`;
                }
                this.setState({ addingCustomer: true });
                try {
                    const merchant: MerchantModel = await getFromStorage("merchant");
                    const requestObject = {
                        metadata: {
                            name: contact.displayName,
                            notes: "",
                        },
                        msisdn: primaryPhone.value,
                        welcome_msg: false,
                        activity_id: CustomerActivityTypes.addCustomer,
                        merchant_id: merchant ?.id ?.$oid
                    };

                    await createActivity(requestObject)
                        .then(async (message) => {
                            await this.updateProfileState();
                            this.setState({
                                toastOpen: true,
                                toastMessage: "success",
                                toastColor: "success",
                                customerObject: {
                                    metadata: {
                                        name: "",
                                        note: "",
                                    },
                                    msisdn: "",
                                }
                            }, async () => {
                                // Track event
                                Amplitude.getInstance().logEvent(EVENTS_TRACKED.ADD_CUSTOMER,
                                    { merchantDetails: merchant });

                                this.props.history.push('/main/customers');
                            });

                        })
                        .catch(
                            error => {
                                this.setState({
                                    toastOpen: true,
                                    toastMessage: error || "request failed",
                                    toastColor: "danger"
                                });
                            }
                        );

                } catch (e) {
                    // catch global
                    console.log(e);
                } finally {
                    this.setState({
                        addingCustomer: false,
                    });
                }
            } else {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Error adding contact. View and edit the contact in your phone-book and try again",
                    toastColor: "danger"
                });
            }
        }
    };

    closeToast = () => {
        this.setState({ toastOpen: false });
    };

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{ title: "Add Customer" }} />
                </IonHeader>
                {this.state.pageLoading &&
                    (<ContentLoader />)
                }
                {/* <IonToast
                    isOpen={this.state.toastOpen}
                    message={this.state.toastMessage}
                    color={this.state.toastColor}
                    onDidDismiss={() => this.closeToast}
                    duration={4000}
                /> */}

                <IonContent>
                    <IonLoading
                        cssClass=''
                        isOpen={this.state.addingCustomer}
                        message={'Loading'} />

                    <div className="page-section -section-insert no-margin">
                        <div className="title">
                            {this.state.addingCustomer &&
                                (<IonSpinner color="black" name="crescent" />)
                            }
                        </div>
                    </div>

                    <div className="import-methods">
                        <div className="page-section">
                            <div className="section-content">
                                {
                                    !isPlatform('pwa') ? <>
                                     
                                        <div onClick={() => this.openContactPicker()} className="ajua-card import-method">
                                            <div className="method-details">
                                                <p className="method">From Phonebook</p>
                                                <p className="description">Import customer details from your phone’s contact
                                                    list</p>
                                            </div>
                                            <IonIcon icon={chevronForwardOutline} />
                                        </div>

                                        <div className="or-divider">
                                            <hr />
                                            <p>Or</p>
                                            <hr />
                                        </div>

                                     </> : null
                                }
                                

                                <Link to="/manual-customer-entry" className="ajua-card import-method">
                                    <div className="method-details">
                                        <p className="method">Manual Entry</p>
                                        <p className="description">Manually enter customer information</p>
                                    </div>
                                    <IonIcon icon={chevronForwardOutline} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Toast message={this.state.toastMessage} open={this.state.toastOpen} position="bottom" color={this.state.toastColor}
                        closeToast={this.closeToast} />
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(SelectCustomerImportMethod);

import React from "react";
import "./new-referral.sass";
import {IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonPage, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {ErrorMessage, Field, FieldProps, Form, Formik} from "formik";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import * as yup from "yup";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import {Toast} from "../../shared/components/generic/toast";
import {ToastNotificationModel} from "../../shared/models/toast-notification.model";
import NewReferralService from "./new-referral.service";
import PhoneInput from "react-phone-number-input";
import {shareOutline} from "ionicons/icons";
import ContentLoader from "../../shared/components/content-loader/contentLoader";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {Plugins} from "@capacitor/core";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";
import { isPlatform } from '@ionic/react';

interface IState {
    merchant: MerchantModel,
    referralCode: string,
    toastOptions: ToastNotificationModel,
    pageLoading: boolean,
    loading: boolean
}

interface ReferralCodeFormSchema {
    phone_number: string,
}

class NewReferral extends React.Component<any, IState> {
    private newReferralService = new NewReferralService();

    state = {
        merchant: {} as MerchantModel,
        referralCode: '',
        pageLoading: true,
        loading: false,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: "success",
        }
    };

    async ionViewDidEnter() {
        const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        await this.fetchReferralCode(merchant.id.$oid);
        this.setState({merchant, pageLoading: false});
    }

    private referralCodeFormSchema = yup.object().shape({
        phone_number: yup.string().required('The phone number of the person being referred is needed.').test('phone_number', 'Please enter a valid phone number', UtilityFunctions.validatePhoneNumber)
    });

    async fetchReferralCode(merchantID: string) {
        return this.newReferralService.getMerchantReferralCode(merchantID)
            .then(response => {
                this.setState({referralCode: response.referral_code});
            })
    }

    async sendReferralCodeSMS(values: ReferralCodeFormSchema, resetForm: any) {
        this.setState({loading: true});
        await this.newReferralService.sendReferralCode(this.state.merchant.id.$oid, values.phone_number).then((response) => {
            this.setState({
                toastOptions: {
                    toastMessage: "Successfully sent referral code.",
                    toastOpen: true,
                    toastColor: "success",
                },
                loading: false
            })
        }, error => {
            this.setState({
                toastOptions: {
                    toastMessage: "Failed to share referral code.",
                    toastOpen: true,
                    toastColor: "danger",
                },
                loading: false
            });
        })
    }

    async socialShare() {
        const {Share} = Plugins;
        const shareSheetOptions = {
            // text: `Make more sales, save money on new stock and keep your customers with real-time feedback. Sign up and get first month FREE. Download from PlayStore: https://play.google.com/store/apps/details?id=com.mtn.gauge`,
            // text: `I found this app MTN EnGauge really great in growing my business. They are offering a unique USSD Code for mobile payments and several tools to engage my customers in one platform. Tap here to download and subscribe for the 30-day trial: https://cutt.ly/Sx4X2PQ`,
            text: `I found this app MTN EnGauge really great in growing my business. It enables you to receive payments using a shortcode. Use the referral code '${this.state.merchant.metadata.mtn.ussd_code}' when registering. Tap here to download and subscribe for the 30-day trial: https://cutt.ly/Sx4X2PQ`,
            title: ` MTN EnGauge Referral Code`,
            dialogTitle: 'Share Referral Code',
        };

        if(isPlatform('pwa')){
            navigator.share(shareSheetOptions).then(async (resp) => {
                Amplitude.getInstance().logEvent(EVENTS_TRACKED.REFER_A_FRIEND);
            }).catch(e => {
                console.log('Error: ', e.toString());
                this.setState({
                    toastOptions: {
                        toastMessage: "Failed to share referral code.",
                        toastOpen: true,
                        toastColor: "danger",
                    }
                });
            });
        }else{
            Share.share(shareSheetOptions).then(async (resp) => {
                Amplitude.getInstance().logEvent(EVENTS_TRACKED.REFER_A_FRIEND);
            }).catch(e => {
                console.log('Error: ', e.toString());
                this.setState({
                    toastOptions: {
                        toastMessage: "Failed to share referral code.",
                        toastOpen: true,
                        toastColor: "danger",
                    }
                });
            });
        }
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Invite your friends"}}/>
                </IonHeader>
                <IonContent>
                    {this.state.pageLoading &&
                    (<ContentLoader/>)
                    }
                    <div className="new-referral-page">
                        <IonLoading
                            cssClass=''
                            isOpen={this.state.loading}
                            message={'Loading'}/>

                        <img src={'https://image.flaticon.com/icons/svg/561/561047.svg'} className="reward-img" alt="none"/>
                        <h1 className="about">Invite your friends to MTN EnGauge
                        {/* and get <span className="neutral-color">100 Naira of airtime!</span> */}
                        </h1>
                        <p className="referral-description">Tip your friends to grow your businesses together by owning their unique business codes for mobile transactions and accessing simplified CRM tools</p>
                        <div className="referral-form">
                            <Formik
                                initialValues={{
                                    phone_number: '',
                                }}
                                validationSchema={this.referralCodeFormSchema}
                                onSubmit={(values, {resetForm}) => this.sendReferralCodeSMS(values, resetForm)}>

                                {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                    <Form>
                                        <Field type="text" id="phone_number" name="phone_number">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <PhoneInput
                                                        {...fieldProps.field}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        countries={['NG']}
                                                        autoComplete="on"
                                                        onChange={(value) => setFieldValue('phone_number', value)}
                                                        displayInitialValueAsLocalNumber
                                                        defaultCountry='NG'
                                                        className="ajua-input"
                                                        placeholder="Enter phone number"
                                                        name="phone_number"/>
                                                    <ErrorMessage name="phone_number">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg}/>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field>
                                        <button type="submit" className="ajua-button -success -full-width"
                                                disabled={!(isValid && dirty)}>
                                            Send Referral Code
                                        </button>
                                        <p className="hint"><small>We will send an SMS to this phone number inviting
                                            them to MTN EnGauge.</small></p>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className="or-divider">
                            <hr/>
                            <p>Or</p>
                            <hr/>
                        </div>

                        <button onClick={() => this.socialShare()} className="ajua-button -neutral -full-width">
                            <IonIcon icon={shareOutline} className="-left"/> Share On Social Media
                        </button>

                        <Toast message={this.state.toastOptions?.toastMessage} open={this.state.toastOptions?.toastOpen}
                               position="bottom"
                               color={this.state.toastOptions?.toastColor}
                               closeToast={this.closeToast}/>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(NewReferral);

import ApiService from "../services/network-services/api.service";
import {getFromStorage} from "../helpers/storage";
import {SupplierOfferModel} from "../../pages/pulse/pulse";

/**
 * get nps activity feed
 * @param {number} accountId
 * @param {string} startDate
 * @param {string} endDate
 * @param {number} limit
 * @param {number} offset
 * @return {Promise<void>}
 */
export async function getNpsComments(accountId: number, startDate: string, endDate: string,
                                     limit: number = 20, offset: number = 0) {
    const apiService = new ApiService();

    return await apiService.getResource(
        `/main/activity-feed?detractors=true&passives=true&promoters=true&from=${startDate}&to=${endDate}&limit=${limit}&offset=${offset}`,
        {baseUrl: "https://staging-primary-bridge.msurvey.co/api/0.1.0"},
        {
            "Authorization":
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImJpdG5hbWkiLCJwYXNzd29yZCI6ImNvbnZlcnNAdGlvbnMgICAiLCJpYXQiOjE1ODIyMzMwMTl9.ZABk910KsJG7BsvOAT7l7AWDkPz7lWws0ZDHgLoSD5U",
            "x-account-id": accountId,
        })
        .then(nps => {
            return nps?.data?.Data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * Get all customer activities
 * @return {Promise<any>}
 * @param {string=}parameters
 */
export async function getActivities(parameters: string = "") {
    const apiService = new ApiService();
    const merchant: any = await getFromStorage("merchant");
    return await apiService.getResource(`/customer-activity?merchant_id=${merchant?.id?.$oid}${parameters}`)
        .then(activities => {
            return activities?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

export async function getMerchant(id: string) {
    const apiService = new ApiService();
    return await apiService.getResource(
        `/merchant-profile?merchant_id=${id}`)
        .then(activities => {
            return activities?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

export async function fetchSupplierOffers(industry: string): Promise<SupplierOfferModel[]> {
    const apiService = new ApiService();
    return await apiService.getResource(
        `/v0/app2R7EXJv9qxuONf/MerchantOffers`,
        {baseUrl: "https://api.airtable.com"},
        {"Authorization": 'Bearer keymLhdlULWJ3yIGe', 'Content-type': 'application/x-www-form-urlencoded'})
        .then(response => {
            let offers: SupplierOfferModel[] = response?.data.records;
            return offers;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * Fetches the customer profile
 * @param {string}phone
 * @return {Promise<any | void>}
 */
export async function getCustomer(phone: string) {
    const apiService = new ApiService();
    const merchant: any = await getFromStorage("merchant");
    return await apiService.getResource(`/customer-profile?merchant_id=${merchant?.id?.$oid}&msisdn=${phone}`)
        .then(customer => {
            return customer?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * Get all the activites of a specific customer
 * @return {Promise<any>}
 * @param {string=}parameters
 */
export async function getCustomerActivities(parameters: string = "") {
    const apiService = new ApiService();
    const merchant: any = await getFromStorage("merchant");
    return await apiService.getResource(`/customer-activity?merchant_id=${merchant?.id?.$oid}${parameters}`)
        .then(activities => {
            return activities?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * Create customer activity
 * @return {Promise<any>}
 * @param body
 */
export async function updateCustomerProfile(body: object) {
    const apiService = new ApiService();
    return await apiService.createResource(body, '/customer-profile')
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error;
            }
        );
}

/**
 * Send offer to customer
 * @return {Promise<any>}
 * @param body
 */
export async function sendOfferToCustomer(body: object): Promise<any> {
    const apiService = new ApiService();
    return await apiService.createResource(body, '/send-offer')
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error;
            }
        );
}

/**
 * create customer activity
 * @return {Promise<any>}
 * @param body
 */
export async function createActivity(body: object) {
    const apiService = new ApiService();
    const merchant: any = await getFromStorage("merchant");
    return await apiService.createResource(body, `/customer-activity?merchant_id=${merchant?.id?.$oid}`)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error;
            }
        );
}


/**
 * create customer activity
 * @return {Promise<any>}
 * @param body
 */
export async function sendSurvey(body: object) {
    const apiService = new ApiService();
    return await apiService.createResource(body, `/send_survey`)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error;
            }
        );
}

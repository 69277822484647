import React from 'react';
import {
    IonContent,
    IonIcon, IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    IonToast,
    withIonLifeCycle
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import './pulse.sass';
import DiscountIdeaCard from "../../shared/components/discount-idea-card/discountIdeaCard";
import AdvertiseYourBusinessAction from "../../shared/components/advertise-your-business-action/AdvertiseYourBusinessAction";
import AdvertiseWidget from "../../shared/components/advertise-widget/advertiseWidget";
import InAppAdsWidget from "../../shared/components/in-app-ads-card/inAppAdsWidget";
import RevenueGraphWidget from "../../shared/components/revenue-graph-widget/revenueGraphWidget"
import {
    getNpsComments,
    getActivities,
    fetchSupplierOffers, getMerchant
} from "../../shared/apiCalls/customerCalls";
import moment from "moment";
import ActivityCard from "../../shared/components/activity-card/ActivityCard";
import TransactionCard from "../../shared/components/transaction-card/transactionCard";
import { getFromStorage, STORAGE_FIELDS } from "../../shared/helpers/storage";
import EmptyState from "../../shared/components/empty-state/emptyState";
import {
    closeOutline,
    albumsOutline,
    megaphoneOutline,
    checkmarkDoneOutline,
    pricetagsOutline,
    cashOutline
} from "ionicons/icons";
import { Link } from "react-router-dom";
import { SocialSharing } from "@ionic-native/social-sharing";
import { MerchantModel } from "../../shared/models/merchant.model";
import { TransactionCardModel } from "../../shared/models/transactionCard.model"
import { inAppAdsWidgetModel} from "../../shared/models/inAppAdsWidget.model";
import ContentLoader from "../../shared/components/content-loader/contentLoader"
import AdsService from "../../shared/apiCalls/ads.service"
import TransactionsService from "../../shared/apiCalls/transactions.service"
import { randomIntFromInterval } from "../../shared/helpers/utility-functions"
import environment from '../../environments/environment'
import { Toast } from "../../shared/components/generic/toast"

export interface SupplierOfferModel {
    id: string,
    fields: {
        whatsapp: string,
        expiry_date: string,
        offer_id: 0,
        industry: string[],
        country: string[],
        supplier: string,
        offer_description: string,
        deal_image: string;
    },
    createdTime: string
}

//Set Data for Bar Chart. In Realtime you may bing this using the data coming from API or service. 
const dailyRevenueChartData = {
    labels: ['Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'],
    datasets: [
      {
        label: 'Last 7 days',
        // backgroundColor: 'rgba(255,99,132,0.2)',
        backgroundColor: 'rgba(255, 204, 0, 1)',
        borderColor: 'rgba(255, 204, 0, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 204, 0, 1)',
        hoverBorderColor: 'rgba(255, 204, 0, 1)',
        data: [400, 590, 650, 710, 760, 650, 600]
      }
    ]
    };
const monthlyRevenueChartData = {
    labels: ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        label: 'Last 6 months',
        // backgroundColor: 'rgba(255,99,132,0.2)',
        backgroundColor: 'rgba(255, 204, 0, 1)',
        borderColor: 'rgba(255, 204, 0, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 204, 0, 1)',
        hoverBorderColor: 'rgba(255, 204, 0, 1)',
        data: [6550, 8000, 9000, 5640, 7500, 4000]
      }
    ]
    };

class Pulse extends React.Component<any, any> {
    private adsService: AdsService = new AdsService()
    private transactionsService: TransactionsService = new TransactionsService()
    state = {
        //TODO: remove npsComments once rating implementation is complete
        npsComments: [{
            commId: "",
            createDate: "",
            npsComment: "",
            npsScore: 0,
        }],
        // customerTransactions: [] as TransactionCardModel[],
        customerTransactions: [],
        customerActivities: [{
            ACTIVITY_ID: "",
            CUSTOMER_ID: "",
            TIMESTAMP: { $date: 0 },
            METADATA: {}
        }],
        npsLoading: true,
        supplierOffers: [] as SupplierOfferModel[],
        pageLoading: false,
        transactionsLoading: false,
        adsLoading: false,
        activityLoading: true,
        // offersLoading: true,
        pullToRefresh: true,
        advertisements: [
            { image: 'assets/images/ad_bg.jpg', businessName: 'N/A', businessCode: 'N/A' },
            { image: 'assets/images/ad_bg1.jpg', businessName: 'N/A', businessCode: 'N/A' },
        ],
        inAppAds: [] as inAppAdsWidgetModel[],
        daily_revenue: [],
        monthly_revenue: [],
        merchant: {} as MerchantModel,
        /* merchant: {
            business_name: "",
            ussd_string: "",
            metadata: { mtn: { ussd_code: '' } },
            ajua_account_details: { subscription_status: false, city: '', country: '' }
        }, */
        creditRequest: {} as SupplierOfferModel,
        quickActionsModal: false,
        filterBy: null,
        canCreateAds: true,
        toastOptions: {
            toastMessage: "",
            toastOpen: false,
            toastColor: ""
        },
    };

    constructor(props: any) {
        super(props);
        this.addCustomers = this.addCustomers.bind(this);
    }

    async ifCanCreateAds() {
        try {
            const now = Math.floor(Date.now()/1000)
            const adsTrialStart = await getFromStorage(STORAGE_FIELDS.ADS_TRIAL_START)
            const adsTrialExpiry = adsTrialStart + (Number(environment.adsTrialPeriod) * 24 * 60 *  60) // 7 days after trial starts
            
            const { subscription_status, subscription_expiry_date } = this.state.merchant.ajua_account_details
            const tstmp = Math.floor(Number(subscription_expiry_date.$date) / 1000)
            
            if (!adsTrialStart) { // this simply means this is the first time this user is using the ads feature
                this.props.history.push({pathname: '/create-ad'});
                return true
            }
            if (subscription_status && tstmp > now) {
                this.props.history.push({pathname: '/create-ad'});
                return true
            }
            if (!subscription_status && adsTrialExpiry > now) {
                this.props.history.push({pathname: '/create-ad'});
                return true
            }
            this.setState({
                loading: false,
                toastOptions: {
                    toastOpen: true,
                    toastMessage: "Creating Ads is <strong>only available to paying subscribers.</strong>",
                    toastColor: "danger"
                }
            })
            return false
        } catch (e) { 
            // Todo
        }

    }

    async ionViewWillEnter() {
        // var d = new Date();
        // var n = d.getHours(); 
        //console.log('time now', n)
        const creditRequest: SupplierOfferModel = this.props.location.state ?.creditRequest;
        if (creditRequest) {
            setTimeout(() => {
                this.setState({
                    creditRequest: creditRequest
                })
            }, 3000);
        }

        const filterBy = this.props.location.state ?.filterBy;
        this.setState({ filterBy: filterBy ? filterBy : null })

        //const merchantID = (await getFromStorage(STORAGE_FIELDS.MERCHANT)).id ?.$oid;
        let merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        //canCreateAds
        

        /* if (merchantID) {
            merchant = await getMerchant(merchantID);
        }*/

        await this.setState({
            merchant: merchant,
            advertisements: [
                {
                    image: 'assets/images/ad_bg1.jpg',
                    businessName: merchant ?.business_name,
                    businessCode: merchant ?.ussd_string
                },
                {
                    image: 'assets/images/ad_bg2.jpg',
                    businessName: merchant ?.business_name,
                    businessCode: merchant ?.ussd_string
                }
            ],
            daily_revenue: [...Array(500)].map(e=>Math.random()*500|0),
            monthly_revenue: [...Array(15000)].map(e=>Math.random()*15000|0),
        }, async () => {

            // await this.getNpsActivity();
            
            await this.getCustomerActivities()
            try {
                await this.getCustomerTransactions()
                await this.fetchAds();
            } catch (e) {
                // catch global
                console.log(e);
            }/*  */
        });
    }

    async getCustomerActivities(refresherEvent?: CustomEvent<RefresherEventDetail>) {
        try {
            this.setState({ activityLoading: true });

            const params = this.state.filterBy ? "&activity_id=" + this.state.filterBy : "";
            const customerActivities = await getActivities(params);

            this.setState({ customerActivities, activityLoading: false }, () => {
                if (refresherEvent) {
                    refresherEvent ?.detail.complete();
                }
            });
            this.setState({ pageLoading: false });
        } catch (e) {
            // catch global
            console.log(e);
        }
    };

    async getCustomerTransactions() {
        try {
            if (this.state.merchant?.collaborators) {
                this.setState({ transactionsLoading: true });
                const payload = {
                    msisdn: this.state?.merchant?.collaborators[0].msisdn, 
                    merchant_name: this.state?.merchant?.business_name, 
                    type:"payments"
                }
                // const payload = {
                //     msisdn: '2348039314408', 
                //     merchant_name: 'king court furnitures', 
                //     type:"payments"
                // }
                await this.transactionsService.getTransactionHistory(payload).then((res) => {
                    if (Number(res.success_code) === 2) {
                        this.setState({
                            loading: false,
                            transactionsLoading: false,
                            toastOptions: {
                                toastOpen: true,
                                toastMessage: `<strong>${res.message}</strong>`,
                                toastColor: "danger"
                            }
                        })
                    }
                    this.setState({ customerTransactions: res.data, transactionsLoading: false });
                });
                
            }
        } catch (e) {
            this.setState({
                loading: false,
                transactionsLoading: false,
                toastOptions: {
                    toastOpen: true,
                    toastMessage: `<strong>${e.toString()}</strong>`,
                    toastColor: "danger"
                }
            })
        }
    }

    fetchAds = async () => {
        const city = this.state.merchant.ajua_account_details.city || 'Lagos'
        const country = this.state.merchant.ajua_account_details.country
        const industry = this.state.merchant.ajua_account_details.industry || 'Retail'
        this.setState({ adsLoading: true })
        try {
            await this.adsService.getAds(city, country, industry).then((res) => {
                this.setState({ inAppAds: res.data, adsLoading: false })
            })
        } catch (e) {
            await this.setState({loading: false, adsLoading: false});
            throw e;
        } finally {
            await this.setState({loading: false, adsLoading: false,});
        }
    };

    /**
     * get nps activity feed for merchant account
     * @return {Promise<void>}
     */
    getNpsActivity = async () => {
        try {
            // do the request
            const npsComments = await getNpsComments(691, "2010-01-01",
                moment().format('YYYY-MM_DD').toString());
            this.setState({ npsComments }, () => {
                this.setState({ npsLoading: false });
            });
        } catch (e) {
            // catch global
            console.log(e);
        } finally {

        }
    };

    // Invokes the customer addition flow
    addCustomers() {
        this.props.history.push("/select-customer-import-method")
    }

    /*
    * Opens Whatsapp to contact the supplier of the deal.
    * */
    contactSupplier(supplierOffer: SupplierOfferModel, code: string) {
        SocialSharing.shareViaWhatsAppToReceiver(supplierOffer.fields.whatsapp, `${supplierOffer.fields.offer_description} has been purchased with code:`).then(shareStatus => {
        }).catch(e => {
            this.setState({
                toastOpen: true,
                toastMessage: "Unable purchase product. Try again",
                toastColor: "danger",
            });
        });

    }

    /*
    * Toggles the visibility of quick actions modal that provides more context to a pulse activity item
    * @param open: boolean -> State of the modal
    * */
    toggleQuickActions(open: boolean) {
        this.setState({ quickActionsModal: open });
    }

    closeToast = () => {
        this.setState({toastOptions: {...this.state.toastOptions, toastOpen: false}});
    };

    render() {
        const supplierOfferCode = `SUP${Math.random() * 100}`;

        return (
            <IonPage>
                {this.state.pageLoading &&
                    (<ContentLoader />)
                }
                <IonContent>
                    <IonRefresher snapbackDuration="400ms" slot="fixed"
                        pullFactor={0.5} pullMin={100} pullMax={100}
                        onIonRefresh={(e) => this.getCustomerActivities(e)}>
                        <IonRefresherContent
                            pullingText="Pull to refresh"
                            refreshingText="Updating Content">
                        </IonRefresherContent>
                    </IonRefresher>

                    <div className="title-section mb-1">
                        <div className="page-details">
                            <h1 className="page-title">Pulse</h1>
                        </div>
                    </div>

                    {/**  <div className="page-section -section-insert no-margin">
                        <div className="title">
                            {this.state.offersLoading &&
                                (<IonSpinner color="black" name="crescent" />)
                            }
                        </div>
                    </div> */}

                    <div className="page-section advertise-store no-margin">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={megaphoneOutline} /> Ads</p>
                        </div>
                        <div className="section-content -horizontal-scroll">
                            <div className="horizontal-scroll">
                                <div className="advertisements">
                                    {this.state.adsLoading &&
                                        (<IonSpinner color="black" name="crescent" />)
                                    }
                                    {!this.state.adsLoading && this.state?.inAppAds?.length > 0 ? 
                                        this.state.inAppAds.map((advert, index) => {
                                            return <InAppAdsWidget key={index} advertisement={advert} />

                                        })
                                    : ''}
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <AdvertiseYourBusinessAction 
                                onClickToCreateAd={() => this.ifCanCreateAds()}
                                message="Advertise your business & offers to over 20000 potential customers" />
                        </div>
                    </div><br/>

                    <div className="page-section activity-feed">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={cashOutline} /> Recent payments</p>
                        </div>
                        <div className="section-content">
                            {this.state.transactionsLoading &&
                                (<IonSpinner color="black" name="crescent" />)
                            }
                            {!this.state.transactionsLoading && this.state.customerTransactions ?.length === 0 ?
                            (<div>
                                <EmptyState icon={'assets/icons/ic_payments.svg'}
                                    title={'No payment activity'}
                                    description=""/>
                            </div>)
                            : <>
                                {
                                    this.state.customerTransactions.slice(0,6).map((transaction, index) => {
                                        return <TransactionCard
                                            toggleQuickActions={(open: any) => this.toggleQuickActions(open)} key={index}
                                            transaction={transaction} />
                                    })
                                }
                                <Link to={'/transactions-page'} className="ajua-button no-decoration -neutral-text -small-button">
                                    <IonIcon icon={cashOutline} className="-left"/> Go to transactions
                                </Link>
                            </>}
                        </div>
                    </div><br/>
                    <div className="page-section activity-feed">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={albumsOutline} /> Recent Activity</p>
                        </div>
                        <div className="section-content">
                            {this.state.activityLoading &&
                                (<IonSpinner color="black" name="crescent" />)
                            }
                            {(!this.state.activityLoading && this.state.customerActivities ?.length === 0) ? (<div>
                                <EmptyState icon={'assets/icons/store_front.svg'}
                                    title={'No Recent Activity'}
                                    actionButtonText={'Add Customers'}
                                    description={'Get started by adding your customers using the button below.'}
                                    actionCallback={this.addCustomers} />
                            </div>) : (
                                    <>
                                        {
                                            this.state.creditRequest.id ?
                                                (<div
                                                    onClick={() => this.contactSupplier(this.state.creditRequest, supplierOfferCode)}
                                                    className="activity-card deal-activity">
                                                    <div className="card-content-left">
                                                        <IonIcon className="deal-activity-icon"
                                                            icon={checkmarkDoneOutline} />
                                                    </div>
                                                    <div className="card-content-right">
                                                        <p className="activity-title">LOAN APPROVED</p>
                                                        <p className="deal">{this.state.creditRequest.fields.offer_description}</p>
                                                        <p className="deal-code"><strong>Purchase
                                                        Code:</strong> {supplierOfferCode}</p>
                                                        <p className="activity-time">{moment().fromNow()}</p>
                                                    </div>
                                                </div>) : null
                                        }
                                        {
                                            this.state.customerActivities.slice(0,12).map((activity, index) => {
                                                return <ActivityCard USSD={this.state.merchant.ussd_string}
                                                    toggleQuickActions={(open) => this.toggleQuickActions(open)} key={index}
                                                    activity={activity} />
                                            })
                                        }
                                    </>
                                )
                            }

                        </div>
                    </div>

                    {/* <div className="page-section advertise-store no-margin">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={megaphoneOutline} /> Advertise</p>
                        </div>
                        <div className="section-content -horizontal-scroll">
                            <div className="horizontal-scroll">
                                <div className="advertisements">
                                    {
                                        this.state.advertisements.map((advert, index) => {
                                            return <AdvertiseWidget key={index} advertisement={advert} />

                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="page-section discount-ideas">
                        <div className="title">
                            <p className="section-title"><IonIcon icon={pricetagsOutline} /> Create & Send Offers</p>
                        </div>
                        <div className="section-content">
                            <DiscountIdeaCard idea={{
                                discount: `Welcome to ${this.state.merchant ?.business_name}`,
                                duration: moment().format('DD-MM-YYYY')
                            }} />
                        </div>
                        
                        <IonToast
                            isOpen={this.state.toastOptions?.toastOpen || false}
                            message={this.state.toastOptions?.toastMessage}
                            position="bottom"
                            color={this.state.toastOptions?.toastColor}
                            duration={4000}
                            onDidDismiss={() => this.closeToast()}
                            buttons={[
                                {
                                    text: 'X',
                                    role: 'cancel',
                                    handler: () => {
                                        this.closeToast();
                                    }
                                }
                            ]}
                        />
                    </div>

                    <IonModal isOpen={this.state.quickActionsModal} cssClass='my-custom-class'>
                        <p>This is modal content</p>
                    </IonModal>

                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Pulse);


import ApiService from '../../shared/services/network-services/api.service'
import environment from '../../environments/environment'

interface imgObject {
    id: number, 
    imgUrl: string
}
class GetAdImageService {

    static async getImage(imageUrl: string, templateId: number): Promise<any> {
        let apiService = new ApiService();
        // return apiService.getResource(postObj, URL_ENDPOINTS.bulkOffersUrl)
        return await apiService.getImageResource(imageUrl)
        .then(image => {
            const { success_code, data } = image.data
            
            // return Number(success_code) === 1 ?  'data:image/png;base64,' + data : this.returnImageUrl(templateId);
            return Number(success_code) === 1 ?  'data:image/png;base64,' + data : environment.inAppAdsTemplates.filter(img => img.template_id === templateId )[0].imageUrl
        })
        .catch(
            error => {
                throw error
            }
        );
    }
}

export default GetAdImageService;

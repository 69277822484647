import ApiService from "../network-services/api.service";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../helpers/storage";
import {MerchantModel} from "../../models/merchant.model";

class MerchantProvider{
    static async getMerchantDetails(id: string){
        const apiService = new ApiService();
        return await apiService.getResource(
            `/merchant-profile?merchant_id=${id}`)
            .then(merchantProfile => {
                return merchantProfile?.data;
            })
            .catch(
                error => {
                    throw error
                }
            );
    }

    /*
    * Refreshes the merchant profile in the local store
    * */
    static async refreshOfflineMerchantProfile(): Promise<MerchantModel | undefined>{
        // Confirm if there's an merchant profile in the local store then proceed to refresh profile
        const initialMerchantProfile: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        if(initialMerchantProfile?.id?.$oid){
            const updatedMerchantProfile = await MerchantProvider.getMerchantDetails(initialMerchantProfile.id.$oid);
            await saveToStorage(STORAGE_FIELDS.MERCHANT, updatedMerchantProfile);
            return updatedMerchantProfile;
        }

        return undefined;
    }
}

export default MerchantProvider;

import React from "react";
import "./dealDetails.sass";
import {IonAlert, IonContent, IonHeader, IonIcon, IonLoading, IonPage, withIonLifeCycle} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {cartOutline, cardOutline} from "ionicons/icons";
import {SocialSharing} from "@ionic-native/social-sharing";
import {Toast} from "../../shared/components/generic/toast";
import {SupplierOfferModel} from "../pulse/pulse";
import {MerchantModel} from "../../shared/models/merchant.model";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {IProfileMilestones} from "../../App";

class DealDetails extends React.Component<any, any> {
    state = {
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        payWithCreditConfirmation: false,
        creditConfirmation: false,
        loading: true,
        supplierOffer: {} as SupplierOfferModel,
        merchant: {} as MerchantModel
    };

    async ionViewDidEnter() {
        const merchant = await getFromStorage(STORAGE_FIELDS.MERCHANT);
        this.setState({merchant, supplierOffer: this.props.location.state.supplierOffer, loading: false});
        //await this.updateProfileState();
    }

    // async updateProfileState() {
    //     let profileMilestones: IProfileMilestones = await getFromStorage(STORAGE_FIELDS.PROFILE_MILESTONES_STATES);
    //     profileMilestones.supplierDeals = true;

    //     await saveToStorage(STORAGE_FIELDS.PROFILE_MILESTONES_STATES, profileMilestones);
    // }

    async openWhatsApp() {
        if (this.state?.merchant?.ajua_account_details.subscription_status) {
            const supplierOffer: SupplierOfferModel = this.state?.supplierOffer;
            const merchant: MerchantModel = await getFromStorage(STORAGE_FIELDS.MERCHANT);
            const message = `I'm interested in your offer: "${supplierOffer.fields.offer_description}". ~ From ${merchant.business_name}`;
            SocialSharing.shareViaWhatsAppToReceiver(supplierOffer.fields.whatsapp, message).then(shareStatus => {
            }).catch((e) => {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Unable purchase product. Try again",
                    toastColor: "danger",
                });
            });
        }else{
            this.setState({
                toastOpen: true,
                toastMessage: "Supplier deals requires a subscription.",
                toastColor: "danger",
            }, () => {
                this.props.history.push({pathname: "/payments-subscriptions"});
            });
        }
    }

    closeToast = () => {
        this.setState({toastOpen: false});
    };

    payWithCreditDialogue(status: boolean) {
        this.setState({payWithCreditConfirmation: status});
    }


    confirmCreditDialogue(status: boolean) {
        this.payWithCreditDialogue(false);
        if (status) {
            this.setState({loading: true}, () => {
                setTimeout(() => {
                    this.setState({creditConfirmation: status, loading: false});
                }, 800);
            });
        } else {
            this.setState({creditConfirmation: status});
            this.props.history.push({pathname: "/main/pulse", state: {creditRequest: this.state.supplierOffer}});
        }

    }

    render() {
        const dealImage = this.state.supplierOffer?.fields?.deal_image;
        const fallbackImage = 'https://gocryptowise.com/wp-content/uploads/2019/09/Crypto-deals.pngs';

        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: this.state.supplierOffer?.fields?.supplier}}/>
                </IonHeader>

                <IonContent>
                    <div className="deal-details-page">
                        <div className="page-section product-details">
                            <div className="section-content">
                                <div className="product-image">
                                    <img
                                        onError={(e: any) => {
                                            e.target.onerror = null;
                                            e.target.src = fallbackImage
                                        }}
                                        src={dealImage ? dealImage : fallbackImage}/>
                                </div>
                                <div className="product-description">
                                    <p className="product-name">{this.state.supplierOffer?.fields?.supplier}</p>
                                    {/*<p className="product-price">KES 500 <span>Before: KES 600</span></p>*/}
                                    <p className="supplier-contact">
                                        Contact: {this.state.supplierOffer?.fields?.whatsapp}
                                    </p>
                                    <p className="product-detailed-description">
                                        {this.state.supplierOffer?.fields?.offer_description}
                                    </p>
                                </div>
                                <div className="purchase-actions">
                                    <button onClick={() => this.openWhatsApp()}
                                            className="ajua-button -full-width -medium-button -success">
                                        <IonIcon
                                            icon={cartOutline}/> Buy Product
                                    </button>
                                    {/*<div className="or-divider">*/}
                                    {/*    <hr/>*/}
                                    {/*    <p>Or</p>*/}
                                    {/*    <hr/>*/}
                                    {/*</div>*/}
                                    {/*<button onClick={() => this.payWithCreditDialogue(true)}*/}
                                    {/*        className="ajua-button -neutral -full-width -medium-button">*/}
                                    {/*    <IonIcon icon={cardOutline}/> Apply for SME credit*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </div>

                        <IonLoading
                            cssClass=''
                            isOpen={this.state.loading}
                            message={'Sending Request'}/>

                        <IonAlert
                            isOpen={this.state.payWithCreditConfirmation}
                            onDidDismiss={() => this.payWithCreditDialogue(false)}
                            cssClass=''
                            header={'Buy with credit'}
                            subHeader={''}
                            message={"Do you agree to the <a href='https://ajua.com/terms-conditions/'>Terms & Conditions</a> of this service?"}
                            buttons={[
                                {
                                    text: 'Disagree',
                                    handler: () => {
                                        this.payWithCreditDialogue(false);
                                    }
                                },
                                {
                                    text: 'Agree',
                                    handler: () => {
                                        this.confirmCreditDialogue(true);
                                    }
                                }
                            ]}
                        />

                        <IonAlert
                            isOpen={this.state.creditConfirmation}
                            onDidDismiss={() => this.confirmCreditDialogue(false)}
                            cssClass='credit-confirmation-dialogue'
                            buttons={['Ok']}
                            header={'Sent'}
                            subHeader={''}
                            message={"Your request has been sent. Wait for confirmation in <strong>a few seconds</strong>."}
                        />
                        <Toast message={this.state.toastMessage} open={this.state.toastOpen} position="bottom"
                               color={this.state.toastColor}
                               closeToast={this.closeToast}/>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}


export default withIonLifeCycle(DealDetails);

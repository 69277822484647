import React from "react";
import "./comingSoon.sass";
import {IonContent, IonHeader, IonPage} from "@ionic/react";
import {Link} from "react-router-dom";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";

class ComingSoon extends React.Component<any, any> {
    goBack(){
        this.props.history.goBack();
    }
    render() {
        return (
            <IonPage>
                <IonContent>
                    <div className="coming-soon">
                        <div className="illustration">
                            <img src={'assets/icons/stopwatch.png'}/>
                        </div>
                        <div className="message">
                            <p className="title">Coming Soon</p>
                            <p className="message-content">This feature will be launching soon.</p>
                        </div>

                        <div className="actions">
                            <button onClick={() => this.goBack()} className="ajua-button -medium-button -small-width -success">Go Back</button>
                        </div>

                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default ComingSoon;

import {Subject} from "rxjs";
import {IContextMenuOptions} from "./context-menu";
import {ReactNode} from "react";

const menuState: Subject<IContextMenuOptions> = new Subject();

export const ContextMenuService = {
    openContextMenu: (content: ReactNode) => menuState.next({show: true, content: content}),
    closeContextMenu: () => menuState.next({show: false, content: ''}),
    getMenuState: () => menuState.asObservable()
};

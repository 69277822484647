import environment from "../../../environments/environment";
import { RequestOptionsModel } from "../../models/request-options.model";
import { HeadersModel } from "./headers.model";
import { getToken } from "../authentication/auth"


export default class ApiService {
    private axios = require('axios').default;

    /**
     * Constructs a valid url from a given endpoint
     * @param baseUrl
     * @param endpoint: string
     * @return url: string
     * */
    buildUrl(baseUrl: string, endpoint: string): string {
        return `${baseUrl}${endpoint}`;
    }

    /**
     * Performs a GET request
     * @param endpoint: string
     * @param options: RequestOptionsModel
     * @param headers: HeadersModel
     * @return Promise<any>
     * **/
    async getResource<T>(endpoint: string, options?: RequestOptionsModel, headers?: HeadersModel, body: any = null): Promise<any> {
        const data = JSON.stringify(body) || null
        const axiosGetOptions = {
            method: 'get',
            withCredentials: options ?.withCredentials ? options.withCredentials : '',
            headers: headers ? { ...headers } : { ...(await this.defaultGETHeaders()) },
            data: data ? `${data.replaceAll('\\', '')}` : null
        };
        
        return this.axios.get(this.buildUrl(options ?.baseUrl || environment.baseUrl, endpoint), axiosGetOptions);
    }

    /**
     * Performs a GET request
     * @param endpoint: string
     * @param options: RequestOptionsModel
     * @param headers: HeadersModel
     * @return Promise<any>
     * **/
     async getImageResource<T>(url: string, options?: RequestOptionsModel, headers?: HeadersModel): Promise<any> {
        const axiosGetOptions = {
            method: 'get',
            withCredentials: options ?.withCredentials ? options.withCredentials : '',
            headers: headers ? { ...headers } : { ...(await this.defaultGETHeaders()) }
        };

        return this.axios.get(url, axiosGetOptions);
    }

    /*
    * Returns the default headers used in all GET requests
    * @return Promise<HeadersModel>
    * */
    async defaultGETHeaders(): Promise<HeadersModel> {
        return {
            'Content-type': 'application/json',
            Accept: 'application/json',
            // Authentication: await getFromStorage(STORAGE_FIELDS.TOKEN)
            Authentication: await getToken()
        }
    }

    /**
     * Performs a POST request
     * @param postObj: any - Data to POST to the url
     * @param endpoint: string
     * @param options: RequestOptionsModel
     * @param headers: HeadersModel
     * @return Promise<any>
     * **/
    async createResource<T>(postObj: object, endpoint: string, options?: RequestOptionsModel, headers?: HeadersModel): Promise<any> {
        const axiosPostOptions = {
            method: 'post',
            withCredentials: options ?.withCredentials ? options.withCredentials : '',
            headers: headers ? { ...headers } : { ...(await this.defaultPOSTHeaders()) }
        };

        return this.axios.post(this.buildUrl(options ?.baseUrl || environment.baseUrl, endpoint), postObj, axiosPostOptions);
    }

    /*
    * Returns the default headers used in all POST requests
    * @return Promise<HeadersModel>
    * */
    async defaultPOSTHeaders(): Promise<HeadersModel> {
        return {
            // Authentication: await getFromStorage(STORAGE_FIELDS.TOKEN)
            Authentication: await getToken()
        }
    }

    /**
     * Performs a POST request
     * @param postObj: any - Data to POST to the url
     * @param endpoint
     * @param options: RequestOptionsModel
     * @param headers
     * @return Promise<any>
     * **/
    async updateResource<T>(postObj: object, endpoint: string, options?: RequestOptionsModel, headers?: HeadersModel): Promise<any> {
        const axiosPostOptions = {
            method: 'put',
            withCredentials: options ?.withCredentials ? options.withCredentials : '',
            headers: {
                ...(await this.defaultPOSTHeaders()),
                ...headers,
            }
        };
        return this.axios.patch(this.buildUrl(options ?.baseUrl || environment.baseUrl, endpoint), postObj, axiosPostOptions);
    }

    /**
     * Performs a PUT request
     * @param putObj: any - Data to PUT to the url
     * @param endpoint
     * @param options: RequestOptionsModel
     * @param headers
     * @return Promise<any>
     * **/
    async updateResourcePut<T>(putObj: object, endpoint: string, options?: RequestOptionsModel, headers?: HeadersModel): Promise<any> {
        const axiosPostOptions = {
            method: 'put',
            withCredentials: options ?.withCredentials ? options.withCredentials : '',
            headers: {
                ...(await this.defaultPOSTHeaders()),
                ...headers,
            }
        };
        return this.axios.put(this.buildUrl(options ?.baseUrl || environment.baseUrl, endpoint), putObj, axiosPostOptions);
    }

}


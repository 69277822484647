export const INDUSTRIES = [
    'Agriculture',
    "​Construction/Real Estate",
    "Consumer Goods",
    "​Financial Services",
    "Healthcare",
    "Industrial Goods",
    "Information & Communications Technology",
    "​Natural Resources",
    "Professional Services",
    "​​​Oil & Gas",
    "Utilities",
    "Retail"
];

export const COUNTRIES = ['Kenya', 'Nigeria'];
export const KENYAN_COUNTIES = [
    'Nairobi',
    'Mombasa',
    'Kisumu',
    'Nakuru',
    'Eldoret',
    'Nairobi',
    'Mombasa',
    'Busia',
    'Kajiado',
    'Kiambu',
    'Uasin Gishu',
    'Kwale',
    'Migori',
    'Kilifi',
    'Mandera',
    'Taita Taveta',
    'Wajir',
    'Garissa',
    'Nakuru',
    'Kisumu',
    'Kitui',
    'Siaya',
    'Nyeri',
    'Turkana',
    'Homa Bay',
    'Machakos',
    "Murang'a",
    'Isiolo',
    'Bungoma',
    'Laikipia',
    'Meru',
    'Kisii',
    'Makueni',
    'Nyandarua',
    'Kericho',
    'Bomet',
    'Nyamira',
    'Kirinyaga',
    'Embu',
    'Kakamega',
    'Vihiga',
    'Marsabit',
    'Elgeyo Marakwet',
    'Trans Nzoia',
];

export const NIGERIAN_STATES = [
    {
        state: "Abia",
        lgas: [
            "Aba North",
            "Aba South",
            "Arochukwu",
            "Bende",
            "Ikawuno",
            "Ikwuano",
            "Isiala-Ngwa North",
            "Isiala-Ngwa South",
            "Isuikwuato",
            "Umu Nneochi",
            "Obi Ngwa",
            "Obioma Ngwa",
            "Ohafia",
            "Ohaozara",
            "Osisioma",
            "Ugwunagbo",
            "Ukwa West",
            "Ukwa East",
            "Umuahia North",
            "Umuahia South"
        ]
    },
    {
        "state": "Adamawa",
        "lgas": [
            "Demsa",
            "Fufore",
            "Ganye",
            "Girei",
            "Gombi",
            "Guyuk",
            "Hong",
            "Jada",
            "Lamurde",
            "Madagali",
            "Maiha",
            "Mayo-Belwa",
            "Michika",
            "Mubi-North",
            "Mubi-South",
            "Numan",
            "Shelleng",
            "Song",
            "Toungo",
            "Yola North",
            "Yola South"
        ]
    },
    {
        "state": "Akwa Ibom",
        "lgas": [
            "Abak",
            "Eastern-Obolo",
            "Eket",
            "Esit-Eket",
            "Essien-Udim",
            "Etim-Ekpo",
            "Etinan",
            "Ibeno",
            "Ibesikpo-Asutan",
            "Ibiono-Ibom",
            "Ika",
            "Ikono",
            "Ikot-Abasi",
            "Ikot-Ekpene",
            "Ini",
            "Itu",
            "Mbo",
            "Mkpat-Enin",
            "Nsit-Atai",
            "Nsit-Ibom",
            "Nsit-Ubium",
            "Obot-Akara",
            "Okobo",
            "Onna",
            "Oron",
            "Oruk Anam",
            "Udung-Uko",
            "Ukanafun",
            "Urue-Offong/Oruko",
            "Uruan",
            "Uyo"
        ]
    },
    {
        "state": "Anambra",
        "lgas": [
            "Aguata",
            "Anambra East",
            "Anambra West",
            "Anaocha",
            "Awka North",
            "Awka South",
            "Ayamelum",
            "Dunukofia",
            "Ekwusigo",
            "Idemili-North",
            "Idemili-South",
            "Ihiala",
            "Njikoka",
            "Nnewi-North",
            "Nnewi-South",
            "Ogbaru",
            "Onitsha-North",
            "Onitsha-South",
            "Orumba-North",
            "Orumba-South"
        ]
    },
    {
        "state": "Bauchi",
        "lgas": [
            "Alkaleri",
            "Bauchi",
            "Bogoro",
            "Damban",
            "Darazo",
            "Dass",
            "Gamawa",
            "Ganjuwa",
            "Giade",
            "Itas\/Gadau",
            "Jama'Are",
            "Katagum",
            "Kirfi",
            "Misau",
            "Ningi",
            "Shira",
            "Tafawa-Balewa",
            "Toro",
            "Warji",
            "Zaki"
        ]
    },
    {
        "state": "Benue",
        "lgas": [
            "Ado",
            "Agatu",
            "Apa",
            "Buruku",
            "Gboko",
            "Guma",
            "Gwer-East",
            "Gwer-West",
            "Katsina-Ala",
            "Konshisha",
            "Kwande",
            "Logo",
            "Makurdi",
            "Ogbadibo",
            "Ohimini",
            "Oju",
            "Okpokwu",
            "Otukpo",
            "Tarka",
            "Ukum",
            "Ushongo",
            "Vandeikya"
        ]
    },
    {
        "state": "Borno",
        "lgas": [
            "Abadam",
            "Askira-Uba",
            "Bama",
            "Bayo",
            "Biu",
            "Chibok",
            "Damboa",
            "Dikwa",
            "Gubio",
            "Guzamala",
            "Gwoza",
            "Hawul",
            "Jere",
            "Kaga",
            "Kala\/Balge",
            "Konduga",
            "Kukawa",
            "Kwaya-Kusar",
            "Mafa",
            "Magumeri",
            "Maiduguri",
            "Marte",
            "Mobbar",
            "Monguno",
            "Ngala",
            "Nganzai",
            "Shani"
        ]
    },
    {
        "state": "Bayelsa",
        "lgas": [
            "Brass",
            "Ekeremor",
            "Kolokuma\/Opokuma",
            "Nembe",
            "Ogbia",
            "Sagbama",
            "Southern-Ijaw",
            "Yenagoa"
        ]
    },
    {
        "state": "Cross River",
        "lgas": [
            "Abi",
            "Akamkpa",
            "Akpabuyo",
            "Bakassi",
            "Bekwarra",
            "Biase",
            "Boki",
            "Calabar-Municipal",
            "Calabar-South",
            "Etung",
            "Ikom",
            "Obanliku",
            "Obubra",
            "Obudu",
            "Odukpani",
            "Ogoja",
            "Yakurr",
            "Yala"
        ]
    },
    {
        "state": "Delta",
        "lgas": [
            "Aniocha North",
            "Aniocha-North",
            "Aniocha-South",
            "Bomadi",
            "Burutu",
            "Ethiope-East",
            "Ethiope-West",
            "Ika-North-East",
            "Ika-South",
            "Isoko-North",
            "Isoko-South",
            "Ndokwa-East",
            "Ndokwa-West",
            "Okpe",
            "Oshimili-North",
            "Oshimili-South",
            "Patani",
            "Sapele",
            "Udu",
            "Ughelli-North",
            "Ughelli-South",
            "Ukwuani",
            "Uvwie",
            "Warri South-West",
            "Warri North",
            "Warri South"
        ]
    },
    {
        "state": "Ebonyi",
        "lgas": [
            "Abakaliki",
            "Afikpo-North",
            "Afikpo South (Edda)",
            "Ebonyi",
            "Ezza-North",
            "Ezza-South",
            "Ikwo",
            "Ishielu",
            "Ivo",
            "Izzi",
            "Ohaukwu",
            "Onicha"
        ]
    },
    {
        "state": "Edo",
        "lgas": [
            "Akoko Edo",
            "Egor",
            "Esan-Central",
            "Esan-North-East",
            "Esan-South-East",
            "Esan-West",
            "Etsako-Central",
            "Etsako-East",
            "Etsako-West",
            "Igueben",
            "Ikpoba-Okha",
            "Oredo",
            "Orhionmwon",
            "Ovia-North-East",
            "Ovia-South-West",
            "Owan East",
            "Owan-West",
            "Uhunmwonde"
        ]
    },
    {
        "state": "Ekiti",
        "lgas": [
            "Ado-Ekiti",
            "Efon",
            "Ekiti-East",
            "Ekiti-South-West",
            "Ekiti-West",
            "Emure",
            "Gbonyin",
            "Ido-Osi",
            "Ijero",
            "Ikere",
            "Ikole",
            "Ilejemeje",
            "Irepodun\/Ifelodun",
            "Ise-Orun",
            "Moba",
            "Oye"
        ]
    },
    {
        "state": "Enugu",
        "lgas": [
            "Aninri",
            "Awgu",
            "Enugu-East",
            "Enugu-North",
            "Enugu-South",
            "Ezeagu",
            "Igbo-Etiti",
            "Igbo-Eze-North",
            "Igbo-Eze-South",
            "Isi-Uzo",
            "Nkanu-East",
            "Nkanu-West",
            "Nsukka",
            "Oji-River",
            "Udenu",
            "Udi",
            "Uzo-Uwani"
        ]
    },
    {
        "state": "Federal Capital Territory",
        "lgas": [
            "Abuja",
            "Kwali",
            "Kuje",
            "Gwagwalada",
            "Bwari",
            "Abaji"
        ]
    },
    {
        "state": "Gombe",
        "lgas": [
            "Akko",
            "Balanga",
            "Billiri",
            "Dukku",
            "Funakaye",
            "Gombe",
            "Kaltungo",
            "Kwami",
            "Nafada",
            "Shongom",
            "Yamaltu\/Deba"
        ]
    },
    {
        "state": "Imo",
        "lgas": [
            "Aboh-Mbaise",
            "Ahiazu-Mbaise",
            "Ehime-Mbano",
            "Ezinihitte",
            "Ideato-North",
            "Ideato-South",
            "Ihitte\/Uboma",
            "Ikeduru",
            "Isiala-Mbano",
            "Isu",
            "Mbaitoli",
            "Ngor-Okpala",
            "Njaba",
            "Nkwerre",
            "Nwangele",
            "Obowo",
            "Oguta",
            "Ohaji-Egbema",
            "Okigwe",
            "Onuimo",
            "Orlu",
            "Orsu",
            "Oru-East",
            "Oru-West",
            "Owerri-Municipal",
            "Owerri-North",
            "Owerri-West"
        ]
    },
    {
        "state": "Jigawa",
        "lgas": [
            "Auyo",
            "Babura",
            "Biriniwa",
            "Birnin-Kudu",
            "Buji",
            "Dutse",
            "Gagarawa",
            "Garki",
            "Gumel",
            "Guri",
            "Gwaram",
            "Gwiwa",
            "Hadejia",
            "Jahun",
            "Kafin-Hausa",
            "Kaugama",
            "Kazaure",
            "Kiri kasama",
            "Maigatari",
            "Malam Madori",
            "Miga",
            "Ringim",
            "Roni",
            "Sule-Tankarkar",
            "Taura",
            "Yankwashi"
        ]
    },
    {
        "state": "Kebbi",
        "lgas": [
            "Aleiro",
            "Arewa-Dandi",
            "Argungu",
            "Augie",
            "Bagudo",
            "Birnin-Kebbi",
            "Bunza",
            "Dandi",
            "Fakai",
            "Gwandu",
            "Jega",
            "Kalgo",
            "Koko-Besse",
            "Maiyama",
            "Ngaski",
            "Sakaba",
            "Shanga",
            "Suru",
            "Wasagu/Danko",
            "Yauri",
            "Zuru"
        ]
    },
    {
        "state": "Kaduna",
        "lgas": [
            "Birnin-Gwari",
            "Chikun",
            "Giwa",
            "Igabi",
            "Ikara",
            "Jaba",
            "Jema'A",
            "Kachia",
            "Kaduna-North",
            "Kaduna-South",
            "Kagarko",
            "Kajuru",
            "Kaura",
            "Kauru",
            "Kubau",
            "Kudan",
            "Lere",
            "Makarfi",
            "Sabon-Gari",
            "Sanga",
            "Soba",
            "Zangon-Kataf",
            "Zaria"
        ]
    },
    {
        "state": "Kano",
        "lgas": [
            "Ajingi",
            "Albasu",
            "Bagwai",
            "Bebeji",
            "Bichi",
            "Bunkure",
            "Dala",
            "Dambatta",
            "Dawakin-Kudu",
            "Dawakin-Tofa",
            "Doguwa",
            "Fagge",
            "Gabasawa",
            "Garko",
            "Garun-Mallam",
            "Gaya",
            "Gezawa",
            "Gwale",
            "Gwarzo",
            "Kabo",
            "Kano-Municipal",
            "Karaye",
            "Kibiya",
            "Kiru",
            "Kumbotso",
            "Kunchi",
            "Kura",
            "Madobi",
            "Makoda",
            "Minjibir",
            "Nasarawa",
            "Rano",
            "Rimin-Gado",
            "Rogo",
            "Shanono",
            "Sumaila",
            "Takai",
            "Tarauni",
            "Tofa",
            "Tsanyawa",
            "Tudun-Wada",
            "Ungogo",
            "Warawa",
            "Wudil"
        ]
    },
    {
        "state": "Kogi",
        "lgas": [
            "Adavi",
            "Ajaokuta",
            "Ankpa",
            "Dekina",
            "Ibaji",
            "Idah",
            "Igalamela-Odolu",
            "Ijumu",
            "Kabba\/Bunu",
            "Kogi",
            "Lokoja",
            "Mopa-Muro",
            "Ofu",
            "Ogori\/Magongo",
            "Okehi",
            "Okene",
            "Olamaboro",
            "Omala",
            "Oyi",
            "Yagba-East",
            "Yagba-West"
        ]
    },
    {
        "state": "Katsina",
        "lgas": [
            "Bakori",
            "Batagarawa",
            "Batsari",
            "Baure",
            "Bindawa",
            "Charanchi",
            "Dan-Musa",
            "Dandume",
            "Danja",
            "Daura",
            "Dutsi",
            "Dutsin-Ma",
            "Faskari",
            "Funtua",
            "Ingawa",
            "Jibia",
            "Kafur",
            "Kaita",
            "Kankara",
            "Kankia",
            "Katsina",
            "Kurfi",
            "Kusada",
            "Mai-Adua",
            "Malumfashi",
            "Mani",
            "Mashi",
            "Matazu",
            "Musawa",
            "Rimi",
            "Sabuwa",
            "Safana",
            "Sandamu",
            "Zango"
        ]
    },
    {
        "state": "Kwara",
        "lgas": [
            "Asa",
            "Baruten",
            "Edu",
            "Ekiti (Araromi/Opin)",
            "Ilorin-East",
            "Ilorin-South",
            "Ilorin-West",
            "Isin",
            "Kaiama",
            "Moro",
            "Offa",
            "Oke-Ero",
            "Oyun",
            "Pategi"
        ]
    },
    {
        "state": "Lagos",
        "lgas": [
            "Agege",
            "Ajeromi-Ifelodun",
            "Alimosho",
            "Amuwo-Odofin",
            "Apapa",
            "Badagry",
            "Epe",
            "Eti-Osa",
            "Ibeju-Lekki",
            "Ifako-Ijaiye",
            "Ikeja",
            "Ikorodu",
            "Kosofe",
            "Lagos-Island",
            "Lagos-Mainland",
            "Mushin",
            "Ojo",
            "Oshodi-Isolo",
            "Shomolu",
            "Surulere",
            "Yewa-South"
        ]
    },
    {
        "state": "Nasarawa",
        "lgas": [
            "Akwanga",
            "Awe",
            "Doma",
            "Karu",
            "Keana",
            "Keffi",
            "Kokona",
            "Lafia",
            "Nasarawa",
            "Nasarawa-Eggon",
            "Obi",
            "Wamba",
            "Toto"
        ]
    },
    {
        "state": "Niger",
        "lgas": [
            "Agaie",
            "Agwara",
            "Bida",
            "Borgu",
            "Bosso",
            "Chanchaga",
            "Edati",
            "Gbako",
            "Gurara",
            "Katcha",
            "Kontagora",
            "Lapai",
            "Lavun",
            "Magama",
            "Mariga",
            "Mashegu",
            "Mokwa",
            "Moya",
            "Paikoro",
            "Rafi",
            "Rijau",
            "Shiroro",
            "Suleja",
            "Tafa",
            "Wushishi"
        ]
    },
    {
        "state": "Ogun",
        "lgas": [
            "Abeokuta-North",
            "Abeokuta-South",
            "Ado-Odo\/Ota",
            "Ewekoro",
            "Ifo",
            "Ijebu-East",
            "Ijebu-North",
            "Ijebu-North-East",
            "Ijebu-Ode",
            "Ikenne",
            "Imeko-Afon",
            "Ipokia",
            "Obafemi-Owode",
            "Odeda",
            "Odogbolu",
            "Ogun-Waterside",
            "Remo-North",
            "Shagamu",
            "Yewa North"
        ]
    },
    {
        "state": "Ondo",
        "lgas": [
            "Akoko North-East",
            "Akoko North-West",
            "Akoko South-West",
            "Akoko South-East",
            "Akure-North",
            "Akure-South",
            "Ese-Odo",
            "Idanre",
            "Ifedore",
            "Ilaje",
            "Ile-Oluji-Okeigbo",
            "Irele",
            "Odigbo",
            "Okitipupa",
            "Ondo West",
            "Ondo-East",
            "Ose",
            "Owo"
        ]
    },
    {
        "state": "Osun",
        "lgas": [
            "Atakumosa West",
            "Atakumosa East",
            "Ayedaade",
            "Ayedire",
            "Boluwaduro",
            "Boripe",
            "Ede South",
            "Ede North",
            "Egbedore",
            "Ejigbo",
            "Ife North",
            "Ife South",
            "Ife-Central",
            "Ife-East",
            "Ifelodun",
            "Ila",
            "Ilesa-East",
            "Ilesa-West",
            "Irepodun",
            "Irewole",
            "Isokan",
            "Iwo",
            "Obokun",
            "Odo-Otin",
            "Ola Oluwa",
            "Olorunda",
            "Oriade",
            "Orolu",
            "Osogbo"
        ]
    },
    {
        "state": "Oyo",
        "lgas": [
            "Afijio",
            "Akinyele",
            "Atiba",
            "Atisbo",
            "Egbeda",
            "Ibadan North",
            "Ibadan North-East",
            "Ibadan North-West",
            "Ibadan South-East",
            "Ibadan South-West",
            "Ibarapa-Central",
            "Ibarapa-East",
            "Ibarapa-North",
            "Ido",
            "Ifedayo",
            "Irepo",
            "Iseyin",
            "Itesiwaju",
            "Iwajowa",
            "Kajola",
            "Lagelu",
            "Ogo-Oluwa",
            "Ogbomosho-North",
            "Ogbomosho-South",
            "Olorunsogo",
            "Oluyole",
            "Ona-Ara",
            "Orelope",
            "Ori-Ire",
            "Oyo-West",
            "Oyo-East",
            "Saki-East",
            "Saki-West",
            "Surulere"
        ]
    },
    {
        "state": "Plateau",
        "lgas": [
            "Barkin-Ladi",
            "Bassa",
            "Bokkos",
            "Jos-East",
            "Jos-North",
            "Jos-South",
            "Kanam",
            "Kanke",
            "Langtang-North",
            "Langtang-South",
            "Mangu",
            "Mikang",
            "Pankshin",
            "Qua'an Pan",
            "Riyom",
            "Shendam",
            "Wase"
        ]
    },
    {
        "state": "Rivers",
        "lgas": [
            "Abua\/Odual",
            "Ahoada-East",
            "Ahoada-West",
            "Akuku Toru",
            "Andoni",
            "Asari-Toru",
            "Bonny",
            "Degema",
            "Eleme",
            "Emuoha",
            "Etche",
            "Gokana",
            "Ikwerre",
            "Khana",
            "Obio\/Akpor",
            "Ogba-Egbema-Ndoni",
            "Ogba\/Egbema\/Ndoni",
            "Ogu\/Bolo",
            "Okrika",
            "Omuma",
            "Opobo\/Nkoro",
            "Oyigbo",
            "Port-Harcourt",
            "Tai"
        ]
    },
    {
        "state": "Sokoto",
        "lgas": [
            "Binji",
            "Bodinga",
            "Dange-Shuni",
            "Gada",
            "Goronyo",
            "Gudu",
            "Gwadabawa",
            "Illela",
            "Kebbe",
            "Kware",
            "Rabah",
            "Sabon Birni",
            "Shagari",
            "Silame",
            "Sokoto-North",
            "Sokoto-South",
            "Tambuwal",
            "Tangaza",
            "Tureta",
            "Wamako",
            "Wurno",
            "Yabo"
        ]
    },
    {
        "state": "Taraba",
        "lgas": [
            "Ardo-Kola",
            "Bali",
            "Donga",
            "Gashaka",
            "Gassol",
            "Ibi",
            "Jalingo",
            "Karim-Lamido",
            "Kurmi",
            "Lau",
            "Sardauna",
            "Takum",
            "Ussa",
            "Wukari",
            "Yorro",
            "Zing"
        ]
    },
    {
        "state": "Yobe",
        "lgas": [
            "Bade",
            "Bursari",
            "Damaturu",
            "Fika",
            "Fune",
            "Geidam",
            "Gujba",
            "Gulani",
            "Jakusko",
            "Karasuwa",
            "Machina",
            "Nangere",
            "Nguru",
            "Potiskum",
            "Tarmuwa",
            "Yunusari",
            "Yusufari"
        ]
    },
    {
        "state": "Zamfara",
        "lgas": [
            "Anka",
            "Bakura",
            "Birnin Magaji/Kiyaw",
            "Bukkuyum",
            "Bungudu",
            "Gummi",
            "Gusau",
            "Isa",
            "Kaura-Namoda",
            "Kiyawa",
            "Maradun",
            "Maru",
            "Shinkafi",
            "Talata-Mafara",
            "Tsafe",
            "Zurmi"
        ]
    }
];


export const STATIC_LIST_OF_BANKS = [
    {
        code: '044',
        id: 1,
        'name': 'Access Bank'
    }, {
        code: '023',
        id: 2,
        name: 'Citi Bank'
    }, {
        code: '050',
        id: 4,
        name: 'EcoBank PLC'
    }, {
        code: '011',
        id: 5,
        name: 'First Bank PLC'
    }, {
        code: '214',
        id: 6,
        name: 'First City Monument Bank'
    }, {
        code: '070',
        id: 7,
        name: 'Fidelity Bank'
    }, {
        code: '058',
        id: 8,
        name: 'Guaranty Trust Bank'
    }, {
        code: '076',
        id: 9,
        name: 'Polaris bank'
    }, {
        code: '221',
        id: 10,
        name: 'Stanbic IBTC Bank'
    }, {
        code: '068',
        id: 11,
        name: 'Standard Chaterted bank PLC'
    }, {
        code: '232',
        id: 12,
        name: 'Sterling Bank PLC'
    }, {
        code: '033',
        id: 13,
        name: 'United Bank for Africa'
    }, {
        code: '032',
        id: 14,
        name: 'Union Bank PLC'
    }, {
        code: '035',
        id: 15,
        name: 'Wema Bank PLC'
    }, {
        code: '057',
        id: 16,
        name: 'Zenith bank PLC'
    }, {
        code: '215',
        id: 17,
        name: 'Unity Bank PLC'
    }, {
        code: '101',
        id: 18,
        name: 'ProvidusBank PLC'
    }, {
        code: '082',
        id: 183,
        name: 'Keystone Bank'
    }, {
        code: '301',
        id: 184,
        name: 'Jaiz Bank'
    }, {
        code: '030',
        id: 186,
        name: 'Heritage Bank'
    }, {
        code: '100',
        id: 231,
        name: 'Suntrust Bank'
    }, {
        code: '608',
        id: 252,
        name: 'FINATRUST MICROFINANCE BANK'
    }, {
        code: '090175',
        id: 253,
        name: 'Rubies Microfinance Bank'
    }, {
        code: '090267',
        id: 254,
        name: 'Kuda'
    }, {
        code: '090115',
        id: 258,
        name: 'TCF MFB'
    }, {
        code: '400001',
        id: 259,
        name: 'FSDH Merchant Bank'
    }, {
        code: '502',
        id: 260,
        name: 'Rand merchant Bank'
    }, {
        code: '103',
        id: 301,
        name: 'Globus Bank'
    }, {
        code: '327',
        id: 389,
        name: 'Paga'
    }, {
        code: '000026',
        id: 395,
        name: 'Taj Bank Limited'
    }, {
        code: '100022',
        id: 596,
        name: 'GoMoney'
    }, {
        code: '090180',
        id: 597,
        name: 'AMJU Unique Microfinance Bank'
    }, {
        code: '090393',
        id: 638,
        name: 'BRIDGEWAY MICROFINANCE BANK'
    }, {
        code: '090328',
        id: 639,
        name: 'Eyowo MFB'
    }, {
        code: '090281',
        id: 640,
        name: 'Mint-Finex MICROFINANCE BANK'
    }, {
        code: '070006',
        id: 659,
        name: 'Covenant Microfinance Bank'
    }, {
        code: '090110',
        id: 660,
        name: 'VFD Micro Finance Bank'
    }, {
        code: '090317',
        id: 661,
        name: 'PatrickGold Microfinance Bank'
    }, {
        code: '090325',
        id: 728,
        name: 'Sparkle'
    }, {
        code: '305',
        id: 784,
        name: 'Paycom'
    }, {
        code: '070001',
        id: 811,
        name: 'NPF MicroFinance Bank'
    }, {
        code: '110001',
        id: 812,
        name: 'PayAttitude Online'
    }, {
        code: '100027',
        id: 813,
        name: 'Intellifin'
    }, {
        code: '100032',
        id: 814,
        name: 'Contec Global Infotech Limited (NowNow)'
    }, {
        code: '100031',
        id: 815,
        name: 'FCMB Easy Account'
    }, {
        code: '100030',
        id: 816,
        name: 'EcoMobile'
    }, {
        code: '100029',
        id: 817,
        name: 'Innovectives Kesh'
    }, {
        code: '100026',
        id: 818,
        name: 'One Finance'
    }, {
        code: '100025',
        id: 819,
        name: 'Zinternet Nigera Limited'
    }, {
        code: '100023',
        id: 820,
        name: 'TagPay'
    }, {
        code: '100021',
        id: 821,
        name: 'Eartholeum'
    }, {
        code: '100020',
        id: 822,
        name: 'MoneyBox'
    }, {
        code: '100019',
        id: 824,
        name: 'Fidelity Mobile'
    }, {
        code: '000019',
        id: 825,
        name: 'Enterprise Bank'
    }, {
        code: '060001',
        id: 826,
        name: 'Coronation Merchant Bank'
    }, {
        code: '060002',
        id: 827,
        name: 'FBNQUEST Merchant Bank'
    }, {
        code: '060003',
        id: 828,
        name: 'Nova Merchant Bank'
    }, {
        code: '070007',
        id: 829,
        name: 'Omoluabi savings and loans'
    }, {
        code: '090001',
        id: 830,
        name: 'ASOSavings & Loans'
    }, {
        code: '090005',
        id: 831,
        name: 'Trustbond Mortgage Bank'
    }, {
        code: '090006',
        id: 832,
        name: 'SafeTrust '
    }, {
        code: '090107',
        id: 833,
        name: 'FBN Mortgages Limited'
    }, {
        code: '100024',
        id: 834,
        name: 'Imperial Homes Mortgage Bank'
    }, {
        code: '100028',
        id: 835,
        name: 'AG Mortgage Bank'
    }, {
        code: '070009',
        id: 836,
        name: 'Gateway Mortgage Bank'
    }, {
        code: '070010',
        id: 837,
        name: 'Abbey Mortgage Bank'
    }, {
        code: '070011',
        id: 838,
        name: 'Refuge Mortgage Bank'
    }, {
        code: '070012',
        id: 839,
        name: 'Lagos Building Investment Company'
    }, {
        code: '070013',
        id: 840,
        name: 'Platinum Mortgage Bank'
    }, {
        code: '070014',
        id: 841,
        name: 'First Generation Mortgage Bank'
    }, {
        code: '070015',
        id: 842,
        name: 'Brent Mortgage Bank'
    }, {
        code: '070016',
        id: 843,
        name: 'Infinity Trust Mortgage Bank'
    }, {
        code: '090003',
        id: 844,
        name: 'Jubilee-Life Mortgage  Bank'
    }, {
        code: '070017',
        id: 845,
        name: 'Haggai Mortgage Bank Limited'
    }, {
        code: '090108',
        id: 846,
        name: 'New Prudential Bank'
    }, {
        code: '070002',
        id: 847,
        name: 'Fortis Microfinance Bank'
    }, {
        code: '070008',
        id: 848,
        name: 'Page Financials'
    }, {
        code: '090004',
        id: 849,
        name: 'Parralex Microfinance bank'
    }, {
        code: '090097',
        id: 850,
        name: 'Ekondo MFB'
    }, {
        code: '090112',
        id: 851,
        name: 'Seed Capital Microfinance Bank'
    }, {
        code: '090114',
        id: 852,
        name: 'Empire trust MFB'
    }, {
        code: '090116',
        id: 853,
        name: 'AMML MFB'
    }, {
        code: '090117',
        id: 854,
        name: 'Boctrust Microfinance Bank'
    }, {
        code: '090118',
        id: 855,
        name: 'IBILE Microfinance Bank'
    }, {
        code: '090119',
        id: 856,
        name: 'Ohafia Microfinance Bank'
    }, {
        code: '090120',
        id: 857,
        name: 'Wetland Microfinance Bank'
    }, {
        code: '090121',
        id: 858,
        name: 'Hasal Microfinance Bank'
    }, {
        code: '090122',
        id: 859,
        name: 'Gowans Microfinance Bank'
    }, {
        code: '090123',
        id: 860,
        name: 'Verite Microfinance Bank'
    }, {
        code: '090124',
        id: 861,
        name: 'Xslnce Microfinance Bank'
    }, {
        code: '090125',
        id: 862,
        name: 'Regent Microfinance Bank'
    }, {
        code: '090126',
        id: 863,
        name: 'Fidfund Microfinance Bank'
    }, {
        code: '090127',
        id: 864,
        name: 'BC Kash Microfinance Bank'
    }, {
        code: '090128',
        id: 865,
        name: 'Ndiorah Microfinance Bank'
    }, {
        code: '090129',
        id: 866,
        name: 'Money Trust Microfinance Bank'
    }, {
        code: '090130',
        id: 867,
        name: 'Consumer Microfinance Bank'
    }, {
        code: '090131',
        id: 868,
        name: 'Allworkers Microfinance Bank'
    }, {
        code: '090132',
        id: 869,
        name: 'Richway Microfinance Bank'
    }, {
        code: '090133',
        id: 870,
        name: ' AL-Barakah Microfinance Bank'
    }, {
        code: '090134',
        id: 871,
        name: 'Accion Microfinance Bank'
    }, {
        code: '090135',
        id: 872,
        name: 'Personal Trust Microfinance Bank'
    }, {
        code: '090136',
        id: 873,
        name: 'Baobab Microfinance Bank'
    }, {
        code: '090137',
        id: 874,
        name: 'PecanTrust Microfinance Bank'
    }, {
        code: '090138',
        id: 875,
        name: 'Royal Exchange Microfinance Bank'
    }, {
        code: '090139',
        id: 876,
        name: 'Visa Microfinance Bank'
    }, {
        code: '090140',
        id: 877,
        name: 'Sagamu Microfinance Bank'
    }, {
        code: '090141',
        id: 878,
        name: 'Chikum Microfinance Bank'
    }, {
        code: '090142',
        id: 879,
        name: 'Yes Microfinance Bank'
    }, {
        code: '090143',
        id: 880,
        name: 'Apeks Microfinance Bank'
    }, {
        code: '090144',
        id: 881,
        name: 'CIT Microfinance Bank'
    }, {
        code: '090145',
        id: 882,
        name: 'Fullrange Microfinance Bank'
    }, {
        code: '090146',
        id: 883,
        name: 'Trident Microfinance Bank'
    }, {
        code: '090147',
        id: 884,
        name: 'Hackman Microfinance Bank'
    }, {
        code: '090148',
        id: 885,
        name: 'Bowen Microfinance Bank'
    }, {
        code: '090149',
        id: 886,
        name: 'IRL Microfinance Bank'
    }, {
        code: '090150',
        id: 887,
        name: 'Virtue Microfinance Bank'
    }, {
        code: '090151',
        id: 888,
        name: 'Mutual Trust Microfinance Bank'
    }, {
        code: '090152',
        id: 889,
        name: 'Nagarta Microfinance Bank'
    }, {
        code: '090153',
        id: 890,
        name: 'FFS Microfinance Bank'
    }, {
        code: '090154',
        id: 891,
        name: 'CEMCS Microfinance Bank'
    }, {
        code: '090155',
        id: 892,
        name: 'La  Fayette Microfinance Bank'
    }, {
        code: '090156',
        id: 893,
        name: 'e-Barcs Microfinance Bank'
    }, {
        code: '090157',
        id: 894,
        name: 'Infinity Microfinance Bank'
    }, {
        code: '090158',
        id: 895,
        name: 'Futo Microfinance Bank'
    }, {
        code: '090159',
        id: 896,
        name: 'Credit Afrique Microfinance Bank'
    }, {
        code: '090160',
        id: 897,
        name: 'Addosser Microfinance Bank'
    }, {
        code: '090161',
        id: 898,
        name: 'Okpoga Microfinance Bank'
    }, {
        code: '090162',
        id: 899,
        name: 'Stanford Microfinance Bak'
    }, {
        code: '090164',
        id: 900,
        name: 'First Royal Microfinance Bank'
    }, {
        code: '090165',
        id: 901,
        name: 'Petra Microfinance Bank'
    }, {
        code: '090166',
        id: 902,
        name: 'Eso-E Microfinance Bank'
    }, {
        code: '090167',
        id: 903,
        name: 'Daylight Microfinance Bank'
    }, {
        code: '090168',
        id: 904,
        name: 'Gashua Microfinance Bank'
    }, {
        code: '090169',
        id: 905,
        name: 'Alpha Kapital Microfinance Bank'
    }, {
        code: '090171',
        id: 906,
        name: 'Mainstreet Microfinance Bank'
    }, {
        code: '090172',
        id: 907,
        name: 'Astrapolaris Microfinance Bank'
    }, {
        code: '090173',
        id: 908,
        name: 'Reliance Microfinance Bank'
    }, {
        code: '090174',
        id: 909,
        name: 'Malachy Microfinance Bank'
    }, {
        code: '090175',
        id: 910,
        name: 'HighStreet Microfinance Bank'
    }, {
        code: '090176',
        id: 911,
        name: 'Bosak Microfinance Bank'
    }, {
        code: '090177',
        id: 912,
        name: 'Lapo Microfinance Bank'
    }, {
        code: '090178',
        id: 913,
        name: 'GreenBank Microfinance Bank'
    }, {
        code: '090179',
        id: 914,
        name: 'FAST Microfinance Bank'
    }, {
        code: '090188',
        id: 915,
        name: 'Baines Credit Microfinance Bank'
    }, {
        code: '090189',
        id: 916,
        name: 'Esan Microfinance Bank'
    }, {
        code: '090190',
        id: 917,
        name: 'Mutual Benefits Microfinance Bank'
    }, {
        code: '090191',
        id: 918,
        name: 'KCMB Microfinance Bank'
    }, {
        code: '090192',
        id: 919,
        name: 'Midland Microfinance Bank'
    }, {
        code: '090193',
        id: 920,
        name: 'Unical Microfinance Bank'
    }, {
        code: '090194',
        id: 921,
        name: 'NIRSAL Microfinance Bank'
    }, {
        code: '090195',
        id: 922,
        name: 'Grooming Microfinance Bank'
    }, {
        code: '090196',
        id: 923,
        name: 'Pennywise Microfinance Bank'
    }, {
        code: '090197',
        id: 924,
        name: 'ABU Microfinance Bank'
    }, {
        code: '090198',
        id: 925,
        name: 'RenMoney Microfinance Bank'
    }, {
        code: '090205',
        id: 926,
        name: 'New Dawn Microfinance Bank'
    }, {
        code: '090251',
        id: 927,
        name: 'UNN MFB'
    }, {
        code: '090258',
        id: 928,
        name: 'Imo State Microfinance Bank'
    }, {
        code: '090259',
        id: 929,
        name: 'Alekun Microfinance Bank'
    }, {
        code: '090260',
        id: 930,
        name: 'Above Only Microfinance Bank'
    }, {
        code: '090261',
        id: 931,
        name: 'Quickfund Microfinance Bank'
    }, {
        code: '090262',
        id: 932,
        name: 'Stellas Microfinance Bank'
    }, {
        code: '090263',
        id: 933,
        name: 'Navy Microfinance Bank'
    }, {
        code: '090264',
        id: 934,
        name: 'Auchi Microfinance Bank'
    }, {
        code: '090265',
        id: 935,
        name: 'Lovonus Microfinance Bank'
    }, {
        code: '090266',
        id: 936,
        name: 'Uniben Microfinance Bank'
    }, {
        code: '090268',
        id: 937,
        name: 'Adeyemi College Staff Microfinance Bank'
    }, {
        code: '090269',
        id: 938,
        name: 'Greenville Microfinance Bank'
    }, {
        code: '090270',
        id: 939,
        name: 'AB Microfinance Bank'
    }, {
        code: '090271',
        id: 940,
        name: 'Lavender Microfinance Bank'
    }, {
        code: '090272',
        id: 941,
        name: 'Olabisi Onabanjo University Microfinance Bank'
    }, {
        code: '090273',
        id: 942,
        name: 'Emeralds Microfinance Bank'
    }, {
        code: '090276',
        id: 943,
        name: 'Trustfund Microfinance Bank'
    }, {
        code: '090277',
        id: 944,
        name: 'Al-Hayat Microfinance Bank'
    }, {
        code: '100001',
        id: 945,
        name: 'FET'
    }, {
        code: '100003',
        id: 946,
        name: 'Parkway-ReadyCash'
    }, {
        code: '100005',
        id: 947,
        name: 'Cellulant'
    }, {
        code: '100006',
        id: 948,
        name: 'eTranzact'
    }, {
        code: '100007',
        id: 949,
        name: 'Stanbic IBTC @ease wallet'
    }, {
        code: '100008',
        id: 950,
        name: 'Ecobank Xpress Account'
    }, {
        code: '100009',
        id: 951,
        name: 'GTMobile'
    }, {
        code: '100010',
        id: 952,
        name: 'TeasyMobile'
    }, {
        code: '100011',
        id: 953,
        name: 'Mkudi'
    }, {
        code: '100012',
        id: 954,
        name: 'VTNetworks'
    }, {
        code: '100013',
        id: 955,
        name: 'AccessMobile'
    }, {
        code: '100014',
        id: 956,
        name: 'FBNMobile'
    }, {
        code: '100015',
        id: 957,
        name: 'Kegow'
    }, {
        code: '100016',
        id: 958,
        name: 'FortisMobile'
    }, {
        code: '100017',
        id: 959,
        name: 'Hedonmark'
    }, {
        code: '100018',
        id: 960,
        name: 'ZenithMobile'
    }, {
        code: '110002',
        id: 961,
        name: 'Flutterwave Technology Solutions Limited'
    }, {
        code: '999999',
        id: 962,
        name: 'NIP Virtual Bank'
    }
]
export const STATIC_LIST_OF_BANKS_OLD = [
    { code: "560", id: 132, name: "Page Microfinance Bank" },
    { code: "304", id: 133, name: "Stanbic Mobile Money" },
    { code: "308", id: 134, name: "FortisMobile" },
    { code: "328", id: 135, name: "TagPay" },
    { code: "309", id: 136, name: "FBNMobile" },
    { code: "011", id: 137, name: "First Bank of Nigeria" },
    { code: "326", id: 138, name: "Sterling Mobile" },
    { code: "990", id: 139, name: "Omoluabi Mortgage Bank" },
    { code: "311", id: 140, name: "ReadyCash (Parkway)" },
    { code: "057", id: 141, name: "Zenith Bank" },
    { code: "068", id: 142, name: "Standard Chartered Bank" },
    { code: "306", id: 143, name: "ETranzact" },
    { code: "070", id: 144, name: "Fidelity Bank" },
    { code: "023", id: 145, name: "CitiBank" },
    { code: "215", id: 146, name: "Unity Bank" },
    { code: "323", id: 147, name: "Access Money" },
    { code: "302", id: 148, name: "Eartholeum" },
    { code: "324", id: 149, name: "Hedonmark" },
    { code: "325", id: 150, name: "MoneyBox" },
    { code: "301", id: 151, name: "JAIZ Bank" },
    { code: "050", id: 152, name: "Ecobank Plc" },
    { code: "307", id: 153, name: "EcoMobile" },
    { code: "318", id: 154, name: "Fidelity Mobile" },
    { code: "319", id: 155, name: "TeasyMobile" },
    { code: "999", id: 156, name: "NIP Virtual Bank" },
    { code: "320", id: 157, name: "VTNetworks" },
    { code: "221", id: 158, name: "Stanbic IBTC Bank" },
    { code: "501", id: 159, name: "Fortis Microfinance Bank" },
    { code: "329", id: 160, name: "PayAttitude Online" },
    { code: "322", id: 161, name: "ZenithMobile" },
    { code: "303", id: 162, name: "ChamsMobile" },
    { code: "403", id: 163, name: "SafeTrust Mortgage Bank" },
    { code: "070006", id: 164, name: "Covenant Microfinance Bank" },
    { code: "415", id: 165, name: "Imperial Homes Mortgage Bank" },
    { code: "552", id: 166, name: "NPF MicroFinance Bank" },
    { code: "526", id: 167, name: "Parralex" },
    { code: "035", id: 168, name: "Wema Bank" },
    { code: "084", id: 169, name: "Enterprise Bank" },
    { code: "063", id: 170, name: "Diamond Bank" },
    { code: "305", id: 171, name: "Paycom" },
    { code: "100", id: 172, name: "SunTrust Bank" },
    { code: "317", id: 173, name: "Cellulant" },
    { code: "401", id: 174, name: "ASO Savings and & Loans" },
    { code: "030", id: 175, name: "Heritage" },
    { code: "402", id: 176, name: "Jubilee Life Mortgage Bank" },
    { code: "058", id: 177, name: "GTBank Plc" },
    { code: "032", id: 178, name: "Union Bank" },
    { code: "232", id: 179, name: "Sterling Bank" },
    { code: "076", id: 180, name: "Skye Bank" },
    { code: "082", id: 181, name: "Keystone Bank" },
    { code: "327", id: 182, name: "Pagatech" },
    { code: "559", id: 183, name: "Coronation Merchant Bank" },
    { code: "601", id: 184, name: "FSDH" },
    { code: "313", id: 185, name: "Mkudi" },
    { code: "214", id: 186, name: "First City Monument Bank" },
    { code: "314", id: 187, name: "FET" },
    { code: "523", id: 188, name: "Trustbond" },
    { code: "315", id: 189, name: "GTMobile" },
    { code: "033", id: 190, name: "United Bank for Africa" },
    { code: "044", id: 191, name: "Access Bank" },
    { code: "90115", id: 567, name: "TCF Microfinance Bank" },
    { code: "076", id: 567, name: "Polaris Bank" },
    { code: "101", id: 568, name: "Providus Bank" },
    { code: "102", id: 569, name: "Titan Trust Bank Ltd" },
    { code: "103", id: 570, name: "Globus Bank Limited" },
    { code: "058", id: 571, name: "Guaranty Trust Bank" },
    { code: "608", id: 572, name: "FINATRUST MICROFINANCE Bank" },
    { code: "090175", id: 573, name: "Rubies Microfinance Bank" },
    { code: "090267", id: 574, name: "Kuda Bank" },
    { code: "400001", id: 575, name: "FSDH Merchant Bank" },
    { code: "502", id: 576, name: "Rand merchant Bank" },
    { code: "000026", id: 577, name: "Taj Bank Limited" },
    { code: "100022", id: 578, name: "GoMoney" },
    { code: "090180", id: 579, name: "AMJU Unique Microfinance Bank" },
    { code: "090393", id: 580, name: "BRIDGEWAY Microfinance Bank" },
    { code: "090328", id: 581, name: "Eyowo Microfinance Bank" },
    { code: "090281", id: 582, name: "Mint-Finex Microfinance Bank" },
    { code: "090110", id: 583, name: "VFD Microfinance Bank" },
    { code: "090317", id: 584, name: "PatrickGold Microfinance Bank" },
    { code: "090325", id: 585, name: "Sparkle" },
    { code: "305", id: 586, name: "Paycom" },
  ];

export const STATIC_LIST_OF_CARRIER_PREFIXES = ["07025", "07026", "0703", "0704", "0706", "0803", "0806", "0810", "0813", "0814", "0816", "0903", "0906", "0913"]
import URL_ENDPOINTS from "../url-endpoints";
import ApiService from "../services/network-services/api.service";

export default class EditProfileService{
    private apiService = new ApiService();

    /*
    * Updates the merchant profile
    * @param merchantID: ID of the merchant
    * @param data: Updated details of the merchant
    * */
    async registerMerchant<T>(merchantID: string, data: object): Promise<T> {
        const postObj = {
            merchant_id: merchantID,
            ...data
        };
        const endpoint = URL_ENDPOINTS.updateMerchant;
        return this.apiService.updateResource(postObj, endpoint);
    }
}

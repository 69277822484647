import React from "react";
import "./empty-state.sass";
import { addOutline } from "ionicons/icons";
import { IonIcon } from '@ionic/react';

interface IProps {
    title: string,
    description: string,
    icon?: string,
    actionButtonText?: string,
    actionCallback?: () => void
}

const EmptyState: React.FC<IProps> = (props: IProps) => {
    const {actionCallback} = props;

    return (

        <div className="empty-state-container">
            {!props.icon ? '' 
            : 
            <div className="illustration">
                <img src={props.icon}/>
            </div> }
            
            <div className="message">
                <p className="empty-state-title">{props.title}</p>
                <p className="description">{props.description}</p>
            </div>

            {
                actionCallback ? (
                    <div className="actions">
                        <button onClick={() => actionCallback()}
                                className="ajua-button -medium-button -neutral"><IonIcon icon={addOutline}/> {props.actionButtonText} </button>
                    </div>
                ) : ''
            }

        </div>
    )
};

export default EmptyState;

import { URL_ENDPOINTS } from "../url-endpoints";
import ApiService from "../services/network-services/api.service";

export default class UserProfileService {
  private apiService = new ApiService();

  async updateMerchantDetails<T>(postObj: object): Promise<T> {
    const endpoint = URL_ENDPOINTS.updateMerchant;
    return this.apiService.updateResource(postObj, endpoint);
  }

  async createMerchantPaymentInfo<T>(postObj: object): Promise<T> {
    const endpoint = URL_ENDPOINTS.updateMerchantPayment;
    return this.apiService.createResource(postObj, endpoint);
  }

  async updateMerchantPaymentInfo<T>(postObj: object): Promise<T> {
    const endpoint = URL_ENDPOINTS.updateMerchantPayment;
    return this.apiService.updateResource(postObj, endpoint);
  }
}

import ApiService from "../services/network-services/api.service";
import environment from "../../environments/environment";
import { NEW_ENDPOINTS } from "../../shared/url-endpoints";


/**
 * set API token to use for requests
 * @return {Promise<any>}
 */
export async function setAPIToken() {
    const apiService = new ApiService();
    return await apiService.createResource(
        {
            username: environment.apiUsername,
            password: environment.apiPassword
        }, `/login`)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/** Modified by @Joe on 16/12/2020
 * get API token to use for requests - This will be phased out in January 2021
 * @return {Promise<any>}
 */
export async function getPrefetchToken() {
    const apiService = new ApiService();
    const urlEndpoint = encodeURI(`/token?username=${environment.apiUsername}&password=${environment.apiPassword}`);
    return await apiService.getResource(urlEndpoint)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * get API token to use for requests
 * @return {Promise<any>}
 */
export async function getAPIToken() {
    const apiService = new ApiService();
    const urlEndpoint = encodeURI(`/token?username=${environment.apiUsername}&password=${environment.apiPassword}`);
    return await apiService.getResource(urlEndpoint)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/** This will be DEPRECATED
 * get API token to use for requests
 * @return {Promise<any | void>}
 * @param {string} msisdn
 */
export async function generateOTP(msisdn: string) {
    const apiService = new ApiService();
    return await apiService.getResource(`/generate_otp?msisdn=${msisdn}`)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * get OTP auth service
 * @return {Promise<any | void>}
 * @param {string} msisdn
 */
export async function getAuthOTPForWhatsApp() {
    const apiService = new ApiService();
    const authUrl = { baseUrl: environment.baseUrl };
    return await apiService.createResource({}, `${NEW_ENDPOINTS.getWhatsAppOTP}`, authUrl)
        .then((response: any) => {
            console.log('response?.data', response?.data)
            return response?.data;
        })
        .catch(
            error => {
                console.log('error', error)
                throw error
            }
        );
}

/**
 * get OTP auth service
 * @return {Promise<any | void>}
 * @param {string} msisdn
 */
export async function getAuthOTP(msisdn: string) {
    const apiService = new ApiService();
    const authUrl = { baseUrl: environment.baseUrl };
    return await apiService.getResource(`${NEW_ENDPOINTS.getOTP}?msisdn=${msisdn}`, authUrl)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}

/**
 * validate OTP
 * @return {Promise<any>}
 */
export async function validateOTP(msisdn: string, otp?: string) {
    const apiService = new ApiService();
    return await apiService.createResource(
        {
            msisdn,
            otp
        },
        `${NEW_ENDPOINTS.validateOTP}`,)
        .then((response: any) => {
            return response?.data;
        })
        .catch(
            error => {
                throw error
            }
        );
}



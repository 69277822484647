import ApiService from "../services/network-services/api.service";
import { URL_ENDPOINTS } from "../url-endpoints"

export default class TransactionsService {
    private apiService = new ApiService();

    /**
     * get latest payment records
     * @return {Promise<any>}
     
    async getTransactionHistory(payload: any) {
        const { msisdn, merchant_name, type='payments' } = payload
        const apiService = new ApiService();
        const getBodyObj = {
            type, 
            msisdn, 
            merchant_name
        }
        //const getBodyStr = `{ 'type': '${type}', 'msisdn': '${msisdn}', 'merchant_name': '${merchant_name}' }`
        return await apiService.getResource(
            `${URL_ENDPOINTS.transactionHistory}?msisdn=${msisdn}&merchant_name=${merchant_name}&type=${type}`,
            undefined, undefined, getBodyObj)
            .then(activities => {
                return activities?.data;
            })
            .catch(
                error => {
                    throw error
                }
            )
    } */

    /**
     * get latest payment records
     * @return {Promise<any>}
    */
    async getTransactionHistory(payload: any) {
        const { msisdn, merchant_name, type='payments' } = payload
        const apiService = new ApiService();
        return await apiService.createResource(
            { msisdn, merchant_name, type },
            `${URL_ENDPOINTS.transactionHistory}`)
            .then(activities => {
                return activities?.data;
            })
            .catch(
                error => {
                    throw error
                }
            )
    }/* */

    /**
     * create ad
     * @return {Promise<any>}
     * @param body
     */
    async submitAd(body: object) {
        const apiService = new ApiService();
        return await apiService.createResource(
            body,
            `${URL_ENDPOINTS.submitAd}`,)
            .then((response: any) => {
                return response?.data;
            })
            .catch(
                error => {
                    throw error
                }
            );
    }
}

import React from "react";
import {DiscountIdeaCardModel} from "./discountIdeaCard.model";
import './discountIdeaCard.sass';
import {Link} from "react-router-dom";

const DiscountIdeaCard: React.FC<{ idea: DiscountIdeaCardModel }> = (props: { idea: DiscountIdeaCardModel }) => {
    return (
        <div className="discount-idea-card">
            <div className="icon">
                <img src={'assets/icons/discount_idea.png'}/>
            </div>
            <div className="details">
                <div className="discount-details">
                    <p className="discount-title">{props.idea.discount}</p>
                    <p className="duration">{props.idea.duration}</p>
                </div>
            </div>
            <div className="action-item">
                <Link to={"/create-offer"}>
                    <button className="ajua-button -small-button">Create</button>
                </Link>
            </div>
        </div>
    )
};

export default DiscountIdeaCard;

import ApiService from "../../shared/services/network-services/api.service";
import { URL_ENDPOINTS } from "../../shared/url-endpoints";

class ProductRequestService {

    constructor() {
    }

    async submitRequest(merchantID: string, request: string) {
        const apiService = new ApiService();
        const postObj = {
            merchant_id: merchantID,
            type: 'manufacturer-requests',
            comment: request
        };
        return await apiService.createResource(
            postObj,
            URL_ENDPOINTS.supplierOffersFeedbackUrl,)
            .then((response: any) => {
                return response?.data;
            })
            .catch(
                error => {
                    throw error
                }
            );
    }
}

export default ProductRequestService;

import React from "react";
import './AdvertiseYourBusinessAction.sass';

interface AdvertiseYourBusinessActionModel {
    message: string,
    onClickToCreateAd: (e: any) => void,
}


class AdvertiseYourBusinessAction extends React.Component<AdvertiseYourBusinessActionModel> {
// const AdvertiseYourBusinessAction: React.FC<{ idea: AdvertiseYourBusinessActionModel }> = (props: { idea: AdvertiseYourBusinessActionModel }) => {
    render() {
        return (
            <div className="add-ads-card">
                <div className="icon">
                    <img src={'assets/icons/ic_promotions.svg'} alt=""/>
                </div>
                <div className="details">
                    <div className="discount-details">
                        <p className="discount-title">{this.props.message}</p>
                    </div>
                </div>
                <div className="action-item">
                    <button className="ajua-button -small-button" onClick={() => this.props.onClickToCreateAd(this.props)}>+ Create Ad</button>
                </div>
            </div>
        )
    }
};

export default AdvertiseYourBusinessAction;

import React from "react";
import "./sendMessage.sass";
import {
    IonBadge,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonPage,
    IonSpinner,
    IonTextarea,
    withIonLifeCycle
} from "@ionic/react";
import AjuaToolbar from "../../shared/components/ajua-toolbar/ajuaToolbar";
import {SMS} from "@ionic-native/sms";
import {SocialSharing} from "@ionic-native/social-sharing";
import {Toast} from "../../shared/components/generic/toast";
import {InAppBrowser} from "@ionic-native/in-app-browser";
import UtilityFunctions from "../../shared/helpers/utility-functions";
import AjuaCard from "../../shared/components/ajua-card/ajua-card";
import {mail} from "ionicons/icons";
import FloatingActionBar from "../../shared/components/floating-action-bar/floatingActionBar";
import {getFromStorage, saveToStorage, STORAGE_FIELDS} from "../../shared/helpers/storage";
import {ErrorMessage, Field, FieldProps, Form, Formik} from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-number-input";
import InlineAlert from "../../shared/components/forms/inline-alert/inlineAlert";
import {MerchantModel} from "../../shared/models/merchant.model";
import * as Amplitude from "amplitude-js";
import {EVENTS_TRACKED} from "../../shared/helpers/events-tracked";

interface SendMessageFormSchema {
    phoneNumber: string,
    message: string,
}

class SendMessage extends React.Component<any, any> {
    state = {
        messageChannel: '',
        sendingSMS: false,
        openingWhatsapp: false,
        toastMessage: "success",
        toastOpen: false,
        toastColor: "success",
        whatsappShare: true,
        smsBalance: 0,
        merchant: {} as MerchantModel
    };

    private SendMessageFormValidationSchema = yup.object().shape({
        phoneNumber: yup.string().required('Phone number is required.').test('phoneNumber', 'Enter a valid phone number', UtilityFunctions.validatePhoneNumber),
        message: yup.string().required('Kindly enter your message.'),
    });

    constructor(props: any) {
        super(props);
    }


    async ionViewDidEnter() {
        getFromStorage(STORAGE_FIELDS.SMS_BALANCE).then(async (smsBalance) => {
            if (smsBalance) {
                this.setState({smsBalance})
            } else {
                await saveToStorage(STORAGE_FIELDS.SMS_BALANCE, 150);
                this.setState({smsBalance: 150})
            }
        });
        getFromStorage(STORAGE_FIELDS.MERCHANT).then(async (merchant) => {
            if (merchant) {
                this.setState({merchant})
            }
        });
    }

    componentDidMount(): void {
        SocialSharing.canShareVia('whatsapp').then(status => {
            if (status) {
                this.setState({whatsappShare: true})
            } else {
                this.setState({whatsappShare: false})
            }
        })
    }

    /*
    * Handler that sends a message to the customer using the chosen channel (SMS or Whatsapp)
    * */
    async sendMessageToChannel(values: SendMessageFormSchema, resetForm: any) {
        switch (this.state.messageChannel) {
            case 'sms':
                this.sendSMSMessage(values);
                resetForm({});
                break;
            case 'whatsapp':
                this.sendWhatsappMessage(values);
                resetForm({});
                break;
        }
    }

    /*
       * Deep-links to Whatsapp to send the message
       * */
    async sendWhatsappMessage(messageObject: SendMessageFormSchema) {
        this.setState({openingWhatsapp: true}, () => {
            SocialSharing.shareViaWhatsAppToReceiver(messageObject.phoneNumber, messageObject.message,).then(shareStatus => {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Opening Whatsapp",
                    toastColor: "success",
                    openingWhatsapp: false
                });

                // Track event
                Amplitude.getInstance().logEvent(EVENTS_TRACKED.SEND_MESSAGE_WHATSAPP,
                    {merchantDetails: this.state.merchant});
            }).catch(e => {
                this.setState({
                    toastOpen: true,
                    toastMessage: "Unable to send via Whatsapp. Try again",
                    toastColor: "danger",
                    openingWhatsapp: false
                });
            });
        });

    }

    /*
    * Sends an SMS message to the customer
    * */
    async sendSMSMessage(messageObject: SendMessageFormSchema) {
        this.setState({sendingSMS: true}, async () => {
            const smsOptions = {
                android: {
                    intent: 'INTENT' // Change value to 'INTENT' to use device sms app
                }
            };

            if (await SMS.hasPermission()) {
                await SMS.send(messageObject.phoneNumber, messageObject.message, smsOptions).catch(() => {
                    this.setState({
                        sendingSMS: false,
                        toastOpen: true,
                        toastMessage: "Unable to send message. Check your network and try again.",
                        toastColor: "danger",
                    });
                });
                this.updateSendMessageState();

            } else {
                UtilityFunctions.checkForSMSPermissions().then(async (granted) => {
                    await SMS.send(messageObject.phoneNumber, messageObject.message, smsOptions).catch(error => {
                        throw error;
                    });
                    this.updateSendMessageState();
                }).catch((e) => {
                    this.setState({
                        sendingSMS: false,
                        toastOpen: true,
                        toastMessage: "Unable to send message",
                        toastColor: "danger",
                    });
                })
            }
        })
    }

    updateSendMessageState() {
        let smsBalance = this.state.smsBalance;
        if (this.state.smsBalance > 0) {
            smsBalance = this.state.smsBalance - 1;
            this.updateSMSBalance(smsBalance);
        }

        this.setState({
            sendingSMS: false,
            toastOpen: true,
            message: '',
            toastMessage: "Message sent",
            toastColor: "success",
            smsBalance
        });

        // Track event
        Amplitude.getInstance().logEvent(EVENTS_TRACKED.SEND_MESSAGE_SMS,
            {merchantDetails: this.state.merchant});
    }

    async updateSMSBalance(balance: number) {
        await saveToStorage(STORAGE_FIELDS.SMS_BALANCE, balance);
    }

    closeToast = () => {
        this.setState({toastOpen: false});
    };

    render() {
        const {toastMessage, toastOpen, toastColor} = this.state;

        const customerPhoneNumber = this.props.location.state.customer;
        const subscriptionStatus = this.state.merchant?.ajua_account_details?.subscription_status;
        return (
            <IonPage>
                <IonHeader>
                    <AjuaToolbar toolbar={{title: "Send Message"}}/>
                </IonHeader>
                <IonContent>
                    <div className="page-section send-message-page">
                        <div className="section-content">

                            <FloatingActionBar>
                                <div className="sms-limit-notice">
                                    <AjuaCard>
                                        <p><IonIcon icon={mail}/> <strong>SMS Balance</strong>: <IonBadge
                                            color={'warning'}>{subscriptionStatus ? this.state.smsBalance : 'Subscribe to get free SMS' }</IonBadge></p>
                                    </AjuaCard>
                                </div>
                            </FloatingActionBar>

                            <Formik
                                initialValues={{
                                    phoneNumber: `+${customerPhoneNumber}`,
                                    message: '',
                                }}
                                validationSchema={this.SendMessageFormValidationSchema}
                                onSubmit={(values, {resetForm}) => this.sendMessageToChannel(values, resetForm)}>
                                {({touched, handleChange, handleBlur, errors, isValid, dirty, setFieldValue, values}) => (
                                    <Form>
                                        <Field name="phoneNumber">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">Send To</p>
                                                    <PhoneInput
                                                        {...fieldProps.field}
                                                        onBlur={handleBlur}
                                                        inputMode="tel"
                                                        autoComplete="on"
                                                        onChange={(value) => setFieldValue('phoneNumber', value)}
                                                        displayInitialValueAsLocalNumber
                                                        className="ajua-input"
                                                        placeholder="Enter phone number"
                                                        name="phoneNumber"/>
                                                    <ErrorMessage name="phoneNumber">
                                                        {(msg) => (
                                                            <InlineAlert type={'error'} message={msg}/>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                        </Field>

                                        <Field type="text" name="message">
                                            {(fieldProps: FieldProps) => (
                                                <div className="form-group">
                                                    <p className="ajua-form-label">Message</p>
                                                    <div className="notes">
                                                        <IonTextarea {...fieldProps.field}
                                                                     onBlur={handleBlur}
                                                                     onIonChange={handleChange}
                                                                     name="message"
                                                                     className="ajua-textarea"
                                                                     placeholder="Enter your message here"/>
                                                        <ErrorMessage name="message">
                                                            {(msg) => (
                                                                <InlineAlert type={'error'} message={msg}/>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                    <p className="hint">Note: Normal carrier charges apply once you
                                                        deplete your SMS balance.</p>
                                                </div>
                                            )}
                                        </Field>

                                        <button type="submit" onClick={() => {
                                            this.setState({messageChannel: 'sms'})
                                        }}
                                                className="ajua-button sms -full-width -success"
                                                disabled={this.state.sendingSMS || !(isValid && dirty)}>
                                            {!this.state.sendingSMS ?
                                                (<span>Send SMS Message</span>) :
                                                (<IonSpinner color="white" name="crescent"/>)}
                                        </button>
                                        {
                                            this.state.whatsappShare ? (
                                                <>
                                                    <p className="or">OR</p>
                                                    <button type="submit" className="ajua-button whatsapp -full-width"
                                                            onClick={() => {
                                                                this.setState({messageChannel: 'whatsapp'})
                                                            }}
                                                            disabled={!(isValid && dirty)}>
                                                        {!this.state.openingWhatsapp ?
                                                            (
                                                                <>
                                                                    <img src={'assets/icons/whatsapp.png'}/>
                                                                    Send Whatsapp Message
                                                                </>
                                                            ) :
                                                            (<IonSpinner color="white" name="crescent"/>)
                                                        }
                                                    </button>
                                                </>
                                            ) : ''
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <Toast message={toastMessage} open={toastOpen} position="bottom" color={toastColor}
                               closeToast={this.closeToast}/>
                    </div>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(SendMessage);
